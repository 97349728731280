import styled, { css } from "styled-components";

export type StyledMaterialDivProps = {
  isPrefix: boolean;
  isFocused: boolean;
  hasValue: boolean;
};

export const MaterialTextInput = styled.div<StyledMaterialDivProps>`
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    position: absolute;
    left: ${(props) => (props.isPrefix ? "30px" : "16px")};
    line-height: 1.5;
    height: 56px;
    display: block;
    transform: translateY(-10px);
    transition: 300ms ease all;
    pointer-events: none;
    ${({ isFocused, hasValue, theme }) => {
      if (!isFocused && !hasValue)
        return css`
          top: 50%;
          color: ${theme.colors.grey_50};
        `;
      return css`
        top: 16px;
        font-size: 70%;
        color: ${theme.colors.blue_70};
      `;
    }}
    z-index: 10;
  }

  .ant-input-affix-wrapper {
    border: 0;
    outline: 0;
    box-shadow: none;
    padding: 0 16px 0 0;
    background: transparent;
    border-bottom: 1px solid;
    border-bottom-color: ${(props) => props.theme.colors.blue_70};
    border-radius: 0;

    &.ant-input-affix-wrapper-focused {
      background: ${({ theme }) => theme.colors.blue_5};
    }
  }

  .ant-input-affix-wrapper input,
  input {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    display: block;
    padding-top: 16px;
    padding-left: ${(props) => (props.isPrefix ? "30px" : "16px")};
    height: 56px;
    background: transparent;
    border-bottom-color: ${(props) => props.theme.colors.blue_70};

    &:focus {
      outline: none;
      box-shadow: none !important;
    }

    &:focus,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      background: ${({ theme }) => theme.colors.blue_5};
    }
  }
  .bar {
    position: relative;
    z-index: 10;
    display: block;
    width: 100%;
    &:before {
      content: "";
      height: 2px;
      width: ${({ isFocused }) => (isFocused ? "100%" : "0")};
      bottom: 0px;
      position: absolute;
      background: ${(props) => props.theme.colors.blue_70};
      transition: 300ms ease all;
      left: 0%;
    }
  }
`;
