export enum WorkLocation {
  IN_THE_OFFICE = "In the office",
  REMOTE = "Remote",
}

export type DaysData = (string & number)[][];

export type WorkLocationData = { result: DaysData[]; maxValue: number };

export type InsightsItems<T extends string | WorkLocationData | undefined> =
  readonly [T, T, T];

const workLocationData = (locationType: WorkLocation) => {
  const getData = (data: (WorkLocation | DaysData)[][]) => {
    const workData = data
      ?.find((type) => type[0] === locationType)
      ?.filter((x) => typeof x !== "string")[0] as DaysData;
    const daysTotalCount = workData?.map((day) => day[1]) ?? [];
    const maxValue = Math.max(...daysTotalCount);
    const result = workData?.filter((x) => x[1] === maxValue);
    return { result, maxValue };
  };
  return (data: (WorkLocation | DaysData)[][]): WorkLocationData => {
    const { maxValue, result } = getData(data);
    return { result, maxValue };
  };
};

export const remote = workLocationData(WorkLocation.REMOTE);
export const inOffice = workLocationData(WorkLocation.IN_THE_OFFICE);

export const headlines: InsightsItems<string> = [
  "Day with most people in the office",
  "Day with most people remote",
  "Preferred day for on-site: (if included)",
] as const;
