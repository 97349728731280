import { useContext } from "react";
import { Route, RouteProps, Redirect, useRouteMatch } from "react-router-dom";
import { Routes } from "../routes";
import { AuthContext, IAuthContext } from "utils/context/auth";
import { UserRole } from "codegen/generated/graphql";
import usePermission from "components/Permissions/usePermission";

export interface PrivateRouteProps extends RouteProps {
  restrictedTo?: UserRole[];
}

const isUserVerified = (
  user: IAuthContext["user"],
  firebaseUser: IAuthContext["firebaseUser"]
) => user?.isVerified && firebaseUser?.emailVerified;

const hasActiveSubscription = (user: IAuthContext["user"]) => {
  // @TODO - active subscription
  return user && user.account && user.account && user.account;
};

// @TODO - extract this into utils
const isUserAdmin = (user: IAuthContext["user"]) =>
  user?.roles.includes(UserRole.LlunaAdmin);

const PrivateRoute = ({ ...rest }: PrivateRouteProps) => {
  const { user, firebaseUser } = useContext(AuthContext);
  const match = useRouteMatch();

  const { restrictedTo, path } = rest;

  const [isAllowed] = usePermission(restrictedTo ?? []);

  if (!match.path.includes(path as string)) return null;

  if (user && !!restrictedTo && !isAllowed)
    return <Redirect to={Routes.DASHBOARD.path} />;

  console.log("Auth based redirect...", path, user, firebaseUser);
  // If the user is not authenticated with firebase AND is not found in DB in the same time, redirect him to login page
  if (!user && !firebaseUser) return <Redirect to={Routes.LOGIN.path} />;

  if (
    !isUserVerified(user, firebaseUser) &&
    path !== Routes.SIGN_UP_SUCCESS.path
  )
    return <Redirect to={Routes.SIGN_UP_SUCCESS.path} />;

  // Has user a subscribtion?
  if (
    !isUserAdmin(user) &&
    isUserVerified(user, firebaseUser) &&
    user?.account?.isStripeCustomer &&
    !hasActiveSubscription(user)
  )
    return <Redirect to={Routes.SUBSCRIBE.path} />;

  return <Route {...rest} />;
};

export default PrivateRoute;
