import { useContext } from "react";
import PermissionContext from "./PermissionContext";
import { UserRole } from "codegen/generated/graphql";

const usePermission = (roles: UserRole[]) => {
  const { isAllowed } = useContext(PermissionContext);

  return [isAllowed(roles)];
};

export default usePermission;
