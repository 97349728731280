import { RouteType, LayoutDescriptor } from "types/route";
import { UserRole, UserAdditionalPermissions } from "codegen/generated/graphql";
import { noop } from "./utils";

import { adminRoutes } from "./routes.admin";

/*
  TODO::
  We need to employ a nested routes architecture to split the routes definitions
*/

// Keep in-sync with used routes in /packages/api/env.ts
export const AuthRoutes = {
  MARKETING_WEBSITE: {
    path: "/",
    exact: true,
    component: () => import("pages/marketing-website"),
    type: RouteType.Public,
  },
  SIGN_UP: {
    path: "/sign-up",
    component: () => import("pages/sign-up"),
    type: RouteType.Public,
  },
  AUTH: {
    path: "/auth",
    component: () => import("pages/auth"),
    type: RouteType.Plain,
    exact: false,
  },
  FORGOT_PASSWORD: {
    path: "/forgotten-password",
    component: () => import("pages/forgotten-password"),
    type: RouteType.Public,
  },
  LOGIN: {
    path: "/login",
    component: () => import("pages/login"),
    type: RouteType.Public,
  },
  REQUEST_INVITATION: {
    path: "/request-invitation",
    component: () => import("pages/request-invitation"),
    type: RouteType.Public,
  },
  SUBSCRIBE: {
    path: "/subscribe",
    component: () => import("pages/subscribe"),
    exact: false,
    type: RouteType.Plain,
  },
  SIGN_UP_SUCCESS: {
    path: "/sign-up-success",
    component: () => import("pages/sign-up-success"),
    type: RouteType.Private,
  },
};

export const AppRoutes = {
  DASHBOARD: {
    path: "/app/dashboard",
    component: () => import("pages/dashboard"),
    type: RouteType.Private,
  },
  FRAMEWORKS: {
    path: "/frameworks",
    component: noop,
    type: RouteType.Private,
  },
  FRAMEWORK_PREVIEW: {
    path: "/frameworks/preview/:id",
    component: () => import("pages/frameworks/preview"),
    type: RouteType.Private,
  },
  TEAM_PROFILE: {
    path: "/my-team/profile",
    component: () => import("pages/my-team/profile"),
    type: RouteType.Private,
    exact: false,
  },
  TEAM_PROFILE_SUBSCRIPTION: {
    path: "/my-team/profile/subscriptions",
    component: noop,
    type: RouteType.Private,
  },
  MY_PROFILE_ACCOUNT: {
    path: "/my-profile/:id/account",
    component: () => import("pages/my-team/members/member"),
    type: RouteType.Private,
  },
  MY_PROFILE: {
    path: "/my-profile/:id",
    component: () => import("pages/my-team/members/member"),
    type: RouteType.Private,
  },
  MY_PROFILE_REDIRECT: {
    path: "/my-profile",
    component: () => import("pages/my-team/members/member/redirect"),
    exact: true,
    type: RouteType.Private,
  },
  TEAM_PROFILE_ACCOUNT: {
    path: "/my-team/profile/account",
    component: noop,
    type: RouteType.Private,
  },
  TEAM_INTEGRATIONS: {
    path: "/my-team/profile/integrations",
    component: noop,
    type: RouteType.Private,
  },
  TEAM_MEMBERS: {
    path: "/my-team/members",
    component: () => import("pages/my-team/members"),
    restrictedTo: [
      UserRole.PrimaryAdmin,
      UserRole.Admin,
      UserRole.LlunaAdmin,
      UserRole.TeamManager,
    ],
    type: RouteType.Private,
  },
  TEAM_MEMBER_PROFILE: {
    path: "/my-team/members/profile/:id",
    component: () => import("pages/my-team/members/member"),
    type: RouteType.Private,
    exact: false,
  },
  TEAM_MEMBER_WORK_DESIGNS: {
    path: "/my-team/members/profile/:id/work-designs",
    component: noop,
    type: RouteType.Private,
  },
  TEAM_MEMBER_CREATE: {
    path: "/my-team/members/create",
    component: () => import("pages/my-team/members/new-member"),
    type: RouteType.Private,
  },
  FRAMEWORKS_CREATE_NEW: {
    path: "/frameworks/create-new",
    component: () =>
      import("pages/frameworks/createFromScratch/CreateFromScratch"),
    type: RouteType.Private,
  },
  FRAMEWORKS_EDIT: {
    path: "/frameworks/edit/:id",
    component: () =>
      import("pages/frameworks/createFromScratch/CreateFromScratch"),
    type: RouteType.Private,
  },
  FRAMEWORKS_CREATE_NEW_FROM_SCRATCH: {
    path: "/frameworks/create-new/from-scratch/:id",
    component: () =>
      import("pages/frameworks/createFromScratch/CreateFromScratch"),
    type: RouteType.Private,
  },
  FRAMEWORKS_CREATE_NEW_FROM_TEMPLATE: {
    path: "/frameworks/create-new/from-template",
    component: noop,
    type: RouteType.Private,
  },
  FRAMEWORKS_CREATE_NEW_FROM_COLLECTION: {
    path: "/frameworks/create-new/from-collection",
    component: noop,
    type: RouteType.Private,
  },
  FRAMEWORKS_CREATE_NEW_FROM_ARCHIVE: {
    path: "/frameworks/create-new/from-archive",
    component: noop,
    type: RouteType.Private,
  },
  FRAMEWORKS_COLLECTION: {
    path: "/frameworks/collection",
    component: () => import("pages/frameworks/collection"),
    type: RouteType.Private,
  },
  FRAMEWORKS_FEATURES: {
    path: "/frameworks/features",
    component: () => import("pages/frameworks/features"),
    type: RouteType.Private,
  },
  FRAMEWORKS_FEATURE_CREATE_NEW: {
    path: "/frameworks/features/create-new",
    component: () => import("pages/frameworks/features/create-new"),
    type: RouteType.Private,
  },
  FRAMEWORKS_FEATURE_UPDATE: {
    path: "/frameworks/feature/update/:id",
    component: () => import("pages/frameworks/features/update"),
    type: RouteType.Private,
  },
  FRAMEWORKS_NEW_FEATURE: {
    path: "/frameworks/features/new-feature",
    component: noop,
    type: RouteType.Private,
  },
  FRAMEWORKS_DIMENSIONS: {
    path: "/frameworks/dimensions",
    component: () => import("pages/frameworks/dimensions"),
    type: RouteType.Private,
  },
  FRAMEWORKS_NEW_DIMENSION: {
    path: "/frameworks/dimensions/new-dimension",
    component: () => import("pages/frameworks/dimensions/new-dimension"),
    type: RouteType.Private,
  },
  FRAMEWORKS_EDIT_DIMENSION: {
    path: "/frameworks/dimensions/edit-dimension/:id",
    component: () => import("pages/frameworks/dimensions/new-dimension"),
    type: RouteType.Private,
  },
  FRAMEWORKS_TEMPLATES: {
    path: "/frameworks/templates",
    component: () => import("pages/frameworks/templates"),
    type: RouteType.Private,
  },
  FRAMEWORKS_NEW_TEMPLATE: {
    path: "/frameworks/templates/new-template",
    component: () => import("pages/frameworks/templates/new-template"),
    type: RouteType.Private,
  },
  REPORTS: {
    path: "/reports",
    component: () => import("pages/reports"),
    restrictedTo: [
      UserRole.PrimaryAdmin,
      UserRole.Admin,
      UserRole.TeamManager,
      UserRole.TeamMember,
    ],
    type: RouteType.Private,
  },
  HELP_CENTER: {
    path: "/help-center",
    component: () => import("pages/help-center"),
    type: RouteType.Private,
  },
  WORK_DESIGNS: {
    path: "/work-designs",
    component: () => import("pages/work-design/collection"),
    type: RouteType.Private,
  },
  WORK_DESIGN: {
    path: "/my-latest-work-design",
    component: () => import("pages/work-design"),
    type: RouteType.Private,
  },
  WORK_DESIGN_PERSONALIZE: {
    path: "/my-work-design/:id",
    component: () => import("pages/work-design"),
    type: RouteType.Private,
  },
  WORK_DESIGN_PREVIEW: {
    path: "/work-design-preview/:id",
    component: () => import("pages/work-design/preview"),
    type: RouteType.Private,
  },
  POLL: {
    path: "/app/poll",
    component: () => import("pages/poll"),
    type: RouteType.Private,
  },
  CONGRATULATION_MOBILE: {
    path: "/work-design/:id/congratulation",
    component: () => import("pages/dashboard/congratulation"),
    type: RouteType.Private,
    exact: true,
  },
  ...adminRoutes,
};

export const Routes = { ...AppRoutes, ...AuthRoutes };
const StaticRouter: LayoutDescriptor[] = [
  {
    layout: () => import("layout/AuthLayout"),
    routes: Object.values(AuthRoutes),
  },
  {
    layout: () => import("layout/MainLayout"),
    routes: Object.values(AppRoutes),
  },
];

export default StaticRouter;
