import { Checkbox, Row, Col, Typography } from "antd";
import React, { useEffect, useState } from "react";
import TabsBar from "components/TabsBar";
import { ButtonCore, ImgSvg, Loader } from "components";
import * as S from "./styles";
import { ChoicesPlaceholders } from "constants/miscellaneous";
import {
  ChoiceUpdateInput,
  FeatureType,
  Maybe,
  useImpactsQuery,
} from "codegen/generated/graphql";
import close from "assets/icons/close.svg";

type Props = {
  activeItemId: number;
  onChange: (value: ChoiceUpdateInput[]) => void;
  data: ChoiceUpdateInput[];
  featureType: FeatureType;
};

export const ChoicesSidebar: React.FC<Props> = ({
  activeItemId,
  data,
  onChange,
  featureType,
}) => {
  const placeholderOptions = Object.values(ChoicesPlaceholders);

  const [placeholder, setPlaceholder] = useState<Maybe<string> | undefined>();
  const [impacts, setImpacts] = useState<string[]>([]);

  const clonedData = [...data];

  const handlePlaceholderChange = (value?: string) => {
    if (typeof activeItemId === "undefined") return;
    setPlaceholder(value);
    clonedData[activeItemId].placeholderValue = value;
    onChange(clonedData);
  };

  const handleImpactsChange = (values: string[]) => {
    if (typeof activeItemId === "undefined") return;
    setImpacts(values);
    clonedData[activeItemId].impactIds = values;
    onChange(clonedData);
  };

  useEffect(
    () => {
      setPlaceholder(clonedData[activeItemId]?.placeholderValue);
      setImpacts(clonedData[activeItemId]?.impactIds || []);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeItemId]
  );

  const ComponentsContent = () => (
    <>
      <S.SidebarTitle>Placeholders</S.SidebarTitle>
      {featureType === FeatureType.Text ? (
        <S.PlaceholderInfo>
          You have selected open input question type. If you want to add
          components or more choices, change the question type in the previous
          tab to multi-select or multiple choice.
        </S.PlaceholderInfo>
      ) : (
        <S.RadioGroup
          name="ComponentType"
          options={placeholderOptions}
          value={placeholder}
          onChange={(e) => handlePlaceholderChange(e.target.value)}
          optionType="default"
        />
      )}

      {placeholder && (
        <ButtonCore
          style={{
            width: "100%",
          }}
          icon={<ImgSvg alt="" src={close} />}
          onClick={() => handlePlaceholderChange("")}
        >
          Clear placeholder
        </ButtonCore>
      )}
    </>
  );

  const ImpactsContent = () => {
    const { data, loading } = useImpactsQuery();

    return (
      <>
        <S.SidebarTitle>Impacts</S.SidebarTitle>
        {loading && <Loader />}
        {data?.impacts && (
          <Checkbox.Group
            value={impacts}
            name="ImpactType"
            onChange={(e) => handleImpactsChange(e as string[])}
          >
            <Row>
              {data.impacts.map((s) => (
                <Col span={24}>
                  <Checkbox value={s.id}>
                    <Row>{s.title}</Row>
                    <Typography.Text code style={{ fontSize: 10 }}>
                      {s.formula}
                    </Typography.Text>
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        )}
      </>
    );
  };

  const tabs = [
    {
      tab: "Components",
      key: "components",
      tabsContent: <ComponentsContent />,
    },
    {
      tab: "Impact",
      key: "impact",
      tabsContent: <ImpactsContent />,
    },
    /* {
      tab: "Follow Up",
      key: "follow-up",
      disabled: true,
      tabsContent: <></>,
    },*/
  ];

  return <TabsBar style={{ position: "sticky", top: 0 }} tabsOptions={tabs} />;
};

export default ChoicesSidebar;
