import { FC, useMemo, useState } from "react";
import { Col, Input, Row, Space } from "antd";
import Text from "antd/es/typography/Text";
import { useHistory } from "react-router";
import {
  UserRole,
  CurrentAccountUsersDocument,
} from "codegen/generated/graphql";
import { Routes } from "router/routes";
import { UserWithAttributes } from "common/types";
import { ReactComponent as Search } from "assets/icons/search.svg";
import { ButtonTertiary } from "components/Button";
import ContentLayout from "layout/ContentLayout";
import { ContentType } from "layout/ContentLayout/ContentLayout";
import { Icon, Restricted } from "components";
import { useTable } from "hooks/table";
import { useLazyQuery, DocumentNode } from "@apollo/client";

import { TeamMemberList } from "./TeamMemberList";

const TeamMembers: FC<{
  dataKey?: string;
  documentNode?: DocumentNode;
  variables?: any;
}> = ({
  dataKey = "currentAccountUsers",
  documentNode = CurrentAccountUsersDocument,
  variables = {},
}) => {
  const history = useHistory();
  const handleOnRedirect = (to: string) => history.push(to);

  const [searchInputValue, setSearchInputValue] = useState("");
  // const [isActiveFilter, setIsActiveFilter] = useState(false);
  // const [isInactiveFilter, setIsInactiveFilter] = useState(false);
  const [isDisabledFilter, setIsDisabledFilter] = useState(false);

  const fetch = useLazyQuery(documentNode, { variables: variables || {} });

  const {
    tableProps,
    data: { [dataKey]: data } = {},
    selected,
  } = useTable<UserWithAttributes>(fetch, {
    searchInputValue,
    isDisabledFilter,
    key: dataKey + ".users",
  });

  const memberList = useMemo(() => {
    return (
      <TeamMemberList<UserWithAttributes> tableProps={tableProps} data={data} />
    );
  }, [data, tableProps]);

  return (
    <ContentLayout
      header={{
        title: "Team Members",
        actions: (
          <Space size={10}>
            {/*  <ButtonTertiary
              icon={<Icon fontSize={22} name="upload" />}
              transparent
            >
              <Text strong>Bulk Import</Text>
            </ButtonTertiary>*/}
            <Restricted
              to={[UserRole.PrimaryAdmin, UserRole.Admin, UserRole.LlunaAdmin]}
            >
              <ButtonTertiary
                icon={<Icon fontSize={22} name="plus" />}
                onClick={() => handleOnRedirect(Routes.TEAM_MEMBER_CREATE.path)}
              >
                <Text strong>New Member</Text>
              </ButtonTertiary>
            </Restricted>
          </Space>
        ),
      }}
      typeContent={ContentType.WithSidebar}
      sideContent={
        <Row gutter={[0, 24]}>
          <Input
            placeholder="Search by name..."
            size={"large"}
            suffix={<Search />}
            value={searchInputValue}
            onChange={(e) => {
              setSearchInputValue(e.target.value);
            }}
          />

          {/* <Space direction="vertical" size={12}>
            <Col>
              <Text strong>Filter</Text>
            </Col>
            <Row gutter={[0, 8]}>
               <Col span={24}>
                  <Checkbox
                    checked={isActiveFilter}
                    onChange={(e) => setIsActiveFilter(e.target.checked)}
                  >
                    Active
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    checked={isInactiveFilter}
                    onChange={(e) => setIsInactiveFilter(e.target.checked)}
                  >
                    Inactive
                  </Checkbox>
                </Col>
              <Col span={24}>
                <Checkbox
                  checked={isDisabledFilter}
                  onChange={(e) => setIsDisabledFilter(e.target.checked)}
                >
                  Disabled
                </Checkbox>
              </Col>
            </Row>
          </Space>*/}
        </Row>
      }
    >
      <Row>
        <Col>
          <Row gutter={[0, 24]} justify="space-between">
            <Col span={16}>
              <Text>Team Name</Text>
              <Text type="secondary">{` ${data?.total || 0} Members`}</Text>
            </Col>
            {/* <Col>
              <Dropdown
                overlay={() => (
                  <Menu>
                    <Menu.Item key="2">Invite to Framework</Menu.Item>
                    <Menu.Item
                      key="4"
                      onClick={makeToggleUsers(selected, false)}
                    >
                      Disable Profiles
                    </Menu.Item>
                    <Menu.Item
                      key="5"
                      onClick={makeToggleUsers(selected, true)}
                    >
                      Enable Profiles
                    </Menu.Item>
                  </Menu>
                )}
                trigger={["click"]}
              >
                TODO: Change for button component and ensure the styles are matching
                <AntdButton>
                  Bulk Actions ({selected.length} selected)
                </AntdButton>
              </Dropdown>
            </Col> */}
            <Col span={24}>{memberList}</Col>
          </Row>
        </Col>
      </Row>
    </ContentLayout>
  );
};

export default TeamMembers;
