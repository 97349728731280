import { useState } from "react";
import { Wrap, UIProgress, UITitle, Seats, Bagde } from "./PlanProgress.styles";
import { useApplicationStore } from "store/app";

export interface PlanProgressProps {}

const PlanProgress = () => {
  const { user } = useApplicationStore();

  const subscription = user?.account?.subscriptions[0];

  if (!subscription) return null;
  return (
    <Wrap>
      <UITitle>Your Plan</UITitle>
      <Bagde>Essentials</Bagde>
      <UIProgress
        percent={(subscription.seatsTaken / subscription.seats) * 100}
      />
      <Seats>{`${subscription.seatsTaken} / ${subscription.seats} Active Seats`}</Seats>
    </Wrap>
  );
};

export default PlanProgress;
