import { useMemo } from "react";
import { ColumnType } from "antd/lib/table";
import { TableColumnsType } from "antd";

const EMPTY_PLACEHOLDER = "⎻";

type ExtendedColumnType<T extends any> = ColumnType<T> & {
  formatter?: (text: string) => string;
  Wrapper?: React.FC;
};

const createSimpleColumnsHelper = <T extends any = any>(
  definitions: ExtendedColumnType<T>[],
  sharedDefinitions: Partial<ExtendedColumnType<T>> = {}
) => {
  const components: TableColumnsType<any> = [];
  for (const def of definitions) {
    const Wrapper = def.Wrapper || sharedDefinitions.Wrapper;
    delete def.Wrapper;
    delete sharedDefinitions.Wrapper;

    const key = (
      def.key
        ? def.key
        : Array.isArray(def.dataIndex)
        ? def.dataIndex.join(".")
        : def.dataIndex
    ) as string;

    components.push({
      render: (text) => {
        const formatted = (def.formatter || ((a) => a))(text);
        return Wrapper
          ? Wrapper({ children: formatted || EMPTY_PLACEHOLDER })
          : formatted || EMPTY_PLACEHOLDER;
      },
      ...sharedDefinitions,
      ...def,
      key,
    });
  }

  return components;
};

export const useColumns = <T extends any = any>(
  definitions: ExtendedColumnType<T>[],
  sharedDefinitions: Partial<ExtendedColumnType<T>> = {}
) => {
  const columns = useMemo(
    () => createSimpleColumnsHelper(definitions, sharedDefinitions),
    [definitions, sharedDefinitions]
  );

  return columns;
};
