import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { AdminMenu, TeamManagerMenu, TeamMemberMenu } from "config/menu";

import * as S from "./sidebar.styles";
import { AuthContext } from "utils/context/auth";
import {
  useMyWorkDesignCollectionQuery,
  UserRole,
  WdStatus,
} from "codegen/generated/graphql";
import { Icon } from "../Icon";
import { useReactiveVar } from "@apollo/client";
import { selectedRoleVar } from "store/app";
import { generatePath } from "react-router";
import PlanProgress from "./PlanProgress";
import { Routes } from "router/routes";

const Sidebar: FC = () => {
  // While adding new menu items, be careful about key numbers, they must be incremented by the one compared to
  // previous menu item in order to follow AntdDesign api rules(even in submenu)

  const [selectedKey, setSelectedKey] = useState("Home");
  const history = useHistory();

  const { data, loading } = useMyWorkDesignCollectionQuery();

  const pendingWorkDesign = data?.me?.workDesigns.some((wd) =>
    wd.status.includes(WdStatus.Pending)
  );

  const { user } = useContext(AuthContext);

  const userIsAdmin = user?.roles.includes(UserRole.LlunaAdmin);
  const userRoleIsAdmin = user?.roles.includes(UserRole.Admin);
  const userIsPrimaryAdmin = user?.roles.includes(UserRole.PrimaryAdmin);
  const userIsTeamManager = user?.roles.includes(UserRole.TeamManager);
  const userIsTeamMember = user?.roles.includes(UserRole.TeamMember);

  const createWDDisabled = (isCreateWorkDesignItem: boolean) => {
    if (loading) return true;
    if (!pendingWorkDesign && isCreateWorkDesignItem) return true;
    if (
      userIsPrimaryAdmin ||
      userIsAdmin ||
      userIsTeamManager ||
      userRoleIsAdmin
    )
      return false;
    if ((!userIsTeamManager && pendingWorkDesign) || isCreateWorkDesignItem)
      return false;
    return true;
  };

  const selectedRole = useReactiveVar(selectedRoleVar);

  const getMenuConfig = useCallback(() => {
    if (userIsAdmin) return AdminMenu;
    if (
      userIsPrimaryAdmin ||
      userIsTeamManager ||
      userIsTeamMember ||
      userRoleIsAdmin
    ) {
      return selectedRole?.id === UserRole.TeamMember
        ? TeamMemberMenu(user)
        : TeamManagerMenu;
    }
    return TeamMemberMenu(user);
  }, [
    selectedRole?.id,
    userIsAdmin,
    userIsPrimaryAdmin,
    userRoleIsAdmin,
    userIsTeamManager,
    userIsTeamMember,
    user,
  ]);

  const menu = getMenuConfig().filter((item) => item !== undefined);

  const { pathname } = useLocation();

  useEffect(() => {
    // @ts-ignore
    menu.some(({ path, key, submenu }) => {
      const checkSubmenu = () =>
        submenu?.some((subItem: any) => {
          if (subItem.path === pathname) {
            setSelectedKey(subItem.key);
            return true;
          }
          return false;
        });

      if (typeof path !== "string") return false;
      const rootPath = (path as string).replace("/:id", "");

      if (
        (!!path && path === pathname) ||
        (!!path && pathname.includes(rootPath))
      ) {
        if (checkSubmenu()) return true;
        setSelectedKey(key);
        return true;
      } else return checkSubmenu();
    });
  }, [menu, pathname]);

  const handleMenuClick = (path: string) => {
    history.replace(path);
  };

  return (
    <S.UISidebar>
      <S.UIMenu
        mode="inline"
        defaultSelectedKeys={["home"]}
        selectedKeys={[selectedKey]}
      >
        {menu.map(
          // @ts-ignore
          ({ key, title, path, activeIcon, inactiveIcon, submenu, target }) => {
            const isSelected = selectedKey.split("/")?.[0] === key;

            // Logic below because we want to be redirected on profile member page from sidebar (id in url needed)
            let myProfilePath: string | null = null;
            if (key === "my-profile" && user) {
              myProfilePath = generatePath(path as string, { id: user?.id });
            }
            if (!submenu)
              return (
                <S.UIMenuItem
                  key={key}
                  $active={isSelected}
                  icon={<Icon name={isSelected ? activeIcon : inactiveIcon} />}
                >
                  <Link to={myProfilePath ?? path} target={target}>
                    {title}
                  </Link>
                </S.UIMenuItem>
              );
            return (
              <S.UISubMenu
                key={title}
                icon={<Icon name={isSelected ? activeIcon : inactiveIcon} />}
                title={title}
                onTitleClick={() => !!path && handleMenuClick(path as string)}
              >
                {submenu.map((subItem: any) => (
                  <S.UIMenuItem
                    key={subItem.key}
                    disabled={createWDDisabled(
                      subItem.path === Routes.WORK_DESIGN.path
                    )}
                  >
                    <Link to={subItem.path}>{subItem.title}</Link>
                  </S.UIMenuItem>
                ))}
              </S.UISubMenu>
            );
          }
        )}
      </S.UIMenu>
      {/* TODO: Will be part of next versions */}
      {userIsTeamManager && <PlanProgress />}
    </S.UISidebar>
  );
};

export default Sidebar;
