import React, { ReactNode, useCallback, useState } from "react";
import { Form, FormProps } from "antd";
import Mindset from "./mindset";

import * as S from "./styles";
import FrameworkOverview from "./FrameworkOverview";
import {
  ButtonPrimary,
  ButtonTertiary,
  FeatureCard,
  Icon,
  TabsBar,
  useWorkDesignNavigation,
  WorkDesignView,
} from "components";
import Text from "antd/lib/typography/Text";
import {
  FeatureType,
  useCalculateImpactsLazyQuery,
  useMyWorkDesignCollectionQuery,
  useSubmitWorkDesignMutation,
  WdStatus,
} from "codegen/generated/graphql";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Routes } from "router/routes";
import { useHistory, useParams } from "react-router-dom";
import {
  getMutationConfig,
  TABS,
  WORK_DESIGN_FORM_LIST_NAME,
  WORK_DESIGN_FORM_NAME,
  WorkDesignRoutes,
} from "./constants";
import ContentLayout from "layout/ContentLayout";
import { ContentType } from "layout/ContentLayout/ContentLayout";
import FormList from "antd/es/form/FormList";

const WorkDesign: React.FC = () => {
  const [activeTab, setActiveTab] = useState(TABS[0]);

  const { sm } = useBreakpoint();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const mutationConfig = getMutationConfig(() =>
    history.replace(
      !sm ? Routes.CONGRATULATION_MOBILE.path : Routes.WORK_DESIGNS.path
    )
  );
  const { data } = useMyWorkDesignCollectionQuery();

  const [submit] = useSubmitWorkDesignMutation(mutationConfig);

  const latestWD = data?.me?.workDesigns.find((wd) => {
    if (id) {
      return wd.id === id;
    } else {
      return wd.status === WdStatus.Pending;
    }
  });
  const dimensions = latestWD?.dimensions ?? [];
  const framework = latestWD?.framework;

  const [
    fetchCalculatedImpacts,
    { data: calculatedImpacts, loading: loadingCalculatedImpacts },
  ] = useCalculateImpactsLazyQuery();

  const {
    WDNavigation,
    currentFeature,
    allFeatures,
    setCompletedFeatureIds,
    completedFeatureIds,
  } = useWorkDesignNavigation(dimensions);

  const handleFormChange = useCallback(
    (_, values: any) => {
      const completedIds = Object.entries(values?.[WORK_DESIGN_FORM_LIST_NAME])
        .filter(([, value]) => (Array.isArray(value) ? value.length : value))
        .map(([key]) => key);
      setCompletedFeatureIds(completedIds);
    },
    [setCompletedFeatureIds]
  );

  const [form] = Form.useForm();

  const handleImpactsReload = useCallback(
    (...args: Parameters<NonNullable<FormProps["onFieldsChange"]>>) => {
      const [changedFields] = args;

      // We want to recalculate impacts only when the target feature has impactful choice
      if (
        changedFields.every((field) =>
          [field.value]
            .flat(2)
            .some((choiceId) =>
              currentFeature?.choices.find(
                (ch) => ch.id === choiceId && ch.placeholderText
              )
            )
        )
      )
        // Fetch the data for impacts
        fetchCalculatedImpacts({
          variables: {
            workDesignId: latestWD?.id || "",
            responses: {
              choices: form.getFieldValue(WORK_DESIGN_FORM_LIST_NAME),
            },
          },
        });
    },
    [currentFeature, fetchCalculatedImpacts, form, latestWD?.id]
  );

  if (!latestWD)
    return <S.NoWorkDesign>There is no Work Design to complete</S.NoWorkDesign>;

  const onSubmit = () => {
    if (!latestWD) return;
    const values = form.getFieldsValue([
      WORK_DESIGN_FORM_LIST_NAME,
      "primaryMindset",
      "secondaryMindset",
    ]);
    submit({
      variables: {
        responses: {
          choices: values[WORK_DESIGN_FORM_LIST_NAME],
          primaryMindset: values.primaryMindset && values.primaryMindset.id,
          ...(values.secondaryMindset
            ? {
                secondaryMindset:
                  values.secondaryMindset && values.secondaryMindset.id,
              }
            : {}),
        },
        workDesignId: latestWD?.id,
      },
    });
  };

  const isMobile = !sm;

  if (!allFeatures?.length) return null;

  const Overview = (
    <FrameworkOverview
      primaryMindset={form.getFieldValue("primaryMindset")}
      secondaryMindset={form.getFieldValue("secondaryMindset")}
      startDate={framework?.startDate}
      endDate={framework?.endDate}
      title={framework?.title}
      dimensions={dimensions}
      completedFeatureIds={completedFeatureIds}
      calculatedImpacts={calculatedImpacts?.calculateImpacts || {}}
      isCalculationInProgress={loadingCalculatedImpacts}
    />
  );

  const hasSidebar = !isMobile && activeTab !== "Mindset";

  const selectedChoicesSet = new Set(
    Object.values(
      form.getFieldValue(WORK_DESIGN_FORM_LIST_NAME as any) || {}
    ).flat()
  );

  // Check if non optional features are filled out
  const isEveryNonOptionalFeaturesFilled = allFeatures
    .filter((f) => f.type !== FeatureType.Text)
    .every((f) => f.choices.some((ch) => selectedChoicesSet.has(ch.id)));

  const renderPage = (page: ReactNode) => {
    const NavigationPanel = activeTab === "Features" && (
      <WDNavigation
        setActiveTab={setActiveTab}
        canNavigate={isEveryNonOptionalFeaturesFilled}
      />
    );

    return () => (
      <ContentLayout
        isWDPreview={true}
        typeContent={
          hasSidebar ? ContentType.WithSidebar : ContentType.FullWidth
        }
        sideContent={Overview}
      >
        {page}
        {isMobile && activeTab !== "Mindset" && (
          <S.Footer>
            {isMobile && Overview}
            <S.FooterBG>
              {NavigationPanel}
              {activeTab === "Summary" && (
                <ButtonPrimary
                  onClick={onSubmit}
                  icon={<Icon name="checkmark" />}
                  //disabled={}
                >
                  <Text strong>Submit Work Design</Text>
                </ButtonPrimary>
              )}
            </S.FooterBG>
          </S.Footer>
        )}
        {!isMobile && NavigationPanel}
      </ContentLayout>
    );
  };

  const renderMindset = renderPage(
    <Mindset
      framework={framework}
      onComplete={({ primary, secondary }) => {
        form.setFieldsValue({
          primaryMindset: primary,
          secondaryMindset: secondary,
        });
        setActiveTab(WorkDesignRoutes.Features);
      }}
      currentMindset={{
        primary: form.getFieldValue("primaryMindset"),
        secondary: form.getFieldValue("secondaryMindset"),
      }}
    />
  );

  const renderFeatures = renderPage(
    <S.Wrapper>
      <FormList name={WORK_DESIGN_FORM_LIST_NAME}>
        {() => (
          <FeatureCard
            {...currentFeature}
            isMobile={isMobile}
            isFromCreateFrameWork={false}
          />
        )}
      </FormList>
    </S.Wrapper>
  );

  const renderSummary = renderPage(
    <WorkDesignView
      dimensions={dimensions}
      values={form.getFieldsValue()[WORK_DESIGN_FORM_LIST_NAME]}
    />
  );

  const tabBarExtraContent = {
    right: activeTab === "Summary" && (
      <ButtonTertiary onClick={onSubmit} icon={<Icon name="checkmark" />}>
        <Text strong>Submit Work Design</Text>
      </ButtonTertiary>
    ),
  };

  return (
    <S.FormWrapper
      form={form}
      name={WORK_DESIGN_FORM_NAME}
      onValuesChange={handleFormChange}
      onFieldsChange={handleImpactsReload}
    >
      <TabsBar<WorkDesignRoutes>
        style={{ height: "100%" }}
        activeKey={activeTab}
        onChange={setActiveTab}
        tabsOptions={[
          { tab: WorkDesignRoutes.Mindset, tabsContent: renderMindset() },
          { tab: WorkDesignRoutes.Features, tabsContent: renderFeatures() },
          {
            tab: WorkDesignRoutes.Summary,
            tabsContent: renderSummary(),
            disabled: !isEveryNonOptionalFeaturesFilled,
          },
        ]}
        tabBarExtraContent={tabBarExtraContent}
      />
    </S.FormWrapper>
  );
};

export default WorkDesign;
