import { VFC } from "react";
import * as S from "./styles";
import Image from "components/Image";
import warningImg from "assets/icons/warning.svg";

export const NoData: VFC = () => (
  <S.NoDataWrap>
    <S.Warning>
      <Image src={warningImg} style={{ width: "60px" }} />
    </S.Warning>
    <S.NoDataTitle>No data available yet</S.NoDataTitle>
  </S.NoDataWrap>
);
