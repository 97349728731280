import { Modal, Row, Col, Typography } from "antd";
import Link from "antd/es/typography/Link";
import { ButtonTertiary } from "components";

const { Text } = Typography;

const IntegrationModal = ({
  isIntegrationModalVisible,
  setShowIntegrationModal,
}: {
  isIntegrationModalVisible: boolean;
  setShowIntegrationModal: (visible: boolean) => void;
}) => {
  return (
    <Modal
      visible={isIntegrationModalVisible}
      footer={null}
      style={{
        overflow: "hidden",
        borderRadius: "7px",
        textAlign: "center",
        padding: 0,
      }}
      centered
      closable={false}
      width={500}
    >
      <Row style={{ marginTop: "1rem" }}>
        <Col span={24}>
          <Text>
            Integrations are not available on your current subscription. Please
            contact{" "}
            <Link
              onClick={() =>
                (window.location.href = "mailto: sales@hellolluna.com")
              }
            >
              sales@hellolluna.com
            </Link>{" "}
            for more information and to review your integration options
          </Text>
        </Col>
        <Col
          span={24}
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ButtonTertiary onClick={() => setShowIntegrationModal(false)}>
            <Text strong>Ok</Text>
          </ButtonTertiary>
        </Col>
      </Row>
    </Modal>
  );
};

export default IntegrationModal;
