import styled from "styled-components";

export const Cursor = styled.p`
  cursor: pointer;
  padding: 10px 0;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.1);
  }
`;

export const position = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 1rem;
`;
