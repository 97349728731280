const createUrlBuilder = () => {
  const clone = (base: URL | string) => new URL(base.toString());
  const base = process.env.REACT_APP_API_URI || "http://localhost:4000/api";

  return (controller: string) => {
    const controllerUrl = clone(`${base}/${controller}`);

    return {
      build: () => {
        const url = clone(controllerUrl.toString());

        const api = {
          appendQueryParam: (key: string, value: string) => {
            url.searchParams.append(key, value);
            return api;
          },
          appendPathname: (value: string) => {
            url.pathname += value;
            return api;
          },
          end: () => url.toString(),
        };

        return api;
      },
    };
  };
};

const builder = createUrlBuilder();

export const API_ENDPOINTS = {
  exportXLSX: builder("export"),
};
