import React from "react";

import * as S from "./styles";
type Props = {
  background: string;
  color: string;
  label: string;
};

const DimensionTag: React.FC<Props> = ({
  background,
  color,
  label,
  ...props
}) => (
  <S.Tag background={background} {...props}>
    <S.Label color={color}>{label}</S.Label>
  </S.Tag>
);

export default DimensionTag;
