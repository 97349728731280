import { noop } from "./utils";
import { RouteType } from "types/route";

export const adminRoutes = {
  ADMIN_ACCOUNTS: {
    path: "/account",
    component: () => import("pages/admin/accounts"),
    type: RouteType.Private,
    exact: false,
  },
  ADMIN_ACCOUNT_CREATE: {
    path: "/account/create",
    component: noop,
    type: RouteType.Private,
    exact: false,
  },
  ADMIN_ACCOUNT_USER_LIST: {
    path: "/account/:id",
    component: noop,
    type: RouteType.Private,
    exact: false,
  },
  ADMIN_ACCOUNT_USER_ADD: {
    path: "/account/:id/add",
    component: noop,
    type: RouteType.Private,
    exact: false,
  },
};
