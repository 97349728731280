import { colors } from "theme";

export const DIMENSION_COLORS = [
  {
    color: colors.blue_100,
    background: colors.blue_20,
  },
  {
    color: colors.gold_100,
    background: colors.gold_10,
  },
  {
    color: colors.magenta_80,
    background: colors.magenta_10,
  },
  {
    color: colors.teal_80,
    background: colors.teal_10,
  },
  {
    color: "#1B3118",
    background: "#E3FFE0",
  },
];
