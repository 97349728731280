import { Routes } from "router/routes";
import { MenuItem } from "types/menu";

export const menuItem = (def: MenuItem): MenuItem => {
  return def;
};

export const HELP_CENTER_URI = "https://help.hellolluna.com";

export const DASHBOARD = menuItem({
  key: "home",
  title: "Home",
  activeIcon: "panelsYellow",
  inactiveIcon: "panelsGrey",
  path: Routes.DASHBOARD.path,
});

export const HELP_CENTER = menuItem({
  key: "help-center",
  title: "Help Center",
  activeIcon: "resourcesYellow",
  inactiveIcon: "resourcesGrey",
  path: { pathname: HELP_CENTER_URI },
  target: "_blank",
});
