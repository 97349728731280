import { Popover } from "antd";
import { NoData } from "components/Charts/NoData";
import { useFetchFWStatus } from "hooks/useFetchFWStatus";
import { DashboardCard } from "pages/dashboard/components/DashboardCard";
import { Fragment, useRef, VFC } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { toPng, toJpeg } from "html-to-image";

import {
  DaysData,
  headlines,
  inOffice,
  InsightsItems,
  remote,
  WorkLocation,
  WorkLocationData,
} from "./insightsData";
import * as S from "./styles";

type InsightsRowType = {
  headline: string;
  daysData?: DaysData[];
  maxValue?: number;
};

type Props = {
  data: (WorkLocation | DaysData)[][];
  frameworkId?: string;
};
export const FrameworkInsights: VFC<Props> = ({ data, frameworkId }) => {
  const myRef = useRef<any>(null);

  const { wdCount } = useFetchFWStatus(frameworkId);

  const maxOfficeDays = inOffice(data);
  const maxRemoteDays = remote(data);

  const daysData: InsightsItems<WorkLocationData | undefined> = [
    maxOfficeDays,
    maxRemoteDays,
    undefined,
  ] as const;

  const locationData: InsightsRowType[] = headlines.map((headline, i) => {
    const { result, maxValue } = daysData[i] || {};
    return {
      headline: headline,
      daysData: result?.map((x) => x[0]),
      maxValue: maxValue ?? 0,
    };
  });

  const isData = !!data.length;
  const downloadGraphImage = (imagetype: any) => {
    if (imagetype === "png") {
      toPng(myRef.current, { backgroundColor: "#FFF" })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "BarChart.png";
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (imagetype === "jpeg") {
      toJpeg(myRef.current, { backgroundColor: "#FFF" })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "BarChart.jpeg";
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const content = (
    <div>
      <S.Cursor onClick={() => downloadGraphImage("jpeg")}>
        Download JPEG
      </S.Cursor>
      <S.Cursor onClick={() => downloadGraphImage("png")}>
        Download PNG
      </S.Cursor>
    </div>
  );
  return (
    <S.FrameworkInsights ref={myRef}>
      <DashboardCard title="Schedule Insights" icon="reportsGrey">
        <S.position>
          <Popover
            placement="bottom"
            content={content}
            overlayInnerStyle={{ maxWidth: "fit-content" }}
          >
            <MenuOutlined />
          </Popover>
        </S.position>
        <S.InsightsWrap isData={isData}>
          {isData ? (
            locationData?.map((item, i) => (
              <Fragment key={item.headline}>
                <div>
                  <S.InsightsHeadline>{item.headline}</S.InsightsHeadline>
                  {item?.daysData?.map((x, i) => (
                    <S.Day key={String(x)}>
                      {`${x}${item?.daysData?.length === i + 1 ? "" : ", "}`}
                    </S.Day>
                  ))}
                  <S.OutOf
                    noVotes={item.maxValue === 0}
                  >{`(${item.maxValue} out of ${wdCount})`}</S.OutOf>
                </div>
                {locationData.length - 1 > i && <S.Hr />}
              </Fragment>
            ))
          ) : (
            <NoData />
          )}
        </S.InsightsWrap>
      </DashboardCard>
    </S.FrameworkInsights>
  );
};
