import { useState, useMemo, useEffect, useRef } from "react";
import { TeamMemberList } from "pages/my-team/members/TeamMemberList";
import { useParams, useHistory, generatePath } from "react-router-dom";
import {
  AccountsDocument,
  Account,
  AccountUsersDocument,
  useUpdateLlunaAccountMutation,
} from "codegen/generated/graphql";
import { Routes } from "router/routes";
import ContentLayout from "layout/ContentLayout";
import { ContentType } from "layout/ContentLayout/ContentLayout";
import { useTable } from "hooks/table";
import { useLazyQuery } from "@apollo/client";
import { UserWithAttributes } from "common/types";
import { Input, Row, Space, Typography, Col, Switch, notification } from "antd";
import { ButtonTertiary, Icon } from "components";
import * as S from "./AccountUserList.styles";
const { Text, Title } = Typography;

export const AccountUserList = () => {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();
  const handleOnRedirect = (to: string) => history.push(to);

  const [searchInputValue, setSearchInputValue] = useState("");
  const [hrisIntegrationChecked, setHrisIntegrationChecked] = useState(false);
  const [collaborationToolsChecked, setCollaborationToolsChecked] =
    useState(false);

  const fetchAccounts = useLazyQuery(AccountsDocument);

  const { data: { accounts: accountsData = [] } = {} } = useTable<Account>(
    fetchAccounts,
    {
      key: "accounts",
    }
  );

  const fetch = useLazyQuery(AccountUsersDocument);

  const { tableProps, data: { accountUsers: data } = {} } =
    useTable<UserWithAttributes>(fetch as any, {
      searchInputValue,
      key: "accountUsers.users",
      additionalVariables: {
        accountId: id,
      },
    });

  const memberList = useMemo(() => {
    return (
      <TeamMemberList<UserWithAttributes> tableProps={tableProps} data={data} />
    );
  }, [data, tableProps]);

  const onHrisIntegrationChange = (checked: boolean) => {
    setHrisIntegrationChecked(checked);
    handleAccountUpdate(checked, collaborationToolsChecked);
  };

  const onCollaborationToolsChange = (checked: boolean) => {
    setCollaborationToolsChecked(checked);
    handleAccountUpdate(hrisIntegrationChecked, checked);
  };

  const [updateLlunaAccount] = useUpdateLlunaAccountMutation({
    onCompleted: () => {
      notification.success({
        type: "success",
        message: `Account was updated`,
        placement: "bottomLeft",
      });
    },
    onError: () => {
      notification.error({
        type: "warning",
        message: `Something went wrong`,
        placement: "bottomLeft",
      });
    },
  });

  const handleAccountUpdate = (
    isHrisIntegrationChecked: boolean,
    isCollaborationToolsChecked: boolean
  ) => {
    updateLlunaAccount({
      variables: {
        input: {
          accountId: id,
          integrationHRIS: isHrisIntegrationChecked,
          integrationCollaboration: isCollaborationToolsChecked,
        },
      },
    });
  };

  useEffect(() => {
    if (accountsData && accountsData?.length) {
      const accountItem: any = accountsData.find((item: any) => item.id === id);
      setHrisIntegrationChecked(accountItem?.integrationHRIS);
      setCollaborationToolsChecked(accountItem?.integrationCollaboration);
    } // eslint-disable-next-line
  }, [id, accountsData]);

  return (
    <ContentLayout
      header={{
        title: "Account Info",
      }}
      typeContent={ContentType.FullWidth}
    >
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <S.Wrapper>
            <Col span={12}>
              <Text>HRIS Integration</Text>
              <Space size={10} style={{ marginLeft: "1rem" }}>
                <Switch
                  checked={hrisIntegrationChecked}
                  onChange={onHrisIntegrationChange}
                />
              </Space>
            </Col>
            <Col span={12}>
              <Text>Collaboration Tools Integration</Text>
              <Space size={10} style={{ marginLeft: "1rem" }}>
                <Switch
                  checked={collaborationToolsChecked}
                  onChange={onCollaborationToolsChange}
                />
              </Space>
            </Col>
          </S.Wrapper>
        </Col>
        <Col span={12}>
          <Title level={3}>Users</Title>
        </Col>
        <Col span={12} style={{ display: "flex", justifyContent: "right" }}>
          <ButtonTertiary
            icon={<Icon fontSize={22} name="plus" />}
            onClick={() =>
              handleOnRedirect(
                generatePath(Routes.ADMIN_ACCOUNT_USER_ADD.path, { id })
              )
            }
          >
            Add User
          </ButtonTertiary>
        </Col>

        <Input
          placeholder="Search by name..."
          size={"large"}
          value={searchInputValue}
          onChange={(e) => {
            setSearchInputValue(e.target.value);
          }}
        />
      </Row>
      {memberList}
    </ContentLayout>
  );
};
