import React from "react";
import theme from "../../theme";
import { TagAttributes } from "../../common/types";
import { useTagAttributes } from "../../utils/hooks";
import { CommonTag } from "./commonTag";

// TODO: import real one enum from generated types and change the keys in the config Map
export enum InvoiceStatus {
  OUTSTANDING,
  SENT,
  PAID,
  OVERDUE,
}
type Props = {
  status: InvoiceStatus;
};

const baseAttributes = {
  isRound: false,
  fontWeight: 600,
  textColor: theme.colors.blue_100,
};

const attributesMap = new Map<InvoiceStatus, TagAttributes>([
  [
    InvoiceStatus.OUTSTANDING,
    { text: "Outstanding", bgColor: theme.colors.grey_20, ...baseAttributes },
  ],
  [
    InvoiceStatus.SENT,
    { text: "Sent", bgColor: theme.colors.yellow_10, ...baseAttributes },
  ],
  [
    InvoiceStatus.PAID,
    { text: "Paid", bgColor: theme.colors.green_10, ...baseAttributes },
  ],
  [
    InvoiceStatus.OVERDUE,
    { text: "Overdue", bgColor: theme.colors.red_20, ...baseAttributes },
  ],
]);

export const InvoiceStatusTag: React.FC<Props> = (props) => {
  const { status } = props;

  const attributes = useTagAttributes(attributesMap, status);

  return <CommonTag tagAttributes={attributes} />;
};
