import { Row, Col } from "antd";
import { useState } from "react";
import { Plan } from "types/plan";
import { PlanItem } from "./PlanItem";

type SelectPlanProps = {
  plans: Plan[];
};

export const SelectPlan: React.FC<SelectPlanProps> = ({ plans }) => {
  const [active, setActive] = useState<Plan | null>(
    plans.find((plan) => plan.default) || null
  );

  return (
    <Row gutter={[18, 18]}>
      {plans.map((plan, idx) => (
        <Col xs={24} lg={12} style={{ display: "flex" }} key={plan.label}>
          <PlanItem
            plan={plan}
            onPlanSelect={setActive}
            isActive={plan === active}
          />
        </Col>
      ))}
    </Row>
  );
};
