import { VFC } from "react";
import { Wrap } from "./Account.styles";
import AccountDetail from "./AccountDetailsForm";
import { AccountPasswordChange } from "./AccountPasswordChange";

const Account: VFC = () => {
  return (
    <Wrap>
      <AccountDetail />
      <AccountPasswordChange />
    </Wrap>
  );
};

export default Account;
