import React from "react";
import { Col, Form } from "antd";
import { FeatureCard } from "components";
import { DimensionDataFragment } from "codegen/generated/graphql";

import { useFeatureContext } from "../context";

interface Props {
  selectedDimension?: DimensionDataFragment;
}
export const Preview: React.FC<Props> = ({ selectedDimension }) => {
  const {
    state: {
      data: { text, type, choices },
    },
  } = useFeatureContext();
  return (
    <Form>
      <Col span={16}>
        <FeatureCard
          id="preview"
          text={text}
          type={type}
          dimension={selectedDimension}
          choices={choices}
        />
      </Col>
    </Form>
  );
};

export default Preview;
