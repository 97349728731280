import { FC, useMemo } from "react";
import { Col, Row, Space } from "antd";
import { generatePath, Link, useHistory } from "react-router-dom";
import Text from "antd/es/typography/Text";
import { AccountsDocument, Account } from "codegen/generated/graphql";
import { Routes } from "router/routes";
import { ButtonTertiary } from "components/Button";
import ContentLayout from "layout/ContentLayout";
import { ContentType } from "layout/ContentLayout/ContentLayout";
import { Icon, Table } from "components";
import { useColumns, useTable } from "hooks/table";
import { useLazyQuery } from "@apollo/client";

const AccountList: FC = () => {
  const history = useHistory();

  const fetch = useLazyQuery(AccountsDocument);

  const { tableProps, data: { accounts: data = [] } = {} } = useTable<Account>(
    fetch,
    {
      key: "accounts",
    }
  );

  const columns = useColumns<Account>(
    [
      {
        title: "Account name",
        dataIndex: "name",
        onFilter: (a, b) => {
          return b.name === a;
        },
        filterSearch: true,
        filterMode: "menu",
        filters: data.map((item: Account) => ({
          text: item.name,
          value: item.name,
        })),
      },
      {
        title: "Email",
        dataIndex: "users.0.email".split("."),
      },
      {
        title: "Manager name",
        dataIndex: "users.0.profile.fullName".split("."),
      },
    ],
    {
      Wrapper: ({ children }) => <Text type="secondary">{children}</Text>,
    }
  );

  const membersTableRenderer = useMemo(() => {
    return (
      <Table
        {...tableProps}
        columns={columns}
        onRow={(data) => ({
          onClick() {
            history.push(
              generatePath(Routes.ADMIN_ACCOUNT_USER_LIST.path, { id: data.id })
            );
          },
        })}
        size="small"
        rowKey={"id"}
        rowSelection={undefined}
        locale={{
          emptyText: <Text type="secondary">No accounts found</Text>,
        }}
      />
    );
  }, [columns, history, tableProps]);

  return (
    <ContentLayout
      header={{
        title: "Lluna Accounts",
        actions: (
          <Space size={10}>
            <Link to={Routes.ADMIN_ACCOUNT_CREATE.path}>
              <ButtonTertiary icon={<Icon fontSize={22} name="plus" />}>
                <Text strong>New account</Text>
              </ButtonTertiary>
            </Link>
          </Space>
        ),
      }}
      typeContent={ContentType.FullWidth}
    >
      <Row>
        <Col span={24}>
          <Row gutter={[0, 24]} justify="space-between">
            <Col span={24}>{membersTableRenderer}</Col>
          </Row>
        </Col>
      </Row>
    </ContentLayout>
  );
};

export default AccountList;
