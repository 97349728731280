import React from "react";
import styled from "styled-components";
import Text, { TextProps } from "antd/lib/typography/Text";
import theme from "theme";

type STextCustomProps = {
  textColor?: "red" | "green" | "grey";
};
type STextProps = TextProps & STextCustomProps;
const STextCustomPropNames = new Set(["textColor"]);

const handleTextColor = (textColor?: "red" | "green" | "grey") => {
  switch (textColor) {
    case "red":
      return theme.colors.red_40;
    case "green":
      return theme.colors.green_40;
    case "grey":
      return theme.colors.grey_70;
    default:
      return theme.colors.grey_70;
  }
};

export const SText = styled<React.FC<STextProps>>(Text).withConfig({
  shouldForwardProp: (prop) => !STextCustomPropNames.has(prop),
})<{ textColor?: "red" | "green" | "grey" }>`
  color: ${({ textColor }) => handleTextColor(textColor)};
`;
