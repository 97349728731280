import React from "react";
import blueWaveSvg from "assets/images/blueWave.svg";
import { BackgroundContainer } from "components/BackgroundContainer";
import { ResetPasswordForm } from "components/Forms";
import { confirmPasswordResetLink } from "utils/firebase";
import { ContainerWithImage } from "components/ContainerWithImage";

const ResetPassword: React.FC = () => {
  return (
    <BackgroundContainer>
      <ContainerWithImage
        leftColMarginTop="210px"
        image={{ src: blueWaveSvg, alt: "blue-wave" }}
      >
        <ResetPasswordForm
          confirmPasswordResetLink={confirmPasswordResetLink}
        />
      </ContainerWithImage>
    </BackgroundContainer>
  );
};

export default ResetPassword;
