import { useContext } from "react";
import { Redirect, Route, RouteProps, useRouteMatch } from "react-router-dom";
import { AuthContext } from "utils/context/auth";

import { Routes } from "../routes";

const PublicRoute = (props: RouteProps) => {
  const { user } = useContext(AuthContext);
  const match = useRouteMatch();

  if (match.path !== props.path) return null;

  // If the user is authenticated, redirect him to the corresponding page
  if (user) {
    return <Redirect to={Routes.DASHBOARD.path} />;
  }

  return <Route {...props} />;
};

export default PublicRoute;
