import styled from "styled-components";

export const UIWrapper = styled.div<{
  width?: string;
  bgImage?: string;
  bgColor?: string;
  color?: string;
  height?: string;
  radius?: string;
  padding?: string;
  margin?: string;
  boxShadow?: string;
  gradientBg?: string;
  float?: "left" | "right";
}>`
  width: ${({ width }) => (width ? width : "auto")};
  background-image: ${({ bgImage }) => (bgImage ? bgImage : null)};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : null)};
  color: ${({ color }) => (color ? color : null)};
  padding: ${({ padding }) => (padding ? padding : "0")};
  margin: ${({ margin }) => (margin ? margin : "0")};
  height: ${({ height }) => (height ? height : "auto")};
  border-radius: ${({ radius }) => (radius ? radius : "0px")};
  box-sizing: border-box;
  box-shadow: ${({ boxShadow }) => (boxShadow ? boxShadow : "none")};
  background-image: ${({ gradientBg }) => (gradientBg ? gradientBg : "none")};
  ${({ float }) => (float ? `float: ${float};` : null)};
`;
