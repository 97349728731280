import { Col, Form, Row, Checkbox } from "antd";
import { MaterialInput, ButtonPrimary } from "components";
import { useHistory } from "react-router-dom";
import { Routes } from "router/routes";
import { AccountInput } from "../types";

export const AccountCreate: React.FC<{
  handleCreate: (input: AccountInput) => Promise<any>;
  loading: boolean;
}> = ({ handleCreate, loading }) => {
  const [form] = Form.useForm();
  const history = useHistory();

  return (
    <Form<AccountInput>
      name={"accountCreate"}
      form={form}
      autoComplete={"off"}
      onFinish={(fields) => {
        handleCreate(fields).then(() =>
          history.push(Routes.ADMIN_ACCOUNTS.path)
        );
      }}
      initialValues={{
        useStripe: false,
        integrationHRIS: false,
        integrationCollaboration: false,
      }}
    >
      <Row>
        <Col span={24}>
          <Row justify="space-between" gutter={[16, 0]}>
            <Col span={24}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Value is required",
                  },
                ]}
              >
                <MaterialInput placeholder="Account name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: "Value is required",
                  },
                ]}
              >
                <MaterialInput placeholder="Company name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="teamName"
                rules={[
                  {
                    required: true,
                    message: "Value is required",
                  },
                ]}
              >
                <MaterialInput placeholder="Team name" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="useStripe" valuePropName="checked">
                <Checkbox>Use Stripe?</Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="integrationHRIS" valuePropName="checked">
                <Checkbox>HRIS Integration</Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="integrationCollaboration"
                valuePropName="checked"
              >
                <Checkbox>Collaboration Tools Integration</Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <ButtonPrimary loading={loading} htmlType="submit">
                Add account
              </ButtonPrimary>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
