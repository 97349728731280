import { FC } from "react";
import { Modal as AntModal } from "antd";
import { ModalProps as Props } from "antd/lib/modal";

export const Modal: FC<Props> = ({ children, ...props }) => (
  <AntModal
    style={{
      overflow: "hidden",
      borderRadius: "3px",
      textAlign: "center",
      padding: 0,
    }}
    centered
    width={"65vw"}
    closable={false}
    bodyStyle={{
      height: "80vh",
      minWidth: "930px",
      minHeight: "729px",
      padding: 0,
    }}
    {...props}
  >
    {children}
  </AntModal>
);
