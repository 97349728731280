import React from "react";

import { TagAttributes } from "common/types";
import { STag } from "./styles";

type Props = {
  tagAttributes?: TagAttributes;
};
export const CommonTag: React.FC<Props> = (props) => {
  const { tagAttributes } = props;

  if (!tagAttributes) {
    return null;
  }

  const {
    text,
    bgColor,
    textColor,
    isRound = true,
    fontWeight,
  } = tagAttributes;

  return (
    <STag
      {...props}
      isRound={isRound}
      color={bgColor}
      textColor={textColor}
      fontWeight={fontWeight}
    >
      {text}
    </STag>
  );
};
