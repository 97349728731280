import React from "react";
import { Col, Row, Form } from "antd";
import Title from "antd/es/typography/Title";
import { RadioWithBackground } from "../RadioWithBackground";
import { ModalStepsNavigation } from "../../common/types";
import { PollFooter } from "../index";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const questionnaireOptions = [
  { value: "1", label: "Fearful" },
  { value: "2", label: "Joyful" },
  { value: "3", label: "Surprised" },
  { value: "4", label: "Confused" },
  { value: "5", label: "Anxious" },
  { value: "6", label: "Optimistic" },
  { value: "7", label: "Annoyed" },
];

const formFieldName = "employeeFeeling";

type Props = ModalStepsNavigation & {
  children?: never;
};

export const EmployeePollStepOne: React.FC<Props> = (props) => {
  const { onNextStepClick, onSkipStepClick } = props;
  const { xs } = useBreakpoint();

  return (
    <Form.Item noStyle>
      <Row justify="center">
        <Col style={{ marginTop: xs ? "60px" : "130px" }}>
          <Row justify="space-between" gutter={[0, xs ? 11 : 20]}>
            <Col span={24}>
              <Title level={xs ? 2 : 1} style={{ textAlign: "center" }}>
                Let’s Us Get to Know You Better
              </Title>
            </Col>
            <Col span={24}>
              <Row gutter={[0, 50]}>
                <Col span={24}>
                  <Title
                    type="secondary"
                    level={xs ? 4 : 3}
                    style={{ textAlign: "center" }}
                  >
                    How are you feeling about starting this journey towards
                    <br />
                    personalized employment?
                  </Title>
                </Col>
                <Col span={24}>
                  <Row gutter={[0, xs ? 24 : 80]}>
                    <Col offset={4} span={16}>
                      <RadioWithBackground
                        options={questionnaireOptions}
                        hasTwoColumnLayout={!xs}
                        formFieldName={formFieldName}
                      />
                    </Col>
                    <Col span={24}>
                      <PollFooter
                        nextButtonConfig={{
                          label: "1/2 Next",
                          onClick: onNextStepClick,
                        }}
                        skipButtonConfig={{
                          label: "Skip",
                          onClick: onSkipStepClick,
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Item>
  );
};
