import styled, { css } from "styled-components";
import { Tabs as TabsBase } from "antd";

const heightxMixin = css<{ baseHeight?: boolean }>`
  height: ${(props) => !props.baseHeight && "70px"};
`;

export const Tabs = styled(TabsBase)`
  & {
    .ant-tabs-nav {
      ${() => heightxMixin}
      padding-right: 1em;
    }

    .ant-tabs-tab {
      align-items: flex-end;
    }

    .ant-tabs-content,
    .ant-tabs-content-top {
      display: flex;
      height: 100%;
      flex: 1;
    }

    .ant-tabs-ink-bar {
      position: absolute;
      background: ${({ theme }) => theme.colors.blue_80};
      pointer-events: none;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #363636 !important;
      font-weight: 600;
    }

    .ant-tabs-tab .ant-tabs-tab-btn {
      color: #a0afbc !important;
    }
  }
`;
