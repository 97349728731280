import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { colors } from "theme";
import backIcon from "assets/icons/chevronLeftOrange.svg";

export const padding = css`
  padding: 2rem 0;
`;

export const FormWrap = styled.div`
  width: 30rem;
  max-width: 100%;
`;

export const ButtonsWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 26px 18px;

  & > * {
    margin-bottom: 0.5rem;
    width: 100%;
    max-width: 13.5rem;
  }
`;

// TODO: make reusable component
export const BackLink = styled(Link)`
  position: relative;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  color: ${colors.blue_100};
  font-size: 17px;

  &::before {
    content: url(${backIcon});
    display: inline-block;
    margin-right: 0.625rem;
  }
`;
