import { FC, useEffect, useRef } from "react";
import { TableProps } from "antd";
import * as S from "./styles";

export const Table: FC<TableProps<any>> = (props) => {
  const tableRef = useRef<any>();

  // This is here, because of antd table bug while setting the minimum height
  useEffect(() => {
    const config = { childList: true, subtree: true };
    const observer = new MutationObserver(() => {
      const div = tableRef.current.querySelector(
        ".ant-table-body > div"
      ) as HTMLDivElement;

      if (!div) return;

      const minHeight = div.children[0].scrollHeight;
      div.style.minHeight = minHeight + "px";
    });

    observer.observe(tableRef.current, config);

    return () => {
      observer.disconnect();
    };
  }, [tableRef]);

  return (
    <div ref={tableRef}>
      <S.Table {...props} />
    </div>
  );
};
