import React from "react";
import { Col, Row } from "antd";

import { ImgSvg } from "../ImgSvg";

type ImageProps = {
  src: string;
  alt: string;
};

type Props = {
  image: ImageProps;
  leftColMarginTop?: string;
};

export const ContainerWithImage: React.FC<Props> = (props) => {
  return (
    <Row>
      {/* Left column */}
      <Col
        xs={{ span: 24 }}
        md={{ span: 8, offset: 2 }}
        style={{
          padding: "0 23px",
          marginTop: props.leftColMarginTop ?? "100px",
        }}
      >
        {props.children}
      </Col>
      {/* Right column */}
      <Col offset={2} span={12}>
        <Row justify="end">
          <Col xs={0} md={24}>
            <ImgSvg src={props.image.src} alt={props.image.alt} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
