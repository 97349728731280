import { FC, MouseEventHandler } from "react";
import { UIButton } from "./button.styles";
import { BaseButtonProps } from "antd/lib/button/button";

export interface ButtonProps extends BaseButtonProps {
  $bgColor?: string;
  $color?: string;
  $margin?: string;
  $padding?: string;
  $width?: string;
  $height?: string;
  $border?: string;
  $hoverBgColor?: string;
  $radius?: string;
  onClick?: MouseEventHandler<Element>;
  disabled?: boolean;
  // TODO: Change antd button props to include as well htmlType (used for forms)
  htmlType?: "button" | "submit" | "reset" | undefined;
}

/* @deprecated - use CoreButton or one of the presets (see Storybook) */
export const Button: FC<ButtonProps> = ({ children, ...props }) => {
  return <UIButton {...props}>{children}</UIButton>;
};
