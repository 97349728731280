import { LineChartProps } from ".";

export const lineChartOptions: Pick<LineChartProps, "options">["options"] = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      min: -0.1,
      max: 1.1,
      ticks: {
        callback: function (value) {
          if (value < 0 || value > 1) return;
          return Number(value) * 100 + " %";
        },
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        boxWidth: 8,
        boxHeight: 8,
        usePointStyle: true,
        pointStyle: "circle",
        padding: 20,
        font: {
          size: 14,
        },
      },
    },
    datalabels: {
      display: false,
    },
  },
};
