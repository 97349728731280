import styled from "styled-components";
import { Table as AntdTable } from "antd";

export const Table = styled(AntdTable)`
  .ant-table {
    height: 100%;
    background: transparent;

    & table {
      border-collapse: separate;
      border-spacing: 0 9px;

      // Styles for head part
      .ant-table-column-sorters {
        gap: 10px;
      }

      .ant-table-thead > tr > th:first-child {
        border-radius: 6px 0 0 6px;
        border-left: ${(props) => `1px solid ${props.theme.colors.blue_20}`};
      }

      .ant-table-thead > tr > th:last-child {
        border-radius: 0 6px 6px 0;
        border-right: ${(props) => `1px solid ${props.theme.colors.blue_20}`};
      }

      .ant-table-thead > tr > th.ant-table-cell {
        background: ${(props) => props.theme.colors.blue_5};
        border-bottom: ${(props) => `1px solid ${props.theme.colors.blue_20}`};
        border-top: ${(props) => `1px solid ${props.theme.colors.blue_20}`};
      }

      td.ant-table-cell {
        border: none;
        white-space: nowrap;
        background: white;
      }

      & .ant-table-row,
      & .ant-table-thead {
        border-spacing: 0;

        height: 56px;
        border-radius: 6px;
        border: ${(props) => `1px solid ${props.theme.colors.blue_10}`};

        box-shadow: 0 11px 18px -5px rgba(1, 8, 58, 0.04);
      }

      // Border for default row
      .ant-table-tbody > tr.ant-table-row > td {
        border-bottom: ${(props) => `1px solid ${props.theme.colors.blue_10}`};
        border-top: ${(props) => `1px solid ${props.theme.colors.blue_10}`};
      }
      // Border-radius for default row
      .ant-table-tbody > tr.ant-table-row > td:first-child {
        border-radius: 6px 0 0 6px;
        border-left: ${(props) => `1px solid ${props.theme.colors.blue_10}`};
      }
      .ant-table-tbody > tr.ant-table-row > td:last-child {
        border-radius: 0 6px 6px 0;
        border-right: ${(props) => `1px solid ${props.theme.colors.blue_10}`};
      }

      // Border for selected row
      .ant-table-tbody > tr.ant-table-row-selected > td {
        border-bottom: ${(props) => `1px solid ${props.theme.colors.blue_70}`};
        border-top: ${(props) => `1px solid ${props.theme.colors.blue_70}`};
      }

      // Border-radius for selected row
      .ant-table-tbody > tr.ant-table-row-selected > td:first-child {
        border-radius: 6px 0 0 6px;
        border-left: ${(props) => `1px solid ${props.theme.colors.blue_70}`};
      }

      .ant-table-tbody > tr.ant-table-row-selected > td:last-child {
        border-radius: 0 6px 6px 0;
        border-right: ${(props) => `1px solid ${props.theme.colors.blue_70}`};
      }
    }
  }
`;
