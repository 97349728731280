import React from "react";
import { useHistory } from "react-router";

import { Space, Typography } from "antd";
import { ButtonPrimary, ButtonSecondary, Container, Loader } from "..";

const { Text, Title, Paragraph } = Typography;

export type LinkOrHandler = string | (() => void);

export type Props = {
  loading?: boolean;
  loadingText?: string;
  error?: string;
  errorNote?: string;
  next?: LinkOrHandler;
  nextText?: string;
  retry?: LinkOrHandler;
  retryText?: string;
};

export const DataWrapper: React.FC<Props> = ({
  loading,
  loadingText,
  error,
  errorNote,
  next,
  nextText = "Continue",
  retry,
  retryText = "Retry",
  children,
}) => {
  const history = useHistory();

  if (loading)
    return (
      <Container center>
        <Loader />
        {loadingText ? <Text>{loadingText}</Text> : null}
      </Container>
    );

  if (error) {
    const handler = (l: LinkOrHandler) =>
      typeof l !== "string" ? l : () => history.push(l);
    return (
      <Container center>
        <Title level={2}>{error}</Title>
        {errorNote ? <Paragraph>{errorNote}</Paragraph> : null}
        <Space size="large">
          {retry ? (
            <ButtonSecondary type="link" onClick={handler(retry)}>
              {retryText}
            </ButtonSecondary>
          ) : null}
          {next ? (
            <ButtonPrimary type="link" onClick={handler(next)}>
              {nextText}
            </ButtonPrimary>
          ) : null}
        </Space>
      </Container>
    );
  }

  return <>{children}</>;
};
