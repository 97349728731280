import { FC, useContext, useMemo } from "react";
import { ReactComponent as Upsell } from "assets/icons/upsell.svg";
import ContentLayout from "layout/ContentLayout";
import { ContentType } from "layout/ContentLayout/ContentLayout";
import {
  Link,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Routes } from "router/routes";
import theme from "theme";
import {
  LayoutSubmenu,
  LayoutSubmenuItem,
  MenuUnderline,
} from "pages/my-team/profile/Profile.styles";
import {
  memberMenuItems,
  MemberProfileMenuItems,
  meMenuItems,
} from "./menuItems";
import MemberProfile from "./components/MemberProfile";
import { Icon, Restricted, Wrapper } from "components";
import MemberWorkDesigns from "./components/MemberWorkDesigns";
import { ButtonTertiary } from "./styles";
import { UserRole } from "codegen/generated/graphql";
import { useAuthContext } from "utils/context";
import { AuthContext } from "utils/context/auth";
import { AccountPasswordChange } from "pages/my-team/profile/Account";
import { Col, Row } from "antd";

type PageProps = {
  isMeMember?: boolean;
};

const Page: FC<PageProps> = ({ isMeMember }) => {
  const { path } = useRouteMatch();
  const { user } = useContext(AuthContext);

  const isPrimaryAdmin = user?.roles.includes(UserRole.PrimaryAdmin);
  const isAdmin = user?.roles.includes(UserRole.Admin);
  const isTeamManager = user?.roles.includes(UserRole.TeamManager);

  return (
    <Switch>
      <Route path={Routes.MY_PROFILE_ACCOUNT.path}>
        <Row>
          <Col offset={2} span={16}>
            <AccountPasswordChange />
          </Col>
        </Row>
      </Route>
      <Route exact path={path}>
        <MemberProfile
          isManager={isPrimaryAdmin || isAdmin || isTeamManager}
          isMeMember={isMeMember}
        />
      </Route>
      <Route path={Routes.TEAM_MEMBER_WORK_DESIGNS.path}>
        <MemberWorkDesigns />
      </Route>
    </Switch>
  );
};

type MenuProps = {
  items: MemberProfileMenuItems;
  isMeMember?: boolean;
};

export const Menu: FC<MenuProps> = ({ items }) => {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();

  return (
    <LayoutSubmenu>
      {items.map((item) => {
        const { itemId, activeIcon, inactiveIcon, title, path } = item;
        const isActive = location.pathname === path(id);
        return (
          <MenuUnderline key={itemId}>
            <LayoutSubmenuItem
              $active={isActive}
              key={itemId}
              icon={<Icon name={isActive ? activeIcon : inactiveIcon} />}
            >
              <Link to={path(id)}>{title}</Link>
            </LayoutSubmenuItem>
          </MenuUnderline>
        );
      })}
    </LayoutSubmenu>
  );
};

const Member: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const { user } = useAuthContext();
  // Currently, we reuse the same page for the regular member and for me page
  const isMeMember = useMemo(() => id === user?.id, [id, user]);

  return (
    <ContentLayout
      header={{
        title: (
          <Restricted
            to={[UserRole.PrimaryAdmin, UserRole.Admin, UserRole.TeamManager]}
            fallback="My Profile"
          >
            My Team Profile
          </Restricted>
        ),
      }}
      typeContent={ContentType.WithSubmenu}
      sideContent={<Menu items={isMeMember ? meMenuItems : memberMenuItems} />}
    >
      <Wrapper
        height="84.5vh"
        width="100%"
        gradientBg="linear-gradient(108.84deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.37) 101.71%);"
        radius={theme.radius.commonRadius}
      >
        <Page isMeMember={isMeMember} />
      </Wrapper>
    </ContentLayout>
  );
};

export default Member;
