import { FC } from "react";

import * as S from "./styles";
import { WorkDesignDataFragment } from "codegen/generated/graphql";
import { WorkDesignStatusTag } from "components";
import Text from "antd/lib/typography/Text";
import { generatePath, useHistory } from "react-router";
import { Routes } from "router/routes";
import { GhostButton } from "pages/frameworks/collection/Collection.styles";
import {
  EllipsisOutlined,
  EyeOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import theme from "theme";
import { Link } from "react-router-dom";

type Props = {
  data?: WorkDesignDataFragment[];
  isMyWorkDesign?: boolean;
};

const WorkDesignTable: FC<Props> = ({ data, isMyWorkDesign }) => {
  const history = useHistory();

  const handleOnClick = (id: string) => () => {
    history.push(generatePath(Routes.WORK_DESIGN_PREVIEW.path, { id }));
  };

  return (
    <div style={{ display: "grid" }}>
      {!!data && data?.length > 0 ? (
        data?.map((wd) => (
          <Link
            to={generatePath(Routes.WORK_DESIGN_PREVIEW.path, { id: wd.id })}
          >
            <S.TableRow key={wd.id}>
              <S.Info>
                <Text strong ellipsis>
                  {wd.framework.title}
                </Text>
              </S.Info>
              <S.Detail>
                <Text style={{ marginLeft: "15px", width: "90px" }}>
                  <WorkDesignStatusTag status={wd.status} />
                </Text>
                <Text style={{ marginLeft: "15px", width: "125px" }}>
                  {new Intl.DateTimeFormat("en-US", {
                    dateStyle: "medium",
                  }).format(new Date(wd.framework.startDate))}
                </Text>
                <S.Label>
                  Dimensions&nbsp;&nbsp;
                  <Text strong>{wd.dimensions.length}</Text>
                </S.Label>
                <S.Label>
                  Features&nbsp;&nbsp;
                  <Text strong>
                    {wd.dimensions.flatMap(({ features }) => features).length}
                  </Text>
                </S.Label>
                <div
                  style={{
                    visibility:
                      isMyWorkDesign && wd.status === "PENDING"
                        ? "visible"
                        : "hidden",
                    marginLeft: "15px",
                    width: "150px",
                  }}
                >
                  <Link
                    to={generatePath(Routes.WORK_DESIGN_PERSONALIZE.path, {
                      id: wd.id,
                    })}
                  >
                    <GhostButton
                      type="ghost"
                      title="Personalize now"
                      icon={<UserSwitchOutlined />}
                      style={{
                        marginRight: "5px",
                        minHeight: "40px",
                        minWidth: "40px",
                      }}
                    />
                    Personalize
                  </Link>
                </div>
                <div style={{ marginLeft: "15px", width: "135px" }}>
                  <GhostButton
                    type="ghost"
                    icon={<EyeOutlined />}
                    onClick={handleOnClick(wd.id)}
                    style={{
                      marginRight: "5px",
                      minHeight: "40px",
                      minWidth: "40px",
                    }}
                  />
                  Preview
                  {/* <Dropdown
                  overlay={() => (
                    <Menu>
                    <Menu.Item
                    key="2"
                    onClick={() => {
                      // TODO: share workdesign mutation
                      console.log("Share!!!!");
                    }}
                    >
                    Share with...
                    </Menu.Item>
                    </Menu>
                    )}
                    trigger={["click"]}
                    >
                    <Button
                    type="link"
                    icon={
                      <EllipsisOutlined
                      rotate={90}
                      color={theme.colors.blue_100}
                      />
                    }
                    />
                  </Dropdown> */}
                </div>
              </S.Detail>
            </S.TableRow>
          </Link>
        ))
      ) : (
        <S.NoWorkDesigns>There is no Work Design to complete</S.NoWorkDesigns>
      )}
    </div>
  );
};

export default WorkDesignTable;
