import { CSSProperties, FC, ReactNode } from "react";
import { useReactiveVar } from "@apollo/client";
import { UserRole } from "codegen/generated/graphql";
import Content from "./CarouselContent";
import * as S from "./carousel.styles";
import { useHistory } from "react-router-dom";
import { Routes } from "router/routes";
import { selectedRoleVar } from "store/app";
export interface CarouselContentItem {
  headline: string;
  buttonValue: ReactNode;
  onClickHandler: () => void;
}

type Props = {
  items?: CarouselContentItem[];
  style?: CSSProperties;
};

// @TODO - move carouselData out of this component (make props courselItems)
const Carousel: FC<Props> = ({ style }) => {
  const history = useHistory();
  const selectedRole = useReactiveVar(selectedRoleVar);

  const carouselData: CarouselContentItem[] = [
    (selectedRole?.id === UserRole.PrimaryAdmin ||
      selectedRole?.id === UserRole.TeamManager ||
      selectedRole?.id === UserRole.Admin) && {
      headline: "My Team",
      buttonValue: "New Team Member",
      onClickHandler: () => {
        history.push(Routes.TEAM_MEMBER_CREATE.path);
      },
    },
    /////////////// Won't be the part v1
    // {
    //   headline: "Membership settings",
    //   buttonValue: "Additional administrator",
    //   onClickHandler: () => {
    //     history.push(Routes.TEAM_PROFILE.path);
    //   },
    // },
    {
      headline: "Give feedback",
      buttonValue: (
        <a
          href="mailto:support@hellolluna.com"
          target="_blank"
          rel="noreferrer noopener"
          style={{ color: "white" }}
        >
          Share your feedback
        </a>
      ),
    },
  ].filter(Boolean) as CarouselContentItem[];

  return (
    <S.Carousel autoplay>
      {carouselData.map((carouselItem, i) => (
        <Content
          key={i}
          headline={carouselItem.headline}
          buttonValue={carouselItem.buttonValue}
          onClickHandler={carouselItem.onClickHandler}
        />
      ))}
    </S.Carousel>
  );
};

export default Carousel;
