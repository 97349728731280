import React from "react";
import Text from "antd/es/typography/Text";
import { Table, DimensionTag } from "components";
import { Button, Dropdown, Menu, Row } from "antd";
import { generatePath, useHistory } from "react-router";
import { Routes } from "router/routes";
import {
  DimensionsDocument,
  DimensionsQuery,
  useRemoveDimensionMutation,
} from "codegen/generated/graphql";
import { EllipsisOutlined } from "@ant-design/icons";
import { useLazyQuery } from "@apollo/client";
import theme from "theme";
import { useColumns, useTable } from "hooks/table";

export const DimensionsTable: React.FC = () => {
  const history = useHistory();
  const handleOnRedirect = (to: string) => () => history.push(to);

  const [remove] = useRemoveDimensionMutation({
    update: (cache, { data }) => {
      const originalData = cache.readQuery<DimensionsQuery>({
        query: DimensionsDocument,
      });
      const updatedDimensions = originalData?.dimensions.filter(
        ({ id }) => id !== data?.removeDimension?.id
      );

      if (!updatedDimensions) return;
      cache.writeQuery<DimensionsQuery>({
        query: DimensionsDocument,
        data: {
          ...originalData,
          dimensions: updatedDimensions,
        },
      });
    },
  });

  const fetch = useLazyQuery(DimensionsDocument, {
    fetchPolicy: "cache-first",
  });

  const { tableProps, data: { dimensions } = {} } = useTable<
    DimensionsQuery["dimensions"]
  >(fetch, {
    key: "dimensions",
  });

  const renderMenu = ({ id }: { id: string }) => (
    <Dropdown
      overlay={() => (
        <Menu>
          <Menu.Item
            key="edit"
            onClick={handleOnRedirect(
              generatePath(Routes.FRAMEWORKS_EDIT_DIMENSION.path, {
                id,
              })
            )}
          >
            Edit
          </Menu.Item>
          <Menu.Item key="delete" onClick={() => remove({ variables: { id } })}>
            Delete
          </Menu.Item>
        </Menu>
      )}
      trigger={["click"]}
    >
      <Button
        type="link"
        icon={<EllipsisOutlined rotate={90} color={theme.colors.blue_100} />}
      />
    </Dropdown>
  );

  const columns = useColumns<DimensionsQuery["dimensions"]>([
    {
      title: "Name",
      key: "dimension.name",
      align: "left",
      sorter: false,
      fixed: "left",
      render: ({ name, meta }) => (
        <DimensionTag
          background={meta.backgroundColor}
          color={meta.textColor}
          label={name ?? ""}
        />
      ),
    },
    {
      title: "Features Count",
      key: "features",
      align: "right",
      render: ({ features }) => <Text>{features?.length}</Text>,
    },
    {
      align: "right",
      fixed: "right",
      key: "more",
      render: renderMenu,
    },
  ]);

  const dimensionsCount = dimensions?.length ?? 0;

  return (
    <>
      <Row style={{ margin: "29px 0 23px" }}>
        <Text>{`${dimensionsCount} Dimensions`}</Text>
      </Row>
      <Table
        {...tableProps}
        rowSelection={undefined}
        columns={columns}
        size="small"
        locale={{
          emptyText: (
            <Text type="secondary">
              It seems there are no Dimensions matching your filters
            </Text>
          ),
        }}
      />
    </>
  );
};
