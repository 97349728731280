import styled, { css } from "styled-components";
import { LineChartProps } from ".";

export const Wrap = styled.div<{ isData: boolean }>`
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: flex-end;
  ${({ isData }) =>
    !isData &&
    css`
      height: 280px;
    `}
`;

export const Hint = styled.div`
  color: ${({ theme }) => theme.colors.grey_70};
  font-size: 0.8em;
  margin-bottom: 7px;
`;
export const LabelsEnhancement = styled.div`
  color: ${({ theme }) => theme.colors.grey_70};
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  font-size: 0.8em;
  top: 20px;
`;

export const ChartWrap = styled.div<
  Pick<LineChartProps, "fullWidth" | "isOnDashboard">
>`
  ${({ fullWidth, isOnDashboard }) => {
    const chartHeight = isOnDashboard ? "260px" : "300px";
    return !fullWidth
      ? css`
          height: ${chartHeight};
          width: 90%;
          margin: 0 auto;
        `
      : css`
          height: 260px;
        `;
  }}
`;

export const Cursor = styled.p`
  cursor: pointer;
  padding: 10px 0;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.1);
  }
`;

export const optionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
