import React from "react";
import { Col, Row } from "antd";
import Title from "antd/es/typography/Title";
import { RadioWithFloatingInput } from "../RadioWithFloatingInput";
import { ModalStepsNavigation } from "../../common/types";
import Text from "antd/lib/typography/Text";
import { PollFooter } from "../index";

type Props = ModalStepsNavigation & {
  children?: never;
};

const formFieldNameLeft = "person";
const formFieldNameRight = "channel";

export const ManagerPollStepOne: React.FC<Props> = (props) => {
  const { onNextStepClick, onSkipStepClick } = props;
  return (
    <Row justify="center">
      <Col style={{ marginTop: "130px" }}>
        <Row gutter={[0, 24]} justify="center">
          <Col span={24}>
            <Title level={1}>Let’s Get Started</Title>
          </Col>
          <Col span={24}>
            <Text type="secondary" style={{ fontSize: "26px" }}>
              How did you hear about LLUNA?
            </Text>
          </Col>
          <Col span={24}>
            <Row gutter={[0, 160]}>
              <Col span={24}>
                <RadioWithFloatingInput
                  formFieldNameLeft={formFieldNameLeft}
                  formFieldNameRight={formFieldNameRight}
                />
              </Col>
              <Col span={24}>
                <PollFooter
                  nextButtonConfig={{
                    label: "1/2 Next",
                    onClick: onNextStepClick,
                  }}
                  skipButtonConfig={{ label: "Skip", onClick: onSkipStepClick }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
