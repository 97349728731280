import { FC } from "react";
import { UIWrapper } from "./wrapper.styles";

export interface WrapperProps {
  bgImage?: string;
  radius?: string;
  bgColor?: string;
  color?: string;
  height?: string;
  width?: string;
  padding?: string;
  margin?: string;
  boxShadow?: string;
  gradientBg?: string;
  float?: "left" | "right";
}

const Wrapper: FC<WrapperProps> = ({
  bgImage,
  bgColor,
  height,
  width,
  radius,
  padding,
  margin,
  children,
  color,
  boxShadow,
  gradientBg,
  float,
}) => {
  return (
    <UIWrapper
      bgImage={bgImage}
      bgColor={bgColor}
      color={color}
      height={height}
      radius={radius}
      padding={padding}
      margin={margin}
      boxShadow={boxShadow}
      gradientBg={gradientBg}
      width={width}
      float={float}
    >
      {children}
    </UIWrapper>
  );
};

export default Wrapper;
