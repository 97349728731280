import React from "react";
import { Space, Typography } from "antd";

import { DashboardCard } from "../DashboardCard";
import { MINDSETS } from "pages/work-design/mindset/constants";
import { Icon } from "components";

const { Title, Text } = Typography;

export const EmployeeMindset: React.FC = () => (
  <DashboardCard
    title="What Is Your Mindset? - COMING SOON"
    icon="eclipseYellow"
    style={{ opacity: 0.3 }}
  >
    <Space size={15} direction="vertical" style={{ padding: "0 1rem 0 32px" }}>
      {MINDSETS.map(({ id, description, icon }) => (
        <Space key={id} size={20} align="start">
          <Icon name={icon} fontSize={22} />
          <Space direction="vertical" size={0}>
            <Title level={4} style={{ fontWeight: 600 }}>
              {id}
            </Title>
            <Text type="secondary">{description}</Text>
          </Space>
        </Space>
      ))}
    </Space>
  </DashboardCard>
);
