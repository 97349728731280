import { useMemo } from "react";
import { ChartData } from "chart.js";
import {
  useAggregateFrameworkStatusQuery,
  WdStatus,
} from "codegen/generated/graphql";
import { labelColors } from "components/Charts/DoughnutChart/plugins";

const WDStatusSorted = [
  WdStatus.Active,
  WdStatus.Pending,
  WdStatus.Ready,
  WdStatus.Ended,
];

export const useFetchFWStatus = (
  frameworkId: string | undefined,
  userScope?: any,
  isOnDashboard?: boolean
) => {
  const { data: { aggregateFrameworkStatus } = {}, loading } =
    useAggregateFrameworkStatusQuery({
      fetchPolicy: "no-cache",
      variables: {
        frameworkId: frameworkId ?? "",
        userScope,
      },
    });

  const colors = useMemo(() => labelColors(4), []);

  if (!aggregateFrameworkStatus)
    return {
      isFetching: loading,
      dataWithFormattedLabels: { datasets: [], labels: [] },
      wdCount: 0,
    };
  // without cannot set "cutout" which is necessary
  const datasets: ChartData<"doughnut">["datasets"] &
    {
      cutout: number;
    }[] = [
    {
      ...aggregateFrameworkStatus.datasets[0],
      hoverOffset: 30,
      borderColor: "transparent",
      borderWidth: 50,
      cutout: isOnDashboard ? 102 : 107,
      backgroundColor: colors,
    },
  ];

  const labels = WDStatusSorted.map(
    (status) => status.slice(0, 1) + status.slice(1).toLocaleLowerCase()
  );

  const dataWithFormattedLabels: ChartData<"doughnut"> = {
    ...aggregateFrameworkStatus,
    datasets,
    // To get e.g. "Active" from "ACTIVE" etc.
    labels: labels,
  };

  const wdCount = aggregateFrameworkStatus.datasets[0].data.reduce(
    (a, b) => a + b,
    0
  );
  return { isFetching: loading, dataWithFormattedLabels, wdCount };
};
