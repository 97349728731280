import { Placeholder, FeatureType } from "codegen/generated/graphql";
import { ChoicesPlaceholders } from "constants/miscellaneous";

export const featureTypeString = (type: FeatureType): string => {
  switch (type) {
    case FeatureType.SingleOption:
      return "Single Choice";
    case FeatureType.MultiOption:
      return "Multi-Select";
    case FeatureType.Text:
      return "Open Question";

    default:
      return "";
  }
};
export type FeaturePlaceholder = {
  id: Placeholder;
  placeholderValue: ChoicesPlaceholders;
};
