import { Mindset } from "codegen/generated/graphql";
import { IconName } from "components";

export type MindsetInfo = {
  id: string;
  description: string;
  icon: IconName;
};

export const MINDSETS: MindsetInfo[] = [
  {
    id: Mindset.Balancer,
    description:
      "I am content with my career progression and currently prioritize life activities and responsibilities.",
    icon: "mindset-balancer",
  },
  {
    id: Mindset.Driver,
    description:
      "I am focused on accelerating my career development and upward growth trajectory.",
    icon: "mindset-driver",
  },
  {
    id: Mindset.Juggler,
    description:
      "While career-focused, right now I am juggling a lot in work and life.",
    icon: "mindset-juggler",
  },
  {
    id: Mindset.Saver,
    description: "I am invested in maximizing my savings.",
    icon: "mindset-saver",
  },
  {
    id: Mindset.Traveler,
    description:
      "I am interested in working hard and traveling far - seeing the world in my passion!",
    icon: "mindset-traveler",
  },
];
