import styled from "styled-components";
import Input from "components/Input";

export const Wrap = styled.span`
  color: ${({ theme }) => theme.colors.blue_100};
  background-color: ${({ theme }) => theme.colors.blue_10};
  border: 1px solid #dce8f0;
  border-radius: 3px;
  width: fit-content;
  padding: 3px 5px;
  cursor: pointer;
  font-size: 13px;
  font-weight: lighter;
  white-space: nowrap;
`;
export const ValueWrap = styled.span``;
export const PlaceholderInput = styled(Input)`
  width: 40px;
  height: 20px;
  margin: 0 5px 0 0;
  padding: 3px;
  font-size: 1.3em;
`;
