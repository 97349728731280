import React from "react";
import * as S from "./styles";
import { ImgSvg } from "../ImgSvg";
import mouse from "assets/images/mouse.svg";
import message from "assets/images/message.svg";
import { Col, Row } from "antd";
import { MaterialInput } from "../MaterialInput";

type Props = {
  formFieldNameLeft: string;
  formFieldNameRight: string;
};

export const RadioWithFloatingInput: React.FC<Props> = (props) => {
  const { formFieldNameLeft, formFieldNameRight } = props;

  return (
    <Row justify="center" gutter={[24, 0]} style={{ minHeight: "200px" }}>
      <Col>
        <S.AbsoluteFormItem name={formFieldNameLeft}>
          <MaterialInput placeholder="Name of your Company" />
        </S.AbsoluteFormItem>
        <S.TileContainer htmlFor="companyName">
          <S.RadioInput type="radio" name="questionnaire" id="companyName" />
          <S.RadioElement className="content">
            <ImgSvg src={message} alt="mouse" />
            From somebody at my company who is already using the platform
          </S.RadioElement>
        </S.TileContainer>
      </Col>
      <Col>
        <S.AbsoluteFormItem name={formFieldNameRight}>
          <MaterialInput placeholder="Name the specific channel" />
        </S.AbsoluteFormItem>
        <S.TileContainer htmlFor="socialMedia">
          <S.RadioInput type="radio" name="questionnaire" id="socialMedia" />
          <S.RadioElement className="content">
            <ImgSvg src={mouse} alt="mouse" />
            Social Media, News, Website, other channels...
          </S.RadioElement>
        </S.TileContainer>
      </Col>
    </Row>
  );
};
