import { Form, Row, Col, Select as Dropdown, Input, DatePicker } from "antd";
import * as S from "./styles";
import {
  useFrameworksCollectionQuery,
  UserRole,
  UserAdditionalPermissions,
  useUserScopesQuery,
} from "codegen/generated/graphql";
import { Select, Button } from "components";
import theme from "theme";
import { FC, useEffect, useMemo, useState } from "react";
import { useDownload } from "hooks";
import { getFormattedDate } from "utils/formatters";
import { useAuthContext } from "utils/context";
import Restricted from "../../components/Permissions/Restricted";
import { ReportsHeader } from "./components/ReportsHeader";
import { DimensionsList } from "./components/DimensionsList";
import { FinancialTables } from "./components/FinancialTables";
import { Calendar } from "./components/Calendar";
import { ChartsRow } from "./components/ChartsRow";
import { API_ENDPOINTS } from "constants/urls";
import Text from "antd/es/typography/Text";
import Link from "antd/es/typography/Link";

const Teams: FC = () => {
  const [form] = Form.useForm();
  const [currentFrameworkId, setCurrentFrameworkId] = useState<string>();
  const defaultFrameworkIdx = 0;
  const { user } = useAuthContext();
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedOffice, setOfficeValue] = useState([]);
  const [selectedManager, setSelectedManager] = useState("");
  const [selectedJobTitle, setJobTitleValue] = useState([]);
  const [userScope, setUserScope] = useState({});
  const [selectedEmploymentType, setEmploymentTypeValue] = useState([]);
  const [selectedFunction, setSelectedFunction] = useState([]);
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedRace, setSelectedRace] = useState([]);
  const [selectedRange, setSelectedRange] = useState("");
  const [selectedTenure, setSelectedTenure] = useState([]);

  const { data, loading } = useFrameworksCollectionQuery({
    variables: {
      sharedOnly: true,
    },
  });

  const sortedFws = useMemo(
    () => [...(data?.frameworks || [])].reverse(),
    [data]
  );

  const selectOptions = sortedFws.map((framework) => {
    return {
      label: (
        <Row gutter={12}>
          <Col>{framework.title}</Col>
          <Col>
            <span style={{ fontSize: "smaller", color: "grey" }}>
              from {getFormattedDate(framework.startDate)}
            </span>
          </Col>
        </Row>
      ),
      value: framework.id,
    };
  }) ?? [{ label: "", value: "" }];

  const handleChange = (value: any) => {
    setSelectedFilter(value);
    form.resetFields();
  };

  const {
    data: userScopeData,
    loading: userScopeLoading,
    error: userScopeError,
  } = useUserScopesQuery({});

  const jobTitles = userScopeData?.userScopes?.jobTitles || [];
  const jobTitleOptions = jobTitles?.map((item: any) => {
    return { label: item, value: item };
  });

  const offices = userScopeData?.userScopes?.offices || [];
  const officeOptions = offices?.map((item: any) => {
    return { label: item, value: item };
  });

  const employmentTypes = userScopeData?.userScopes?.employmentTypes || [];
  const employmentTypeOptions = employmentTypes?.map((item: any) => {
    return { label: item, value: item };
  });

  const functions = userScopeData?.userScopes?.functions || [];
  const functionOptions = functions?.map((item: any) => {
    return { label: item, value: item };
  });

  const genders = userScopeData?.userScopes?.genders || [];
  const genderOptions = genders?.map((item: any) => {
    return { label: item, value: item };
  });

  const raceEthinicities = userScopeData?.userScopes?.raceEthinicities || [];
  const raceOptions = raceEthinicities?.map((item: any) => {
    return { label: item, value: item };
  });

  const filterOptions: any = [
    {
      label: "Job Title",
      value: "jobTitle",
    },
    {
      label: "Office",
      value: "office",
    },
    {
      label: "Manager Yes/No",
      value: "manager",
    },
    {
      label: "Employment Type",
      value: "employmentType",
    },
    {
      label: "Function",
      value: "function",
    },
    {
      label: "Gender",
      value: "gender",
    },
    {
      label: "Race/ethnicity",
      value: "race",
    },
    {
      label: "Hire date",
      value: "hireDate",
    },
    {
      label: "Tenure",
      value: "tenure",
    },
    {
      label: "Salary",
      value: "salary",
    },
  ];

  const selectFilterProps: any = {
    mode: "single",
    style: {
      width: "100%",
    },
    value: selectedFilter,
    options: filterOptions,
    onChange: (newValue: any) => {
      setSelectedFilter(newValue);
      form.resetFields();
    },
    placeholder: "Select Item...",
    maxTagCount: "responsive",
  };

  const selectJobTitleProps: any = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value: selectedJobTitle,
    options: jobTitleOptions,
    onChange: (newValue: any) => {
      setJobTitleValue(newValue);
    },
    placeholder: "Select Item...",
    maxTagCount: "responsive",
  };

  const selectOfficeProps: any = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value: selectedOffice,
    options: officeOptions,
    onChange: (newValue: any) => {
      setOfficeValue(newValue);
    },
    placeholder: "Select Item...",
    maxTagCount: "responsive",
  };

  const managerOptions: any = [
    {
      label: "Yes",
      value: "YES",
    },
    {
      label: "No",
      value: "NO",
    },
  ];

  const selectManagerProps: any = {
    mode: "single",
    style: {
      width: "100%",
    },
    value: selectedManager,
    options: managerOptions,
    onChange: (newValue: any) => {
      setSelectedManager(newValue);
    },
    placeholder: "Select Item...",
    maxTagCount: "responsive",
  };

  const selectEmploymentTypeProps: any = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value: selectedEmploymentType,
    options: employmentTypeOptions,
    onChange: (newValue: any) => {
      setEmploymentTypeValue(newValue);
    },
    placeholder: "Select Item...",
    maxTagCount: "responsive",
  };

  const selectFunctionProps: any = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value: selectedFunction,
    options: functionOptions,
    onChange: (newValue: any) => {
      setSelectedFunction(newValue);
    },
    placeholder: "Select Item...",
    maxTagCount: "responsive",
  };

  const selectGenderProps: any = {
    mode: "single",
    style: {
      width: "100%",
    },
    value: selectedGender,
    options: genderOptions,
    onChange: (newValue: any) => {
      setSelectedGender(newValue);
    },
    placeholder: "Select Item...",
    maxTagCount: "responsive",
  };

  const selectRaceProps: any = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value: selectedRace,
    options: raceOptions,
    onChange: (newValue: any) => {
      setSelectedRace(newValue);
    },
    placeholder: "Select Item...",
    maxTagCount: "responsive",
  };

  const rangeOptions: any = [
    {
      label: "Less than",
      value: "LESS_THAN",
    },
    {
      label: "Greater than",
      value: "GREATER_THAN",
    },
    {
      label: "Equals To",
      value: "EQUALS_TO",
    },
  ];

  const selectRangeProps: any = {
    mode: "single",
    style: {
      width: "100%",
    },
    value: selectedRange,
    options: rangeOptions,
    onChange: (newValue: any) => {
      setSelectedRange(newValue);
    },
    placeholder: "Select Item...",
    maxTagCount: "responsive",
  };

  const hireDateRangeOptions: any = [
    {
      label: "Before",
      value: "LESS_THAN",
    },
    {
      label: "After",
      value: "GREATER_THAN",
    },
    {
      label: "On",
      value: "EQUALS_TO",
    },
  ];

  const selectHireDateRangeProps: any = {
    mode: "single",
    style: {
      width: "100%",
    },
    value: selectedRange,
    options: hireDateRangeOptions,
    onChange: (newValue: any) => {
      setSelectedRange(newValue);
    },
    placeholder: "Select Item...",
    maxTagCount: "responsive",
  };

  const tenureOptions: any = [
    {
      label: "Less than 1 year",
      value: "[0, 1]",
    },
    {
      label: "1-2 years",
      value: "[1, 2]",
    },
    {
      label: "2-5 years",
      value: "[2, 5]",
    },
    {
      label: "5-10 years",
      value: "[5, 10]",
    },
    {
      label: "10-20 years",
      value: "[10, 20]",
    },
    {
      label: "More than 20 years",
      value: "[21, 21]",
    },
  ];

  const selectTenureProps: any = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value: selectedTenure,
    options: tenureOptions,
    onChange: (newValue: any) => {
      setSelectedTenure(newValue);
    },
    placeholder: "Select Item...",
    maxTagCount: "responsive",
  };

  const handleApplyFilter = (values: any) => {
    setUserScope(values);
  };

  useEffect(() => {
    if (!sortedFws.length) return;
    setCurrentFrameworkId(sortedFws[defaultFrameworkIdx]?.id);
  }, [sortedFws]);

  const { isInProgress, download } = useDownload();

  const performXlsxExport = () => {
    if (!currentFrameworkId) return;
    download(
      `report_${currentFrameworkId}`,
      API_ENDPOINTS.exportXLSX
        .build()
        .appendQueryParam("frameworkId", currentFrameworkId)
        .end()
    );
  };

  const onValueChange = (value: any) => {
    if (selectedFilter === "jobTitle") {
      form.setFieldsValue({
        jobTitles: value,
      });
    } else if (selectedFilter === "office") {
      form.setFieldsValue({
        offices: value,
      });
    } else if (selectedFilter === "manger") {
      form.setFieldsValue({
        isManager: value,
      });
    } else if (selectedFilter === "employmentType") {
      form.setFieldsValue({
        employmentTypes: value,
      });
    } else if (selectedFilter === "function") {
      form.setFieldsValue({
        functions: value,
      });
    } else if (selectedFilter === "gender") {
      form.setFieldsValue({
        genders: value,
      });
    } else if (selectedFilter === "race") {
      form.setFieldsValue({
        raceEthinicities: value,
      });
    } else if (selectedFilter === "tenure") {
      form.setFieldsValue({
        tenure: value,
      });
    }
  };

  const resetFilter = () => {
    setSelectedFilter("");
    form.resetFields();
    setUserScope({});
  };

  return (
    <Restricted
      to={
        user?.roles[0] === UserRole.TeamMember &&
        user?.additionalPermissions.includes(
          UserAdditionalPermissions.ReportViewer
        )
          ? [UserRole.TeamMember]
          : [UserRole.PrimaryAdmin, UserRole.Admin, UserRole.TeamManager]
      }
    >
      <S.Grid isData={!!currentFrameworkId}>
        <ReportsHeader onExportButtonClick={performXlsxExport} />
        {/* Filter */}
        <div
          style={{
            gridColumn: "1 / 4",
            display: "flex",
          }}
        >
          <Form.Item
            name="frameworks"
            style={{
              width: "300px",
            }}
          >
            {!loading ? (
              selectOptions[defaultFrameworkIdx]?.label ? (
                <Select
                  key="1"
                  size="large"
                  defaultValue={selectOptions[defaultFrameworkIdx].value}
                  options={selectOptions}
                  onSelect={(value: any) => {
                    setCurrentFrameworkId(value);
                    setSelectedFilter("");
                    setUserScope("");
                  }}
                />
              ) : (
                <Select
                  key="3"
                  size="large"
                  defaultValue="No shared frameworks available"
                />
              )
            ) : (
              <Select key="2" loading defaultValue="Loading..." />
            )}
          </Form.Item>
          <Form
            name="frameworkUserfilter"
            layout="vertical"
            style={{ display: "flex" }}
            form={form}
            onFinish={(values: any) => handleApplyFilter(values)}
          >
            <Form.Item
              label={
                <Text strong style={{ marginRight: "1rem" }}>
                  Filter by
                </Text>
              }
              style={{
                width: "15rem",
                marginLeft: "1rem",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Dropdown
                placeholder="Select"
                onChange={handleChange}
                {...selectFilterProps}
              />
            </Form.Item>
            {selectedFilter === "office" && (
              <Form.Item
                label={
                  <Text strong style={{ marginRight: "1rem" }}>
                    Select Office
                  </Text>
                }
                style={{
                  width: "18rem",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  display: "flex",
                  flexDirection: "row",
                }}
                name="offices"
                rules={[
                  {
                    required: true,
                    message: "Please select office",
                  },
                ]}
              >
                <Dropdown
                  placeholder="Select"
                  {...selectOfficeProps}
                  onChange={onValueChange}
                />
              </Form.Item>
            )}
            {selectedFilter === "jobTitle" && (
              <>
                <Form.Item
                  label={
                    <Text strong style={{ marginRight: "1rem" }}>
                      Select Job Title
                    </Text>
                  }
                  style={{
                    width: "27rem",
                    marginLeft: "1rem",
                    marginRight: "1rem",
                    display: "flex",
                    flexDirection: "row",
                  }}
                  name="jobTitles"
                  rules={[
                    {
                      required: true,
                      message: "Please select job title",
                    },
                  ]}
                >
                  <Dropdown
                    placeholder="Select"
                    {...selectJobTitleProps}
                    onChange={onValueChange}
                  />
                </Form.Item>
              </>
            )}
            {selectedFilter === "manager" && (
              <Form.Item
                label={
                  <Text strong style={{ marginRight: "1rem" }}>
                    Select option
                  </Text>
                }
                style={{
                  width: "18rem",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                }}
                name="isManager"
                rules={[
                  {
                    required: true,
                    message: "Please select option",
                  },
                ]}
              >
                <Dropdown
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  {...selectManagerProps}
                  allowClear
                  onChange={onValueChange}
                />
              </Form.Item>
            )}
            {selectedFilter === "employmentType" && (
              <Form.Item
                label={
                  <Text strong style={{ marginRight: "1rem" }}>
                    Select Emp Type
                  </Text>
                }
                style={{
                  width: "20rem",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  display: "flex",
                  flexDirection: "row",
                }}
                name="employmentTypes"
                rules={[
                  {
                    required: true,
                    message: "Please select emp type",
                  },
                ]}
              >
                <Dropdown
                  placeholder="Select"
                  {...selectEmploymentTypeProps}
                  onChange={onValueChange}
                />
              </Form.Item>
            )}
            {selectedFilter === "function" && (
              <Form.Item
                label={
                  <Text strong style={{ marginRight: "1rem" }}>
                    Select Function
                  </Text>
                }
                style={{
                  width: "20rem",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  display: "flex",
                  flexDirection: "row",
                }}
                name="functions"
                rules={[
                  {
                    required: true,
                    message: "Please select function",
                  },
                ]}
              >
                <Dropdown
                  placeholder="Select"
                  {...selectFunctionProps}
                  onChange={onValueChange}
                />
              </Form.Item>
            )}
            {selectedFilter === "gender" && (
              <Form.Item
                label={
                  <Text strong style={{ marginRight: "1rem" }}>
                    Select Gender
                  </Text>
                }
                style={{
                  width: "20rem",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  display: "flex",
                  flexDirection: "row",
                }}
                name="genders"
                rules={[
                  {
                    required: true,
                    message: "Please select gender",
                  },
                ]}
              >
                <Dropdown
                  placeholder="Select"
                  allowClear
                  {...selectGenderProps}
                  onChange={onValueChange}
                />
              </Form.Item>
            )}
            {selectedFilter === "race" && (
              <Form.Item
                label={
                  <Text strong style={{ marginRight: "1rem" }}>
                    Select Race
                  </Text>
                }
                style={{
                  width: "20rem",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  display: "flex",
                  flexDirection: "row",
                }}
                name="raceEthinicities"
                rules={[
                  {
                    required: true,
                    message: "Please select race",
                  },
                ]}
              >
                <Dropdown
                  placeholder="Select"
                  {...selectRaceProps}
                  onChange={onValueChange}
                />
              </Form.Item>
            )}
            {selectedFilter === "salary" && (
              <Form.Item
                label={
                  <Text strong style={{ marginRight: "1rem" }}>
                    Select Range
                  </Text>
                }
                style={{
                  width: "24rem",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Input.Group style={{ display: "flex" }}>
                  <Form.Item
                    name={["salary", "operator"]}
                    style={{
                      width: "7rem",
                      marginLeft: "1rem",
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Please select range",
                      },
                    ]}
                  >
                    <Dropdown
                      placeholder="Select"
                      {...selectRangeProps}
                      onChange={onValueChange}
                    />
                  </Form.Item>
                  <Form.Item
                    name={["salary", "value"]}
                    style={{
                      width: "8rem",
                      marginLeft: "1rem",
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Please input salary",
                      },
                    ]}
                  >
                    <Input placeholder="Input salary" type="number" min={0} />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            )}
            {selectedFilter === "tenure" && (
              <Form.Item
                label={
                  <Text strong style={{ marginRight: "1rem" }}>
                    Select Tenure
                  </Text>
                }
                style={{
                  width: "18rem",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  display: "flex",
                  flexDirection: "row",
                }}
                name="tenure"
                rules={[
                  {
                    required: true,
                    message: "Please select tenure",
                  },
                ]}
              >
                <Dropdown
                  placeholder="Select"
                  {...selectTenureProps}
                  onChange={onValueChange}
                />
              </Form.Item>
            )}
            {selectedFilter === "hireDate" && (
              <Form.Item
                label={
                  <Text strong style={{ marginRight: "1rem" }}>
                    Select Range
                  </Text>
                }
                style={{
                  width: "24rem",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Input.Group style={{ display: "flex" }}>
                  <Form.Item
                    name={["hireDate", "operator"]}
                    style={{
                      width: "7rem",
                      marginLeft: "1rem",
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Please select range",
                      },
                    ]}
                  >
                    <Dropdown
                      placeholder="Select"
                      {...selectHireDateRangeProps}
                    />
                  </Form.Item>
                  <Form.Item
                    name={["hireDate", "value"]}
                    style={{
                      width: "8rem",
                      marginLeft: "1rem",
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Please input date",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder="Pick a date"
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            )}
            {selectedFilter !== "" ? (
              <>
                <Button
                  htmlType="submit"
                  $width="5rem"
                  $bgColor={theme.colors.blue_100}
                  $margin="0 1rem"
                >
                  <Text strong>Apply</Text>
                </Button>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "2rem",
                  }}
                >
                  <Link onClick={() => resetFilter()}>Reset</Link>
                </div>
              </>
            ) : null}
          </Form>
        </div>
        {!loading && (
          <>
            <ChartsRow
              frameworkId={currentFrameworkId}
              userScopeValue={userScope}
            />
            <FinancialTables
              frameworkId={currentFrameworkId}
              userScopeValue={userScope}
            />
            <Calendar
              frameworkId={currentFrameworkId}
              userScopeValue={userScope}
            />
            <DimensionsList
              frameworkId={currentFrameworkId}
              userScopeValue={userScope}
            />
          </>
        )}
      </S.Grid>
    </Restricted>
  );
};

export default Teams;
