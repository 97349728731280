export enum PollStep {
  MANAGER_STEP_ONE = "MANAGER_STEP_ONE",
  MANAGER_STEP_TWO = "MANAGER_STEP_TWO",
  MANAGER_STEP_THREE = "MANAGER_STEP_THREE",
  EMPLOYEE_STEP_ONE = "EMPLOYEE_STEP_ONE",
  EMPLOYEE_STEP_TWO = "EMPLOYEE_STEP_TWO",
  EMPLOYEE_STEP_THREE = "EMPLOYEE_STEP_THREE",
}

export const enum ProfileFields {
  GENDER = "gender",
  RACE_OR_ETHNICITY = "raceOrEthnicity",
}
