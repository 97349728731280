import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
`;

export const Row = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  align-items: center;
`;

export const Scroll = styled.div`
  overflow-y: scroll;
  height: 72vh;
`;
export const Userscount = styled.h3`
  color: dimgrey;
  margin-top: 1rem;
}`;
