import moment from "moment";
import { Dispatch, SetStateAction, useState, FC, useMemo, memo } from "react";
import { TimePicker } from "antd";
import { FeaturePlaceholder } from "utils/helpers";
import { getFormattedTime } from "utils/formatters";
import { ChoicesPlaceholders } from "constants/miscellaneous";
import { PlaceholderField } from "./placeholderField";

import * as S from "./placeholder.styles";

export type PlaceholderProps = {
  value?: FeaturePlaceholder | string;
  text?: string | null;
  isEditable?: boolean;
  onClick?: Dispatch<SetStateAction<boolean>>;
  onChangePlaceholderText?: (value: string) => void;
};

const Input: FC<
  PlaceholderProps & { setIsEditing: (state: boolean) => void }
> = ({ onChangePlaceholderText = () => void 0, text, setIsEditing }) => (
  <input
    name="placeholderValue"
    autoFocus
    type="number"
    step="0.01"
    onChange={(e) => onChangePlaceholderText(e.target.value)}
    value={text ?? ""}
    onBlur={() => setIsEditing(false)}
  />
);

const TimeRangePicker: FC<PlaceholderProps> = ({
  text,
  isEditable,
  onChangePlaceholderText = () => void 0,
}) => {
  const [from, to] = text?.split("|") || [];
  // @TODO - get rid of styling here
  // This component is shrinked and almost invisible in admin without that
  return isEditable ? (
    <TimePicker.RangePicker
      style={{ width: "300px", height: "40px" }}
      bordered={false}
      use12Hours
      format="h:mm A"
      minuteStep={15}
      defaultValue={(from && to && [moment(from), moment(to)]) || undefined}
      onChange={(range) =>
        onChangePlaceholderText(
          `${range?.[0]?.toISOString()}|${range?.[1]?.toISOString()}`
        )
      }
    />
  ) : (
    <PlaceholderField
      text={`${getFormattedTime(from)} - ${getFormattedTime(to)}`}
    />
  );
};

const Placeholder: FC<PlaceholderProps> = (props) => {
  const { value, isEditable = false, text } = props;

  const [isEditing, setIsEditing] = useState(false);

  let component;
  let isValueOnlyVisible = false;
  switch (value) {
    case ChoicesPlaceholders.DATE:
      isValueOnlyVisible = true;
      component = <TimeRangePicker {...props} />;
      break;
    default:
      component = <Input {...props} setIsEditing={setIsEditing} />;
  }

  return (
    <>
      {(isValueOnlyVisible || (isEditable && isEditing)) && component}
      <S.Wrap hidden={isValueOnlyVisible}>
        <S.ValueWrap
          onClick={() =>
            isEditable && !!setIsEditing && setIsEditing(!isEditing)
          }
        >
          {!isEditing && text} {value}
        </S.ValueWrap>
      </S.Wrap>
    </>
  );
};

export default Placeholder;
