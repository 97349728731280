import styled from "styled-components";
import { Tag } from "antd";
import theme from "theme";

type Props = {
  active?: boolean;
};

export const Chip = styled(Tag)<Props>`
  height: 30px;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  padding: 4px 4px 4px 12px;
  font-weight: 400;
  display: flex;
  background-color: ${({ active }) =>
    active ? theme.colors.blue_100 : theme.colors.white};
  color: ${({ active }) =>
    active ? theme.colors.white : theme.colors.teal_80};
  &:hover {
    cursor: pointer;
  }
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;
