import styled, { css } from "styled-components";
import { Props } from "./Container";

export const SContainer = styled.div<Props>`
  ${({ center, width = "100%", height = "auto", padding = 0 }) => css`
    ${center
      ? css`
          display: grid;
          place-items: center;
          text-align: center;
        `
      : ""}
    width: ${width};
    min-height: ${height};
    padding: ${padding};
  `}
`;
