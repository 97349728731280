import { FC, useContext } from "react";
import Input from "components/Input";
import Button from "components/Button";
import theme from "theme";
import {
  useAccountUserQuery,
  useProfileUpdateMutation,
} from "codegen/generated/graphql";
import { Col, notification, Row, Form } from "antd";
import { AuthContext } from "utils/context/auth";
import Loader from "components/Loader";
import { Title } from "../TeamProfile/TeamProfile.styles";

enum FORM_FIELD_NAME {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  EMAIL = "email",
}

const AccountDetail: FC = () => {
  const [form] = Form.useForm();

  const { user } = useContext(AuthContext);

  const { data, loading } = useAccountUserQuery({
    variables: {
      id: user?.id || "",
    },
  });

  const [updateProfile] = useProfileUpdateMutation();

  const handleSubmit = async (
    firstName: string,
    surName: string,
    email: string
  ) => {
    await updateProfile({
      variables: {
        input: {
          name: firstName,
          surname: surName,
          email,
          id: data?.accountUser?.id || "",
        },
      },
    })
      .then(() => {
        notification.success({
          type: "success",
          message: "Account Owner saved",
          placement: "bottomLeft",
        });
      })
      .catch((e) => {
        notification.error({
          type: "error",
          message: `${e}`,
          placement: "bottomLeft",
        });
      });
  };

  if (loading) return <Loader />;

  return (
    <Form
      name={"accountDetailForm"}
      form={form}
      autoComplete={"off"}
      initialValues={{
        firstName: data?.accountUser?.profile?.name,
        lastName: data?.accountUser?.profile?.surname,
        email: data?.accountUser?.email,
      }}
      onFinish={({
        firstName,
        lastName,
        email,
      }: {
        [FORM_FIELD_NAME.FIRST_NAME]: string;
        [FORM_FIELD_NAME.LAST_NAME]: string;
        [FORM_FIELD_NAME.EMAIL]: string;
      }) => {
        handleSubmit(firstName, lastName, email);
      }}
    >
      <Title level={4} margin="0 0 1rem 0">
        Account Owner
      </Title>
      <Row gutter={[10, 0]}>
        <Col span={12}>
          <Form.Item
            name={FORM_FIELD_NAME.FIRST_NAME}
            rules={[
              {
                required: true,
                message: "Value is required",
              },
            ]}
          >
            <Input label="First Name" name="firstName" placeholder="Name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={FORM_FIELD_NAME.LAST_NAME}
            rules={[
              {
                required: true,
                message: "Value is required",
              },
            ]}
          >
            <Input label="Last Name" name="lastName" placeholder="Surname" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name={FORM_FIELD_NAME.EMAIL}
        rules={[
          {
            required: true,
            message: "Value is required",
          },
        ]}
      >
        <Input label="Email" name="email" type="email" />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <Button
            $width="100%"
            $bgColor={theme.colors.blue_100}
            $color="white"
            $padding="0.6rem 0 1.9rem 0"
            htmlType="submit"
            disabled={
              !form.isFieldsTouched() ||
              !!form.getFieldsError().filter(({ errors }) => errors.length)
                .length
            }
          >
            <strong>Save Changes</strong>
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

export default AccountDetail;
