import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  display: flex;
  height: 56px;
  border-radius: 6px;
  border: ${(props) => `1px solid ${props.theme.colors.blue_10}`};
  background: linear-gradient(
    109.04deg,
    rgba(255, 255, 255, 0.63) -23.19%,
    rgba(255, 255, 255, 0.09) 101.71%
  );
  padding: 8px 26px;
  box-shadow: 0 11px 18px -5px rgba(1, 8, 58, 0.04);
  cursor: pointer;
  transition: all 0.75s;

  &:hover {
    box-shadow: 0 11px 18px -5px rgba(1, 8, 58, 0.14);
  }
`;
