import styled from "styled-components";

import Logo from "components/Logo";

export const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;

  width: 100%;
  flex-direction: column;
  justify-content: stretch;
  background: ${({ theme }) => theme.colors.blue_100};
`;

export const Row = styled.div`
  flex-direction: row;
  display: flex;
`;

export const Tab = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  align-items: center;
  cursor: pointer;
`;

export const TabLabel = styled.span<{ isActive: boolean }>`
  padding: 10px 0;
  text-align: center;
  font-weight: ${({ isActive }) => (isActive ? 600 : "normal")};
  color: ${({ isActive, theme: { colors } }) =>
    isActive ? colors.white : colors.grey_50};
`;

export const Highlight = styled.div`
  height: 3px;
  width: 38px;
  background: ${({ theme }) => theme.colors.gold_60};
`;

export const LogoImage = styled(Logo)`
  margin: 10px 16px;
`;
