import { Card, Typography, Col } from "antd";
import theme from "theme";
import styled from "styled-components";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";
import { ButtonTertiary as BtnTertiary } from "components/Button";

type TextTypes = {
  secondary: boolean;
};

const { Text } = Typography;

export const UICard = styled(Card)`
  width: 300px;
  margin: 30px;
  margin-left: auto;
  background-color: ${({ theme }) => theme.colors.blue_5};
`;

export const UIText = styled(Text)<TextTypes>`
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.red_40};
`;

export const UIRightOutlined = styled(RightOutlined)`
  font-size: 6px;
  vertical-align: middle;
  margin-right: 10px;
`;

export const UIDeleteOutlined = styled(DeleteOutlined)`
  font-size: 16px;
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.red_40};
  margin-left: -5px;
`;

export const ButtonTertiary = styled(BtnTertiary)`
  background-color: ${({ theme }) => theme.colors.grey_40};
`;

export const Wrapper = styled(Col)<{ active?: boolean }>`
  padding: 30px;
  margin-top: 15px;
  background: linear-gradient(
    109.04deg,
    rgba(255, 255, 255, 0.63) -23.19%,
    rgba(255, 255, 255, 0.09) 101.71%
  );
  box-shadow: 0px 11px 18px -5px rgba(1, 8, 58, 0.04);
  border-radius: 20px;
  color: ${({ active }) =>
    active ? theme.colors.white : theme.colors.blue_100};

  // disable blue background when selecting text
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
`;
