import styled from "styled-components";
import { Row } from "antd";

export const TitleContainer = styled(Row)`
  margin-bottom: 2rem;

  .trial-feature {
    font-size: 26px;
  }
`;
