import styled from "styled-components";

export const NoDataWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NoDataTitle = styled.div`
  margin-bottom: 30px;
  margin-bottom: 50px;
  color: ${({ theme }) => theme.colors.grey_70};
`;

export const Warning = styled.div`
  text-align: center;
  margin: 15px 0;
`;
