import { VFC, useEffect } from "react";
import { useStripeCreateCustomerPortalSessionMutation } from "codegen/generated/graphql";
import { Loader } from "components";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import { Routes } from "router/routes";

export const ManageSubscription: VFC = () => {
  const [getSessionUrl] = useStripeCreateCustomerPortalSessionMutation();
  const history = useHistory();
  useEffect(() => {
    const confirmed = window.confirm("Do you want to continue on Stripe page?");
    const fn = async () => {
      const sessionUrl = await getSessionUrl();
      const url = sessionUrl.data?.stripeCreateCustomerPortalSession;

      if (!url)
        Modal.error({
          title: "Error while fetching stripe customer portal session token",
        });
      if (confirmed) window.location.href = url ?? "";
      else history.push(Routes.TEAM_PROFILE.path);
    };

    fn();
  }, [getSessionUrl, history]);
  return <Loader />;
};

export default ManageSubscription;
