import React from "react";
import { Button, Col, Form, Row, Tooltip } from "antd";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import { MaterialInput } from "../MaterialInput";
import Link from "antd/es/typography/Link";
import theme from "../../theme";
import { Routes } from "../../router/routes";
import { useHistory } from "react-router-dom";
import { useQuery } from "utils/router";
import { PasswordTooltipContent } from "../Tooltip/PasswordTooltipContent";

enum FORM_FIELD_NAME {
  PASSWORD = "password",
  REPEATED_PASSWORD = "repeatedPassword",
}

interface Props {
  confirmPasswordResetLink: (
    oobCode: string,
    newPassword: string
  ) => Promise<void>;
}

export const ResetPasswordForm: React.FC<Props> = ({
  confirmPasswordResetLink,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const query = useQuery();

  return (
    <Form
      name={"resetPasswordForm"}
      form={form}
      autoComplete={"off"}
      onFinish={() =>
        confirmPasswordResetLink(
          query.get("oobCode") ?? "",
          form.getFieldValue(FORM_FIELD_NAME.PASSWORD)
        ).then(() => {
          history.push(Routes.LOGIN.path);
        })
      }
    >
      <Row justify="start" gutter={[8, 24]}>
        <Col span={17}>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Title level={1} style={{ marginBottom: 0 }}>
                Reset
              </Title>
              <Title level={1} style={{ margin: 0 }}>
                your Password?
              </Title>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Col span={24}>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldsValue }) => {
                const { password } = getFieldsValue();
                return (
                  <Tooltip
                    visible={!!password}
                    color="#fff"
                    placement="right"
                    overlayInnerStyle={{
                      boxShadow: "0px 2px 4px rgba(232, 232, 233, 0.5)",
                      width: "270px",
                      padding: "15px",
                      fontSize: "13px",
                    }}
                    title={
                      <PasswordTooltipContent
                        isLengthValid={true}
                        isUppercaseValid={false}
                        isStrengthValid={true}
                      />
                    }
                  >
                    <Form.Item
                      name={FORM_FIELD_NAME.PASSWORD}
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <MaterialInput placeholder={"Password"} type="password" />
                    </Form.Item>
                  </Tooltip>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={FORM_FIELD_NAME.REPEATED_PASSWORD}
              dependencies={[FORM_FIELD_NAME.PASSWORD]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, repeatedPassword) {
                    if (
                      !repeatedPassword ||
                      getFieldValue(FORM_FIELD_NAME.PASSWORD) ===
                        repeatedPassword
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <MaterialInput placeholder={"Repeat Password"} type="password" />
            </Form.Item>
          </Col>
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Row justify="center">
                <Col>
                  <Button type="primary" htmlType={"submit"}>
                    Submit New Password
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="center">
                <Col>
                  <Text>
                    {"Don’t you need a new one? "}
                    <Link
                      color={theme.colors.blue_70}
                      underline={true}
                      onClick={() => {
                        history.push(Routes.LOGIN.path);
                      }}
                    >
                      Log in
                    </Link>
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
