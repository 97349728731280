import React from "react";
import theme from "../../theme";
import { useTagAttributes } from "../../utils/hooks";
import { TagAttributes } from "../../common/types";
import { CommonTag } from "./commonTag";
import { FrameworkStatus } from "codegen/generated/graphql";

export { FrameworkStatus };
type Props = {
  status: FrameworkStatus;
};

const attributesMap = new Map<FrameworkStatus, TagAttributes>([
  [FrameworkStatus.Created, { text: "Created", bgColor: theme.colors.blue_50 }],
  [FrameworkStatus.Shared, { text: "Shared", bgColor: theme.colors.yellow_40 }],
  [FrameworkStatus.Active, { text: "Active", bgColor: theme.colors.green_40 }],
  [FrameworkStatus.Ended, { text: "Ended", bgColor: theme.colors.grey_70 }],
]);

export const FrameworksStatusTag: React.FC<Props> = (props) => {
  const { status } = props;

  const attributes = useTagAttributes(attributesMap, status);

  return <CommonTag tagAttributes={attributes} />;
};
