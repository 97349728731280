import { Checkbox as AntCheckbox } from "antd";
import styled from "styled-components";

export const Checkbox = styled(AntCheckbox)`
  // Color for Label
  color: ${(props) => props.theme.colors.grey_70};
  font-size: 15px;

  & .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(props) => props.theme.colors.blue_70};
  }

  & .ant-checkbox-input:hover + .ant-checkbox-inner {
    border-color: ${(props) => props.theme.colors.blue_70};
  }
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.theme.colors.blue_70};
    border-color: ${(props) => props.theme.colors.blue_70};
  }

  & .ant-checkbox-checked::after {
    border: none;
  }
`;
