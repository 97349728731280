import { ApolloProvider } from "@apollo/client";
import { IntercomProvider } from "react-use-intercom";
import { ThemeProvider } from "styled-components";
import { Router } from "react-router-dom";
import { client } from "apolloClient";

import { Router as AppRouter } from "./router";
import theme from "./theme";
import { Bootstrap } from "./bootstrap";
import { history } from "./router/history";

import AuthProvider from "utils/context/auth";
import { PollContextProvider } from "./utils/context";
import "App.less";

import PermissionProvider from "components/Permissions/PermissionsProvider";

// @TODO - move to env or consts. Prefferably env
const INTERCOM_APP_ID = "pj5zemub";

const App = () => {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <Bootstrap>
              <AuthProvider>
                <PermissionProvider>
                  <PollContextProvider>
                    <AppRouter />
                  </PollContextProvider>
                </PermissionProvider>
              </AuthProvider>
            </Bootstrap>
          </Router>
        </ThemeProvider>
      </ApolloProvider>
    </IntercomProvider>
  );
};
export default App;
