import styled, { css } from "styled-components";
import { Props } from "../Legend";
import { Doughnut as ChartjsDoughnut } from "react-chartjs-2";

const isDashboardStyles = css`
  position: relative;
  top: -8px;
`;

const isDataStyles = css`
  padding: 10px 10px 10px 10px;
`;

const isNotDataStyles = (isOnDashboard?: boolean) =>
  css`
    height: ${isOnDashboard ? "288" : "280"}px;
  `;

export const Wrap = styled.div<{ isData?: boolean; isOnDashboard?: boolean }>`
  padding-bottom: 15px;
  display: flex;
  ${({ isOnDashboard }) => isOnDashboard && isDashboardStyles}
  flex-direction: column;
  justify-content: flex-end;
  ${({ isData, isOnDashboard }) =>
    isData ? isDataStyles : isNotDataStyles(isOnDashboard)}
`;

export const Doughnut = styled(ChartjsDoughnut)<{ diameterInPx?: number }>`
  height: ${({ diameterInPx }) =>
    diameterInPx ? `${diameterInPx}px` : "350px"} !important;
  width: ${({ diameterInPx }) =>
    diameterInPx ? `${diameterInPx}px` : "350px"} !important;
  margin: auto;
`;

export const LegendWrap = styled.div<
  { margin?: string } & Pick<Props, "numOfCols">
>`
  width: ${({ numOfCols }) => (numOfCols === 2 ? "50%" : "100%")};
  display: grid;
  grid-template-columns: ${({ numOfCols }) =>
    css`repeat(${numOfCols}, minmax(0, 1fr))`};
  grid-column-gap: ${({ numOfCols }) => (numOfCols === 2 ? "100px" : "35px")};
  padding-bottom: 10px;
  margin: ${({ margin }) => margin ?? "0"};
`;

export const LegendItemColor = styled.div<{ bgColor: string }>`
  --color: ${({ bgColor }) => bgColor};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LegendItemWrap = styled.div`
  text-align: center;
  white-space: nowrap;
  display: flex;
  margin-bottom: 6px;
`;

export const LegendItem = styled.span`
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.grey_70};
`;

export const Count = styled.span`
  color: var(--color);
  font-weight: 800;
  font-size: small;
  border-right: 1px solid #dfdfdf;
  line-height: 100%;
  min-width: 20px;
  padding-right: 10px;
`;

export const Dot = styled.div`
  width: 0.7rem;
  border-radius: 50%;
  height: 0.7rem;
  background-color: var(--color);
`;

export const Cursor = styled.p`
  cursor: pointer;
  padding: 10px 0;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.1);
  }
`;

export const position = styled.div`
  display: flex;
  justify-content: end;
`;
