import {
  FeatureType,
  useAggregateFrameworkDataQuery,
} from "codegen/generated/graphql";
import * as S from "../styles";
import { Collapse } from "antd";
import { DashboardCard } from "pages/dashboard/components/DashboardCard";
import { DoughnutChart } from "components/Charts/DoughnutChart";
import { dougnutChartOptionsWithOnClickHandler } from "components/Charts/DoughnutChart/mock";
import { VFC, useMemo, useCallback } from "react";
import { omit } from "lodash";
import { labelColors } from "components/Charts/DoughnutChart/plugins";
import { Loader } from "components";
import {
  OpenTextTable,
  Responder,
  useOpenTextTableModal,
} from "./OpenTextTable/OpenTextTable";

const { Panel } = Collapse;

type Props = {
  frameworkId: string | undefined;
  userScopeValue?: any;
};

export const DimensionsList: VFC<Props> = ({ frameworkId, userScopeValue }) => {
  const { data, loading } = useAggregateFrameworkDataQuery({
    fetchPolicy: "no-cache",
    variables: {
      frameworkId: frameworkId ?? "",
      userScope: userScopeValue,
    },
  });

  const [OpenTextTableModal, openOpenTextTableModal] = useOpenTextTableModal();

  const additionalChartData = (steps: number) => {
    return {
      backgroundColor: labelColors(steps),
      hoverOffset: 30,
      borderColor: "transparent",
      borderWidth: 60,
      cutout: 87,
    };
  };

  // @TODO typing
  const featureData = useCallback((datasets: any, feature: any) => {
    const dataset = datasets[0] ?? {};

    const enhancedDataset = {
      ...dataset,
      ...additionalChartData(datasets[0].data.length),
    };
    const chartData = {
      ...feature,
      datasets: [omit(enhancedDataset, "label")],
    };
    const isOpenText = chartData.datasets[0].featureType === FeatureType.Text;

    return { ...dataset, isOpenText, chartData };
  }, []);

  const responsesWithUsers = (feature: any) =>
    feature?.responders.map((responders: Responder[], i: number) => {
      return { responders, answer: feature.labels[i] };
    });

  const memoizedResult = useMemo(() => {
    if (loading) return <Loader />;
    return (
      <S.DimensionsWrap>
        {data?.aggregateFrameworkData.map((dimension, dimensionIdx) => (
          <S.DimensionWrap key={dimension.name}>
            <S.Collapse defaultActiveKey={[dimensionIdx.toString()]}>
              <Panel header={dimension.name} key={dimensionIdx}>
                <S.DimensionReports>
                  {dimension.segments.map((feature, featureIdx) => {
                    if (!!feature.datasets) {
                      const { isOpenText, title, label, data, chartData } =
                        featureData(feature.datasets, feature);

                      return (
                        <S.Feature key={featureIdx} isOpenText={isOpenText}>
                          <DashboardCard
                            title={title ? title : label}
                            isTitleHoverable={true}
                            titlePadding={1}
                            titleCutIdx={100}
                            isFeature
                          >
                            {isOpenText ? (
                              <OpenTextTable
                                tableData={responsesWithUsers(feature)}
                              />
                            ) : (
                              <DoughnutChart
                                total={data.reduce(
                                  (a: number, b: number) => a + b,
                                  0
                                )}
                                data={chartData}
                                options={dougnutChartOptionsWithOnClickHandler(
                                  feature,
                                  openOpenTextTableModal
                                )}
                                numOfCols={1}
                                diameterInPx={260}
                              />
                            )}
                          </DashboardCard>
                        </S.Feature>
                      );
                    }
                    return null;
                  })}
                </S.DimensionReports>
              </Panel>
            </S.Collapse>
          </S.DimensionWrap>
        ))}
      </S.DimensionsWrap>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.aggregateFrameworkData, featureData, loading]);

  return (
    <>
      <OpenTextTableModal tableData={[]} />
      {memoizedResult}
    </>
  );
};
