import React from "react";
import { Col, Row, Form } from "antd";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import { RadioWithBackground } from "../RadioWithBackground";
import { ModalStepsNavigation } from "../../common/types";
import { PollFooter } from "../index";

const questionnaireOptions = [
  { value: "1", label: "Team engagement" },
  { value: "2", label: "Talent attraction" },
  { value: "3", label: "Employee retention" },
  { value: "4", label: "Inclusion & belonging" },
  { value: "5", label: "Team productivity" },
];

const formFieldName = "managerGoal";

type Props = ModalStepsNavigation & {
  children?: never;
};

export const ManagerPollStepTwo: React.FC<Props> = (props) => {
  const { onNextStepClick, onBackStepClick, onSkipStepClick } = props;
  return (
    <Form.Item noStyle>
      <Row justify="center">
        <Col style={{ marginTop: "130px" }}>
          <Row justify="space-between">
            <Col span={24}>
              <Title level={1}>Let’s Get Started</Title>
            </Col>
            <Col span={24}>
              <Row gutter={[0, 50]}>
                <Col span={24}>
                  <Text type="secondary" style={{ fontSize: "26px" }}>
                    Which of the following is most important for you right now?
                  </Text>
                </Col>
                <Col span={24}>
                  <Row gutter={[0, 80]}>
                    <Col offset={4} span={16}>
                      <RadioWithBackground
                        options={questionnaireOptions}
                        formFieldName={formFieldName}
                      />
                    </Col>
                    <Col span={24}>
                      <PollFooter
                        backButtonConfig={{
                          label: "Back",
                          onClick: onBackStepClick,
                        }}
                        nextButtonConfig={{
                          label: "2/2 Next",
                          onClick: onNextStepClick,
                        }}
                        skipButtonConfig={{
                          label: "Skip",
                          onClick: onSkipStepClick,
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Item>
  );
};
