import React from "react";
import { Space } from "antd";
import Text from "antd/lib/typography/Text";

import TabsBar from "components/TabsBar";
import { Radio } from "components/Radio";
import { Input, Wrapper, Checkbox } from "components";

import * as S from "./styles";
import { FeatureType, Maybe } from "codegen/generated/graphql";
import { featureTypeString } from "utils/helpers";
import { useFeatureContext } from "../context";

const options = [
  FeatureType.SingleOption,
  FeatureType.MultiOption,
  FeatureType.Text,
];

const QuestionReportConfigContent = () => {
  const {
    onChange,
    state: {
      data: { hasReport, reportKey = "" },
    },
  } = useFeatureContext();

  return (
    <>
      <Wrapper margin="0.5rem 0 1.5rem 0">
        <Text strong>Reporting*</Text>
      </Wrapper>
      <Space direction="vertical">
        <Checkbox
          label="Is reportable?"
          checked={!!hasReport}
          onChange={(e) => onChange("hasReport")(e.target.checked)}
        />
        <Input
          hidden={!hasReport}
          name="reportKey"
          placeholder="Report key"
          value={reportKey ?? ""}
          onChange={(e) => onChange("reportKey")(e.target.value)}
        />
      </Space>
    </>
  );
};

const QuestionTypeContent: React.VFC<{
  value: string;
  onChange: (val: string) => void;
}> = ({ value, onChange }) => (
  <>
    <Wrapper margin="0.5rem 0 1.5rem 0">
      <Text strong>Question Type*</Text>
    </Wrapper>
    <S.RadioGroup
      name="QuestionType"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      optionType="default"
    >
      {options.map((option) => (
        <Radio key={option} value={option}>
          {featureTypeString(option)}
        </Radio>
      ))}
    </S.RadioGroup>
  </>
);

const FeatureTitleContent: React.VFC<{
  value?: Maybe<string>;
  onChange: (val: string) => void;
}> = ({ value, onChange }) => (
  <>
    <Wrapper margin="0.5rem 0 1.5rem 0">
      <Text strong>Feature Title</Text>
    </Wrapper>
    <Input
      name="featureTitle"
      value={value ?? ""}
      onChange={(e) => onChange(e.target.value)}
    />
  </>
);

export const QuestionSidebar: React.VFC = () => {
  const {
    onChange,
    state: {
      data: { type: typeValue, title: titleValue },
    },
  } = useFeatureContext();

  const tabs = [
    {
      tab: "Attributes",
      key: "attributes",
      disabled: false,
      tabsContent: (
        <>
          <FeatureTitleContent
            value={titleValue}
            onChange={onChange("title")}
          />
          <QuestionTypeContent value={typeValue} onChange={onChange("type")} />
          <QuestionReportConfigContent />
        </>
      ),
    },
    /*  {
      tab: "Tips",
      key: "tips",
      disabled: true,
      tabsContent: <></>,
    },
    {
      tab: "Facts",
      key: "facts",
      disabled: true,
      tabsContent: <></>,
    },*/
  ];

  return (
    <S.Wrapper>
      <TabsBar tabsOptions={tabs} />
    </S.Wrapper>
  );
};

export default QuestionSidebar;
