import styled from "styled-components";

export const OpenTextWrap = styled.div`
  padding: 0 22px 22px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0.2fr 1fr;
  gap: 10px 0px;
  grid-template-areas:
    "header header"
    ". .";
  max-height: 400px;
  overflow-y: scroll;
  grid-auto-flow: dense;
  box-shadow: inset 0px -32px 19px -13px rgba(255, 255, 255, 0.2);
  -webkit-mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
`;
export const OpenTextName = styled.div`
  width: 35%;
  color: ${({ theme }) => theme.colors.black};
`;
export const OpenTextAnswer = styled.div`
  width: 65%;
  color: ${({ theme }) => theme.colors.grey_70};
`;
export const OpenTextNamePopup = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
`;
export const Header = styled.header`
  display: flex;
  grid-area: header;
  /* align-items: start; */
  position: sticky;
  background-color: ${({ theme }) => theme.colors.blue_5};
  top: 0;
  padding: 10px;
  border: 0.5px solid ${({ theme }) => theme.colors.grey_4};
  border-radius: ${({ theme }) => theme.radius.buttonRadius};
  box-shadow: 0 11px 18px -5px rgba(1, 8, 58, 0.04);
  transition: 0.5s;
  &:hover {
    border: 0.5px solid ${({ theme }) => theme.colors.grey_40};
  }
  z-index: 2;
`;

export const HeaderItem = styled.span`
  color: ${({ theme }) => theme.colors.grey_70};
  font-size: 0.9em;
  width: 35%;
`;
export const HeaderItemPopup = styled.span`
  color: ${({ theme }) => theme.colors.grey_70};
  font-size: 0.9em;
  width: 100%;
`;
export const OpenTextItem = styled.article`
  border: 0.5px solid ${({ theme }) => theme.colors.blue_20};
  border-radius: ${({ theme }) => theme.radius.buttonRadius};
  padding: 13px;
  grid-column: span 2;
  display: flex;
  box-shadow: 0 11px 18px -5px rgba(1, 8, 58, 0.04);
`;
