import styled from "styled-components";

export const Wrapper = styled.div`
  height: 65vh;
  flex-direction: column;
  overflow: auto;
  justify-content: flex-start;

  background: linear-gradient(
    109.04deg,
    rgba(255, 255, 255, 0.63) -23.19%,
    rgba(255, 255, 255, 0.09) 101.71%
  );
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  box-shadow: 0 11px 18px -5px rgba(1, 8, 58, 0.04);
  backdrop-filter: blur(32px);

  border-radius: 20px;
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  max-height: 60px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 13px 10px 0;
`;
