import React from "react";
import { SContainer } from "./styles";

export type Props = {
  center?: boolean;
  width?: string;
  height?: string;
  padding?: string;
};

export const Container: React.FC<Props> = ({ children, ...props }) => {
  return <SContainer {...props}>{children}</SContainer>;
};
