import styled from "styled-components";
import { Tag, TimePicker } from "antd";
import { default as AntTitle } from "antd/lib/typography/Title";

export const Wrap = styled.div`
  padding: 2.2rem 1rem;
  overflow: auto;
  height: 100%;
  margin-right: 3rem;
`;

export const Title = styled(AntTitle)<{ margin?: string }>`
  color: ${({ theme }) => theme.colors.blue_100} !important;
  font-weight: bold;
  margin: ${({ margin }) => (margin ? margin : 0)} !important;
`;

export const NoTeamsWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding-top: 5rem;
`;

export const InfoIcon = styled.span`
  background-color: ${({ theme }) => theme.colors.blue_100};
  padding: 0.15rem 0.5rem 0.15rem 0.55rem;
  text-align: center;
  margin-left: 0.5rem;
  position: relative;
  top: -0.3rem;
  border-radius: 50%;
  color: white;
  font-size: 0.7em;
  font-weight: bolder;
  cursor: pointer;
`;

export const Answer = styled.p`
  color: ${({ theme }) => theme.colors.grey_70};
  font-size: 0.9em;
`;

export const NoTeamsText = styled.p`
  color: ${({ theme }) => theme.colors.grey_70};
  margin-top: 3rem;
`;

export const PriorityWrap = styled.div`
  clear: both;
`;

export const PriorityTag = styled(Tag)<{
  selected: boolean;
  $displayed: boolean;
}>`
  padding: 0.2rem 1rem;
  border-radius: 20px;
  margin: 0.5rem;
  border: ${({ selected }) =>
    selected ? "1px solid tranparent" : "1px solid #D0D6DF"};
  box-shadow: 0px 11px 18px -5px rgba(1, 8, 58, 0.04);
  color: ${({ selected, theme }) =>
    selected ? "white" : theme.colors.blue_100};
  display: ${({ $displayed }) => ($displayed ? "inline-block" : "none")};
  cursor: pointer;
  user-select: none;
`;

export const WorkingTime = styled.div`
  user-select: none;
  margin: 1rem;
`;

export const SRangePicker = styled(TimePicker.RangePicker)`
  width: 260px;
  background-color: #fbfbff;
  border-radius: 3px;
`;
