import React, {
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
  Dispatch,
} from "react";
import * as S from "./styles";
import arrowIcon from "assets/icons/downArrow.svg";
import { FeatureType, FwDimensionFragment } from "codegen/generated/graphql";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { HoverButton } from "components/Button";
import { Typography } from "antd";
import { ImgSvg } from "components";
import rightArrow from "assets/icons/rightArrowYellow.svg";
import { TABS, WorkDesignRoutes } from "pages/work-design/constants";
const { Text } = Typography;

type Props = {
  onNext: () => void;
  onPrev: () => void;
  currentIndex: number;
  total: number;
  disabledNext?: boolean;
  completedFeatures: number;
  isPreview?: boolean;
  setActiveTab?: Dispatch<SetStateAction<WorkDesignRoutes>>;
  canNavigate: boolean;
};

const WorkDesignNavigation: React.FC<Props> = ({
  onNext,
  onPrev,
  currentIndex,
  total,
  disabledNext,
  completedFeatures,
  isPreview,
  setActiveTab,
  canNavigate,
}) => {
  const { sm } = useBreakpoint();

  return (
    <S.Wrapper isMobile={!sm}>
      <S.ProgressWrapper>
        {!isPreview && (
          <>
            <S.ProgressLabel>{`${completedFeatures} / ${total} Completed `}</S.ProgressLabel>
            <S.ProgressBar
              percent={(completedFeatures / total) * 100}
              showInfo={false}
              strokeWidth={5}
            />
            {canNavigate && (
              <HoverButton
                onClick={() => setActiveTab && setActiveTab(TABS[2])}
              >
                <Text strong>Next</Text>
                <ImgSvg
                  src={rightArrow}
                  alt="next"
                  style={{ marginLeft: 10 }}
                />
              </HoverButton>
            )}
          </>
        )}
      </S.ProgressWrapper>
      <S.NavBtn onClick={onPrev} disabled={currentIndex === 0}>
        <S.IconPrev src={arrowIcon} />
      </S.NavBtn>
      <S.NavBtn
        onClick={onNext}
        disabled={currentIndex === total - 1 || disabledNext}
      >
        <S.IconNext src={arrowIcon} />
      </S.NavBtn>
    </S.Wrapper>
  );
};

export const useWorkDesignNavigation = (dimensions: FwDimensionFragment[]) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [completedFeatureIds, setCompletedFeatureIds] = useState<string[]>([]);

  const allFeatures = useMemo(
    () => dimensions.map(({ features }) => features).flat(),
    [dimensions]
  );
  const onNext = useCallback(() => {
    setCurrentIndex((step) => step + 1);
  }, []);
  const onPrev = useCallback(() => {
    setCurrentIndex((step) => step - 1);
  }, []);

  const currentFeature = allFeatures[currentIndex];

  const isNextDisabled =
    currentFeature &&
    currentFeature.type !== FeatureType.Text &&
    !completedFeatureIds.some((id) => id === currentFeature.id);

  const WDNavigation: FC<
    Pick<Props, "disabledNext" | "isPreview" | "setActiveTab" | "canNavigate">
  > = ({ disabledNext, isPreview, setActiveTab, canNavigate }) => (
    <WorkDesignNavigation
      setActiveTab={setActiveTab}
      onNext={onNext}
      onPrev={onPrev}
      currentIndex={currentIndex}
      total={allFeatures.length}
      disabledNext={disabledNext !== undefined ? disabledNext : isNextDisabled}
      isPreview={isPreview}
      completedFeatures={completedFeatureIds.length}
      canNavigate={canNavigate}
    />
  );

  return {
    WDNavigation,
    allFeatures,
    currentFeature,
    completedFeatureIds,
    setCompletedFeatureIds,
  };
};
