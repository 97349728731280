import styled from "styled-components";
import { Typography, Collapse as ACollapse, Card as ACard } from "antd";
import CheckOutlined from "@ant-design/icons/lib/icons/CheckOutlined";

const { Paragraph: AParagraph, Title: ATtitle, Text: AText } = Typography;

export const Card = styled(ACard)<{ $isActive?: boolean }>`
  border-radius: 6px;
  transition: 1s box-shadow;
  box-shadow: ${({ $isActive }) =>
    $isActive ? "0 4px 12px rgba(0,0,0,0.1)" : "none"};
  & > .ant-card-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
  }
`;

export const Paragraph = styled(AParagraph)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.grey};
    font-size: 13px;
    line-height: 19px;
  }
`;

export const Text = styled(AText)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.grey};
    font-size: 13px;
    line-height: 19px;
  }
`;

export const TextTitle = styled(AText)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.blue_100};
    font-size: 17px;
    line-height: 26px;
  }
`;

export const Title = styled(ATtitle)`
  &.ant-typography {
    font-weight: 400;
  }
`;

export const LargeText = styled(AText)`
  &.ant-typography {
    font-size: 42px;
    line-height: 54px;
  }
`;

export const Collapse = styled(ACollapse)`
  font-size: 13px;
  margin-bottom: 12px;

  & > .ant-collapse-item > .ant-collapse-header {
    padding: 2px 0;
  }
`;

export const CheckIcon = styled(CheckOutlined)`
  color: ${({ theme }) => theme.colors.blue_70};
  font-size: 9px;
  line-height: 22px;
  margin-right: 10px;
`;

export const FancyCard = styled(ACard)`
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  box-shadow: 0px 5px 48px -20px rgba(1, 8, 58, 0.2);
  backdrop-filter: blur(100px);
  border-radius: 20px;
  width: 100%;

  & > .ant-card-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 25px 29px;
  }
`;

export const ButtonWrap = styled.div`
  margin-top: auto;

  .ant-space {
    width: 100%;
  }
`;
