import styled from "styled-components";

import { ButtonSecondary } from "components/Button";

import logoSrc from "assets/icons/lluna-logo.svg";
import { Space } from "antd";

export const Screen = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  padding: 20px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  margin-top: 50px;
`;

export const Logo = styled.img.attrs({
  src: logoSrc,
})`
  margin-bottom: 20px;
`;

export const Description = styled.p`
  font-size: 17px;
  line-height: 25px;
  text-align: center;
`;

export const Bold = styled.b`
  font-weight: 600;
`;

export const Submit = styled(ButtonSecondary)`
  width: 345px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &.ant-btn {
    height: 48px;
  }
`;

export const ButtonLabel = styled.span`
  color: #01083a;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
`;

export const Footer = styled.div`
  display: flex;
  flex: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 26px 0 31px;

  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.blue_10};
`;

export const Handle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 163px;
  height: 22px;
  border-radius: 10px 10px 0 0;
  background: ${({ theme }) => theme.colors.blue_80};

  position: absolute;
  top: -22px;
  left: 0;
  right: 0;
  margin: 0 auto;
`;

export const HandleIcon = styled.img`
  width: 12px;
  transform: rotate(90deg);
`;

export const Row = styled.div<{ flex: number }>`
  flex: ${({ flex }) => flex};
  display: flex;
  flex-direction: row;
`;

export const Spacer = styled.div`
  height: 1rem;
  width: 1rem;
`;

export const ContentWrap = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;
