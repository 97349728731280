import React from "react";
import { Col } from "antd";
import Title from "antd/lib/typography/Title";

import * as S from "./ContentLayout.styles";

export enum ContentType {
  FullWidth = "FullWidth",
  WithSidebar = "WithSidebar",
  WithSubmenu = "WithSubmenu",
}

type HeaderConfig = {
  title: React.ReactNode;
  actions?: React.ReactNode;
};

type Props = {
  typeContent: ContentType;
  header?: HeaderConfig;
  sideContent?: React.ReactNode;
  isWDPreview?: boolean;
};

const ContentLayout: React.FC<Props> = ({
  header,
  typeContent,
  children,
  sideContent,
  isWDPreview,
}) => (
  <S.Wrapper isWDPreview={isWDPreview}>
    {header && (
      <S.Header>
        <Title level={3} style={{ fontWeight: "normal" }}>
          {header.title}
        </Title>
        {header.actions && <S.Actions>{header.actions}</S.Actions>}
      </S.Header>
    )}
    <S.Container hasHeader={!!header}>
      {typeContent === ContentType.FullWidth && (
        <S.Content>{children}</S.Content>
      )}
      {typeContent === ContentType.WithSidebar && (
        <>
          <S.Content>{children}</S.Content>
          <Col span={8}>{sideContent}</Col>
        </>
      )}
      {typeContent === ContentType.WithSubmenu && (
        <>
          <Col>{sideContent}</Col>
          <S.Content>{children}</S.Content>
        </>
      )}
    </S.Container>
  </S.Wrapper>
);

export default ContentLayout;
