import { Button } from "antd";
import styled, { css } from "styled-components";

import { COLOR_PRESETS } from "./colors";
import { colors } from "theme";

export const ButtonCore = styled(Button)`
  border-radius: ${({ theme }) => theme.radius.buttonRadius};

  &.ant-btn {
    box-shadow: none;
    padding: 0 1rem;
  }

  &.ant-btn > img,
  &.ant-btn > svg {
    position: relative;
    margin-right: 10px;
  }

  &:disabled {
    border: 1px solid transparent;
  }
  &:hover,
  :focus {
    color: ${({ theme }) => theme.colors.blue_100};
    border: 1px solid ${({ theme }) => theme.colors.blue_100};
  }
`;

export const ButtonPrimary = styled(ButtonCore)`
  ${({ theme: { colors } }) => css`
    width: 100%;
    &.ant-btn {
      height: 48px;
    }

    & > span,
    & > span > strong {
      color: ${colors.white};
    }

    background-color: ${colors.blue_100};
    &:hover {
      background-color: ${colors.blue_90};
    }
    &:focus {
      background-color: ${colors.blue_70};
    }
  `}
`;

export type SecondaryButtonProps = {
  presetName?: keyof typeof COLOR_PRESETS;
  outlined?: boolean;
};

export const ButtonSecondary = styled(ButtonCore)<SecondaryButtonProps>`
  ${({ theme, presetName, outlined, icon }) => {
    const preset = presetName ? COLOR_PRESETS[presetName] : COLOR_PRESETS.gold;

    const color =
      // Check whether the button s of type "goldWhite", because we have two different colors of "gold" button value
      outlined && presetName !== "goldWhite"
        ? theme.colors.blue_100
        : preset.color;

    if (outlined) {
      return css`
        &.ant-btn {
          height: 40px;
        }
        background-color: transparent;
        & > span,
        & > span > strong {
          color: ${color};
        }
        border: 1px solid ${!!icon ? preset.background : theme.colors.blue_100};

        &:hover {
          background-color: transparent;
          border: 1px solid ${theme.colors.blue_70};
        }

        &:active {
          background-color: ${theme.colors.blue_70};
          color: ${theme.colors.white};
          & > span,
          & > span > strong {
            color: ${theme.colors.white};
          }
        }
      `;
    }

    return css`
      &.ant-btn {
        height: 40px;
      }
      & > span,
      & > span > strong {
        color: ${color};
      }

      background-color: ${preset.background};
      border: 1px solid ${preset.background};
      &:hover,
      &:active {
        background-color: ${preset.hover};
        color: ${color};
        border: 1px solid ${preset.background};
      }
    `;
  }}
`;

export type TertiaryButtonProps = {
  outlined?: boolean;
  transparent?: boolean;
};

export const ButtonTertiary = styled(
  ({ outlined, transparent, ...otherProps }) => <ButtonCore {...otherProps} />
)<TertiaryButtonProps>`
  &.ant-btn {
    height: 36px;
  }
  & > span,
  & > span > strong {
    margin-top: 3px;
    color: ${({ theme }) => theme.colors.blue_100};
  }
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ outlined, transparent, theme: { colors } }) => {
    if (outlined || transparent) {
      return css`
        &.ant-btn {
          background: transparent; !important;
        }
        border: 1px solid ${transparent ? "transparent" : colors.blue_100};
        &:hover {
          border: 1px solid ${transparent ? colors.grey_4 : colors.blue_70};
        }
      `;
    }
    return css`
      & > span,
      & > span > strong {
        color: ${colors.white};
      }

      background-color: ${colors.blue_100};

      &:focus,
      &:hover {
        background-color: ${colors.blue_90};
        border: 1px solid ${colors.blue_90};
      }
    `;
  }}
`;

export const HoverButton = styled(ButtonTertiary)`
  position: fixed;
  bottom: 19px;
  right: 100px;
  z-index: 2;
  &.ant-btn {
    height: 45px;
  }
  border: 1px solid ${colors.blue_90};
  align-items: center;
  justify-content: center;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey_30};
  }
`;

export const HoverBackButton = styled(ButtonTertiary)`
  position: fixed;
  bottom: 19px;
  right: 210px;
  z-index: 2;
  &.ant-btn {
    height: 45px;
  }
  border: 1px solid ${colors.blue_90};
  align-items: center;
  justify-content: center;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey_30};
  }
`;

export const HoverFilterButton = styled(ButtonSecondary)`
  &.ant-btn {
    height: 2.5rem;
  }
  border: 1px solid ${colors.blue_90};
  align-items: center;
  justify-content: center;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey_30};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.blue_100};
  }
`;

/* @deprecated - use one of above instead */
export const UIButton = styled(Button)<{
  $bgColor?: string;
  $hoverBgColor?: string;
  $color?: string;
  $margin?: string;
  $padding?: string;
  $width?: string;
  $height?: string;
  $border?: string;
  $radius?: string;
}>`
  &.ant-btn > svg {
    position: relative;
    left: -5px;
    top: 2px;
  }
  font-weight: 600;
  & > span > strong {
    color: ${({ color }) => (color ? color : "white")};
  }
  color: ${({ $color }) => ($color ? $color : "white")};
  background-color: ${({ $bgColor, theme }) =>
    $bgColor ? $bgColor : theme.colors.gold_60};
  margin: ${({ $margin }) => ($margin ? $margin : "0")};
  padding: ${({ $padding }) => ($padding ? $padding : "0 1rem")};
  border-radius: ${({ theme, $radius }) =>
    $radius ? $radius : theme.radius.buttonRadius};
  border: ${({ $border }) =>
    $border ? `${$border}` : "1px solid transparent"};
  width: ${({ $width }) => ($width ? $width : "auto")};
  ${({ $height }) => ($height ? `height: ${$height}` : null)};
  &:hover,
  &:focus {
    // @NOTE: This exist for ActionBanner button component, take a look at UIKit in Figma
    background-color: ${({ $bgColor, theme }) => {
      switch ($bgColor) {
        case theme.colors.gold_60:
          return theme.colors.gold_30;
        case theme.colors.blue_100:
          return theme.colors.blue_60;
        case theme.colors.teal_70:
          return theme.colors.teal_50;
        case theme.colors.magenta_70:
          return theme.colors.magenta_50;
        case theme.colors.white:
          return "none";
        default:
          return theme.colors.white;
      }
    }};
    border: ${({ $bgColor }) =>
      $bgColor ? `1px solid ${$bgColor}` : `1px solid transparent`};
    color: ${({ color }) => (color ? color : "white")};
  }

  &:disabled {
    border: 1px solid transparent;
  }
`;
