import { FC } from "react";
import { useQuery } from "utils/router";
import ResetPassword from "./reset-password";
import VerifyEmail from "./verify-email";

const AuthActions: FC = () => {
  const query = useQuery();
  const [mode, oobCode] = ["mode", "oobCode"].map((key) => query.get(key));
  console.log(`Auth action '${mode}' (${oobCode})`);
  return mode === "verifyEmail" ? (
    <VerifyEmail oobCode={oobCode} />
  ) : (
    <ResetPassword />
  );
};

export default AuthActions;
