import React from "react";
import theme from "../../theme";
import { TagAttributes } from "../../common/types";
import { useTagAttributes } from "../../utils/hooks";
import { CommonTag } from "./commonTag";
import { FeatureType } from "../../codegen/generated/graphql";

const baseAttributes = {
  bgColor: theme.colors.grey_20,
  isRound: false,
  textColor: theme.colors.blue_100,
  fontWeight: 600,
};

const attributesMap = new Map<FeatureType, TagAttributes>([
  [
    FeatureType.SingleOption,
    {
      text: "Multiple Choice",
      ...baseAttributes,
    },
  ],
  [
    FeatureType.MultiOption,
    {
      text: "Multi-Select",
      ...baseAttributes,
    },
  ],
  [
    FeatureType.Text,
    {
      text: "Open Question",
      ...baseAttributes,
    },
  ],
]);

type Props = {
  type: FeatureType;
};

export const FeatureTypeTag: React.FC<Props> = (props) => {
  const { type } = props;

  const attributes = useTagAttributes(attributesMap, type);

  return <CommonTag tagAttributes={attributes} />;
};
