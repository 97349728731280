import { FC } from "react";
import { AvatarProps } from "antd";
import { ReactComponent } from "assets/images/defaultAvatar.svg";

import * as S from "./styles";

type Props = {
  fallback?: string;
  disallowInitials?: boolean;
} & AvatarProps;

const getInitials = (name?: string): string => {
  const words = name?.split(" ");

  if (!words?.length) return "";

  if (words.length === 1) {
    return words[0].substr(0, 2);
  }

  return words
    .slice(0, 2)
    .map((name) => name.substr(0, 1))
    .join("");
};

const Avatar: FC<Props> = ({
  children,
  src,
  fallback = "",
  disallowInitials = true,
  ...props
}: Props) => {
  const initials = getInitials(fallback).toUpperCase();

  if (disallowInitials && !children && !src)
    src = (
      <ReactComponent
        fill="#ffffffee"
        style={{
          width: "100%",
          height: "100%",
          padding: 2,
          transform: "translateY(0.5px)",
        }}
      />
    );

  return (
    <S.Avatar {...props} src={src}>
      {children ? children : initials}
    </S.Avatar>
  );
};

export default Avatar;
