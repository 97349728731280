import styled from "styled-components";

export const Tag = styled.span<{ background: string }>`
  background: ${({ background }) => `#${background}`};
  border-radius: 20px;
  padding: 4px 12px;
  align-self: flex-start;
  box-shadow: 0 11px 18px -5px rgba(1, 8, 58, 0.04);
`;

export const Label = styled.span<{ color: string }>`
  color: #${({ color }) => `${color}`};
`;
