import { Suspense, lazy, memo } from "react";
import { PageNotFound } from "pages";
import { Switch, Route } from "react-router-dom";
import StaticRoutes from "./routes";
import { RouteType, LayoutDescriptor } from "types/route";
import Loader from "components/Loader";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

const RouteComponentsMap = {
  [RouteType.Plain]: Route,
  [RouteType.Private]: PrivateRoute,
  [RouteType.Public]: PublicRoute,
};

const getLayoutPaths = (layout: LayoutDescriptor) =>
  layout.routes.map((route) => route.path);

const Layout: React.FC<LayoutDescriptor> = memo(({ layout, routes }) => {
  const normalizedRoutes = routes // Determine if the route should be exact
    .map((route) => ({
      ...route,
      exact: route.exact ?? true,
    }))
    .filter((route) => !route.component.isNested);

  const LayoutComponent = lazy(layout);
  return (
    <LayoutComponent
      children={normalizedRoutes.map(
        ({ component, restrictedTo, path, exact, type, ...rest }) => {
          const RouteComponent = RouteComponentsMap[type];
          return (
            <RouteComponent
              key={path}
              path={path}
              exact={exact}
              restrictedTo={restrictedTo}
              component={lazy(component)}
              {...rest}
            />
          );
        }
      )}
    />
  );
});

export const Router = () => {
  return (
    <Suspense fallback={<Loader fullScreen />}>
      <Switch>
        {StaticRoutes.map((layout, i) => (
          <Route key={i} exact path={getLayoutPaths(layout)}>
            <Layout {...layout} />
          </Route>
        ))}
        <Route component={PageNotFound} />
      </Switch>
    </Suspense>
  );
};
