import React from "react";
import { CheckboxProps } from "antd";
import * as S from "./styles";

type Props = {
  label?: string;
} & CheckboxProps;

export const Checkbox: React.FC<Props> = ({ label, children, ...rest }) => {
  return <S.Checkbox {...rest}>{label || children}</S.Checkbox>;
};
