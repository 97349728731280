import { ReactElement } from "react";
import { makeUseModal } from "lib/useModal";
import SubscribePage from "./Subscribe.page";

const SubscribeDialog = (): ReactElement => {
  return <SubscribePage isDialog={true} />;
};

export const useSubscribeDialog = makeUseModal(SubscribeDialog, {
  width: 1200,
});
