import { ComponentType } from "react";
import { UserRole } from "codegen/generated/graphql";

export enum RouteType {
  Public = "PublicRoute",
  Private = "PrivateRoute",
  Plain = "Route",
}

interface AsyncRouteComponent {
  (): Promise<{
    default: ComponentType<any>;
  }>;
  isNested?: symbol;
}

export type LayoutDescriptor = {
  layout: AsyncRouteComponent;
  routes: RouteDescriptor[];
};

export type RouteDescriptor = {
  path: string;
  component: AsyncRouteComponent;
  type: RouteType;
  restrictedTo?: UserRole[];
  // @deafult - true
  exact?: boolean;
};
