import { Form, Popover } from "antd";
import { useDownload } from "hooks";
import { Icon, Select } from "components";
import { BarChart } from "components/Charts/BarChart";
import { DashboardCard } from "pages/dashboard/components/DashboardCard";
import { VFC, useMemo, useState, useRef } from "react";
import * as S from "./styles";
import Text from "antd/lib/typography/Text";
import theme from "theme";
import { preferredTimesOptions } from "components/Charts/BarChart/mock";
import { NoData } from "components/Charts/NoData";
import { useAggregateFeaturesData } from "pages/reports/hooks/useAggregateFeaturesData";
import { labelColors } from "components/Charts/DoughnutChart/plugins";
import { useOpenTextTableModal } from "../OpenTextTable/OpenTextTable";
import { useFrameworkQuery } from "codegen/generated/graphql";
import { API_ENDPOINTS } from "constants/urls";
import { MenuOutlined } from "@ant-design/icons";
import { toPng, toJpeg } from "html-to-image";

const borderRadius = 10;
const perc = 0.4;

// @TODO - proper typings
function createChart(datasets: any) {
  if (!datasets || !datasets.length)
    return {
      labels: [],
      datasets: [],
    };

  const colors = labelColors(datasets[1].length);

  const labels = datasets[1].map((d: any) => d.text);
  const data = datasets[1].map((d: any, i: number, arr: any[]) => {
    const [from, to] = d.placeholderText
      .split("|")
      .map((date: string) => new Date(date).getTime());

    const array = Array.from({ length: arr.length }).fill(0);
    array[i] = [from, to];
    return array;
  });

  return {
    labels,
    datasets: datasets[1].map((dataset: any, i: number) => ({
      label: dataset.text,
      data: data[i],
      backgroundColor: colors[i],
      borderRadius,
      barPercentage: perc,
      categoryPercentage: perc,
      borderSkipped: false,
    })),
  };
}

const rebaseDate = (date: Date, newBaseDate: Date) => {
  const [dateIso, newBaseDateIso] = [date, newBaseDate].map((date) =>
    date.toISOString()
  );

  const dateIsoMatch = dateIso.match(/^(.*)T/)?.[1];
  const newBaseDateIsoDateMatch = newBaseDateIso.match(/^(.*)T/)?.[1];

  return new Date(
    dateIso.replace(dateIsoMatch ?? "", newBaseDateIsoDateMatch ?? "")
  );
};

const endOfTheDay = (date: Date) => {
  date.setUTCHours(23, 59, 59);
  return date;
};

const preferredMeetingsOptions = [
  {
    label: "Preferred Meeting Time",
    value: "preferred_meetings",
  },
  {
    label: "Work Unavailability",
    value: "work-healthy",
  },
  {
    label: "Preferred Thinking Time",
    value: "best-thinking",
  },
];

export const PreferredTimes: VFC<{
  frameworkId?: string;
  userScopeValue?: any;
}> = ({ frameworkId = "", userScopeValue }) => {
  const [key, setKey] = useState("preferred_meetings");

  const [preferredMeetings] = useAggregateFeaturesData({
    frameworkId,
    userScope: userScopeValue ?? {},
    reportKeys: [key],
    mapping: [
      {
        label: "",
        get(data) {
          return data.features[0].choices.filter(
            (choice) => choice.placeholderText
          );
        },
      },
    ],
  });

  const [UsersDialog, showUsers] = useOpenTextTableModal();

  const chartData = useMemo(() => {
    return createChart(preferredMeetings);
  }, [preferredMeetings]);

  const { data } = useFrameworkQuery({
    variables: {
      id: frameworkId,
    },
    fetchPolicy: "no-cache",
  });
  const myRef = useRef<any>(null);

  const events = useMemo(
    () =>
      chartData.datasets.map((dataset: any, i: number) => {
        const [from, to] = dataset.data.find((i: any) => Array.isArray(i));

        return {
          start: rebaseDate(
            new Date(from),
            new Date(data?.framework?.startDate)
          ).toUTCString(),
          end: endOfTheDay(new Date(data?.framework?.endDate)).toUTCString(),
          duration: Math.round((to - from) / 1000 / 60),
          title: `[LLUNA] ${
            preferredMeetingsOptions.find((i) => i.value === key)?.label
          } - ${dataset.label}`,
          attendees: preferredMeetings[1][i].responders.map((r: any) => ({
            name: r.profile.fullName,
            uid: r.userId,
          })),
        };
      }),
    [chartData, data, preferredMeetings, key]
  );

  const { download, isInProgress } = useDownload("text/calendar", {
    body: JSON.stringify({
      events,
      calName: preferredMeetingsOptions.find((i) => i.value === key)?.label,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });

  const chartDateTimeBase = useMemo(() => {
    return chartData.datasets.reduce(
      ([from, to]: [number, number], dataset: any) => {
        return [
          Math.min(
            from || Date.now(),
            ...dataset.data
              .filter((d: any) => Array.isArray(d))
              .flatMap((d: number[]) => d[0] || Date.now())
          ),
          Math.max(
            to,
            ...dataset.data
              .filter((d: any) => Array.isArray(d))
              .flatMap((d: number[]) => d[1] || 0)
          ),
        ];
      },
      [0, 0]
    );
  }, [chartData]);

  const isData = !!chartData.datasets.length;

  const downloadGraphImage = (imagetype: any) => {
    if (imagetype === "png") {
      toPng(myRef.current, { backgroundColor: "#FFF" })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "BarChart.png";
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (imagetype === "jpeg") {
      toJpeg(myRef.current, { backgroundColor: "#FFF" })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "BarChart.jpeg";
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const content = (
    <div>
      <S.Cursor onClick={() => downloadGraphImage("jpeg")}>
        Download JPEG
      </S.Cursor>
      <S.Cursor onClick={() => downloadGraphImage("png")}>
        Download PNG
      </S.Cursor>
    </div>
  );

  return (
    <>
      <S.PreferredTimes ref={myRef}>
        <UsersDialog tableData={[]} />

        <DashboardCard
          title="Preferred Times"
          icon="reportsGrey"
          actionElem={
            isData && (
              <S.ButtonTertiary
                transparent
                style={{ border: `1px solid ${theme.colors.blue_100}` }}
                icon={<Icon fontSize={15} name="calendar" />}
                loading={isInProgress}
                onClick={() =>
                  download(
                    `${key}_${frameworkId}`,
                    API_ENDPOINTS.exportXLSX
                      .build()
                      .appendPathname("/ics")
                      .end()
                  )
                }
              >
                <Text strong>Export to .ics</Text>
              </S.ButtonTertiary>
            )
          }
        >
          <S.position>
            <Popover
              placement="bottom"
              content={content}
              overlayInnerStyle={{ maxWidth: "fit-content" }}
            >
              <MenuOutlined />
            </Popover>
          </S.position>

          <Form.Item name="preferred">
            <Select
              defaultValue="Preferred meetings"
              options={preferredMeetingsOptions}
              onChange={(e) => setKey(e)}
            />
          </Form.Item>
          {/* @TODO - style divs with styled-components */}
          {isData ? (
            <>
              <S.BarWrap>
                <BarChart
                  options={preferredTimesOptions(
                    chartDateTimeBase,
                    // @TODO - proper typings
                    preferredMeetings[1].map((item: any) => item),
                    showUsers
                  )}
                  data={chartData}
                />
              </S.BarWrap>
            </>
          ) : (
            <S.NoDataWrap>
              <NoData />
            </S.NoDataWrap>
          )}
        </DashboardCard>
      </S.PreferredTimes>
    </>
  );
};
