import { useEffect, useState } from "react";
import { Row, Col, Space, Modal, Divider } from "antd";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

import { history } from "router/history";

import * as S from "./Subscribe.styles";
import * as Sp from "./Payment.styles";
import { ButtonPrimary } from "components/Button";
import { useReactiveVar } from "@apollo/client";
import { selectedPlanVar, amountOfSeatsVar } from "store/plan";
import {
  useStripeSetupPaymentIntentMutation,
  useCreateSubscriptionMutation,
  MeDocument,
} from "codegen/generated/graphql";
import { Routes } from "router/routes";
import { Loader } from "components";
import { withCommas } from "./PlanSummary";

export const Checkout = () => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? ""
  );
  const [setupPaymentIntent] = useStripeSetupPaymentIntentMutation();
  const [clientSecret, setClientSecret] = useState("");
  const [isCheckoutFormReady, setIsCheckoutFormReady] = useState(false);
  const [t, setT] = useState(false);
  useEffect(() => {
    async function fetchClientSecret() {
      const { data, errors } = await setupPaymentIntent();
      if (!errors && data) {
        setClientSecret(data.stripeSetupPaymentIntent.clientSecret ?? "");
        setTimeout(() => setT(true), 0);
      }
    }

    fetchClientSecret();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!clientSecret) return <Loader />;
  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
      }}
    >
      <div
        style={{
          maxHeight: t && isCheckoutFormReady ? 1000 : 30,
          transition: "all",
          transitionDuration: "1000ms",
          transitionTimingFunction: "ease-out",
          overflow: "hidden",
        }}
      >
        {(!t || !isCheckoutFormReady) && <Loader />}
        <Payment setIsCheckoutFormReady={setIsCheckoutFormReady} />
      </div>
    </Elements>
  );
};

const Payment: React.VFC<{
  setIsCheckoutFormReady: (state: boolean) => void;
}> = ({ setIsCheckoutFormReady }) => {
  const stripe = useStripe();
  const elements = useElements();

  const planTuple = useReactiveVar(selectedPlanVar);
  const amountOfSeats = useReactiveVar(amountOfSeatsVar);

  const [loading, setLoading] = useState(false);

  const [createSubscribtion] = useCreateSubscriptionMutation();

  if (!stripe || !elements) return null;
  if (!planTuple) return null;

  const [plan, priceIndex] = planTuple;

  const pricePerMonth = plan.pricing[priceIndex].price;
  const totalPrice = ((amountOfSeats * pricePerMonth) / 5).toFixed(2);

  const onContinueHandler = async () => {
    setLoading(true);
    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
    });

    if (error) {
      Modal.error({ title: error.message });
    }

    if (setupIntent && setupIntent.payment_method) {
      const subscriptionResult = await createSubscribtion({
        variables: {
          input: {
            quantity: amountOfSeats,
            paymentMethodId: setupIntent.payment_method as string,
            planPriceId: plan.pricing[priceIndex].stripeId ?? "",
          },
        },
        // @TODO - use cache update instead
        refetchQueries: [MeDocument],
        awaitRefetchQueries: true,
      });

      const { data, errors } = subscriptionResult;
      const subscription = data?.createSubscription;

      if (!errors && subscription) {
        const onOk = () => history.replace(Routes.DASHBOARD.path);
        if (subscription.active) {
          Modal.success({
            onOk,
            title: "Success!",
            content: "The LLUNA service has been provisioned. Enjoy!",
          });
        } else {
          Modal.warning({
            onOk,
            title: "Action required",
            content:
              "The LLUNA service will be provisioned when you complete the required action.",
          });
        }
      }

      setLoading(false);
    }
  };

  const total = withCommas(totalPrice);

  const timePeriod = (monthCount: number) => {
    if (monthCount === 1) return monthCount + " month";
    if (monthCount === 12) return 1 + " year";
    if (1 < monthCount && monthCount < 11) return monthCount + "month";
    const years = Math.round(monthCount / 12);
    return years + " years";
  };

  return (
    <Row gutter={24}>
      <Col span={12} style={{ display: "flex" }}>
        <S.FancyCard>
          <Space direction="vertical" size="middle">
            <S.Paragraph>Try LLUNA - {plan.label}</S.Paragraph>
            <div>
              <S.LargeText strong>7 days free</S.LargeText>
              <S.Paragraph>
                Then ${totalPrice} per{" "}
                {timePeriod(plan.pricing[priceIndex].months)}
              </S.Paragraph>
            </div>
            <div>
              <Row justify="space-between">
                <Col>
                  <Sp.Text strong>LLUNA {plan.label}</Sp.Text>
                </Col>
                <Col>
                  <Sp.Text strong>7 days free</Sp.Text>
                </Col>
              </Row>

              <Row justify="space-between">
                <Col>
                  <Sp.Text alt>Quantity {amountOfSeats}</Sp.Text>
                </Col>
                <Col>
                  <Sp.Text alt>
                    ${total} / {timePeriod(plan.pricing[priceIndex].months)}
                  </Sp.Text>
                </Col>
              </Row>
            </div>
            <Row justify="space-between">
              <Col>
                <Sp.Text strong>Subtotal</Sp.Text>
              </Col>
              <Col>
                <Sp.Text strong>${total}</Sp.Text>
              </Col>
              <Divider />
            </Row>

            <div>
              <Row justify="space-between">
                <Col>
                  <Sp.Text alt>Total after trial</Sp.Text>
                </Col>
                <Col>
                  <Sp.Text alt>${total}</Sp.Text>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col>
                  <Sp.Text strong>Total due today</Sp.Text>
                </Col>
                <Col>
                  <Sp.Text strong>${withCommas(Number(0).toFixed(2))}</Sp.Text>
                </Col>
              </Row>
            </div>
          </Space>
        </S.FancyCard>
      </Col>
      <Col span={12} style={{ display: "flex" }}>
        <S.FancyCard>
          <PaymentElement onReady={() => setIsCheckoutFormReady(true)} />

          <S.ButtonWrap>
            <ButtonPrimary
              onClick={() => !loading && onContinueHandler()}
              style={{ fontWeight: 600 }}
              loading={loading}
            >
              {loading ? "Processing..." : "Start trial"}
            </ButtonPrimary>
          </S.ButtonWrap>
        </S.FancyCard>
      </Col>
    </Row>
  );
};
