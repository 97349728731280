import React from "react";
import theme from "../../theme";
import { TagAttributes } from "../../common/types";
import { useTagAttributes } from "../../utils/hooks";
import { CommonTag } from "./commonTag";

// TODO: import real one enum from generated types and change the keys in the config Map
export enum PricingPlansStatus {
  TEAMS,
  ORGANIZATIONS,
  ENTERPRISE,
}
type Props = {
  status: PricingPlansStatus;
};

const baseAttributes = {
  bgColor: theme.colors.blue_100,
  fontWeight: 600,
};

const attributesMap = new Map<PricingPlansStatus, TagAttributes>([
  [PricingPlansStatus.TEAMS, { text: "Teams", ...baseAttributes }],
  [
    PricingPlansStatus.ORGANIZATIONS,
    { text: "Organizations", ...baseAttributes },
  ],
  [PricingPlansStatus.ENTERPRISE, { text: "Enterprise", ...baseAttributes }],
]);

export const PricingPlansStatusTag: React.FC<Props> = (props) => {
  const { status } = props;

  const attributes = useTagAttributes(attributesMap, status);

  return <CommonTag tagAttributes={attributes} />;
};
