import { FC, useState } from "react";
import { Col, Row, Typography, notification, Modal } from "antd";
import { useFinchConnect, SuccessEvent, ErrorEvent } from "lib/finch-connect";
import {
  useSubmitAuthCodeMutation,
  useIntegrationsQuery,
  IntegrationsDocument,
} from "codegen/generated/graphql";
import ContentLayout from "layout/ContentLayout";
import { ContentType } from "layout/ContentLayout/ContentLayout";
import { ButtonPrimary, ButtonTertiary, Icon } from "components";
import { useAuthContext } from "utils/context";
import * as S from "./Integrations.styles";

const { Text, Paragraph } = Typography;

const Integrations: FC = () => {
  const { user } = useAuthContext();
  const { data } = useIntegrationsQuery();

  const getTryFinch = data?.integrations.find(
    (item: any) => item.name === "Try Finch"
  );

  const [submitAuthCode] = useSubmitAuthCodeMutation({});

  const onSuccess = ({ code }: SuccessEvent) => {
    console.log("Authorization code ==>>", code);
    if (user && user?.account?.id) {
      submitAuthCode({
        variables: {
          input: {
            accountId: user?.account?.id,
            authCode: code,
            atsName: getTryFinch
              ? getTryFinch?.accountIntegrations[0]?.atsName
              : "",
            accountIntegrationId: getTryFinch
              ? getTryFinch?.accountIntegrations[0]?.id
              : "",
            integrationId: getTryFinch ? getTryFinch?.id : "",
          },
        },
        refetchQueries: [IntegrationsDocument],
      })
        .then((res) =>
          Modal.success({
            title: `Connection successful! Your data will be synced with Lluna in few hours today and will be updated on a daily basis.​`,
          })
        )
        .catch(() =>
          Modal.error({
            title: "Something went wrong",
          })
        );
    }
  };
  const onError = ({ errorMessage }: ErrorEvent) => console.error(errorMessage);
  const onClose = () => console.log("User exited Finch Connect");

  const { open } = useFinchConnect({
    clientId: process.env.REACT_APP_FINCH_CLIENT_ID ?? "",
    products: ["company", "directory", "individual", "employment"],
    payrollProvider: getTryFinch
      ? getTryFinch?.accountIntegrations[0]?.atsName
      : "",
    sandbox: process.env.REACT_APP_FINCH_SANDBOX === "false" ? false : true,
    onSuccess,
    onError,
    onClose,
  });

  return (
    <ContentLayout typeContent={ContentType.FullWidth}>
      <Row style={{ display: "flex", flex: 1 }} gutter={[15, 0]}>
        <Col span={24}>
          <S.Wrapper>
            <S.Title level={5} margin="0 0 1rem 0">
              Connect your HRIS
            </S.Title>
            {!getTryFinch ? (
              <>
                <Paragraph>
                  Integrate your HR system of record with LLUNA. This will
                  automatically fetch and keep up-to-date: employee name, email,
                  date of hire, race/ethnicity, gender, function,
                  reports-to-manager, and location.
                </Paragraph>
                <Row>
                  <Col
                    span={8}
                    style={{
                      marginTop: "1rem",
                    }}
                  >
                    <ButtonPrimary onClick={() => open()}>
                      <Text strong>Establish Integration</Text>
                    </ButtonPrimary>
                  </Col>
                </Row>
              </>
            ) : getTryFinch?.accountIntegrations[0]?.status === "ACTIVE" ? (
              <>
                <Paragraph>
                  Your HR system is integrated with Lluna and employee data is
                  getting updated on a daily basis.
                </Paragraph>
                <Paragraph>
                  Your HR system authorization with Lluna is active.
                </Paragraph>
              </>
            ) : (
              <>
                <Paragraph>
                  Your HR system is integrated with Lluna and employee data is
                  getting updated on a daily basis.
                </Paragraph>
                <Paragraph>
                  If your HR system authorization with Lluna is expired, you can
                  regenerate it here.
                </Paragraph>
                <Row>
                  <Col
                    span={8}
                    style={{
                      marginTop: "1rem",
                    }}
                  >
                    <ButtonPrimary onClick={() => open()}>
                      <Text strong>Regenerate</Text>
                    </ButtonPrimary>
                  </Col>
                </Row>
              </>
            )}
          </S.Wrapper>
        </Col>
      </Row>
      <Row style={{ display: "flex", flex: 1 }} gutter={[15, 0]}>
        <Col span={12}>
          <S.Wrapper>
            <S.Title level={5} margin="0 0 1rem 0">
              Slack Integration - Coming Soon!
            </S.Title>
            <ButtonTertiary
              htmlType="submit"
              outlined
              disabled
              style={{ fontSize: "12px" }}
              icon={<Icon fontSize={15} name="slack" />}
            >
              <Text style={{ textAlign: "center" }}>
                Connect to a Slack Workspace
              </Text>
            </ButtonTertiary>
          </S.Wrapper>
        </Col>
        <Col span={12}>
          <S.Wrapper>
            <S.Title level={5} margin="0 0 1rem 0">
              MS Teams Integration - Coming Soon!
            </S.Title>
            <ButtonTertiary
              htmlType="submit"
              outlined
              disabled
              style={{ fontSize: "12px" }}
              icon={<Icon fontSize={15} name="microsoftteams" />}
            >
              <Text style={{ textAlign: "center" }}>
                Connect to a MS Teams Workspace
              </Text>
            </ButtonTertiary>
          </S.Wrapper>
        </Col>
      </Row>
      <Row style={{ display: "flex", flex: 1 }} gutter={[15, 0]}>
        <Col span={24}>
          <S.Wrapper>
            <S.Title level={5} margin="0 0 1rem 0">
              Connect your Lattice account
            </S.Title>
            <Paragraph>
              Integrate your Lattice data with LLUNA. This will automatically
              fetch and keep up-to-date: employee name, email, date of hire,
              race/ethnicity, gender, function, reports-to-manager, and
              location.
            </Paragraph>
            <Row>
              <Col
                span={10}
                style={{
                  marginTop: "1rem",
                }}
              >
                <ButtonPrimary
                  type="link"
                  href="mailto: howdy@hellolluna.com"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text strong>Contact LLUNA</Text>
                </ButtonPrimary>
              </Col>
            </Row>
          </S.Wrapper>
        </Col>
      </Row>
    </ContentLayout>
  );
};

export default Integrations;
