import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Checkbox,
  Space,
} from "antd";
import Title from "antd/lib/typography/Title";
import Text from "antd/es/typography/Text";
import Link from "antd/es/typography/Link";
import { FC, useState } from "react";
import { generatePath, useHistory } from "react-router";
import { Routes } from "router/routes";
import Loader from "components/Loader";
import { DimensionChip } from "components/DimensionChip";
import { useAuthContext } from "utils/context";
import * as S from "./NewMember.styles";
import {
  useCreateTeamMemberMutation,
  UserRole,
  useTeamProfilesFieldsQuery,
  useUserScopesQuery,
  useReportingManagersQuery,
} from "codegen/generated/graphql";
import { Restricted } from "components";
import { ProfileFields } from "common/constants";
import PhoneInput from "react-phone-input-2";
import { phoneNumberStyle } from "../styles";
import ConfigureScopeModal from "../ConfigureScopeModal";
import PrimaryAdminConfirmModal from "../PrimaryAdminConfirmModal";
import { trim } from "lodash-es";

type AutoCompleteOptionsType = { value: string }[];

const NewMember: FC = () => {
  const history = useHistory();
  const handleOnRedirect = (to: string) => history.push(to);
  const { user } = useAuthContext();

  const [form] = Form.useForm();
  const [showUserPermissionsSection, setShowUserPermissionsSection] =
    useState(false);
  const [showConfigureScopeModal, setShowConfigureScopeModal] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<any[]>([]);
  const [userAdditionalPermissions, setUserAdditionalPermissions] = useState<
    any[]
  >([]);
  const [configureScopeSelectedData, setConfigureScopeSelectedData] =
    useState<any>({});
  const [isPrimaryAdminConfirmModalVisible, setShowPrimaryAdminConfirmModal] =
    useState(false);

  const [createTeamMember] = useCreateTeamMemberMutation();

  const { data } = useTeamProfilesFieldsQuery({
    variables: {
      keys: [ProfileFields.GENDER, ProfileFields.RACE_OR_ETHNICITY],
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    data: reportingManagerData,
    loading: reportingManagerLoading,
    error: reportingManagerError,
  } = useReportingManagersQuery({});

  const {
    data: userScopeData,
    loading: userScopeLoading,
    error: userScopeError,
  } = useUserScopesQuery({});

  const genderTeamAnswers = data?.teamProfilesFields[0].values.map(
    (value: string) => {
      return { value: value.toUpperCase().trim() };
    }
  );

  const raceTeamAnswers = data?.teamProfilesFields[1].values.map(
    (value: string) => {
      return { value: value.toUpperCase().trim() };
    }
  );

  const genderOptions = (genderTeamAnswers ?? []).concat([
    { value: "I’D PREFER NOT TO SAY" },
  ]);

  const uniqueGenderOptions: AutoCompleteOptionsType = genderOptions.filter(
    (value, index, self) =>
      index === self.findIndex((gender) => gender.value === value.value)
  );

  const raceOptions = (raceTeamAnswers ?? []).concat([
    { value: "HISPANIC OR LATINO" },
    { value: "AMERICAN INDIAN OR ALASKA NATIVE" },
    { value: "NATIVE HAWAIIAN OR OTHER PACIFIC ISLANDER" },
    { value: "ASIAN" },
    { value: "BLACK OR AFRICAN AMERICAN" },
    { value: "WHITE" },
  ]);

  const uniqueRaceOptions: AutoCompleteOptionsType = raceOptions.filter(
    (value, index, self) =>
      index === self.findIndex((race) => race.value === value.value)
  );

  const renderReportingManager = () => {
    return (
      reportingManagerData &&
      reportingManagerData?.reportingManagers &&
      reportingManagerData?.reportingManagers.map((reportingManager: any) => {
        return (
          <Select.Option value={reportingManager.id} key={reportingManager.id}>
            {reportingManager.name}
          </Select.Option>
        );
      })
    );
  };

  const primaryRolesList = [
    { id: 1, name: "PrimaryAdmin", value: "PRIMARY_ADMIN" },
    { id: 2, name: "Admin", value: "ADMIN" },
    { id: 3, name: "Manager", value: "TEAM_MANAGER" },
    { id: 4, name: "Member", value: "TEAM_MEMBER" },
  ];

  const rolesList = [
    { id: 1, name: "Admin", value: "ADMIN" },
    { id: 2, name: "Manager", value: "TEAM_MANAGER" },
    { id: 3, name: "Member", value: "TEAM_MEMBER" },
  ];

  const continuePrimaryRoleSelection = () => {
    setSelectedRoles([primaryRolesList[0]]);
    setShowPrimaryAdminConfirmModal(false);
  };

  const triggerRoleSelection = (dimension: any) => {
    if (dimension.value === UserRole.PrimaryAdmin) {
      setShowPrimaryAdminConfirmModal(true);
    } else {
      setSelectedRoles([dimension]);
      setShowUserPermissionsSection(true);
    }
  };

  const additionalPermissions = [
    { id: 1, label: "Report Viewer", value: "REPORT_VIEWER" },
    { id: 2, label: "Framework Manager", value: "FRAMEWORK_MANAGER" },
  ];

  const onChangeUserPermission = (checkedValues: any) => {
    setUserAdditionalPermissions(checkedValues);
  };

  if (userScopeLoading || reportingManagerLoading) return <Loader />;

  if (reportingManagerError || userScopeError) {
    return <Text>Something went wrong</Text>;
  }

  return (
    <S.Wrapper>
      <Title level={3}>
        <Restricted
          to={[UserRole.PrimaryAdmin, UserRole.Admin, UserRole.TeamManager]}
        >
          {" "}
          New{" "}
        </Restricted>
        Team Member
      </Title>
      <Form
        form={form}
        name="register"
        onFinish={(values) => {
          createTeamMember({
            variables: {
              input: {
                ...values,
                function: values.function.trim(),
                gender: values.gender.trim(),
                jobTitle: values.jobTitle.trim(),
                office: values.office.trim(),
                name: values.name.trim(),
                surname: values.surname.trim(),
                raceOrEthnicity: values.raceOrEthnicity.trim(),
                roles: [selectedRoles[0]?.value],
                additionalPermissions: userAdditionalPermissions,
                filterScope: configureScopeSelectedData,
              },
            },
            // @TODO - solve with updating the cache
            update: (cache) => {
              cache.evict({
                fieldName: "accountUsers",
              });
            },
          })
            .then((res) => {
              console.log(values);
              message.success("Team member added!");
              form.resetFields();
              handleOnRedirect(generatePath(Routes.TEAM_MEMBERS.path));
            })
            .catch((error) => {
              console.log(error);
              message.error(
                "Something went wrong. Please make sure the data is correct."
              );
            });
        }}
        layout="vertical"
        scrollToFirstError
      >
        <Row gutter={[24, 0]} justify="space-between">
          <Col>
            <Row gutter={[16, 0]}>
              <Col span={24} md={12}>
                <Form.Item
                  name="name"
                  label="First Name"
                  required
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="surname"
                  label="Last Name"
                  required
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="email"
              label="Email"
              required
              rules={[{ type: "email" }, { required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item name="phoneNumber" label="Cell Phone Number">
              <PhoneInput
                buttonStyle={phoneNumberStyle}
                inputStyle={phoneNumberStyle}
                country={"us"}
                onChange={form.setFieldsValue}
              />
            </Form.Item>
            <Restricted
              to={[UserRole.PrimaryAdmin, UserRole.Admin, UserRole.TeamManager]}
            >
              <Form.Item name="employeeId" label="Employee ID">
                <Input />
              </Form.Item>

              <Row gutter={[16, 0]}>
                <Col span={24} md={12}>
                  <Form.Item name="hireDate" label="Hire Date">
                    <DatePicker />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item name="employmentType" label="Employment Type">
                    <Select allowClear>
                      <Select.Option value="Full Time">Full Time</Select.Option>
                      <Select.Option value="Part Time">Part Time</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item name="gender" label="Gender">
                <AutoComplete
                  options={uniqueGenderOptions}
                  filterOption={(inputValue, option) =>
                    option?.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </Form.Item>

              <Form.Item name="raceOrEthnicity" label="Race / Ethnicity">
                <AutoComplete
                  options={uniqueRaceOptions}
                  filterOption={(inputValue, option) =>
                    option?.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </Form.Item>
            </Restricted>
            <Form.Item name="function" label="Function">
              <Input />
            </Form.Item>

            <Row gutter={[16, 0]}>
              <Col span={24} md={12}>
                <Form.Item name="jobTitle" label="Job Title">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item name="office" label="Office">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Restricted
              to={[UserRole.PrimaryAdmin, UserRole.Admin, UserRole.TeamManager]}
            >
              <Row gutter={[16, 0]}>
                <Col span={24} md={12}>
                  <Form.Item
                    name="annualSalary"
                    label="Annual Salary"
                    rules={[{ type: "integer", min: 0 }]}
                  >
                    <InputNumber />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    name="paidTimeOff"
                    label="Paid Time Off (Days)"
                    rules={[{ type: "integer", min: 0 }]}
                  >
                    <InputNumber />
                  </Form.Item>
                </Col>
              </Row>
            </Restricted>
            <Form.Item
              label={<Text strong>Reporting Manager</Text>}
              name="managerId"
            >
              <Select style={{ width: 400 }}>{renderReportingManager()}</Select>
            </Form.Item>
            <Form.Item label={<Text strong>Select Role</Text>} name="roles">
              <Row gutter={[16, 12]} style={{ paddingLeft: "7px" }}>
                {(user?.roles[0] === UserRole.PrimaryAdmin
                  ? primaryRolesList
                  : rolesList
                ).map((dimension) => {
                  let isActive = false;
                  if (selectedRoles && selectedRoles.length) {
                    isActive = selectedRoles.some(
                      ({ value }) => value === dimension?.value
                    );
                  }
                  return (
                    <DimensionChip
                      key={dimension.id}
                      active={isActive}
                      label={`${dimension.name}`}
                      onClick={() => triggerRoleSelection(dimension)}
                    />
                  );
                })}
              </Row>
              <br></br>
              <a
                href="https://help.hellolluna.com/en/articles/6244831-roles-in-lluna"
                target="_blank"
                rel="noreferrer"
              >
                Learn more about role types.
              </a>
            </Form.Item>
            <Row>
              <Col span={24}>
                {showUserPermissionsSection &&
                  (selectedRoles?.[0]?.value === UserRole.TeamManager ||
                    selectedRoles?.[0]?.value === UserRole.TeamMember) && (
                    <S.SectionWrapper>
                      <div style={{ marginBottom: "15px" }}>
                        <Text strong>Additional User Permissions</Text>
                      </div>
                      <Checkbox.Group
                        options={additionalPermissions}
                        onChange={onChangeUserPermission}
                        defaultValue={userAdditionalPermissions}
                      />
                      <Space style={{ marginTop: "8px", display: "flex" }}>
                        <Link onClick={() => setShowConfigureScopeModal(true)}>
                          Configure Scope{" "}
                          <S.UIRightOutlined style={{ fontSize: "15px" }} />
                        </Link>
                      </Space>
                      <div style={{ marginTop: "15px" }}>
                        <strong>Report Viewer:</strong> Can view/create reports
                        for a specific scope, but cannot create/send frameworks
                      </div>
                      <div>
                        <strong>Framework Manager:</strong> Can help create
                        frameworks, but cannot view results
                      </div>
                    </S.SectionWrapper>
                  )}
              </Col>
            </Row>
          </Col>
          <Col>
            <Restricted
              to={[UserRole.PrimaryAdmin, UserRole.Admin, UserRole.TeamManager]}
            >
              <Form.Item>
                <Button htmlType="submit">Create Member</Button>
              </Form.Item>
            </Restricted>
          </Col>
        </Row>
      </Form>
      <ConfigureScopeModal
        isConfigureScopeModalVisible={showConfigureScopeModal}
        setShowConfigureScopeModal={setShowConfigureScopeModal}
        userScopeData={userScopeData}
        setConfigureScopeSelectedData={setConfigureScopeSelectedData}
      />
      <PrimaryAdminConfirmModal
        isPrimaryAdminConfirmModalVisible={isPrimaryAdminConfirmModalVisible}
        setShowPrimaryAdminConfirmModal={setShowPrimaryAdminConfirmModal}
        continuePrimaryRoleSelection={continuePrimaryRoleSelection}
      />
    </S.Wrapper>
  );
};

export default NewMember;
