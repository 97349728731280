import styled from "styled-components";

export const DashboardType = styled.div`
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
`;

export const UITabs = styled.div`
  display: flex;
  justify-content: center;
`;

export const UITabItem = styled.a<{ $active: boolean }>`
  color: ${({ theme, $active }) =>
    $active ? theme.colors.blue_100 : theme.colors.grey_50};
  font-weight: ${({ $active }) => ($active ? "bolder" : "normal")};
  height: 3.7rem;
  padding: 0 2rem;
  cursor: pointer;
  border-bottom: 2px solid
    ${({ theme, $active }) => ($active ? theme.colors.blue_100 : "none")};
  transition: 0.2s;
  &:hover {
    color: ${({ theme, $active }) =>
      $active ? theme.colors.blue_100 : theme.colors.grey_70};
  }
`;
