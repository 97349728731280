import React, { useEffect } from "react";
import { notification } from "antd";
import { useHistory } from "react-router-dom";
import { omit } from "lodash";
import {
  ChoiceUpdateInput,
  useUpdateFeatureMutation,
  useFeatureQuery,
  FeatureUpdateInput,
  FeaturesDocument,
} from "codegen/generated/graphql";
import { Routes } from "router/routes";
import { useParams } from "react-router";
import UpsertFeatureForm from "../components/UpsertFeatureForm";

import {
  StateProvider,
  useFeatureContext,
  ActionType,
} from "../components/UpsertFeatureForm/context";
import { Loader } from "components";

export const Update: React.FC = () => {
  const history = useHistory();
  const [updateFeature] = useUpdateFeatureMutation({
    refetchQueries: [{ query: FeaturesDocument }],
    onError: (e) => {
      notification.error({
        type: "error",
        message: `${e}`,
        placement: "bottomLeft",
      });
    },
    onCompleted: () => {
      notification.success({
        type: "success",
        message: "New Feature updated",
        placement: "bottomLeft",
      });
      history.push(Routes.FRAMEWORKS_FEATURES.path);
    },
  });
  const { dispatch } = useFeatureContext();

  const { id } = useParams<{ id: string }>();

  const { data, loading } = useFeatureQuery({
    variables: {
      featureId: id,
    },
  });

  const hasId = (input: any): input is FeatureUpdateInput => {
    if (input.id) return true;
    return false;
  };

  useEffect(() => {
    if (!data || !data.feature) return;
    const feature = data.feature;
    dispatch({
      type: ActionType.UPDATE_ALL,
      data: {
        // TODO: fix type once "order" property is fixed in schema - shouldn't be optional
        choices: feature.choices.map((choice) =>
          omit(choice, "__typename")
        ) as ChoiceUpdateInput[],
        dimensionId: feature.dimension.id,
        text: feature.text,
        title: feature.title,
        type: feature.type,
        accountId: feature.accountId,
        reportKey: feature.reportKey,
        hasReport: feature.hasReport,
      },
    });
  }, [data, dispatch]);

  if (loading) return <Loader />;
  // @TODO trigger 404 error here?
  if (!loading && !data?.feature) return null;

  return (
    <UpsertFeatureForm
      id={id}
      onSubmit={(values) =>
        (hasId(values) &&
          updateFeature({
            variables: {
              data: values,
            },
          })) ||
        Promise.resolve()
      }
    />
  );
};

const UpdateStatefulWrapper: React.VFC = () => (
  <StateProvider>
    <Update />
  </StateProvider>
);

export default UpdateStatefulWrapper;
