import { Button, Col, Form, Modal, notification, Row } from "antd";
import Title from "antd/es/typography/Title";
import { MaterialInput } from "../MaterialInput";
import Link from "antd/es/typography/Link";
import theme from "../../theme";
import { Routes } from "../../router/routes";
import Text from "antd/es/typography/Text";
import { ReactComponent as GoogleSvg } from "../../assets/icons/google.svg";
import { ReactComponent as OutlookSvg } from "../../assets/icons/outlook.svg";
import { ReactComponent as LinkedinSvg } from "../../assets/icons/linkedin.svg";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  UserCredential,
  AuthProviderId,
  getAuthErrorMessage,
} from "utils/firebase";
import { ButtonPrimary } from "../Button";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

enum FORM_FIELD_NAME {
  EMAIL = "email",
  PASSWORD = "password",
}

interface Props {
  signInWithPassword: (
    email: string,
    password: string
  ) => Promise<UserCredential>;
  signInWithProvider: (provider: AuthProviderId) => Promise<UserCredential>;
}

export const LogInForm: React.FC<Props> = ({
  signInWithPassword,
  signInWithProvider,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { lg, xl, md, xs } = useBreakpoint();
  const [loading, setLoading] = useState(false);

  return (
    <Form
      name="logInForm"
      form={form}
      autoComplete="off"
      initialValues={{ remember: true }}
      onFinish={(values: {
        [FORM_FIELD_NAME.EMAIL]: string;
        [FORM_FIELD_NAME.PASSWORD]: string;
      }) => {
        setLoading(true);
        signInWithPassword(
          values[FORM_FIELD_NAME.EMAIL].trim(),
          values[FORM_FIELD_NAME.PASSWORD]
        )
          .catch((e) => {
            console.log(e);
            notification.error({
              type: "error",
              message: "Incorrect email/password",
              placement: "bottomLeft",
            });
          })
          .finally(() => setLoading(false));
      }}
    >
      <Row justify={xl || lg || md ? "start" : "center"} gutter={[8, 24]}>
        <Col sm={{ span: undefined }} md={{ span: 24 }}>
          <Title level={3} type="secondary">
            Log In
          </Title>
        </Col>
        <Col>
          <Title level={xl || lg || md ? 1 : 2}>Welcome Back!</Title>
        </Col>
        <Col>
          <Row gutter={[0, 50]}>
            <Col span={24}>
              <Col span={24}>
                <Form.Item
                  name={FORM_FIELD_NAME.EMAIL}
                  rules={[
                    {
                      required: true,
                      message: "Please input your E-mail!",
                      whitespace: true,
                    },
                  ]}
                >
                  <MaterialInput placeholder={"Email Address"} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={FORM_FIELD_NAME.PASSWORD}
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                      whitespace: true,
                    },
                  ]}
                >
                  <MaterialInput placeholder={"Password"} type="password" />
                </Form.Item>
              </Col>
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <Row justify="end">
                    <Col>
                      <Link
                        color={theme.colors.blue_80}
                        underline={true}
                        onClick={() => {
                          history.push(Routes.FORGOT_PASSWORD.path);
                        }}
                      >
                        Forgot Password?
                      </Link>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <ButtonPrimary htmlType="submit" loading={loading}>
                    Log in
                  </ButtonPrimary>
                </Col>
                <Col span={24}>
                  <Row justify="center">
                    <Col>
                      <Text type="secondary">Or continue with</Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row justify="space-between" gutter={[10, 10]}>
                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                      <Button
                        block
                        style={{
                          height: "48px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() =>
                          signInWithProvider("google").catch((e) =>
                            Modal.error({ title: getAuthErrorMessage(e) })
                          )
                        }
                      >
                        <GoogleSvg />
                      </Button>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                      <Button
                        block
                        style={{
                          height: "48px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() =>
                          signInWithProvider("microsoft").catch((e) =>
                            Modal.error({ title: getAuthErrorMessage(e) })
                          )
                        }
                      >
                        <OutlookSvg />
                      </Button>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                      <Button
                        block
                        style={{
                          height: "48px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() =>
                          signInWithProvider("linkedin").catch((e) =>
                            Modal.error({ title: getAuthErrorMessage(e) })
                          )
                        }
                      >
                        <LinkedinSvg />
                      </Button>
                    </Col>
                  </Row>
                </Col>
                {!xs && (
                  <>
                    <Col span={24}>
                      <Text>Don’t have an account?</Text>{" "}
                      <Link
                        color={theme.colors.blue_70}
                        underline={true}
                        onClick={() => {
                          history.push(Routes.SIGN_UP.path);
                        }}
                      >
                        Sign Up
                      </Link>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between">
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                          <Row>
                            <Col>
                              <Text>Managers</Text>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Link
                                underline={true}
                                onClick={() => {
                                  history.push(Routes.SUBSCRIBE.path);
                                }}
                              >
                                Pick the best plan for you
                              </Link>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 10 }}>
                          {/*<Row>*/}
                          {/*  <Col>*/}
                          {/*    <Text>Employees</Text>*/}
                          {/*  </Col>*/}
                          {/*</Row>*/}
                          {/*<Row>*/}
                          {/*  <Col>*/}
                          {/*    <Link*/}
                          {/*      color={theme.colors.blue_80}*/}
                          {/*      underline={true}*/}
                          {/*      onClick={() => {*/}
                          {/*        history.push(Routes.REQUEST_INVITATION.path);*/}
                          {/*      }}*/}
                          {/*    >*/}
                          {/*      Request a new invitation*/}
                          {/*    </Link>*/}
                          {/*  </Col>*/}
                          {/*</Row>*/}
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
