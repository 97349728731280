export const colors = {
  black: "black",
  blue_5: "#FBFBFF",
  blue_10: "#F3F4FF",
  blue_20: "#F3F4FF",
  blue_30: "#C3CAFE",
  blue_40: "#9BA6FD",
  blue_50: "#5E71FD",
  blue_60: "#223BFC",
  blue_70: "#031AC9",
  blue_80: "#091794",
  blue_90: "#071159",
  blue_100: "#01083A",
  body_bg_color: "#e5e5e5",
  common_bg:
    "linear-gradient(109.04deg, rgba(255, 255, 255, 0.63) -23.19%, rgba(255, 255, 255, 0.09) 101.71%)",
  gold_5: "#FFFDFB",
  gold_10: "#FFF8EE",
  gold_20: "#FFF0D9",
  gold_30: "#FFD592",
  gold_40: "#FFC05B",
  gold_50: "#FDB035",
  gold_60: "#FC9E08",
  gold_70: "#D27905",
  gold_80: "#885303",
  gold_90: "#513201",
  gold_100: "#281D00",
  gold_secondary_10: "#FFF8EE",
  gold_secondary_20: "#FFD592",
  gold_secondary_50: "#FDB035",
  gold_secondary_70: "#DE8A02",
  gold_secondary_80: "#885303",
  grass_green: "#64AC62",
  green_10: "#C7F6C2",
  green_20: "#B3E9AE",
  green_30: "#8FD388",
  green_40: "#64AC62",
  green_50: "#4C8245",
  green_60: "#188181",
  grey: "#62798D",
  grey_1: "#e1e5ea",
  grey_2: "#fbfbff",
  grey_3: "#D0D6DF",
  grey_4: "#e8eef3",
  grey_5: "#FBFDFF",
  grey_10: "#F6FAFF",
  grey_20: "#EDF2F8",
  grey_30: "#D2DAE3",
  grey_40: "#BEC9D6",
  grey_50: "#A0AFBC",
  grey_60: "#8598AA",
  grey_70: "#62798D",
  grey_80: "#3A4F62",
  grey_90: "#24323E",
  grey_100: "#041828",
  magenta_10: "#FFF0F5",
  magenta_20: "#FFA0C0",
  magenta_50: "#E95486",
  magenta_70: "#C02C5D",
  magenta_80: "#8B183F",
  red: "red",
  red_10: "#FDD4CF",
  red_20: "#FDD4CF",
  red_30: "#FF998F",
  red_40: "#EB6E63",
  red_50: "#D04435",
  red_60: "#BF2D5D",
  sidebarWhite: "#fdfbfd",
  siderOpen: "#F6F6F8",
  teal_10: "#EBFFFF",
  teal_20: "#98EBEB",
  teal_50: "#24BCBC",
  teal_70: "#1A8989",
  teal_80: "#0D4545",
  white: "#ffffff",
  yellow_10: "#FFEDC0",
  yellow_20: "#FFE5A4",
  yellow_30: "#FCD676",
  yellow_40: "#F2C141",
  yellow_50: "#D99C00",
};

export const radius = {
  commonRadius: "20px",
  buttonRadius: "3px",
};
export const shadows = {
  commonShadow: "0px 11px 18px -5px rgba(1,8,58,0.05)",
};

const theme = {
  colors,
  radius,
  shadows,
};

export default theme;
