import React, { useEffect, useState } from "react";
import { BackgroundContainer } from "components/BackgroundContainer";
import { Col, Row, Typography, Modal } from "antd";
import Loader from "components/Loader";
import { confirmEmailVerification } from "utils/firebase";
import { ButtonPrimary } from "components/Button";
import { useHistory } from "react-router";
import { Routes } from "router/routes";
import { appMutations } from "store/app";

const { Title, Text } = Typography;

interface Props {
  oobCode: string | null;
}

const VerifyEmail: React.FC<Props> = ({ oobCode }) => {
  const history = useHistory();
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (!oobCode) {
      setError(new Error("No code provided"));
      return;
    }
    confirmEmailVerification(oobCode)
      .then(() => setVerified(true))
      .then(appMutations.refreshUser)
      .catch((err) => setError(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let fn: () => void = () => true;
    if (error) {
      const modal = Modal.error({
        title: error.message,
        onOk: () => history.replace("/"),
      });
      fn = () => modal.destroy();
    }

    return fn;
  }, [error, history]);
  return (
    <BackgroundContainer>
      <Row align="middle" justify="center" style={{ height: "100vh" }}>
        {/* Left column */}
        <Col>
          <Row style={{ flexDirection: "column" }} align="middle">
            {!verified ? (
              <>
                <Loader />
                <Text>Verifying the email...</Text>
              </>
            ) : (
              <>
                <Title>You email address has been confirmed.</Title>
                <ButtonPrimary
                  onClick={() => history.push(Routes.DASHBOARD.path)}
                >
                  Continue
                </ButtonPrimary>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </BackgroundContainer>
  );
};

export default VerifyEmail;
