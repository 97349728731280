import { FinancialTable, ReportsContentData } from "./FinancialTable";
import { useEffect, useState, useMemo, VFC } from "react";
import {
  useAggregateFrameworkImpactsQuery,
  useAggregateFrameworkStatusQuery,
  AggregateFrameworkImpactsQuery,
  AggregateFrameworkStatusQuery,
} from "codegen/generated/graphql";

import { formattedUsd } from "utils/formatters";

import { useAggregateFeaturesData } from "../../hooks/useAggregateFeaturesData";

interface FinancialTablesProps {
  frameworkId?: string;
  userScopeValue?: any;
}

const lookupImpact = (
  aggregations: AggregateFrameworkImpactsQuery["aggregateFrameworkImpacts"],
  key: string
) => {
  return aggregations.find((a) => a.key === key);
};

export const FinancialTables: VFC<FinancialTablesProps> = ({
  frameworkId = "",
  userScopeValue,
}) => {
  const { data } = useAggregateFrameworkImpactsQuery({
    fetchPolicy: "no-cache",
    variables: {
      frameworkId: frameworkId,
    },
    skip: !frameworkId,
  });

  const { data: fwStatusData } = useAggregateFrameworkStatusQuery({
    fetchPolicy: "no-cache",
    variables: {
      frameworkId: frameworkId ?? "",
      userScope: userScopeValue ?? {},
    },
  });

  const [aggregations, setAggregations] =
    useState<AggregateFrameworkImpactsQuery["aggregateFrameworkImpacts"]>();

  const [statuses, setStatuses] =
    useState<AggregateFrameworkStatusQuery["aggregateFrameworkStatus"]>();

  useEffect(() => {
    setAggregations(data?.aggregateFrameworkImpacts);
  }, [data]);

  useEffect(() => {
    setStatuses(fwStatusData?.aggregateFrameworkStatus);
  }, [fwStatusData]);

  const aggregateCost: ReportsContentData[] | undefined = useMemo(
    () =>
      aggregations && [
        {
          name: "Adjusted base salary",
          amount: formattedUsd(
            lookupImpact(aggregations, "baseSalary")?.sum || 0
          ),
        },
        {
          name: "Bonus",
          amount: formattedUsd(lookupImpact(aggregations, "bonus")?.sum || 0),
        },
        {
          name: "Taxable income",
          amount: formattedUsd(
            lookupImpact(aggregations, "taxableIncome")?.sum || 0
          ),
          lastItem: true,
        },
      ],
    [aggregations]
  );

  const aggregateExpenses: ReportsContentData[] | undefined = useMemo(
    () =>
      aggregations &&
      statuses && [
        {
          name: "Average member expenses",
          amount:
            // @TODO - refactor
            formattedUsd(
              (lookupImpact(aggregations, "reimbursableExpense")?.sum || 0) /
                ([0, 2, 3].reduce(
                  (acc, d) => acc + statuses.datasets[0]?.data[d],
                  0
                ) || 1)
            ),
        },
        {
          name: "Total reimbursable expenses",
          amount: formattedUsd(
            lookupImpact(aggregations, "reimbursableExpense")?.sum || 0
          ),
          lastItem: true,
        },
      ],
    [aggregations, statuses]
  );

  const daysOffData = useAggregateFeaturesData({
    reportKeys: ["timeoff", "elder_care"],
    frameworkId,
    userScope: userScopeValue ?? {},
    mapping: [
      {
        label: "People who elected more time off",
        get: (res) =>
          res.users.filter((u) =>
            u.evaluatedImpacts.find(
              (impact) => impact.key === "paidTimeOff" && impact.value > 0
            )
          ).length,
      },
      {
        label: "People who elected less time off",
        get: (res) =>
          res.users.filter((u) =>
            u.evaluatedImpacts.find(
              (impact) => impact.key === "paidTimeOff" && impact.value < 0
            )
          ).length,
      },
      {
        label: "People with no difference in time off",
        get: (res) =>
          res.users.filter((u) =>
            u.evaluatedImpacts.find(
              (impact) => impact.key === "paidTimeOff" && impact.value === 0
            )
          ).length,
      },
    ],
  });

  const daysOff: ReportsContentData[] | undefined = useMemo(
    () =>
      aggregations &&
      daysOffData && [
        ...daysOffData.map(([name, amount]) => ({ name, amount })),
        {
          name: "Difference in total days off",
          amount: lookupImpact(aggregations, "paidTimeOff")?.sum || 0,
          lastItem: true,
          highlightedText: true,
        },
      ],
    [aggregations, daysOffData]
  );

  if (!aggregateCost || !aggregateExpenses || !daysOff) return null;

  return (
    <>
      <FinancialTable title="Aggregate Income Changes" data={aggregateCost} />
      <FinancialTable
        title="Average Expenses Per Person"
        data={aggregateExpenses}
      />
      <FinancialTable title="Days Off Summary" data={daysOff} />
    </>
  );
};
