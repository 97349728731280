import { FC } from "react";
import { Link } from "react-router-dom";

type MarketingWebsiteProps = {
  props?: string;
};

const MarketingWebsite: FC<MarketingWebsiteProps> = () => {
  return (
    <>
      <Link to="/login">Login</Link>
      <Link to="/subscribe">Sign Up</Link>
    </>
  );
};

export default MarketingWebsite;
