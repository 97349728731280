import { FC } from "react";
import { UserRole } from "codegen/generated/graphql";
import PermissionContext from "./PermissionContext";
import { useAuthContext } from "utils/context";

// This provider is intended to be surrounding the whole application.
// It should receive the user roles as a parameter
const PermissionProvider: FC = ({ children }) => {
  const { user } = useAuthContext();
  // Creates a method that returns whether the requested role is available in the list of roles
  // passed as a parameter
  const isAllowed = (roles: UserRole[]): boolean => {
    const userRoles = user?.roles || [];
    return roles.some((role) => userRoles.includes(role));
  };

  // This component will render its children wrapped around a PermissionContext's provider whose
  // value is set to the method defined above
  return (
    <PermissionContext.Provider value={{ isAllowed }}>
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionProvider;
