import { FC, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { DashboardType, UITabItem, UITabs } from "./NavTab.styles";
import { useReactiveVar } from "@apollo/client";
import { appMutations, selectedRoleVar } from "store/app";
import {
  USER_TABS_PRIMARY_ADMIN,
  USER_TABS_ADMIN,
  USER_TABS_MANAGER,
} from "store/app/constants";
import { UserRole } from "codegen/generated/graphql";
import { AuthContext } from "utils/context/auth";
import { Routes } from "router/routes";

type Props = {
  enabled: boolean;
};

const NavTab: FC<Props> = ({ enabled }) => {
  const history = useHistory();
  const selectedRole = useReactiveVar(selectedRoleVar);
  const { user } = useContext(AuthContext);
  const { setSelectedRole } = appMutations;

  const handleTabChange = (role: any) => {
    setSelectedRole(role);
    history.push(Routes.DASHBOARD.path);
  };

  useEffect(() => {
    if (enabled && user?.roles.includes(UserRole.PrimaryAdmin)) {
      enabled && setSelectedRole(USER_TABS_PRIMARY_ADMIN[0]);
    } else if (enabled && user?.roles.includes(UserRole.TeamManager)) {
      enabled && setSelectedRole(USER_TABS_MANAGER[0]);
    } else {
      enabled && setSelectedRole(USER_TABS_ADMIN[0]);
    }
  }, [enabled, setSelectedRole, user]);

  if (!enabled) return null;

  return (
    <DashboardType>
      <UITabs>
        {(user?.roles.includes(UserRole.PrimaryAdmin)
          ? USER_TABS_PRIMARY_ADMIN
          : user?.roles.includes(UserRole.TeamManager)
          ? USER_TABS_MANAGER
          : USER_TABS_ADMIN
        ).map((role) => (
          <UITabItem
            key={role.title}
            $active={selectedRole?.id === role.id}
            onClick={() => handleTabChange(role)}
          >
            {role.title}
          </UITabItem>
        ))}
      </UITabs>
    </DashboardType>
  );
};

export default NavTab;
