import styled from "styled-components";
import { Col } from "antd";
import theme from "theme";

export const Wrapper = styled(Col)<{ active?: boolean }>`
  display: flex;
  padding: 30px !important;
  margin-top: 15px;
  background: linear-gradient(
    109.04deg,
    rgba(255, 255, 255, 0.63) -23.19%,
    rgba(255, 255, 255, 0.09) 101.71%
  );
  box-shadow: 0px 11px 18px -5px rgba(1, 8, 58, 0.04);
  border-radius: 6px;
  color: ${({ active }) =>
    active ? theme.colors.white : theme.colors.blue_100};

  // disable blue background when selecting text
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
`;
