import styled from "styled-components";
import { Carousel as AntCarousel } from "antd";
import carouselBg from "./carouselBg.svg";

export const Carousel = styled(AntCarousel)`
  border-radius: ${({ theme }) => theme.radius.commonRadius};
  background-color: ${({ theme }) => theme.colors.blue_100};
  background-image: url(${carouselBg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  display: flex;
  flex-grow: 1;

  /* TODO: styles for .ant-carousel can't be applied from here, currently styled in src/App.less as workaround */

  div > h3 {
    color: white;
  }
  ul > li > button {
    border-radius: 10rem !important;
    opacity: 1 !important;
    width: 7px !important;
    height: 7px !important;
    margin: 0 !important;
  }
  ul > li.slick-active > button {
    margin: 0 !important;
    width: 7px !important;
    height: 7px !important;
    background: ${({ theme }) => theme.colors.gold_60} !important;
  }
  ul > li.slick-active {
    margin: 0 !important;
    width: 5px !important;
  }
  ul.slick-dots > li {
    width: 5px !important;
    margin: 0.3rem !important;
  }
  ul.slick-dots {
    left: 2rem;
    position: absolute !important;
    display: block !important;
    margin: 0 !important;
    top: 2rem;
    bottom: unset;
  }
`;

export const Wrapper = styled.div`
  margin-top: 50px;
  height: 25vh;
  max-width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 38px;
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
