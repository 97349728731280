import { Input } from "antd";
import styled from "styled-components";

export const UIInput = styled(Input)`
  background-color: ${({ theme }) => theme.colors.blue_5};
  padding: 0.5rem 1rem;
  border-radius: ${({ theme }) => theme.radius.buttonRadius};
  color: ${({ theme }) => theme.colors.grey_70};
  border: 1px solid ${({ theme }) => theme.colors.grey_4};
  &::placeholder {
    color: ${({ theme }) => theme.colors.grey_70};
  }
  &:focus {
    color: ${({ theme }) => theme.colors.blue_100};
    border: 1px solid ${({ theme }) => theme.colors.blue_70};
  }
  margin: 0 0 2rem 0;

  &.ant-input-group-wrapper {
    padding: 0;
    border: 0;

    .ant-input-group-addon {
      background: transparent;
    }

    input {
      padding: 0.5rem 1rem;

      &::placeholder {
        color: ${({ theme }) => theme.colors.grey_70};
      }
      &:focus {
        color: ${({ theme }) => theme.colors.blue_100};
        border: 1px solid ${({ theme }) => theme.colors.blue_70};
      }
    }
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.blue_100};
  font-size: 0.9em;
`;

export const IconWrap = styled.span`
  position: relative;
  right: 5%;
  top: 2.1rem;
  z-index: 2;
  float: right;
  cursor: pointer;
`;
