import styled from "styled-components";
import { Checkbox as AntCheckbox } from "antd";

export const Checkbox = styled(AntCheckbox)`
  // Color for Label
  color: ${(props) => props.theme.colors.grey_70};
  font-size: 15px;
  // Default background
  &.ant-checkbox-wrapper {
    flex: 1;
    display: flex;
    justify-content: start;
    height: fit-content;
    width: 100%;
    align-items: center;
    background-color: ${(props) => props.theme.colors.white};
    border-bottom: ${(props) => `1px solid ${props.theme.colors.grey_10}`};
    padding: 14px 11px;
    & .ant-checkbox-inner {
      border-radius: 2px;
    }
    & .ant-checkbox {
      position: relative;
      top: 0px;
    }
  }

  & .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(props) => props.theme.colors.blue_70};
  }

  & .ant-checkbox-input:hover + .ant-checkbox-inner {
    border-color: ${(props) => props.theme.colors.blue_70};
  }
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.theme.colors.blue_10};
    border-color: ${(props) => props.theme.colors.blue_70};
  }

  & .ant-checkbox-checked::after {
    border: none;
  }

  & .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${(props) => props.theme.colors.blue_70};
  }
  // Color for background when checked
  &.ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    background-color: ${(props) => props.theme.colors.blue_10};
  }
`;
