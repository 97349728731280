import { Form } from "antd";
import styled from "styled-components";

export const RadioInput = styled.input`
  position: absolute;
  opacity: 0;

  &:checked + .content {
    border-color: ${(props) => props.theme.colors.blue_80};
    color: ${(props) => props.theme.colors.blue_80};
    height: 106px;
    img {
      display: none;
    }
  }
`;

export const TileContainer = styled.label`
  position: relative;
`;

export const RadioElement = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colors.grey_10};
  height: 200px;
  width: 400px;
  padding: 33px 38px;
  border: ${(props) => `1px solid ${props.theme.colors.grey_10}`};
  box-shadow: 0 0 0 6px rgba(255, 255, 255, 1);
  border-radius: 6px;
  color: ${(props) => props.theme.colors.grey_70};
  transition: height 0.15s ease-out;
  cursor: pointer;
`;

export const AbsoluteFormItem = styled(Form.Item)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: inherit;
  margin-bottom: 0;
  z-index: 0;
`;
