import theme from "../../theme";

const { colors } = theme;

export const COLOR_PRESETS = {
  gold: {
    background: colors.gold_60,
    hover: colors.gold_30,
    color: colors.blue_100,
  },
  goldWhite: {
    background: colors.gold_60,
    hover: colors.gold_30,
    color: colors.white,
  },
  blue: {
    background: colors.blue_80,
    hover: colors.blue_60,
    color: colors.white,
  },
  teal: {
    background: colors.teal_70,
    hover: colors.teal_50,
    color: colors.white,
  },
  magenta: {
    background: colors.magenta_70,
    hover: colors.magenta_50,
    color: colors.white,
  },
  red: {
    background: colors.red_40,
    hover: colors.red_50,
    color: colors.white,
  },
};

export type ButtonColorPreset = keyof typeof COLOR_PRESETS;
