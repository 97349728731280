import { useEffect, useState } from "react";

import { Spinner } from "./loader.styles";

export interface LoaderProps {
  fullScreen?: boolean;
  size?: number;
  type?: "ThreeDots" | "Puff";
}
export const Loader = ({ fullScreen, type, size }: LoaderProps) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 300);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const customSize = size ?? 30; // w & h should be same size

  return show || !fullScreen ? (
    <Spinner
      fullScreen={fullScreen}
      type={type || fullScreen ? "ThreeDots" : "Puff"}
      color="#00BFFF"
      height={fullScreen ? 100 : customSize}
      width={fullScreen ? 100 : customSize}
    />
  ) : null;
};
