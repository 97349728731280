import React, { ReactNode } from "react";
import * as S from "./styles";
import { IconName, Loader } from "components";
import { ColProps, Popover, Typography } from "antd";

type Props = {
  title: string;
  icon?: IconName;
  loading?: boolean;
  isTitleHoverable?: boolean;
  titleCutIdx?: number;
  titlePadding?: number;
  actionElem?: ReactNode;
  isFeature?: boolean;
} & ColProps;

export const DashboardCard: React.FC<Props> = ({
  title,
  icon,
  children,
  loading,
  isTitleHoverable,
  titleCutIdx,
  actionElem,
  titlePadding = 5,
  isFeature,
  ...props
}) => (
  <S.Wrapper isFeature={isFeature} {...props}>
    <S.HeaderSection titlePadding={titlePadding}>
      <S.Header>
        {icon && <S.CardIcon name={icon} />}
        {isTitleHoverable && title.length > (titleCutIdx || 0) ? (
          <Popover placement="topLeft" content={title}>
            <Typography.Text style={{ cursor: "help" }} strong>
              {title.slice(0, titleCutIdx) + "..."}
            </Typography.Text>
          </Popover>
        ) : (
          <Typography.Text strong>{title}</Typography.Text>
        )}
      </S.Header>
      {actionElem}
    </S.HeaderSection>
    {loading ? <Loader /> : children}
  </S.Wrapper>
);
