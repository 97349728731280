import { useTeamMemberWorkDesignCollectionQuery } from "codegen/generated/graphql";
import { FC } from "react";
import { useParams } from "react-router";
import { Loader, Wrapper, WorkDesignTable } from "components";

const WorkDesigns: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useTeamMemberWorkDesignCollectionQuery({
    variables: {
      teamMemberId: id,
    },
  });

  if (loading) return <Loader />;

  return (
    <Wrapper>
      <WorkDesignTable data={data?.userWorkDesigns} />
    </Wrapper>
  );
};

export default WorkDesigns;
