import React from "react";
import { Col, Row, Typography } from "antd";

import { DimensionsQueryResult } from "codegen/generated/graphql";
import { DimensionChip } from "components/DimensionChip";
import { useFeatureContext } from "../context";

const { Text } = Typography;

type DimensionsProps = {
  dimensionsData: Pick<DimensionsQueryResult, "data">["data"];
  loading: Pick<DimensionsQueryResult, "loading">["loading"];
  onChange: (val: string) => void;
};

export const Dimension: React.FC<DimensionsProps> = ({
  onChange,
  loading,
  dimensionsData,
}) => {
  const {
    state: {
      data: { dimensionId },
    },
  } = useFeatureContext();

  // TODO: handle loading state and error
  if (loading || !dimensionsData) return null;

  return (
    <Col span={16} style={{ padding: "40px 20px" }}>
      <Text strong>Choose one dimension*</Text>
      <Row gutter={[20, 20]} style={{ marginTop: 20 }}>
        {dimensionsData.dimensions.map((val) => (
          <DimensionChip
            key={val.id}
            active={dimensionId === val.id}
            onClick={() => onChange(val.id)}
            label={val.name}
          />
        ))}
      </Row>
    </Col>
  );
};

export default Dimension;
