import { VFC } from "react";
import { PreferredTimes } from "./PreferredTimes";
import { TeamLocations } from "./TeamLocations";
import { FrameworkInsights } from "./FrameworkInsights";
import { useAggregateFeaturesData } from "pages/reports/hooks/useAggregateFeaturesData";
import { WorkLocation } from "./insightsData";

export const Calendar: VFC<{ frameworkId?: string; userScopeValue?: any }> = ({
  frameworkId,
  userScopeValue,
}) => {
  const data = useAggregateFeaturesData({
    reportKeys: ["remote-work"],
    frameworkId: frameworkId ?? "",
    userScope: userScopeValue ?? {},
    mapping: [
      {
        label: WorkLocation.IN_THE_OFFICE,
        get(data) {
          return data.features[0]?.choices
            .slice(0, 5)
            .map((choice) => [choice.text, data.users.length - choice.count]);
        },
      },
      {
        label: WorkLocation.REMOTE,
        get(data) {
          return data.features[0]?.choices
            .slice(0, 5)
            .map((choice) => [choice.text, choice.count]);
        },
      },
    ],
  });

  return (
    <>
      <PreferredTimes
        frameworkId={frameworkId}
        userScopeValue={userScopeValue}
      />
      <TeamLocations data={data} />
      <FrameworkInsights data={data} frameworkId={frameworkId} />
    </>
  );
};
