import { FC, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form, Space } from "antd";
import Text from "antd/lib/typography/Text";
import { Formik, FormikConfig } from "formik";
import * as Yup from "yup";

import {
  useCreateDimensionMutation,
  useUpdateDimensionMutation,
  useDimensionQuery,
  DimensionsDocument,
  DimensionsQuery,
} from "codegen/generated/graphql";
import ContentLayout from "layout/ContentLayout";
import { ContentType } from "layout/ContentLayout/ContentLayout";
import {
  FormikInput,
  ButtonTertiary,
  Icon,
  Loader,
  DimensionTag,
} from "components";
import * as S from "./NewDimension.styles";
import { getHeaderConfig } from "./constants";
import { ColorPicker } from "./ColorPicker";
import { DIMENSION_COLORS } from "./ColorPicker/colors";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter the dimension's name"),
});

const NewDimension: FC = () => {
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const [createDimension, { loading: createLoading }] =
    useCreateDimensionMutation({
      update: (cache, result) => {
        const queryResult = cache.readQuery<DimensionsQuery>({
          query: DimensionsDocument,
        });
        const newDimension = result?.data?.createDimension;
        const originalData = queryResult?.dimensions ?? [];
        if (!newDimension || !originalData.length) return;
        cache.writeQuery<DimensionsQuery>({
          query: DimensionsDocument,
          data: {
            ...queryResult,
            dimensions: [newDimension, ...originalData],
          },
        });
      },
    });
  const [updateDimension, { loading: updateLoading }] =
    useUpdateDimensionMutation();

  const { data, loading: loadingData } = useDimensionQuery({
    skip: !id,
    variables: { id },
  });

  const initialValues = {
    name: data?.dimension?.name ?? "",
    colors: !!data
      ? {
          color: "#" + data?.dimension?.meta?.textColor ?? "",
          background: "#" + data?.dimension?.meta?.backgroundColor ?? "",
        }
      : DIMENSION_COLORS[0],
  };

  const loading = !!id ? updateLoading : createLoading;

  const goBack = useCallback(() => {
    history.push("/frameworks/dimensions");
  }, [history]);

  const handleSubmit: FormikConfig<typeof initialValues>["onSubmit"] =
    useCallback(
      async (values, { resetForm }) => {
        try {
          const input = {
            name: values.name,
            textColor: values.colors.color,
            backgroundColor: values.colors.background,
          };

          if (id) {
            await updateDimension({
              variables: {
                input: { id, ...input },
              },
            });
          } else {
            await createDimension({
              variables: { input },
            });
          }

          resetForm();
          goBack();
        } catch (err) {
          // TODO: handle errors
          console.warn(err);
        }
      },
      [createDimension, goBack, id, updateDimension]
    );

  if (loadingData) return <Loader fullScreen />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ values, dirty, submitForm }) => (
        <Form style={{ display: "flex", flex: 1 }}>
          <ContentLayout
            header={getHeaderConfig(id)}
            typeContent={ContentType.WithSidebar}
            sideContent={
              <S.ButtonsWrap>
                <ButtonTertiary
                  loading={loading}
                  style={{ width: "90%" }}
                  onClick={submitForm}
                  disabled={!dirty}
                  icon={<Icon name="checkmark" fontSize="10px" />}
                >
                  <Text strong>Save Dimension</Text>
                </ButtonTertiary>
                <ButtonTertiary
                  style={{ background: "#A0AFBC" }}
                  onClick={goBack}
                >
                  <Text strong>Cancel</Text>
                </ButtonTertiary>
              </S.ButtonsWrap>
            }
          >
            <S.FormWrap>
              <FormikInput
                name="name"
                label="Name"
                placeholder="New Dimension"
              />
            </S.FormWrap>
            <Space direction="vertical">
              <Text strong>Design:</Text>
              <ColorPicker name="colors" />
              <DimensionTag
                background={values.colors.background.substring(1)}
                color={values.colors.color.substring(1)}
                label={!!values.name ? values.name : "New dimension"}
              />
            </Space>
          </ContentLayout>
        </Form>
      )}
    </Formik>
  );
};

export default NewDimension;
