import { Popover, Space } from "antd";
import { VFC } from "react";
import CountUp from "react-countup";
import * as S from "./DoughnutChart/styles";
import Placeholder from "components/Placeholder";

export type LegendItem = {
  bgColor: string;
  label: string;
  count?: number;
  placeholder?: {
    type: string;
    value: string;
  };
};

export type Props = {
  numOfCols?: 1 | 2 | 3;
  items: LegendItem[];
  isCountDisplayed?: boolean;
  margin?: string;
};
export const Legend: VFC<Props> = ({
  numOfCols,
  items,
  isCountDisplayed = true,
  margin,
}) => (
  <S.LegendWrap numOfCols={numOfCols} margin={margin}>
    {items.map(({ bgColor, label, count, placeholder }, idx) => (
      <S.LegendItemWrap key={idx}>
        <S.LegendItemColor bgColor={bgColor}>
          <S.Dot />
          {isCountDisplayed && (
            <S.Count>{<CountUp end={Number(count)} duration={1} />}</S.Count>
          )}
        </S.LegendItemColor>
        <S.LegendItem>
          <Space>
            {placeholder?.type && (
              <Placeholder
                value={placeholder?.type}
                text={placeholder?.value}
              />
            )}
            {label.length > 35 ? (
              <Popover
                placement="topLeft"
                content={
                  <>
                    {placeholder?.type && (
                      <Placeholder
                        value={placeholder?.type}
                        text={placeholder?.value}
                      />
                    )}
                    {label}
                  </>
                }
              >
                <span style={{ cursor: "help" }}>
                  {label.slice(0, 35) + "..."}
                </span>
              </Popover>
            ) : (
              label
            )}
          </Space>
        </S.LegendItem>
      </S.LegendItemWrap>
    ))}
  </S.LegendWrap>
);
