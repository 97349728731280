import styled from "styled-components";
import { Radio as AntRadio } from "antd";

export const Radio = styled(AntRadio)`
  // Color for Label
  color: ${(props) => props.theme.colors.grey_70};
  font-size: 15px;
  // Default background
  &.ant-radio-wrapper {
    display: flex;
    justify-content: start;
    height: min-content;
    width: 100%;
    align-items: center;
    background-color: ${(props) => props.theme.colors.white};
    border-bottom: ${(props) => `1px solid ${props.theme.colors.grey_10}`};
    padding: 14px 11px;
  }

  & .ant-radio {
    position: relative;
    top: 0px;
  }

  & .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${(props) => props.theme.colors.blue_80};
  }

  // Color for Inner dot
  & .ant-radio-inner::after {
    background-color: ${(props) => props.theme.colors.blue_60};
  }

  & .ant-radio-input:hover + .ant-radio-inner {
    border-color: ${(props) => props.theme.colors.blue_70};
  }

  & .ant-radio-checked .ant-radio-inner {
    border-color: ${(props) => props.theme.colors.blue_70};
    box-shadow: none;
  }
  // Color for background when checked
  &.ant-radio-wrapper.ant-radio-wrapper-checked {
    background-color: ${(props) => props.theme.colors.blue_10};
  }
`;
