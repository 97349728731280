import { ReactElement, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import theme from "theme";
import { Input, Col, Row, notification, Space, Form, Select } from "antd";
import Link from "antd/es/typography/Link";
import Title from "antd/lib/typography/Title";
import Text from "antd/es/typography/Text";
import { makeUseModal, ModalProps } from "lib/useModal";
import { UserWithAttributes } from "common/types";
import { ReactComponent as Search } from "assets/icons/search.svg";
import plus from "assets/icons/plus.svg";
import filterBlue from "assets/images/filter-blue.svg";
import filterYellow from "assets/images/filter-yellow.svg";
import { useTable, useColumns } from "hooks/table";
import { getFormattedDate } from "utils/formatters";
import { ImgSvg, Table, Avatar, Button, HoverFilterButton } from "components";
import { Scroll, Userscount } from "./Section.styles";
import {
  useAssignWorkDesignMutation,
  MeDocument,
  InviteUsersDocument,
  useUserScopesQuery,
  useReportingManagersQuery,
  UserScopesDocument,
} from "codegen/generated/graphql";
import { object } from "@storybook/addon-knobs";

type PropsType = {
  id: any;
};

const InviteToFrameworkDialog = ({
  id,
  setShow,
}: PropsType & ModalProps): ReactElement => {
  const [form] = Form.useForm();
  const [filterByName, setFilterByName] = useState("");
  const [filterByAll, setFilterByAll] = useState({});
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [filterButtonIcon, setFilterButtonIcon] = useState(filterBlue);

  const [selectedJobTitle, setJobTitleValue] = useState([]);
  const [selectedOffice, setOfficeValue] = useState([]);
  const [selectedEmploymentType, setEmploymentTypeValue] = useState([]);
  const [selectedTenure, setSelectedTenure] = useState([]);
  const [selectedFunction, setSelectedFunction] = useState([]);
  const [selectedRace, setSelectedRace] = useState([]);
  const [selectedGender, setSelectedGender] = useState([]);
  const [isSalaryRangeSelected, setSalaryRangeSelected] = useState(false);

  const [assignWorkDesign] = useAssignWorkDesignMutation({
    onCompleted: () => {
      notification.success({
        type: "success",
        message: "Framework has been assigned.",
        placement: "bottomLeft",
      });
      setShow(false);
    },
    onError: (e) => {
      notification.error({
        type: "error",
        message: `Could not assign framework`,
        placement: "bottomLeft",
      });
    },
    refetchQueries: [MeDocument],
  });

  const fetch = useLazyQuery(InviteUsersDocument);

  const {
    tableProps,
    data: { inviteUsers: data } = {},
    selected,
  } = useTable<UserWithAttributes>(fetch, {
    searchInputValue: filterByName,
    userScopeInput: filterByAll,
    key: "inviteUsers.users",
  });

  const {
    data: userScopeData,
    loading: userScopeLoading,
    error: userScopeError,
  } = useUserScopesQuery({});

  const jobTitles = userScopeData?.userScopes?.jobTitles || [];
  const jobTitleOptions = jobTitles?.map((item: any) => {
    return { label: item, value: item };
  });

  const offices = userScopeData?.userScopes?.offices || [];
  const officeOptions = offices?.map((item: any) => {
    return { label: item, value: item };
  });

  const employmentTypes = userScopeData?.userScopes?.employmentTypes || [];
  const employmentTypeOptions = employmentTypes?.map((item: any) => {
    return { label: item, value: item };
  });
  const functions = userScopeData?.userScopes?.functions || [];
  const functionOptions = functions?.map((item: any) => {
    return { label: item, value: item };
  });
  const raceEthinicities = userScopeData?.userScopes?.raceEthinicities || [];
  const raceOptions = raceEthinicities?.map((item: any) => {
    return { label: item, value: item };
  });

  const genders = userScopeData?.userScopes?.genders || [];
  const genderOptions = genders?.map((item: any) => {
    return { label: item, value: item };
  });

  const {
    data: reportingManagerData,
    loading: reportingManagerLoading,
    error: reportingManagerError,
  } = useReportingManagersQuery({});

  const renderReportingManager = () => {
    return (
      reportingManagerData &&
      reportingManagerData?.reportingManagers &&
      reportingManagerData?.reportingManagers.map((reportingManager: any) => {
        return (
          <Select.Option value={reportingManager.id} key={reportingManager.id}>
            {reportingManager.name}
          </Select.Option>
        );
      })
    );
  };

  const columns = useColumns<UserWithAttributes>([
    {
      title: "Name",
      key: "profile.name",
      fixed: "left",
      render: ({ profile, id, isEnabled }) => (
        <Space size={12}>
          <Avatar src={profile.avatar} />
          <Text disabled={!isEnabled}>
            {profile.name} {profile.surname}
          </Text>
        </Space>
      ),
      sorter: true,
    },
    {
      title: "Hire Date",
      dataIndex: "profile.hireDate".split("."),
      formatter: getFormattedDate,
      sorter: true,
    },
    {
      title: "Function",
      dataIndex: "profile.function".split("."),
      sorter: true,
    },
  ]);

  const handleOnInvite = async () => {
    await assignWorkDesign({
      variables: {
        input: {
          frameworkIds: [id],
          userIds: selected,
        },
      },
    });
  };

  const nonInvitedUsers = (data?.users ?? []).filter((user: any) => {
    return !user.workDesigns.some((wd: any) => wd.frameworkId === id);
  });

  const selectJobTitleProps: any = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value: selectedJobTitle,
    options: jobTitleOptions,
    onChange: (newValue: any) => {
      setJobTitleValue(newValue);
    },
    placeholder: "Select job title",
    maxTagCount: "responsive",
    size: "large",
  };

  const selectOfficeProps: any = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value: selectedOffice,
    options: officeOptions,
    onChange: (newValue: any) => {
      setOfficeValue(newValue);
    },
    placeholder: "Select office",
    maxTagCount: "responsive",
    size: "large",
  };

  const selectEmploymentTypeProps: any = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value: selectedEmploymentType,
    options: employmentTypeOptions,
    onChange: (newValue: any) => {
      setEmploymentTypeValue(newValue);
    },
    placeholder: "Select employment type",
    maxTagCount: "responsive",
    size: "large",
  };

  const tenureOptions: any = [
    {
      label: "Less than 1 year",
      value: "[0, 1]",
    },
    {
      label: "1-2 years",
      value: "[1, 2]",
    },
    {
      label: "2-5 years",
      value: "[2, 5]",
    },
    {
      label: "5-10 years",
      value: "[5, 10]",
    },
    {
      label: "10-20 years",
      value: "[10, 20]",
    },
    {
      label: "More than 20 years",
      value: "[21, 21]",
    },
  ];

  const selectTenureProps: any = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value: selectedTenure,
    options: tenureOptions,
    onChange: (newValue: any) => {
      setSelectedTenure(newValue);
    },
    placeholder: "Select tenure",
    maxTagCount: "responsive",
    size: "large",
  };

  const selectFunctionProps: any = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value: selectedFunction,
    options: functionOptions,
    onChange: (newValue: any) => {
      setSelectedFunction(newValue);
    },
    placeholder: "Select function",
    maxTagCount: "responsive",
    size: "large",
  };

  const selectRaceProps: any = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value: selectedRace,
    options: raceOptions,
    onChange: (newValue: any) => {
      setSelectedRace(newValue);
    },
    placeholder: "Select race/ethnicity",
    maxTagCount: "responsive",
    size: "large",
  };

  const selectGenderProps: any = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value: selectedGender,
    options: genderOptions,
    onChange: (newValue: any) => {
      setSelectedGender(newValue);
    },
    placeholder: "Select gender",
    maxTagCount: "responsive",
    size: "large",
  };

  const handleApplyFilter = (values: any) => {
    const filterScope = {
      employmentTypes:
        typeof values?.employmentTypes === "object"
          ? values.employmentTypes
          : values?.employmentTypes
          ? [values?.employmentTypes]
          : [],
      functions:
        typeof values?.functions === "object"
          ? values.functions
          : values?.functions
          ? [values.functions]
          : [],
      genders:
        typeof values?.genders === "object"
          ? values.genders
          : values?.genders
          ? [values.genders]
          : [],
      jobTitles:
        typeof values?.jobTitles === "object"
          ? values.jobTitles
          : values?.jobTitles
          ? [values.jobTitles]
          : [],
      manager:
        typeof values?.manager === "object"
          ? values.manager
          : values?.manager
          ? [values.manager]
          : [],
      offices:
        typeof values?.offices === "object"
          ? values.offices
          : values?.offices
          ? [values.offices]
          : [],
      raceEthinicities:
        typeof values?.raceEthinicities === "object"
          ? values.raceEthinicities
          : values?.raceEthinicities
          ? [values.raceEthinicities]
          : [],
      salary: values?.salary ? values.salary : undefined,
      tenure:
        typeof values?.tenure === "object"
          ? values.tenure
          : values?.tenure
          ? [values.tenure]
          : [],
    };
    setFilterByAll(filterScope);
    setShowFilterMenu(false);
  };

  const resetFilter = () => {
    setFilterByAll({});
    setSalaryRangeSelected(false);
    form.resetFields();
  };

  const onSalaryRangeChange = () => {
    setSalaryRangeSelected(true);
  };

  return (
    <>
      <Scroll>
        <Title level={3}>Invite to Framework</Title>
        <Row>
          <Col span={12}>
            <Input
              placeholder="Search by name..."
              size={"large"}
              suffix={<Search />}
              value={filterByName}
              onChange={(e) => {
                setFilterByName(e.target.value);
              }}
            />
          </Col>
          <Col span={2} offset={1}>
            <HoverFilterButton
              outlined
              onMouseOver={() => setFilterButtonIcon(filterYellow)}
              onMouseOut={() => setFilterButtonIcon(filterBlue)}
              onClick={() => setShowFilterMenu(!showFilterMenu)}
            >
              <ImgSvg
                src={filterButtonIcon}
                alt="filter"
                style={{ margin: 0, height: "1.2rem" }}
              />
            </HoverFilterButton>
          </Col>
          <Col span={7} offset={1}>
            <Button
              $width="171px"
              size="large"
              icon={
                <ImgSvg
                  src={plus}
                  alt={"plus"}
                  style={{ marginRight: "8px" }}
                />
              }
              $bgColor={theme.colors.blue_100}
              onClick={handleOnInvite}
              disabled={showFilterMenu}
            >
              <Text strong>
                Invite
                {selected.length > 0 ? ` (${selected.length})` : ""}
              </Text>
            </Button>
          </Col>
        </Row>
        {showFilterMenu ? (
          <Row>
            <Col span={24} style={{ marginTop: "1rem" }}>
              <Form
                name="frameworkUserfilter"
                layout="vertical"
                form={form}
                onFinish={(values) => handleApplyFilter(values)}
              >
                <Row style={{ marginBottom: "1rem" }}>
                  <Col
                    span={4}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Button
                      htmlType="submit"
                      $width="10rem"
                      $bgColor={theme.colors.blue_100}
                    >
                      <Text strong>Apply</Text>
                    </Button>
                  </Col>
                  <Col offset={1} style={{ marginTop: "0.5rem" }}>
                    <Link onClick={() => resetFilter()}>Reset</Link>
                  </Col>
                </Row>
                <Row>
                  <Col span={11}>
                    <Form.Item
                      label={<Text strong>Job Title</Text>}
                      name="jobTitles"
                    >
                      <Select {...selectJobTitleProps} />
                    </Form.Item>
                  </Col>
                  <Col offset={1} span={11}>
                    <Form.Item
                      label={<Text strong>Office</Text>}
                      name="offices"
                    >
                      <Select {...selectOfficeProps} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={11}>
                    <Form.Item
                      label={<Text strong>Manager</Text>}
                      name="manager"
                    >
                      <Select
                        placeholder="Select manager"
                        size={"large"}
                        allowClear
                      >
                        {renderReportingManager()}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col offset={1} span={11}>
                    <Form.Item
                      label={<Text strong>Employment Type</Text>}
                      name="employmentTypes"
                    >
                      <Select {...selectEmploymentTypeProps} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={11}>
                    <Form.Item label={<Text strong>Tenure</Text>} name="tenure">
                      <Select {...selectTenureProps} />
                    </Form.Item>
                  </Col>
                  <Col offset={1} span={11}>
                    <Form.Item label={<Text strong>Salary</Text>}>
                      <Input.Group style={{ display: "flex" }}>
                        <Form.Item name={["salary", "operator"]}>
                          <Select
                            placeholder="Select"
                            style={{ width: "inherit", marginRight: "1rem" }}
                            size={"large"}
                            onChange={onSalaryRangeChange}
                          >
                            <Select.Option value="LESS_THAN" key="1">
                              Less than
                            </Select.Option>
                            <Select.Option value="GREATER_THAN" key="2">
                              Greater than
                            </Select.Option>
                            <Select.Option value="EQUALS_TO" key="3">
                              Equals To
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={["salary", "value"]}
                          rules={[
                            {
                              required: isSalaryRangeSelected,
                              message: "Please input salary",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Input salary"
                            type="number"
                            min={0}
                            size={"large"}
                          />
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={11}>
                    <Form.Item
                      label={<Text strong>Function</Text>}
                      name="functions"
                    >
                      <Select {...selectFunctionProps} />
                    </Form.Item>
                  </Col>
                  <Col offset={1} span={11}>
                    <Form.Item
                      label={<Text strong>Race/ethnicity</Text>}
                      name="raceEthinicities"
                    >
                      <Select {...selectRaceProps} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={11}>
                    <Form.Item
                      label={<Text strong>Gender</Text>}
                      name="genders"
                    >
                      <Select {...selectGenderProps} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        ) : null}
        <Userscount>
          {Object.keys(filterByAll).length > 0
            ? data?.users.length
            : nonInvitedUsers.length}{" "}
          of {data?.total} Users
        </Userscount>

        <Table
          {...tableProps}
          dataSource={nonInvitedUsers}
          columns={columns}
          rowSelection={{
            getCheckboxProps: (record: any) => ({
              disabled: !record.isEnabled,
            }),
            ...tableProps.rowSelection,
          }}
          size="small"
          rowKey={"id"}
          locale={{
            emptyText: (
              <Text type="secondary">
                It seems there are no Members who match your filters
              </Text>
            ),
          }}
        />
      </Scroll>
    </>
  );
};

export const useInviteToFrameworkDialog = makeUseModal<PropsType>(
  InviteToFrameworkDialog,
  { width: "60vw" }
);

export default InviteToFrameworkDialog;
