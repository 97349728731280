import { useAggregateFrameworkWorkStyleQuery } from "codegen/generated/graphql";
import { DoughnutChart } from "components/Charts/DoughnutChart";
import { doughnutChartOptions } from "components/Charts/DoughnutChart/mock";
import { DashboardCard } from "pages/dashboard/components/DashboardCard";
import { LineChart } from "components/Charts/LineChart";
import { VFC } from "react";
import { useFetchFWStatus } from "../../../hooks/useFetchFWStatus";

type Props = {
  frameworkId?: any;
  userScopeValue?: any;
};

export const ChartsRow: VFC<Props> = ({ frameworkId, userScopeValue }) => {
  const { dataWithFormattedLabels, wdCount, isFetching } = useFetchFWStatus(
    frameworkId,
    userScopeValue
  );

  const { data: workStyles } = useAggregateFrameworkWorkStyleQuery({
    variables: {
      frameworkId: frameworkId ?? "",
      userScope: userScopeValue,
    },
  });

  return (
    <>
      <DashboardCard title="Work Design Status" icon="reportsGrey">
        <DoughnutChart
          total={wdCount || 0}
          data={dataWithFormattedLabels}
          options={doughnutChartOptions}
          numOfCols={2}
          loading={isFetching}
          diameterInPx={290}
        />
      </DashboardCard>
      <DashboardCard
        title="Team Work Style - Primary Choice"
        icon="reportsGrey"
      >
        <LineChart
          data={workStyles?.aggregateFrameworkWorkStyle[0]}
          {...frameworkId}
        />
      </DashboardCard>
      <DashboardCard
        title="Team Work Style - Secondary Choice"
        icon="reportsGrey"
      >
        <LineChart
          data={workStyles?.aggregateFrameworkWorkStyle[1]}
          {...frameworkId}
        />
      </DashboardCard>
    </>
  );
};
