import React from "react";
import { Tag, TagProps } from "antd";
import styled, { css } from "styled-components";
import {
  CustomTagAttributeNames,
  CustomTagAttributes,
} from "../../common/types";

const roundStyle = css`
  border-radius: 15px;
  padding: 0 10px;
  align-items: center;
`;

export type STagProps = TagProps & CustomTagAttributes;

export const STag = styled<React.FC<STagProps>>(Tag).withConfig({
  shouldForwardProp: (prop) => !CustomTagAttributeNames.has(prop),
})`
  color: ${({ textColor }) => textColor ?? textColor};
  font-weight: ${({ fontWeight }) => fontWeight ?? fontWeight};
  ${({ isRound }) => (isRound ? roundStyle : undefined)};
`;
