import { FC } from "react";
import { Col, Row } from "antd";
import ActionBanner from "components/ActionBanner";
import Carousel from "components/Carousel";
import { usePoll } from "utils/hooks/usePoll";
import {
  useFrameworksCollectionQuery,
  useMeQuery,
  useAggregateFrameworkWorkStyleQuery,
} from "codegen/generated/graphql";
import ContentLayout from "layout/ContentLayout";
import { ContentType } from "layout/ContentLayout/ContentLayout";
import { LineChart } from "components/Charts/LineChart";
import { DoughnutChart } from "components/Charts/DoughnutChart";

import * as S from "./Dashboard.styles";
import { doughnutChartOptions } from "components/Charts/DoughnutChart/mock";
import { DashboardCard } from "../components/DashboardCard";
import { useFetchFWStatus } from "hooks/useFetchFWStatus";

type Props = {
  props?: string;
};

const Dashboard: FC<Props> = () => {
  usePoll();
  const { data: workStyles } = useAggregateFrameworkWorkStyleQuery({});
  const { data, loading } = useMeQuery();
  const { data: fwData } = useFrameworksCollectionQuery({
    variables: {
      sharedOnly: true,
    },
  });

  const sortedFws = [...(fwData?.frameworks || [])].reverse();

  const { dataWithFormattedLabels, wdCount, isFetching } = useFetchFWStatus(
    sortedFws[0]?.id,
    true
  );

  const tabOptions = [
    {
      tab: "Primary mindset",
      tabsContent: (
        <LineChart
          data={workStyles?.aggregateFrameworkWorkStyle[0]}
          isOnDashboard
        />
      ),
    },
    {
      tab: "Secondary mindset",
      tabsContent: (
        <LineChart
          data={workStyles?.aggregateFrameworkWorkStyle[1]}
          isOnDashboard
        />
      ),
    },
  ];

  const isData = !dataWithFormattedLabels?.datasets[0]?.data.every(
    (el) => el === 0
  );

  return (
    <ContentLayout typeContent={ContentType.FullWidth}>
      <Row style={{ display: "flex", flex: 1 }} gutter={[15, 0]}>
        <Col span={16}>
          <ActionBanner meData={data?.me} isLoading={loading} />
        </Col>
        <Col span={8}>
          <Carousel />
        </Col>
      </Row>
      <Row
        style={{ display: "flex", flex: 2, marginTop: "1rem", height: "10vh" }}
      >
        <DashboardCard
          title="Team Work Style"
          icon="reportsYellow"
          // TODO: Temp solution for the purposes of v1, charts shall be part of v2
          // style={{ opacity: 0.3 }}
        >
          <S.Tab baseHeight tabsOptions={tabOptions}></S.Tab>
        </DashboardCard>
        <S.Spacer />
        <DashboardCard
          title="Work Design Status"
          icon="reportsYellow"
          titlePadding={!isData ? 42 : 0}
        >
          <DoughnutChart
            total={wdCount || 0}
            data={dataWithFormattedLabels}
            options={doughnutChartOptions}
            numOfCols={2}
            loading={isFetching}
            diameterInPx={280}
            isOnDashboard
          />
        </DashboardCard>
      </Row>
    </ContentLayout>
  );
};

export default Dashboard;
