import { Choice } from "../../codegen/generated/graphql";
export const sortChoices = (choices: any) => {
  const sortedArray = [...choices].sort(function (
    a: { order: number },
    b: { order: number }
  ) {
    if (a.order && b.order) {
      return a?.order - b?.order;
    }
    return -1;
  });
  return sortedArray as Choice[];
};
