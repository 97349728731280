import { Popover } from "antd";
import { DashboardCard } from "pages/dashboard/components/DashboardCard";
import { useRef, VFC } from "react";
import { toPng, toJpeg } from "html-to-image";
import * as S from "./styles";
import {
  RevenueImpactItem,
  RevenueImpactRow,
  RevenueImpactValue,
} from "../../styles";
import { MenuOutlined } from "@ant-design/icons";

export type ReportsContentData = {
  name: string;
  amount: number | string;
  lastItem?: boolean;
  highlightedText?: boolean;
};

type Props = {
  title: string;
  data: ReportsContentData[];
};

// const table = document.getElementById('table')
// const test = 'data:image/svg+xml,' + escape(ReactDOMServer.renderToStaticMarkup(table))

//@ TODO - refactor parseInt
export const FinancialTable: VFC<Props> = ({ title, data }) => {
  const myRef = useRef<any>(null);

  const downloadTableImage = (imagetype: any) => {
    if (imagetype === "png") {
      toPng(myRef.current, { backgroundColor: "#FFF" })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "Table.png";
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (imagetype === "jpeg") {
      toJpeg(myRef.current, { backgroundColor: "#FFF" })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "Table.jpeg";
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const content = (
    <div>
      <S.Cursor onClick={() => downloadTableImage("jpeg")}>
        Download JPEG
      </S.Cursor>
      <S.Cursor onClick={() => downloadTableImage("png")}>
        Download PNG
      </S.Cursor>
    </div>
  );
  return (
    <DashboardCard id="table" title={title} icon="reportsGrey">
      <div ref={myRef} style={{ padding: "15px" }}>
        <S.position>
          <Popover
            placement="bottom"
            content={content}
            overlayInnerStyle={{ maxWidth: "fit-content" }}
          >
            <MenuOutlined />
          </Popover>
        </S.position>

        {data.map((el) => (
          <RevenueImpactRow key={el.name} lastItem={el.lastItem}>
            <RevenueImpactItem highlightedText={el.highlightedText}>
              {el.name}
            </RevenueImpactItem>
            <RevenueImpactValue value={parseInt(el.amount.toString())}>
              {el.amount}
            </RevenueImpactValue>
          </RevenueImpactRow>
        ))}
      </div>
    </DashboardCard>
  );
};
