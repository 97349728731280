import { FC, ReactNode } from "react";
import teamIcon from "assets/icons/teamYellow.svg";
import { ButtonSecondary } from "components";
import plusIcon from "assets/icons/plusYellow.svg";
import Image from "components/Image";
import * as S from "../carousel.styles";
import Text from "antd/lib/typography/Text";
import { Space } from "antd";
import { CarouselContentItem } from "../carousel";

const CarouselContent: FC<CarouselContentItem> = ({
  headline,
  buttonValue,
  onClickHandler,
}) => (
  <S.Wrapper>
    <S.Content>
      <Text
        strong
        style={{ color: "white", marginBottom: "10%" }}
        className="white"
      >
        <Space size={5}>
          <Image src={teamIcon} />
          {headline}
        </Space>
      </Text>

      <ButtonSecondary
        outlined
        presetName="goldWhite"
        icon={<Image style={{ margin: "7px 10px 0 0" }} src={plusIcon} />}
        onClick={onClickHandler}
      >
        <Text strong>{buttonValue}</Text>
      </ButtonSecondary>
    </S.Content>
  </S.Wrapper>
);

export default CarouselContent;
