import React from "react";

// There are 3 scenarios of team management state when user is logs in:
// ---

// 1. User needs to create and define a team => "create-team"
// 2. User needs to set up features(questions) => "create-framework"
// 3. User has created at least one framework => "framework-created"
import { TooltipProps } from "antd/lib/tooltip";
import { Maybe, ProfileDataFragment } from "../codegen/generated/graphql";

export enum SetupProgressManager {
  CreateTeam = "create-team",
  CreateFramework = "create-framework",
  ShareFramework = "share-framework",
  FrameworkShared = "framework-shared",
}

export enum SetupProgressMember {
  CreateWorkDesign = "create-workdesign",
  ActiveWorkDesign = "active-workdesign",
}

export type AntdComponentProps<T> = T extends React.FC<infer Props>
  ? Props
  : T extends React.ForwardRefExoticComponent<
      infer Props & React.RefAttributes<HTMLElement>
    >
  ? Props
  : T;

/**
 * Proxy for antd tooltip props
 */
export type CustomTooltipProps = {
  isTooltipVisible?: TooltipProps["visible"];
  tooltipContent?: TooltipProps["title"];
  tooltipPlacement?: TooltipProps["placement"];
};

export type Validator<V = string> = (value: V) => boolean;

/**
 * Radio input option
 */
export type RadioOption = {
  value?: Maybe<string>;
  label?: React.ReactNode;
  placeholder?: {
    value?: string;
    text?: Maybe<string>;
  };
};

/**
 * Modal steps navigation
 */
export type ModalStepsNavigation = {
  onNextStepClick: () => void;
  onBackStepClick?: () => void;
  onSkipStepClick?: () => void;
};

export type UserWithAttributes = {
  id: string;
  email: string;
} & Partial<
  Pick<
    ProfileDataFragment,
    | "avatar"
    | "name"
    | "surname"
    | "gender"
    | "mindset"
    | "hireDate"
    | "function"
    | "annualSalary"
    | "jobTitle"
    | "office"
    | "paidTimeOff"
  >
>;

export interface TagAttributes extends CustomTagAttributes {
  text: string;
  bgColor: string;
}

export interface CustomTagAttributes {
  textColor?: string;
  isRound?: boolean;
  fontWeight?: number;
  bgColor?: string;
}

export const CustomTagAttributeNames = new Set([
  "textColor",
  "isRound",
  "fontWeight",
  "bgColor",
]);
