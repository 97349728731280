import { useCallback, useMemo } from "react";
import { Dropdown, Menu, Space, Button, TableProps } from "antd";
import { Link } from "react-router-dom";
import Text from "antd/es/typography/Text";
import { generatePath } from "react-router";
import { Routes } from "router/routes";
import { ReactComponent as Dots } from "assets/icons/dots.svg";
import { Table, WorkDesignStatusTag, Avatar } from "components";
import { useColumns } from "hooks/table";
import { getFormattedDate } from "utils/formatters";

import { useInviteUserToFrameworkDialog } from "../containers/InviteUserToFramework.dialog";
import { useToggleUsersMutation } from "codegen/generated/graphql";

interface TeamMemberListProps<T extends any> {
  tableProps: TableProps<T>;
  data: any;
}

export const TeamMemberList = <T extends any>({
  tableProps,
  data,
}: TeamMemberListProps<T>) => {
  const [toggleUsers] = useToggleUsersMutation({
    update: (cache) => {
      // Notify Apollo cache that `accountUsers` (with any set of vars) is outdated.
      cache.evict({ fieldName: "accountUsers" });
    },
  });

  const makeToggleUsers = useCallback(
    (userIds: string[], isEnabled: boolean) => () => {
      toggleUsers({ variables: { userIds, isEnabled } });
    },
    [toggleUsers]
  );

  const [InviteDialog, showInviteDialog] = useInviteUserToFrameworkDialog();

  const columns = useColumns<T>(
    [
      {
        title: "Name",
        key: "profile.name",
        fixed: "left",
        render: ({ profile, id, isEnabled }) => (
          <Link
            to={generatePath(Routes.TEAM_MEMBER_PROFILE.path, {
              id,
            })}
            onClick={(e) => !isEnabled && e.preventDefault()}
          >
            <Space size={12}>
              {profile && (
                <>
                  <Avatar src={profile?.avatar} />
                  <Text disabled={!isEnabled}>
                    {profile.name} {profile.surname}
                  </Text>
                </>
              )}
            </Space>
          </Link>
        ),
      },
      {
        title: "Hire Date",
        dataIndex: "profile.hireDate".split("."),
        formatter: getFormattedDate,
      },
      {
        title: "WD Status",
        dataIndex: "workDesigns",
        // @TODO this sort doesn't work now as workDesigns is not scalar
        sorter: false,
        render: (workDesigns) => {
          const status = workDesigns?.[0]?.status;
          return <WorkDesignStatusTag status={status} />;
        },
      },
      { title: "Function", dataIndex: "profile.function".split(".") },
      { title: "Annual Salary", dataIndex: "profile.annualSalary".split(".") },
      { title: "Office", dataIndex: "profile.office".split(".") },
      { title: "Paid Time Off", dataIndex: "profile.paidTimeOff".split(".") },
      { title: "Mindset", dataIndex: "profile.mindset".split(".") },
      {
        key: "dotsMenu",
        fixed: "right",
        sorter: false,
        width: "50px",
        render: ({ id, isEnabled }) => (
          <Dropdown
            overlay={() => (
              <Menu>
                <Menu.Item key="1">
                  <Link
                    to={generatePath(Routes.TEAM_MEMBER_PROFILE.path, { id })}
                  >
                    Edit Profile
                  </Link>
                </Menu.Item>
                <Menu.Item key="2" onClick={() => showInviteDialog({ id })}>
                  Invite to Framework
                </Menu.Item>
                {/* <Menu.Item key="3">Export Current WD</Menu.Item> */}
                {isEnabled ? (
                  <Menu.Item key="4" onClick={makeToggleUsers([id], false)}>
                    Disable Profile
                  </Menu.Item>
                ) : (
                  <Menu.Item key="4" onClick={makeToggleUsers([id], true)}>
                    Enable Profile
                  </Menu.Item>
                )}
              </Menu>
            )}
            trigger={["click"]}
          >
            <Button type="link" icon={<Dots />} />
          </Dropdown>
        ),
      },
    ],
    {
      sorter: true,
      Wrapper: ({ children }) => <Text type="secondary">{children}</Text>,
    }
  );

  const membersTableRenderer = useMemo(() => {
    return (
      <>
        <InviteDialog id={null} />
        <Table
          {...tableProps}
          columns={columns}
          rowSelection={{
            hideSelectAll: true,
            getCheckboxProps: (record: any) => ({
              disabled: !record.isEnabled,
            }),
          }}
          size="small"
          rowKey={"id"}
          locale={{
            emptyText: (
              <Text type="secondary">
                It seems there are no Members who match your filters
              </Text>
            ),
          }}
        />
      </>
    );
  }, [InviteDialog, columns, tableProps]);

  return membersTableRenderer;
};
