import { usePollContext } from "../context";
import { useMeQuery, UserRole } from "../../codegen/generated/graphql";
import { PollStep } from "../../common/constants";
import { useEffect, useMemo } from "react";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";

const getPollStep = (roles: UserRole[] | undefined): PollStep | undefined => {
  if (!roles || roles.length === 0) {
    console.error("User does not have any role");
    return;
  }

  if (roles.length === 1) {
    const userRole = roles[0];

    switch (userRole) {
      case UserRole.TeamMember:
        return PollStep.EMPLOYEE_STEP_ONE;

      // For all roles below we will have returned value
      // case UserRole.EnterpriseAdmin:
      // case UserRole.EnterpriseLead:
      // case UserRole.OrgAdmin:
      // case UserRole.OrgLead:
      case UserRole.PrimaryAdmin:
      case UserRole.Admin:
      case UserRole.TeamManager:
        // case UserRole.TeamLead:
        return PollStep.MANAGER_STEP_ONE;

      case UserRole.LlunaAdmin:
      default:
        return;
    }
  }

  if (roles.includes(UserRole.LlunaAdmin)) {
    return;
  } else {
    return PollStep.MANAGER_STEP_ONE;
  }
};

export const usePoll = () => {
  const { setPollModalVisibility, setCurrentPollStep } = usePollContext();
  const { data } = useMeQuery();
  const { sm, xs } = useBreakpoint();
  const history = useHistory();

  const roles = data?.me?.roles;
  const isOnboardingPollComplete = !!data?.me?.isOnboardingPollComplete;

  const initialPollStep = useMemo(() => getPollStep(roles), [roles]);

  /**
   * Is responsible for redirection to the poll page in case mobile version,
   * or displaying Poll modal in case desktop
   */
  useEffect(() => {
    // We should display poll modal only in case we have initial step defined
    // NOTE: Initial step can be undefined for the "LLUNA_ADMIN" role
    if (data && !isOnboardingPollComplete && initialPollStep) {
      setCurrentPollStep(initialPollStep);
      if (!!xs) {
        // for mobile version we have a separate page for poll
        setPollModalVisibility(false);
        history.push(Routes.POLL.path);
      } else if (!!sm) {
        // for desktop we display the same poll in the Poll modal
        setPollModalVisibility(true);
      }
    }
  }, [
    initialPollStep,
    isOnboardingPollComplete,
    setCurrentPollStep,
    setPollModalVisibility,
    history,
    data,
    sm,
    xs,
  ]);
};
