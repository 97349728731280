import { useMemo } from "react";
import { TagAttributes } from "../../common/types";

export const useTagAttributes = <T extends any>(
  attributesMap: Map<T, TagAttributes>,
  type: T
): TagAttributes | undefined => {
  const tagAttributes = useMemo(
    () => attributesMap.get(type),
    [attributesMap, type]
  );

  if (!tagAttributes) {
    console.error(
      `Tag type: ${type}, wasn't find in map. Please add the type to the map.`
    );
    return;
  }

  return tagAttributes;
};
