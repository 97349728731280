import styled, { css } from "styled-components";

import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import { Row as AntRow, Tag as AntdTag } from "antd";
import * as React from "react";
import { TagProps } from "antd/lib/tag";
import theme from "theme";

export const Container = styled.div<{ isDesktop?: boolean }>`
  ${({ isDesktop }) =>
    isDesktop
      ? css`
          height: 100%;
          padding: 0 1em 1em 0;
        `
      : css`
          overflow-y: auto;
          max-height: 65vh;
          z-index: 150;
        `}
`;

export const Row = styled(AntRow)`
  justify-content: space-between;
`;

export const Name = styled(Title)`
  &.ant-typography {
    font-weight: normal;
    padding: 0 0 9px;
    line-height: 38px;
    font-size: 26px;
  }
`;

export const Label = styled(Paragraph)<{ bold?: boolean }>`
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  color: ${({ theme, bold }) =>
    bold ? theme.colors.blue_100 : theme.colors.grey_70};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #bec9d6;
  margin: 14px 0 24px;
`;

export const Wrapper = styled.div<{ isDesktop?: boolean }>`
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  box-shadow: 0px -11px 18px -5px rgba(1, 8, 58, 0.08);
  backdrop-filter: blur(32px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: ${({ isDesktop }) => (isDesktop ? "20px" : "20px 20px 0 0")};
  ${({ isDesktop }) =>
    isDesktop &&
    css`
      height: 100%;
    `}
  padding: 34px 30px;

  margin-bottom: ${({ isDesktop }) => (isDesktop ? "1rem" : 0)};
`;

export const Handle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 163px;
  height: 22px;
  border-radius: 10px 10px 0 0;
  background: ${({ theme }) => theme.colors.blue_80};

  position: absolute;
  top: -22px;
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
`;

export const HandleIcon = styled.img`
  width: 24px;
`;

export const FrameworkProgressTag = styled<
  React.FC<TagProps & { isCompleted: boolean }>
>(AntdTag)`
  border-radius: 20px;
  padding: 5px 12px;
  align-items: center;
  background-color: ${({ isCompleted }) =>
    isCompleted ? theme.colors.green_40 : theme.colors.white};
  color: ${({ isCompleted }) =>
    isCompleted ? theme.colors.white : theme.colors.blue_100};
`;
