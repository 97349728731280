import {
  useAggregateFeaturesDataQuery,
  AggregateFeaturesDataQuery,
} from "codegen/generated/graphql";

interface useAggregateFeaturesDataProps {
  reportKeys: string[];
  frameworkId: string;
  userScope?: any;
  mapping: {
    label: string;
    get: (result: AggregateFeaturesDataQuery["aggregateFeaturesData"]) => any;
  }[];
}

export const useAggregateFeaturesData = ({
  reportKeys,
  frameworkId,
  userScope,
  mapping,
}: useAggregateFeaturesDataProps) => {
  const { data, loading } = useAggregateFeaturesDataQuery({
    fetchPolicy: "no-cache",
    variables: {
      frameworkId,
      userScope,
      keys: reportKeys,
    },
  });

  if (data?.aggregateFeaturesData.features.length) {
    return mapping.map(({ label, get }) => {
      const dataSubset = get(data.aggregateFeaturesData);
      return [label, dataSubset || []];
    });
  } else return [];
};
