import React from "react";
import { BackgroundContainer } from "components/BackgroundContainer";
import { ContainerWithImage } from "components/ContainerWithImage";
import { signInWithPassword, signInWithProvider } from "utils/firebase";
import skyGirlSvg from "assets/images/logIn.svg";
import { LogInForm } from "../../components/Forms";

type Props = {
  props?: string;
};

const LogIn: React.FC<Props> = () => {
  return (
    <BackgroundContainer>
      <ContainerWithImage image={{ src: skyGirlSvg, alt: "sky-girl" }}>
        <LogInForm
          signInWithPassword={signInWithPassword}
          signInWithProvider={signInWithProvider}
        />
      </ContainerWithImage>
    </BackgroundContainer>
  );
};
export default LogIn;
