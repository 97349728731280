import { IAuthContext } from "utils/context/auth";
import { menuItem } from "config/menu/common";
import { UserRole } from "codegen/generated/graphql";

export const menuItems = (user: IAuthContext["user"]) => [
  menuItem({
    key: "1",
    title: "Team Profile",
    activeIcon: "teamBlue",
    inactiveIcon: "teamGrey",
    path: "",
  }),
  ...(user?.account?.isStripeCustomer &&
  user?.roles.includes(UserRole.PrimaryAdmin)
    ? [
        menuItem({
          key: "2",
          title: "Manage Subscription",
          activeIcon: "subscriptions",
          inactiveIcon: "subscriptions",
          path: "/subscriptions",
        }),
      ]
    : []),
  ...(user?.roles.includes(UserRole.PrimaryAdmin) ||
  user?.roles.includes(UserRole.Admin)
    ? [
        menuItem({
          key: "3",
          title: "Account & Password",
          activeIcon: "accountDark",
          inactiveIcon: "accountLight",
          path: "/account",
        }),
      ]
    : []),
  ...(user?.roles.includes(UserRole.PrimaryAdmin) ||
  user?.roles.includes(UserRole.Admin)
    ? [
        menuItem({
          key: "4",
          title: "Integrations",
          activeIcon: "notification",
          inactiveIcon: "notificationLight",
          path: "/Integrations",
        }),
      ]
    : []),
];
