import styled, { css } from "styled-components";
import { ButtonPrimary } from "components/Button";
import Text from "antd/lib/typography/Text";
import { Row as AntdRow, RowProps } from "antd";
import React from "react";

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 57px 15px 0;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
  margin-bottom: 0;
`;

export const Description = styled.p`
  font-size: 15px;
  line-height: 21px;
  margin: 1em 0;
  color: ${({ theme }) => theme.colors.grey};
`;

export const MindsetDescription = styled.p<{
  isMobile?: boolean;
}>`
  font-size: 13px;
  line-height: 19px;
  text-align: ${({ isMobile }) => (isMobile ? "start" : "center")};
  color: ${({ theme }) => theme.colors.grey};
`;

export const Icon = styled.img``;

export const Card = styled.div<{ isHighlighted: boolean }>`
  ${({ isHighlighted }) =>
    isHighlighted
      ? css`
          background: #ffffff;
          border: 1px solid #99d1ff;
          box-sizing: border-box;
          box-shadow: 0 0 10px rgba(195, 202, 254, 0.6);
        `
      : css`
          background: linear-gradient(
            109.04deg,
            rgba(255, 255, 255, 0.63) -23.19%,
            rgba(255, 255, 255, 0.09) 101.71%
          );
          border: 1px solid rgba(255, 255, 255, 0.3);
          box-shadow: 0 11px 18px -5px rgba(1, 8, 58, 0.04);
          backdrop-filter: blur(32px);
        `}
  border-radius: 12px;
  padding: 16px;
  margin: 0 0 9px;
  height: 100%;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
`;

export const Btn = styled(ButtonPrimary)`
  margin: 45px 0 25px;
  align-items: center;
`;

export const ButtonLabel = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
`;

export const DirectionRow = styled<React.FC<{ isMobile?: boolean } & RowProps>>(
  AntdRow
)`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "row" : "column")};
`;
