import styled from "styled-components";
import { Form } from "antd";

export const FormWrapper = styled(Form)`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
`;

export const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const FooterBG = styled.div`
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.blue_10};
`;

export const NoWorkDesign = styled.div`
  margin: 30vh auto;
`;
