import DashboardManager from "./manager";
import DashboardMember from "./member";
import DashboardAdmin from "./admin";
import { UserRole } from "codegen/generated/graphql";
import { selectedRoleVar } from "store/app";
import { useReactiveVar } from "@apollo/client";
import { useContext } from "react";
import { AuthContext } from "utils/context/auth";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

// @TODO - make the use of this component instead of userIsTeamManager | userIsAdmin | ...
// import { Restricted } from "components";

export const Dashboard = () => {
  const { user } = useContext(AuthContext);

  const userIsPrimaryAdmin = user?.roles.includes(UserRole.PrimaryAdmin);
  const userRoleIsAdmin = user?.roles.includes(UserRole.Admin);
  const userIsAdmin = user?.roles.includes(UserRole.LlunaAdmin);
  const userIsTeamManager = user?.roles.includes(UserRole.TeamManager);
  const { xs } = useBreakpoint();

  const selectedRole = useReactiveVar(selectedRoleVar);

  const shouldShowPrimaryAdmin =
    userIsPrimaryAdmin && selectedRole?.id === UserRole.PrimaryAdmin;
  const shouldShowAdmin =
    userRoleIsAdmin && selectedRole?.id === UserRole.Admin;
  const shouldShowTeamManager =
    userIsTeamManager && selectedRole?.id === UserRole.TeamManager;

  if (shouldShowPrimaryAdmin || shouldShowTeamManager || shouldShowAdmin)
    return <DashboardManager />;
  if (userIsAdmin) return <DashboardAdmin />;
  return <DashboardMember xs={xs} />;
};
