import { FC } from "react";
import Input from "components/Input";
import Button from "components/Button";
import theme from "theme";
import Wrapper from "components/Wrapper";
import { Col, notification, Row, Form } from "antd";
import { Title } from "../TeamProfile/TeamProfile.styles";
import {
  reauthenticateUser,
  auth,
  updateFirebaseUserPassword,
} from "utils/firebase";
import { EmailAuthProvider } from "firebase/auth";

enum FORM_FIELD_NAME {
  CURRENT_PASSWORD = "currentPassword",
  NEW_PASSWORD = "newPassword",
  REPEATED_PASSWORD = "repeatedPassword",
}

export const AccountPasswordChange: FC = () => {
  const [form] = Form.useForm();

  const handleSubmit = async (
    currentPassword: string,
    newPassword: string,
    repeatedNewPassword: string
  ) => {
    if (newPassword !== repeatedNewPassword) {
      notification.error({
        type: "error",
        message: "New password and repeat new password are not matching",
        placement: "bottomLeft",
      });

      return null;
    }

    const firebaseUser = auth.currentUser;

    if (firebaseUser?.email) {
      const credential = EmailAuthProvider.credential(
        firebaseUser.email,
        currentPassword
      );

      try {
        await reauthenticateUser(firebaseUser, credential);
      } catch (e) {
        notification.error({
          type: "error",
          message: "Couldn't re-authenticate, please try again.",
          placement: "bottomLeft",
        });
      }
      updateFirebaseUserPassword(firebaseUser, newPassword)
        .then(() => {
          form.resetFields();
          notification.success({
            type: "success",
            message: "Password changed",
            placement: "bottomLeft",
          });
        })
        .catch((e) => {
          notification.error({
            type: "error",
            message: (
              <>
                {e.message.substring(0, 9) === "Firebase:"
                  ? e.message.slice(10, -1)
                  : e.message}
              </>
            ),
            placement: "bottomLeft",
          });
        });
    }
  };

  return (
    <Form
      name={"accountPasswordChangeForm"}
      form={form}
      autoComplete={"off"}
      onFinish={({
        currentPassword,
        newPassword,
        repeatedPassword,
      }: {
        [FORM_FIELD_NAME.CURRENT_PASSWORD]: string;
        [FORM_FIELD_NAME.NEW_PASSWORD]: string;
        [FORM_FIELD_NAME.REPEATED_PASSWORD]: string;
      }) => {
        handleSubmit(currentPassword, newPassword, repeatedPassword);
      }}
    >
      <Title level={4} margin="3rem 0 1rem 0">
        Update Password
      </Title>
      <Wrapper width="48%">
        <Form.Item
          name={FORM_FIELD_NAME.CURRENT_PASSWORD}
          rules={[
            {
              required: true,
              message: "Value is required",
            },
          ]}
        >
          <Input
            label="Current Password"
            name="currentPassword"
            type="password"
            withIcon
          />
        </Form.Item>
      </Wrapper>
      <Row gutter={[10, 0]}>
        <Col span={12}>
          <Form.Item
            name={FORM_FIELD_NAME.NEW_PASSWORD}
            rules={[
              {
                required: true,
                message: "Value is required",
              },
            ]}
          >
            <Input
              label="New Password"
              name="newPassword"
              type="password"
              withIcon
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={FORM_FIELD_NAME.REPEATED_PASSWORD}
            rules={[
              {
                required: true,
                message: "Value is required",
              },
            ]}
          >
            <Input
              label="Repeat Password"
              name="repeatedPassword"
              type="password"
              withIcon
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item shouldUpdate>
        {() => (
          <Button
            $width="100%"
            $bgColor={theme.colors.blue_100}
            $color="white"
            $padding="0.6rem 0 1.9rem 0"
            htmlType="submit"
            disabled={
              !form.isFieldsTouched() ||
              !!form.getFieldsError().filter(({ errors }) => errors.length)
                .length ||
              form.getFieldValue(FORM_FIELD_NAME.CURRENT_PASSWORD) ===
                undefined ||
              form.getFieldValue(FORM_FIELD_NAME.NEW_PASSWORD) === undefined ||
              form.getFieldValue(FORM_FIELD_NAME.REPEATED_PASSWORD) ===
                undefined
            }
          >
            <strong>Save Changes</strong>
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};
