import { FC } from "react";
import { useMyWorkDesignCollectionQuery } from "codegen/generated/graphql";
import ContentLayout from "layout/ContentLayout";
import { ContentType } from "layout/ContentLayout/ContentLayout";
import { Loader, WorkDesignTable } from "components";

export const WorkDesignCollection: FC = () => {
  const { data, loading } = useMyWorkDesignCollectionQuery();
  if (loading) return <Loader />;

  return (
    <ContentLayout
      header={{ title: "My Work Designs" }}
      typeContent={ContentType.FullWidth}
    >
      <WorkDesignTable data={data?.me?.workDesigns} isMyWorkDesign />
    </ContentLayout>
  );
};

export default WorkDesignCollection;
