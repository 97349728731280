import { FC } from "react";
import { UserRole } from "codegen/generated/graphql";
import usePermission from "./usePermission";

type Props = {
  to: UserRole[];
  fallback?: JSX.Element | string;
  loadingComponent?: JSX.Element | string;
};

// This component is meant to be used everywhere a restriction based on user role is needed
const Restricted: FC<Props> = ({
  to,
  fallback,
  loadingComponent,
  children,
}) => {
  // We "connect" to the provider thanks to the PermissionContext
  const [allowed] = usePermission(to);

  // If the user has the required role, render the children
  if (allowed) {
    return <>{children}</>;
  }

  // Otherwise, render the fallback
  return <>{fallback}</>;
};

export default Restricted;
