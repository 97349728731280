import { CSSProperties, FC } from "react";
import { Typography } from "antd";
import { useReactiveVar } from "@apollo/client";
import { selectedRoleVar, useApplicationStore } from "store/app";

import { ButtonSecondary } from "components";
import * as S from "./actionBanner.styles";

import { SetupProgressManager, SetupProgressMember } from "common/types";
import {
  FrameworkStatus,
  MeQuery,
  useFrameworksCollectionQuery,
  UserRole,
  WdStatus,
} from "codegen/generated/graphql";
import Loader from "components/Loader";
import { Link } from "react-router-dom";
import { actionBanners } from "constants/miscellaneous";
import { getFormattedDate } from "utils/formatters";

type MeData = MeQuery["me"];

export interface ActionBannerProps {
  isLoading: boolean;
  meData?: MeData;
  style?: CSSProperties;
}

const { Text, Title } = Typography;

const useManagerInvariants = (meData?: MeData) => {
  const { data: frameworksData, loading: loadingFrameworks } =
    useFrameworksCollectionQuery();

  const hasTeam = !!meData?.team;

  const frameworks = frameworksData?.frameworks;
  const hasFramework = frameworks && frameworks?.length > 0;

  const didShareFramework = frameworks?.some((framework) =>
    framework.status.includes("SHARED")
  );

  if (!frameworks) return null;

  if (hasTeam && !hasFramework) return SetupProgressManager.CreateFramework;
  if (hasFramework && !didShareFramework)
    return SetupProgressManager.ShareFramework;
  if (hasFramework && didShareFramework)
    return SetupProgressManager.FrameworkShared;

  return SetupProgressManager.CreateTeam;
};

const useMemberInvariants = (meData?: MeData) => {
  const hasWorkDesignComplete =
    meData?.myLatestWorkDesign?.status === WdStatus.Active;

  if (hasWorkDesignComplete) return SetupProgressMember.ActiveWorkDesign;

  return SetupProgressMember.CreateWorkDesign;
};

// @TODO - typing
const hooks: { [key in UserRole]?: any } = {
  [UserRole.PrimaryAdmin]: useManagerInvariants,
  [UserRole.Admin]: useManagerInvariants,
  [UserRole.TeamManager]: useManagerInvariants,
  [UserRole.TeamMember]: useMemberInvariants,
  [UserRole.LlunaAdmin]: () => {
    return null;
  },
};

// @TODO - create better logic for handling different invariants for members and managers
const ActionBanner: FC<ActionBannerProps> = ({ meData, isLoading, style }) => {
  const selectedRole = useReactiveVar(selectedRoleVar);
  const { user } = useApplicationStore();
  const { data: frameworksData, loading: loadingFrameworks } =
    useFrameworksCollectionQuery();

  const role = selectedRole?.id || user?.roles[0];

  const useInvariants = hooks[role || UserRole.TeamMember];
  const progress: SetupProgressManager | SetupProgressMember | null =
    useInvariants(meData);

  if (!role || !progress) return null;

  const actionBannersForRole = actionBanners[role];
  if (!actionBannersForRole) return null;

  const bannerConfig = actionBannersForRole[progress];

  const frameworkEndDate = meData?.myLatestWorkDesign?.availableUntil;

  const activeFrameworks = frameworksData?.frameworks.filter(
    (fw) => fw.status === FrameworkStatus.Shared
  ).length;

  if (isLoading || loadingFrameworks) return <Loader />;
  return (
    <S.Wrapper
      bgColor={bannerConfig.bannerBgColor}
      style={style}
      bgSrc={bannerConfig.imageSrc}
    >
      <S.Container>
        <Title>{bannerConfig.headline}</Title>
        <div>
          <Text strong>
            {typeof bannerConfig.firstLine !== "string"
              ? bannerConfig.firstLine(activeFrameworks)
              : bannerConfig.firstLine}
          </Text>
          <br />
          <Text>{`${bannerConfig.secondLine}${
            progress === "active-workdesign"
              ? getFormattedDate(frameworkEndDate)
              : ""
          }`}</Text>
        </div>
        <Link to={bannerConfig.link}>
          <ButtonSecondary presetName={bannerConfig.buttonPreset}>
            <Text strong>{bannerConfig.buttonText}</Text>
          </ButtonSecondary>
        </Link>
      </S.Container>
    </S.Wrapper>
  );
};

export default ActionBanner;
