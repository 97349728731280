import { ChartProps } from "react-chartjs-2";

export const doughnutChartOptions: ChartProps<"line">["options"] = {
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
};

export const dougnutChartOptionsWithOnClickHandler: (
  datasets: any,
  invokeModal: any
) => ChartProps<"line">["options"] = (datasets, invokeModal) => ({
  onClick(event, elements) {
    const element = elements[0];
    if (!element) return;

    const { index } = element;

    const focusedResponders = datasets.responders[index];
    const focusedLabel = datasets.labels[index];
    const focusedPlaceholderValue = datasets.placeholders[index].value;
    const focusedPlaceholderType = datasets.placeholders[index].type;
    const startHour = focusedPlaceholderValue
      ? new Date(datasets.placeholders[index].value.split("|")[0]).getHours() %
          12 || 12
      : "";
    const endHour = focusedPlaceholderValue
      ? new Date(datasets.placeholders[index].value.split("|")[1]).getHours() %
          12 || 12
      : "";
    const startMinutes = focusedPlaceholderValue
      ? (
          "0" +
          new Date(
            datasets.placeholders[index].value.split("|")[0]
          ).getMinutes()
        ).slice(-2)
      : "";
    const endMinutes = focusedPlaceholderValue
      ? (
          "0" +
          new Date(
            datasets.placeholders[index].value.split("|")[1]
          ).getMinutes()
        ).slice(-2)
      : "";
    const timeRange =
      `${startHour}:${startMinutes}-${endHour}:${endMinutes}` || "";
    console.log(timeRange);
    invokeModal({
      tableData: [
        {
          answer: focusedLabel,
          responders: focusedResponders,
          placholderType: focusedPlaceholderType,
          placeholderValue:
            focusedPlaceholderType === "Date picker"
              ? timeRange
              : focusedPlaceholderValue,
        },
      ],
    });
  },
  ...doughnutChartOptions,
});
