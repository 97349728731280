import { endsWith, trimEnd } from "lodash-es";
import { ChartProps } from "react-chartjs-2";
import { withTheme } from "styled-components";

const timeValues = (value: number | string) => {
  const hour = new Date(value).getHours();

  if (hour === 12) return hour + " PM";
  if (hour >= 12) return hour - 12 + " PM";

  return hour + " AM";
};

export const preferredTimesOptions: (
  dateTimeBase: [number, number],
  // @TODO - proper typings
  datasets: any[],
  invokeModal: any
) => ChartProps<"bar">["options"] = (dateTimeBase, datasets, invokeModal) => ({
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    xAxes: {
      display: false,
      stacked: true,
    },
    yAxes: {
      stacked: true,
      type: "linear",
      beginAtZero: false,
      position: "left",
      reverse: true,
      min: dateTimeBase[0],
      max: dateTimeBase[1],
      ticks: {
        stepSize: 3.6e6,
        callback: timeValues,
      },
    },
  },
  interaction: {
    intersect: true,
  },
  onClick(event, elements) {
    const element = elements[0];
    if (!element) return;

    const { datasetIndex } = element;
    const focusedDataset = datasets[datasetIndex];

    // @TODO Split the repsponders loading into lazy query that is trigerred when modal invoked
    invokeModal({
      tableData: [
        {
          answer: focusedDataset.text,
          responders: focusedDataset.responders.map((r: any) => r.profile),
        },
      ],
    });
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (model) => {
          const { datasetIndex } = model;
          return `Count: ${datasets[datasetIndex].count.toString()}`;
        },
      },
    },
    datalabels: {
      display: true,
      formatter: (value, context) => {
        return datasets[context.datasetIndex].count;
      },
      color: "white",
      anchor: "center",
      align: "bottom",
      offset: -6.5,
    },
  },
});

export const teamLocationsOptions: ChartProps<"bar">["options"] = {
  maintainAspectRatio: false,
  responsive: true,
  layout: {},
  scales: {
    xAxes: {
      position: "top",
      grid: {
        borderColor: "transparent",
      },
      ticks: {
        padding: 10,
      },
    },
    yAxes: {
      display: false,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: true,
      anchor: "end",
      align: "top",
      offset: 0,
      padding: 0,
    },
  } as any,
};
