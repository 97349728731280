import { FC, useEffect, useState } from "react";
import { colors } from "theme";
import { Col, Row, Typography, Button, Spin } from "antd";
import { RocketOutlined, SyncOutlined } from "@ant-design/icons";
import ContentLayout from "layout/ContentLayout";
import { ContentType } from "layout/ContentLayout/ContentLayout";

type Props = {
  props?: string;
};

const Dashboard: FC<Props> = () => {
  const [quote, setQuote] = useState<{
    author: string;
    content: string | null;
  } | null>(null);

  const [counter, setCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetch("https://api.quotable.io/random")
      .then((res) => res.json())
      .then(({ author, content }) => setQuote({ author, content }))
      .catch(() =>
        setQuote({
          author: "Oak's Lab",
          content:
            "We would really like to show some cool quote here, but we have probably reached the limit of this API :(",
        })
      )
      .finally(() => setIsLoading(false));
  }, [counter]);

  // @TODO - refactor - use styled components
  return (
    <ContentLayout typeContent={ContentType.FullWidth}>
      <Row style={{ display: "flex", flex: 1 }} gutter={[15, 0]}>
        <Col span={14} push={1}>
          <Row
            justify="center"
            style={{
              height: "100%",
              alignContent: "baseline",
              flexDirection: "column",
            }}
          >
            <Typography.Text
              style={{
                textTransform: "uppercase",
                fontWeight: 600,
                color: "#222",
                marginBottom: 36,
              }}
            >
              <RocketOutlined /> Hey there, Jess and Aaron. How are you today?
            </Typography.Text>

            <Spin
              tip="Loading something special..."
              spinning={isLoading}
              style={{ paddingTop: 40, paddingBottom: 40 }}
            >
              <Typography.Title
                style={{
                  fontWeight: 800,
                  color: "#aaa",
                }}
              >
                <span
                  style={{
                    fontSize: "150%",
                    transform: "translateY(-24px) translateX(-48px)",
                    fontFamily: "monospace",
                    position: "absolute",
                    color: colors.gold_50,
                  }}
                >
                  “
                </span>
                {quote?.content}
                <span
                  style={{
                    fontSize: "150%",
                    transform: "translateY(-13px) translateX(19px)",
                    fontFamily: "monospace",
                    position: "absolute",
                    color: colors.gold_50,
                  }}
                >
                  „
                </span>
              </Typography.Title>
              {quote && (
                <Typography.Text
                  style={{
                    marginTop: 12,
                    display: "inline-block",
                    textTransform: "uppercase",
                    fontSize: 12,
                    color: "#666",
                  }}
                >
                  —— {quote?.author}
                  <Button
                    shape="circle"
                    size="small"
                    icon={<SyncOutlined style={{ fontSize: 12 }} />}
                    style={{ marginLeft: 12 }}
                    onClick={() => setCounter((i) => i + 1)}
                  />
                </Typography.Text>
              )}
            </Spin>
          </Row>
        </Col>
      </Row>
    </ContentLayout>
  );
};

export default Dashboard;
