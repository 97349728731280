import { Card as ACard } from "antd";
import styled from "styled-components";

// @TODO similar to FancyCard in Subscription - reuse?
export const Card = styled(ACard)`
  background: linear-gradient(
    109.04deg,
    rgba(255, 255, 255, 0.63) -23.19%,
    rgba(255, 255, 255, 0.09) 101.71%
  );
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  box-shadow: 0px 11px 18px -5px rgba(1, 8, 58, 0.04);
  backdrop-filter: blur(32px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 12px;
  width: 100%;

  & > .ant-card-body {
    padding: 25px 29px;
  }
`;
