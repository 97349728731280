import { Button } from "antd";
import styled from "styled-components";

export const GhostButton = styled(Button)`
  width: 40px;
  height: 40px;
  border: ${({ theme }) => `1px solid ${theme.colors.grey_20}`};
  box-shadow: 0px 2px 4px rgba(232, 232, 233, 0.5);
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.gold_60};
    border: ${({ theme }) => `1px solid ${theme.colors.gold_60}`};
  }
`;
