import React from "react";
import { Wrapper } from "components";
import { BackgroundContainer } from "components/BackgroundContainer";
import { Col, Row } from "antd";
import { ImgSvg } from "components/ImgSvg";
import llunas from "assets/images/llunas.svg";
import { selectedPlanVar } from "store/plan";
import { useReactiveVar } from "@apollo/client";
import { PlanSelectContainer } from "./containers/PlanSelect.container";
import { PlanSummaryHashRouter } from "./containers/PlanSummary.container";
import { useApplicationStore } from "store/app";
import { Redirect } from "react-router-dom";
import { Routes } from "router/routes";

interface SubscribePageProps {
  isDialog?: boolean;
}

export const Subscribe: React.VFC<SubscribePageProps> = ({ isDialog }) => {
  const { user } = useApplicationStore();

  const hasActiveSubscription = !!user?.account?.subscriptions[0];

  if (!isDialog && hasActiveSubscription)
    return <Redirect to={Routes.DASHBOARD.path} />;

  return isDialog || hasActiveSubscription ? (
    <UpsellContent />
  ) : (
    <BackgroundContainer
      decorationElement={
        <ImgSvg
          src={llunas}
          alt="llunas"
          style={{
            position: "absolute",
            width: "40%",
            right: 0,
            top: -40,
          }}
        />
      }
    >
      <SubscribeContent />
    </BackgroundContainer>
  );
};

const SubscribeContent: React.VFC = () => {
  const plan = useReactiveVar(selectedPlanVar);

  return (
    <Row>
      {/* Left column */}
      <Col flex="auto" offset={2} xs={20} xl={17} style={{ marginTop: "90px" }}>
        {!plan ? <PlanSelectContainer /> : <PlanSummaryHashRouter />}
      </Col>
    </Row>
  );
};

const UpsellContent: React.VFC = () => {
  const plan = useReactiveVar(selectedPlanVar);

  return (
    <Wrapper padding="48px">
      <Row>
        {/* Left column */}
        <Col span={24}>
          {!plan ? <PlanSelectContainer /> : <PlanSummaryHashRouter />}
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Subscribe;
