import styled from "styled-components";

export const Wrapper = styled.div<{ bgColor: string; bgSrc: string }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
  padding-left: 28px;
  border-radius: ${({ theme }) => theme.radius.commonRadius};
  background: ${({ bgColor }) => bgColor};
  background-image: url(${({ bgSrc }) => bgSrc});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 38px 0;
`;
