import { Button, Col, Form, Row } from "antd";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import { MaterialInput } from "../MaterialInput";
import Link from "antd/es/typography/Link";
import theme from "../../theme";
import { Routes } from "../../router/routes";
import React from "react";
import { useHistory } from "react-router-dom";

enum FORM_FIELD_NAME {
  EMAIL = "email",
}

export const RequestInvitationForm: React.FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  return (
    <Form name={"requestInvitationForm"} form={form} autoComplete={"off"}>
      <Row justify="start" gutter={[8, 24]}>
        <Col span={17}>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Title level={1} style={{ marginBottom: 0 }}>
                Request
              </Title>
              <Title level={1} style={{ margin: 0 }}>
                an invitation
              </Title>
            </Col>
            <Col span={24}>
              <Row justify="start">
                <Col>
                  <Text>
                    Your manager is using LLUNA but you didn’t receive an
                    invitation or it is expired.
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
              name={FORM_FIELD_NAME.EMAIL}
            >
              <MaterialInput placeholder={"Email Address"} />
            </Form.Item>
          </Col>
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Row justify="center">
                <Col>
                  <Button type="primary"> Request an invitation</Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="center">
                <Col>
                  <Text>
                    {"You have an account? "}
                    <Link
                      color={theme.colors.blue_70}
                      underline={true}
                      onClick={() => {
                        history.push(Routes.LOGIN.path);
                      }}
                    >
                      Log in
                    </Link>
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
