import React, { useEffect } from "react";
import { PollForm } from "../../components/Forms/PollForm";
import { Col, Row } from "antd";
import logo from "assets/icons/lluna-logo.svg";
import { ImgSvg } from "../../components";
import { usePollContext } from "../../utils/context";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";

const Poll: React.FC = () => {
  const { currentPollStep } = usePollContext();
  const history = useHistory();

  // Redirects from the Poll page in case of refresh
  useEffect(() => {
    if (!currentPollStep) {
      history.replace(Routes.DASHBOARD.path);
    }
  }, [currentPollStep, history]);

  return (
    <Row justify="center" style={{ padding: "20px" }}>
      <Col>
        <ImgSvg src={logo} alt={"logo"} />
      </Col>
      <Col>
        <PollForm isInModal={false} />
      </Col>
    </Row>
  );
};

export default Poll;
