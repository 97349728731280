import { Header } from "antd/lib/layout/layout";
import styled from "styled-components";
import caretDown from "assets/icons/caretDown.svg";
import { Popover } from "antd";
import { default as AntText } from "antd/lib/typography/Text";

export const Wrapper = styled(Header)`
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: white;
  border-bottom: 1px solid #f3f4f7;

  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.ant-layout-header {
    padding: 0 1rem !important;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
`;

export const SPopover = styled(Popover)`
  &.ant-popover-open {
    background-color: ${({ theme }) => theme.colors.gold_20};
  }
`;

export const AccountButton = styled.div`
  height: 35px;
  width: 35px;
  background-color: #f8f8ff;
  border-radius: 50%;
  cursor: pointer;
  background-image: url(${caretDown});
  background-repeat: no-repeat;
  background-size: 0.5rem;
  background-position: center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.gold_20};
  }
  margin-left: 20px;
`;

export const Text = styled(AntText)`
  color: ${({ theme }) => theme.colors.blue_100};
`;
