import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "antd";
import { useIntercom, IntercomProps } from "react-use-intercom";
import { auth } from "utils/firebase";
import { appMutations, invitationVar, useApplicationStore } from "store/app";
import { client } from "apolloClient";
import { useQuery } from "utils/router";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

import { MobileLoader } from "components/Loader";
import SplashScreen from "components/SplashScreen";

import {
  CheckInvitationDocument,
  CheckInvitationQuery,
  CheckInvitationQueryVariables,
  MeDocument,
  MeQuery,
  useCreateAccountMutation,
  UserRole,
  UserAdditionalPermissions,
} from "codegen/generated/graphql";

import { Me } from "store/app/types";
import { USER_ROLES } from "store/app/constants";

const getLoaderType = (isMobile: boolean) => {
  return isMobile ? MobileLoader : SplashScreen;
};

const getInvitationInfo = async (invitationCode: string) => {
  if (!invitationCode) return;

  const { data } = await client.query<
    CheckInvitationQuery,
    CheckInvitationQueryVariables
  >({
    query: CheckInvitationDocument,
    variables: {
      invitationCode,
    },
  });

  appMutations.setInvitation(data.checkInvitation);
};

const startAuthStateListener = (
  createAccount: ReturnType<typeof useCreateAccountMutation>[0]
) =>
  auth.onAuthStateChanged(async (user) => {
    console.log("User state changed...", user);

    if (!user || !user.email) return appMutations.reset();

    const invitation = invitationVar();
    const invitationEmail = invitation?.user?.email;

    if (invitationEmail && invitationEmail !== user.email) {
      console.error(
        "User signed-in with different email than used for invitation",
        { user, invitation }
      );
      appMutations.reset();
      await auth.signOut();
      Modal.error({
        title: `Please sign-in with Your email ${invitationEmail}`,
      });
      return;
    }

    user.getIdToken().then((val) => {
      console.info("User logged in...", val, user);
    });

    let me: Me;

    try {
      await client
        .query<MeQuery>({
          fetchPolicy: "network-only",
          query: MeDocument,
        })
        .then(({ data, errors }) => {
          if (!errors?.length && data.me) {
            me = data.me;
          }
        })
        .catch((err) =>
          console.warn("User does not exists in database...", err)
        );

      if (!me) {
        // If the user is not found, we need to create an account
        const { data } = await createAccount({
          variables: {
            input: {
              name: user.displayName,
              user: {
                firebaseId: user.uid,
                email: user.email,
              },
            },
          },
        });

        me = data?.createAccount.users[0] as Me;
        console.log("User created...", me);
      }

      client.watchQuery({ query: MeDocument }).subscribe(({ data }) => {
        appMutations.onAuthChange({
          user: data.me,
          firebaseUser: user,
          isLoading: false,
        });
      });

      appMutations.onAuthChange({
        user: me,
        firebaseUser: user,
        isLoading: false,
      });

      appMutations.setSelectedRole(
        USER_ROLES.find((role) => me?.roles.includes(role.id)) || null
      );

      appMutations.setInvitation(null);
    } catch (e) {
      return appMutations.reset();
    }
  });

export const Bootstrap: React.FC = ({ children }) => {
  const { isLoading, user } = useApplicationStore();
  const { xs } = useBreakpoint();
  const query = useQuery();
  const [loaded, setLoaded] = useState(false);
  const complete = () => setLoaded(true);
  const {
    boot: bootIntercom,
    update: updateIntercom,
    shutdown,
  } = useIntercom();

  const [createAccount] = useCreateAccountMutation();

  const invitationCode = query.get("invitationCode") || "";

  useEffect(() => {
    const unsubscribe = startAuthStateListener(createAccount);
    getInvitationInfo(invitationCode).finally(complete);

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rebootIntercom = useCallback(
    (user?: IntercomProps) => {
      shutdown();
      bootIntercom(user);
    },
    [bootIntercom, shutdown]
  );

  useEffect(() => {
    if (user) {
      rebootIntercom({
        name: user.profile?.fullName,
        email: user.email,
        createdAt: (new Date(user.createdAt).getTime() / 1000).toString(),
        userId: user.id,
        customAttributes: {
          primary_admin: user.roles.some(
            (role) => role === UserRole.PrimaryAdmin
          )
            ? "yes"
            : "no",
          admin: user.roles.some((role) => role === UserRole.Admin)
            ? "yes"
            : "no",
          team_manager: user.roles.some((role) => role === UserRole.TeamManager)
            ? "yes"
            : "no",
          framework_manager: user.additionalPermissions.some(
            (permission) =>
              permission === UserAdditionalPermissions.FrameworkManager
          )
            ? "yes"
            : "no",
          report_viewer: user.additionalPermissions.some(
            (permission) =>
              permission === UserAdditionalPermissions.ReportViewer
          )
            ? "yes"
            : "no",
        },
      });
    } else {
      rebootIntercom();
    }
  }, [user, rebootIntercom]);

  if (!loaded || isLoading) return React.createElement(getLoaderType(!!xs));
  return children as React.ReactElement;
};
