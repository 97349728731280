import styled from "styled-components";
import { Typography, Card } from "antd";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";

const { Text, Title } = Typography;

export const UICard = styled(Card)`
  width: 320px;
  background-color: #fbfbff;
`;

export const UITitle = styled(Title)`
  padding: 0 0 1rem 0;
`;

export const UIRightOutlined = styled(RightOutlined)`
  font-size: 6px;
  vertical-align: middle;
  margin-right: 10px;
`;

export const UIText = styled(Text)<{ $active: boolean }>`
  color: ${({ $active, theme }) =>
    $active ? theme.colors.blue_100 : theme.colors.grey_70} !important;
`;

export const ActionWrap = styled.div`
  margin: 0.5rem 0;
  cursor: pointer;
`;

export const DeleteWrap = styled.div`
  cursor: pointer;
  margin-top: 1.5rem;
`;

export const DeleteText = styled.span`
  position: relative;
  top: 0.2rem;
  color: ${({ theme }) => theme.colors.red_40};
`;
