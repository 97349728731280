import { VFC, memo, useRef } from "react";
import * as S from "./styles";
import { Legend, LegendItem } from "../Legend";
import { labelColors, pieChartPlugins } from "./plugins";
import { ChartProps, Doughnut } from "react-chartjs-2";
import { ChartData } from "chart.js";
import { NoData } from "../NoData";
import { Popover, Spin } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { toPng, toJpeg } from "html-to-image";

type IDoughnut = ChartProps<"doughnut">;
type Props = {
  // stronger typing without any is not working here
  data: ChartData<"doughnut"> | any;
  options: IDoughnut["options"] | any;
  numOfCols: 1 | 2 | 3;
  total: number;
  loading?: boolean;
  diameterInPx?: number;
  isOnDashboard?: boolean;
};
const Chart: VFC<Props> = ({
  data,
  options,
  numOfCols,
  total,
  loading,
  diameterInPx,
  isOnDashboard,
}) => {
  const myRef = useRef<any>();
  const legendItems: LegendItem[] = (data?.labels || []).map(
    (label: string, idx: number, labels: string[]) => {
      return {
        label: label || "",
        bgColor: labelColors(labels?.length)[idx],
        count: !!data?.datasets[0].data ? data?.datasets[0]?.data[idx] : 0,
        placeholder: data?.placeholders?.[idx],
      };
    }
  );

  const answersIsEmpty = (arr: number[]) => arr?.every((v) => v === 0);

  const isData = !answersIsEmpty(data?.datasets[0]?.data);

  if (loading) return <Spin />;

  const downloadGraphImage = (imagetype: any) => {
    if (imagetype === "png") {
      toPng(myRef.current, { backgroundColor: "#FFF" })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "Doughnut.png";
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (imagetype === "jpeg") {
      toJpeg(myRef.current, { backgroundColor: "#FFF" })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "Doughnut.jpeg";
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const content = (
    <div>
      <S.Cursor onClick={() => downloadGraphImage("jpeg")}>
        Download JPEG
      </S.Cursor>
      <S.Cursor onClick={() => downloadGraphImage("png")}>
        Download PNG
      </S.Cursor>
    </div>
  );

  return (
    <S.Wrap ref={myRef} isData={isData} isOnDashboard={isOnDashboard}>
      {!isData ? (
        <NoData />
      ) : (
        <div>
          <S.position>
            <Popover
              placement="bottom"
              content={content}
              overlayInnerStyle={{ maxWidth: "fit-content" }}
            >
              <MenuOutlined />
            </Popover>
          </S.position>
          <S.Doughnut
            data={data}
            options={options}
            plugins={pieChartPlugins(total)}
            diameterInPx={diameterInPx}
            redraw
          />
          <Legend items={legendItems} numOfCols={numOfCols} />
        </div>
      )}
    </S.Wrap>
  );
};
export const DoughnutChart = memo(Chart);
