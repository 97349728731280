import { FC } from "react";
import { useHistory } from "react-router";
import { Space } from "antd";
import Text from "antd/es/typography/Text";
import { Routes } from "router/routes";

import { ButtonTertiary, Icon } from "components";
import { DimensionsTable } from "./DimensionsTable";
import ContentLayout from "layout/ContentLayout";
import { ContentType } from "layout/ContentLayout/ContentLayout";

const DimensionCollection: FC = () => {
  const history = useHistory();

  return (
    <ContentLayout
      header={{
        title: "Dimensions Collection",
        actions: (
          <ButtonTertiary
            icon={<Icon name="plus" fontSize="20px" />}
            onClick={() => history.push(Routes.FRAMEWORKS_NEW_DIMENSION.path)}
          >
            <Text strong>New Dimension</Text>
          </ButtonTertiary>
        ),
      }}
      typeContent={ContentType.WithSidebar}
      sideContent={
        <Space direction="vertical" size={12} style={{ marginTop: "80px" }}>
          {/*<Text strong>Show</Text>
          <Space direction="vertical" size={8}>
            {showDataMock.map((showItem) => (
              <Checkbox
                key={showItem}
                value={showItem}
                onChange={handleShow}
                checked={visibleColumns.some((value) => value === showItem)}
                disabled={!workingFilters.some((value) => value === showItem)}
              >
                {showItem}
              </Checkbox>
            ))}
          </Space>*/}
        </Space>
      }
    >
      <DimensionsTable />
    </ContentLayout>
  );
};

export default DimensionCollection;
