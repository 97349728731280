import { IconName } from "components";
import { generatePath } from "react-router";
import { Routes } from "router/routes";

export type MemberProfileMenuItems = {
  itemId: number;
  title: string;
  activeIcon: IconName;
  inactiveIcon: IconName;
  path: (id: string) => string;
}[];

export const memberMenuItems: MemberProfileMenuItems = [
  {
    itemId: 0,
    title: "Member Profile",
    activeIcon: "profileBlue",
    inactiveIcon: "profileGrey",
    path: (id: string) => generatePath(Routes.TEAM_MEMBER_PROFILE.path, { id }),
  },
  {
    itemId: 1,
    title: "Work Designs",
    activeIcon: "eclipseBlue",
    inactiveIcon: "eclipseGrey",
    path: (id: string) =>
      generatePath(Routes.TEAM_MEMBER_WORK_DESIGNS.path, { id }),
  },
];

export const meMenuItems: MemberProfileMenuItems = [
  {
    itemId: 0,
    title: "My Profile",
    activeIcon: "profileBlue",
    inactiveIcon: "profileGrey",
    path: (id: string) => generatePath(Routes.MY_PROFILE.path, { id }),
  },
  {
    itemId: 1,
    title: "Account & Password",
    activeIcon: "accountDark",
    inactiveIcon: "accountLight",
    path: (id: string) => generatePath(Routes.MY_PROFILE_ACCOUNT.path, { id }),
  },
];
