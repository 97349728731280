import { Button, Col, Form, Row, Modal } from "antd";
import Title from "antd/es/typography/Title";
import { MaterialInput } from "../MaterialInput";
import Link from "antd/es/typography/Link";
import theme from "../../theme";
import { Routes } from "../../router/routes";
import Text from "antd/es/typography/Text";
import React from "react";
import { useHistory } from "react-router-dom";
import { useSendPasswordResetLinkMutation } from "codegen/generated/graphql";

enum FORM_FIELD_NAME {
  EMAIL = "email",
}

export const ForgottenPasswordForm: React.FC = () => {
  const [form] = Form.useForm();
  const [sendPasswordResetLink] = useSendPasswordResetLinkMutation();
  const history = useHistory();

  return (
    <Form
      name={"forgottenPasswordForm"}
      form={form}
      autoComplete={"off"}
      onFinish={() => {
        const email = form.getFieldValue(FORM_FIELD_NAME.EMAIL) as string;
        sendPasswordResetLink({ variables: { input: { email } } })
          .then(() =>
            Modal.success({
              title: "Email has been sent",
              onOk: () => history.replace("login"),
            })
          )
          .catch(() =>
            Modal.error({
              title: "Sending email failed",
              onOk: () => history.replace("login"),
            })
          );
      }}
    >
      <Row justify="start" gutter={[8, 24]}>
        <Col span={17}>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Title level={1} style={{ marginBottom: 0 }}>
                Forgot
              </Title>
              <Title level={1} style={{ margin: 0 }}>
                your Password?
              </Title>
            </Col>
            <Col span={24}>
              <Row justify="start">
                <Col>
                  <Text>
                    No Problem! Enter your e-mail address and we’ll send you a
                    link to reset your password
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
              name={FORM_FIELD_NAME.EMAIL}
            >
              <MaterialInput placeholder={"Email Address"} />
            </Form.Item>
          </Col>
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Row justify="center">
                <Col>
                  <Button type="primary" htmlType="submit">
                    Reset Password
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="center">
                <Col>
                  <Text>
                    {"Don’t have an account? "}
                    <Link
                      color={theme.colors.blue_70}
                      underline={true}
                      onClick={() => {
                        history.push(Routes.SIGN_UP.path);
                      }}
                    >
                      Sign Up
                    </Link>
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
