import { VFC } from "react";
import { Bar, ChartProps } from "react-chartjs-2";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(ChartDataLabels);
// Chart.defaults.global.plugins.datalabels.display = false;
// Chart.defaults.plugins.datalabels?.display? = false;

type IBar = ChartProps<"bar">;

type Props = {
  data: IBar["data"];
  options?: IBar["options"];
};
export const BarChart: VFC<Props> = ({ data, options }) => {
  return <Bar data={data} options={options} />;
};
