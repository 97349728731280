import styled from "styled-components";
import TabsBar from "components/TabsBar";

export const Spacer = styled.div`
  width: 1rem;
  height: 1rem;
`;

export const Tab = styled(TabsBar)`
  position: relative;
  top: -25px;
  flex-grow: 1;
  & > div > div > div > div > div.ant-tabs-tab-btn {
    font-size: 0.7em;
  }
`;
