import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  Json: any;
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['ID'];
  integrationCollaboration?: Maybe<Scalars['Boolean']>;
  integrationHRIS?: Maybe<Scalars['Boolean']>;
  isStripeCustomer: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  organizations: Array<Organization>;
  subscriptions: Array<Subscription>;
  users: Array<User>;
};

export type AccountInput = {
  name?: InputMaybe<Scalars['String']>;
  referer?: InputMaybe<Scalars['String']>;
  user: UserInput;
};

export type AccountIntegration = {
  __typename?: 'AccountIntegration';
  accessToken: Scalars['String'];
  accountId?: Maybe<Scalars['String']>;
  atsName: Scalars['String'];
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  integrationId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updatedBy: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
};

export type AssignWorkDesignInput = {
  frameworkIds: Array<Scalars['ID']>;
  userIds: Array<Scalars['ID']>;
};

export type Choice = {
  __typename?: 'Choice';
  createdAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  feature: Feature;
  id: Scalars['ID'];
  impactIds: Array<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  placeholder?: Maybe<Placeholder>;
  placeholderText?: Maybe<Scalars['String']>;
  placeholderValue?: Maybe<Scalars['Json']>;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ChoiceCreateInput = {
  impactIds?: InputMaybe<Array<Scalars['String']>>;
  order: Scalars['Int'];
  placeholderText?: InputMaybe<Scalars['String']>;
  placeholderValue?: InputMaybe<Scalars['Json']>;
  text: Scalars['String'];
};

export type ChoiceReport = {
  __typename?: 'ChoiceReport';
  count: Scalars['Int'];
  impacts: Array<Impact>;
  placeholderText?: Maybe<Scalars['String']>;
  placeholderValue?: Maybe<Scalars['String']>;
  responders: Array<ResponderReport>;
  text: Scalars['String'];
};

export type ChoiceUpdateInput = {
  id?: InputMaybe<Scalars['String']>;
  impactIds?: InputMaybe<Array<Scalars['String']>>;
  order: Scalars['Int'];
  placeholderText?: InputMaybe<Scalars['String']>;
  placeholderValue?: InputMaybe<Scalars['Json']>;
  text: Scalars['String'];
};

export type CreateAccountIntegrationInput = {
  accessToken: Scalars['String'];
  accountId: Scalars['String'];
  atsName: Scalars['String'];
  createdBy?: InputMaybe<Scalars['String']>;
  integrationId: Scalars['String'];
  integrationProviderDetails?: InputMaybe<Scalars['Json']>;
  manual?: InputMaybe<Scalars['Boolean']>;
  status: Scalars['String'];
  updatedBy?: InputMaybe<Scalars['String']>;
};

export type CreatePlainUserInput = {
  accountId: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  roles: Array<UserRole>;
  surname: Scalars['String'];
};

export type CreateSubscriptionInput = {
  /** Stripe Payment Method ID returned by successful SetupPaymentIntent */
  paymentMethodId: Scalars['String'];
  /** ID of selected planPrice item */
  planPriceId: Scalars['String'];
  /** Number of seats */
  quantity: Scalars['Int'];
};

export type CreateUserInput = {
  additionalPermissions: Array<UserAdditionalPermissions>;
  annualSalary?: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  employeeId?: InputMaybe<Scalars['String']>;
  employmentType?: InputMaybe<Scalars['String']>;
  filterScope?: InputMaybe<Scalars['Json']>;
  finchUserId?: InputMaybe<Scalars['String']>;
  function?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  hireDate?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  managerDetails?: InputMaybe<Scalars['String']>;
  managerId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  office?: InputMaybe<Scalars['String']>;
  paidTimeOff?: InputMaybe<Scalars['Int']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  raceOrEthnicity?: InputMaybe<Scalars['String']>;
  roles: Array<UserRole>;
  surname: Scalars['String'];
};

export type DataSet = {
  __typename?: 'DataSet';
  data: Array<Scalars['Int']>;
  featureType?: Maybe<FeatureType>;
  label: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type Dimension = DimensionI & {
  __typename?: 'Dimension';
  features: Array<Feature>;
  id: Scalars['ID'];
  meta?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
};

export type DimensionI = {
  features: Array<Feature>;
  id: Scalars['ID'];
  meta?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
};

export type DimensionInput = {
  backgroundColor: Scalars['String'];
  name: Scalars['String'];
  textColor: Scalars['String'];
};

export type DimensionUpdateInput = {
  backgroundColor: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  textColor: Scalars['String'];
};

export type EvaluatedChoicePlaceholder = {
  __typename?: 'EvaluatedChoicePlaceholder';
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type FwDimension = DimensionI & {
  __typename?: 'FWDimension';
  features: Array<Feature>;
  id: Scalars['ID'];
  meta?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
};

export type Feature = {
  __typename?: 'Feature';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  choices: Array<Choice>;
  dimension: Dimension;
  followUps: Array<Choice>;
  hasReport?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isFollowUp: Scalars['Boolean'];
  meta?: Maybe<Scalars['Json']>;
  reportKey?: Maybe<Scalars['String']>;
  templates: Array<Template>;
  text: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type: FeatureType;
};

export type FeatureCreateInput = {
  accountId?: InputMaybe<Scalars['String']>;
  choices?: InputMaybe<Array<ChoiceCreateInput>>;
  dimensionId: Scalars['String'];
  hasReport?: InputMaybe<Scalars['Boolean']>;
  reportKey?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  type: FeatureType;
};

export type FeatureReport = {
  __typename?: 'FeatureReport';
  choices: Array<ChoiceReport>;
  question: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export enum FeatureType {
  Date = 'DATE',
  MultiOption = 'MULTI_OPTION',
  Rate = 'RATE',
  SingleOption = 'SINGLE_OPTION',
  Text = 'TEXT'
}

export type FeatureUpdateInput = {
  accountId?: InputMaybe<Scalars['String']>;
  choices?: InputMaybe<Array<ChoiceUpdateInput>>;
  dimensionId: Scalars['String'];
  hasReport?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  reportKey?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  type: FeatureType;
};

export type FilterItemInput = {
  key: Scalars['String'];
  operator?: FilterOperatorType;
  value?: InputMaybe<Scalars['String']>;
  valueBoolean?: InputMaybe<Scalars['Boolean']>;
  valueDate?: InputMaybe<Scalars['DateTime']>;
  valueFloat?: InputMaybe<Scalars['Float']>;
  valueNumber?: InputMaybe<Scalars['Int']>;
};

export enum FilterOperatorType {
  Contains = 'CONTAINS',
  Equals = 'EQUALS',
  Gt = 'GT',
  Gte = 'GTE',
  Lt = 'LT',
  Lte = 'LTE'
}

export type FinchIntegrationInput = {
  accountId: Scalars['String'];
  accountIntegrationId: Scalars['String'];
  atsName: Scalars['String'];
  authCode: Scalars['String'];
  integrationId: Scalars['String'];
};

export type FinchIntegrationResponse = {
  __typename?: 'FinchIntegrationResponse';
  data?: Maybe<Scalars['Json']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

export type FinchSendEmailInput = {
  accountId: Scalars['String'];
};

export type Framework = {
  __typename?: 'Framework';
  account?: Maybe<Account>;
  deleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  dimensions: Array<FwDimension>;
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  managerReminderDate?: Maybe<Scalars['DateTime']>;
  memberReminderDate?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['Int']>;
  organization?: Maybe<Organization>;
  participants?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  startDate: Scalars['DateTime'];
  status: FrameworkStatus;
  title: Scalars['String'];
  workDesigns: Array<WorkDesign>;
};

export type FrameworkDimension = {
  __typename?: 'FrameworkDimension';
  name: Scalars['String'];
  segments: Array<FrameworkSegment>;
};

export type FrameworkDimensionFeatureChoiceSnapshotType = {
  choiceId: Scalars['ID'];
  order?: InputMaybe<Scalars['Int']>;
  placeholderText?: InputMaybe<Scalars['String']>;
  placeholderValue?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type FrameworkDimensionFeatureSnapshotType = {
  choices: Array<FrameworkDimensionFeatureChoiceSnapshotType>;
  featureId: Scalars['ID'];
};

export type FrameworkDimensionSnapshotType = {
  dimensionId: Scalars['ID'];
  features: Array<FrameworkDimensionFeatureSnapshotType>;
};

export type FrameworkImpact = {
  __typename?: 'FrameworkImpact';
  impacts: Array<Impact>;
  key: Scalars['String'];
  sum: Scalars['Float'];
};

export type FrameworkInputType = {
  description: Scalars['String'];
  dimensions: Array<FrameworkDimensionSnapshotType>;
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  title: Scalars['String'];
};

export type FrameworkReport = {
  __typename?: 'FrameworkReport';
  features: Array<FeatureReport>;
  users: Array<ResponderEvaluatedReport>;
};

export type FrameworkSegment = {
  __typename?: 'FrameworkSegment';
  datasets: Array<DataSet>;
  labels: Array<Scalars['String']>;
  placeholders?: Maybe<Array<EvaluatedChoicePlaceholder>>;
  responders?: Maybe<Array<Array<Profile>>>;
  type: Scalars['String'];
};

export enum FrameworkStatus {
  Active = 'ACTIVE',
  Created = 'CREATED',
  Ended = 'ENDED',
  Shared = 'SHARED'
}

export type Impact = {
  __typename?: 'Impact';
  customFormula?: Maybe<Scalars['String']>;
  formula: Scalars['String'];
  frequency?: Maybe<ImpactFrequency>;
  id: Scalars['ID'];
  isCostRelated?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  operation?: Maybe<ImpactOperation>;
  target?: Maybe<ImpactTarget>;
  title?: Maybe<Scalars['String']>;
};

export enum ImpactFrequency {
  Annualy = 'ANNUALY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export enum ImpactOperation {
  Decrease = 'DECREASE',
  Divide = 'DIVIDE',
  Increase = 'INCREASE',
  Multiply = 'MULTIPLY'
}

export enum ImpactTarget {
  BaseSalary = 'BASE_SALARY',
  Bonus = 'BONUS',
  PaidTimeOff = 'PAID_TIME_OFF',
  TaxableIncome = 'TAXABLE_INCOME'
}

export type Integration = {
  __typename?: 'Integration';
  accountIntegrations: Array<AccountIntegration>;
  createdBy?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: Scalars['String'];
  updatedBy?: Maybe<Scalars['String']>;
};

export type IntegrationCreateInput = {
  createdBy: Scalars['String'];
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  status: Scalars['String'];
};

export type Invitation = {
  __typename?: 'Invitation';
  code: Scalars['String'];
  expirationDate?: Maybe<Scalars['DateTime']>;
  status: InvitationStatus;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export enum InvitationStatus {
  Active = 'Active',
  Expired = 'Expired',
  Invalid = 'Invalid',
  Used = 'Used'
}

export enum Mindset {
  Balancer = 'Balancer',
  Driver = 'Driver',
  Juggler = 'Juggler',
  Saver = 'Saver',
  Traveler = 'Traveler'
}

export type Mutation = {
  __typename?: 'Mutation';
  assignWorkDesign: Array<Framework>;
  createAccount: Account;
  createAccountIntegration: AccountIntegration;
  createDimension: Dimension;
  createFeature: Feature;
  createFramework?: Maybe<Framework>;
  createOrganization: Organization;
  createPlainAccount: Account;
  createSubscription: Subscription;
  createTeam: Team;
  createTeamMember: User;
  createUser: User;
  deleteFramework?: Maybe<Framework>;
  removeDimension: Dimension;
  removeFeature: Feature;
  sendPasswordResetLink: Scalars['Boolean'];
  stripeCreateCheckoutSession?: Maybe<Scalars['String']>;
  stripeCreateCustomerPortalSession: Scalars['String'];
  stripeCreatePaymentIntent: StripePaymentIntent;
  stripeSetupPaymentIntent: StripePaymentIntent;
  submitAuthCode: FinchIntegrationResponse;
  submitWorkDesign: WorkDesign;
  toggleUsers: Scalars['Boolean'];
  updateAccountIntegration: AccountIntegration;
  updateCurrentUserPoll: User;
  updateDimension: Dimension;
  updateFeature: Feature;
  updateFramework?: Maybe<Framework>;
  updateLastLoggedIn: User;
  updateLlunaAccount: Account;
  updateMindsets: User;
  updateProfile: Profile;
  updateProfileAvatar: Profile;
  updateTeam: Team;
  updateUserMetaData: User;
};


export type MutationAssignWorkDesignArgs = {
  input: AssignWorkDesignInput;
};


export type MutationCreateAccountArgs = {
  input: AccountInput;
};


export type MutationCreateAccountIntegrationArgs = {
  input: CreateAccountIntegrationInput;
};


export type MutationCreateDimensionArgs = {
  data: DimensionInput;
};


export type MutationCreateFeatureArgs = {
  data: FeatureCreateInput;
};


export type MutationCreateFrameworkArgs = {
  input: FrameworkInputType;
};


export type MutationCreateOrganizationArgs = {
  data: OrganizationInput;
};


export type MutationCreatePlainAccountArgs = {
  input: PlainAccountInput;
};


export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};


export type MutationCreateTeamArgs = {
  data: TeamCreateInput;
};


export type MutationCreateTeamMemberArgs = {
  input: CreateUserInput;
};


export type MutationCreateUserArgs = {
  input: CreatePlainUserInput;
};


export type MutationDeleteFrameworkArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveDimensionArgs = {
  id: Scalars['String'];
};


export type MutationRemoveFeatureArgs = {
  id: Scalars['String'];
};


export type MutationSendPasswordResetLinkArgs = {
  input: UserEmailInput;
};


export type MutationSubmitAuthCodeArgs = {
  input: FinchIntegrationInput;
};


export type MutationSubmitWorkDesignArgs = {
  responses: Scalars['Json'];
  workDesignId: Scalars['ID'];
};


export type MutationToggleUsersArgs = {
  isEnabled: Scalars['Boolean'];
  userIds: Array<Scalars['ID']>;
};


export type MutationUpdateAccountIntegrationArgs = {
  input: UpdateAccountIntegrationInput;
};


export type MutationUpdateCurrentUserPollArgs = {
  pollResult?: InputMaybe<Scalars['Json']>;
};


export type MutationUpdateDimensionArgs = {
  data: DimensionUpdateInput;
};


export type MutationUpdateFeatureArgs = {
  data: FeatureUpdateInput;
};


export type MutationUpdateFrameworkArgs = {
  id: Scalars['ID'];
  input: FrameworkInputType;
};


export type MutationUpdateLlunaAccountArgs = {
  input: UpdateAccountInput;
};


export type MutationUpdateMindsetsArgs = {
  primary?: InputMaybe<Mindset>;
  secondary?: InputMaybe<Mindset>;
};


export type MutationUpdateProfileArgs = {
  input: ProfileUpdateInput;
};


export type MutationUpdateProfileAvatarArgs = {
  src?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateTeamArgs = {
  input: TeamUpdateInput;
};


export type MutationUpdateUserMetaDataArgs = {
  input: UserMetaDataInput;
};

export type OrderByItemInput = {
  key: Scalars['String'];
  order: OrderType;
};

export enum OrderType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  teams: Array<Team>;
};

export type OrganizationInput = {
  name?: InputMaybe<Scalars['String']>;
};

export enum Placeholder {
  Days = 'DAYS',
  Hours = 'HOURS',
  Months = 'MONTHS',
  Percentage = 'PERCENTAGE',
  Usd = 'USD',
  Weeks = 'WEEKS',
  Years = 'YEARS'
}

export type PlainAccountInput = {
  companyName: Scalars['String'];
  integrationCollaboration?: InputMaybe<Scalars['Boolean']>;
  integrationHRIS?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  teamName: Scalars['String'];
  useStripe: Scalars['Boolean'];
};

export type Plan = {
  __typename?: 'Plan';
  id: Scalars['ID'];
  meta?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  prices: Array<PlanPrice>;
};

export type PlanPrice = {
  __typename?: 'PlanPrice';
  id: Scalars['ID'];
  pricePerUnit: Scalars['Float'];
};

export enum PlanType {
  Enterprise = 'ENTERPRISE',
  Team = 'TEAM'
}

export type Profile = {
  __typename?: 'Profile';
  additionalPermissions?: Maybe<UserAdditionalPermissions>;
  addresses: Array<Address>;
  annualSalary?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  filterScope?: Maybe<Scalars['Json']>;
  fullName: Scalars['String'];
  function?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  hireDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  jobTitle?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['String']>;
  mindset?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  office?: Maybe<Scalars['String']>;
  paidTimeOff?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  raceOrEthnicity?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<UserRole>>;
  surname?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
};

export type ProfileUpdateInput = {
  additionalPermissions?: InputMaybe<Array<InputMaybe<UserAdditionalPermissions>>>;
  annualSalary?: InputMaybe<Scalars['Float']>;
  email: Scalars['String'];
  employmentType?: InputMaybe<Scalars['String']>;
  filterScope?: InputMaybe<Scalars['Json']>;
  function?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  hireDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  jobTitle?: InputMaybe<Scalars['String']>;
  managerId?: InputMaybe<Scalars['String']>;
  mindset?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  office?: InputMaybe<Scalars['String']>;
  paidTimeOff?: InputMaybe<Scalars['Int']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  raceOrEthnicity?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<InputMaybe<UserRole>>>;
  surname?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  accountIntegration: Array<AccountIntegration>;
  accountUser?: Maybe<User>;
  accountUsers: Users;
  accounts: Array<Account>;
  aggregateFeaturesData: FrameworkReport;
  aggregateFrameworkData: Array<FrameworkDimension>;
  aggregateFrameworkImpacts: Array<FrameworkImpact>;
  aggregateFrameworkStatus: FrameworkSegment;
  aggregateFrameworkWorkStyle: Array<FrameworkSegment>;
  calculateImpacts: Scalars['Json'];
  checkInvitation: Invitation;
  currentAccountUsers: Users;
  dimension?: Maybe<Dimension>;
  dimensions: Array<Dimension>;
  feature?: Maybe<Feature>;
  features: Array<Feature>;
  framework?: Maybe<Framework>;
  frameworks: Array<Framework>;
  impacts: Array<Impact>;
  integrations: Array<Integration>;
  inviteUsers: Users;
  me?: Maybe<User>;
  organization?: Maybe<Organization>;
  organizations: Array<Organization>;
  profile?: Maybe<Profile>;
  profiles: Array<Profile>;
  reportingManagers: Array<ReportingManager>;
  subscription?: Maybe<Subscription>;
  subscriptions: Array<Subscription>;
  team?: Maybe<Team>;
  teamProfilesFields: Array<UniqueProfileFields>;
  teams: Array<Team>;
  userScopes: UserScope;
  userWorkDesigns: Array<WorkDesign>;
  workDesign?: Maybe<WorkDesign>;
  workDesigns: Array<WorkDesign>;
};


export type QueryAccountUserArgs = {
  id: Scalars['String'];
};


export type QueryAccountUsersArgs = {
  accountId: Scalars['String'];
  input: TableDataInput;
};


export type QueryAccountsArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type QueryAggregateFeaturesDataArgs = {
  frameworkId: Scalars['String'];
  keys: Array<Scalars['String']>;
  userScope?: InputMaybe<Scalars['Json']>;
};


export type QueryAggregateFrameworkDataArgs = {
  frameworkId: Scalars['String'];
  userScope?: InputMaybe<Scalars['Json']>;
};


export type QueryAggregateFrameworkImpactsArgs = {
  frameworkId: Scalars['String'];
};


export type QueryAggregateFrameworkStatusArgs = {
  frameworkId: Scalars['String'];
  userScope?: InputMaybe<Scalars['Json']>;
};


export type QueryAggregateFrameworkWorkStyleArgs = {
  frameworkId?: InputMaybe<Scalars['String']>;
  userScope?: InputMaybe<Scalars['Json']>;
};


export type QueryCalculateImpactsArgs = {
  responses: Scalars['Json'];
  workDesignId: Scalars['ID'];
};


export type QueryCheckInvitationArgs = {
  invitationCode: Scalars['String'];
};


export type QueryCurrentAccountUsersArgs = {
  input: TableDataInput;
};


export type QueryDimensionArgs = {
  id: Scalars['ID'];
};


export type QueryFeatureArgs = {
  id: Scalars['ID'];
};


export type QueryFeaturesArgs = {
  input: TableDataInput;
};


export type QueryFrameworkArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryFrameworksArgs = {
  filter?: InputMaybe<Array<FilterItemInput>>;
  search?: InputMaybe<Scalars['String']>;
  sharedOnly?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Array<OrderByItemInput>>;
};


export type QueryInviteUsersArgs = {
  input: TableDataInput;
};


export type QueryOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryProfileArgs = {
  id: Scalars['ID'];
};


export type QuerySubscriptionArgs = {
  id: Scalars['ID'];
};


export type QueryTeamArgs = {
  id: Scalars['ID'];
};


export type QueryTeamProfilesFieldsArgs = {
  keys: Array<Scalars['String']>;
};


export type QueryUserWorkDesignsArgs = {
  teamMemberId: Scalars['String'];
};


export type QueryWorkDesignArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryWorkDesignsArgs = {
  frameworkId?: InputMaybe<Scalars['ID']>;
};

export type ReportingManager = {
  __typename?: 'ReportingManager';
  accountId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ResponderEvaluatedReport = {
  __typename?: 'ResponderEvaluatedReport';
  evaluatedImpacts: Array<UserImpactReport>;
  userId: Scalars['String'];
};

export type ResponderReport = {
  __typename?: 'ResponderReport';
  profile?: Maybe<Profile>;
  userId: Scalars['String'];
  userImpacts: Array<UserImpactReport>;
};

export type StripePaymentIntent = {
  __typename?: 'StripePaymentIntent';
  clientSecret?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  accountId: Scalars['String'];
  active: Scalars['Boolean'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  id: Scalars['ID'];
  isTrial: Scalars['Boolean'];
  plan?: Maybe<PlanPrice>;
  seats: Scalars['Int'];
  seatsTaken: Scalars['Int'];
};

export type TableDataInput = {
  cursor?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<FilterItemInput>>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<OrderByItemInput>>;
  take?: InputMaybe<Scalars['Int']>;
  userScope?: InputMaybe<Scalars['Json']>;
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['ID'];
  meta?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']>;
  users: Array<User>;
};

export type TeamCreateInput = {
  name?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
};

export type TeamUpdateInput = {
  meta?: InputMaybe<Scalars['Json']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Teams = {
  __typename?: 'Teams';
  id: Scalars['String'];
  name: Scalars['String'];
  organizationId: Scalars['String'];
};

export type Template = {
  __typename?: 'Template';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  features: Array<Feature>;
  id: Scalars['ID'];
  name: Scalars['String'];
  onlyOrgs: Scalars['Boolean'];
  teamTypology?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type UniqueProfileFields = {
  __typename?: 'UniqueProfileFields';
  key: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type UpdateAccountInput = {
  accountId: Scalars['String'];
  companyName?: InputMaybe<Scalars['String']>;
  integrationCollaboration?: InputMaybe<Scalars['Boolean']>;
  integrationHRIS?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  teamName?: InputMaybe<Scalars['String']>;
  useStripe?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateAccountIntegrationInput = {
  accessToken: Scalars['String'];
  accountId: Scalars['String'];
  atsName: Scalars['String'];
  id: Scalars['String'];
  integrationId: Scalars['String'];
  integrationProviderDetails?: InputMaybe<Scalars['Json']>;
  manual?: InputMaybe<Scalars['Boolean']>;
  status: Scalars['String'];
  updatedBy?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  account?: Maybe<Account>;
  additionalPermissions: Array<UserAdditionalPermissions>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  filterScope?: Maybe<Scalars['Json']>;
  id: Scalars['ID'];
  isEnabled: Scalars['Boolean'];
  isOnboardingPollComplete: Scalars['Boolean'];
  isVerified: Scalars['Boolean'];
  lastLoginAt?: Maybe<Scalars['DateTime']>;
  managerId?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['Json']>;
  myLatestWorkDesign?: Maybe<WorkDesign>;
  profile?: Maybe<Profile>;
  roles: Array<UserRole>;
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars['String']>;
  workDesigns: Array<WorkDesign>;
};

export enum UserAdditionalPermissions {
  FrameworkManager = 'FRAMEWORK_MANAGER',
  ReportViewer = 'REPORT_VIEWER'
}

export type UserEmailInput = {
  email: Scalars['String'];
};

export type UserImpactReport = {
  __typename?: 'UserImpactReport';
  id: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['Float'];
};

export type UserInput = {
  email: Scalars['String'];
  firebaseId: Scalars['String'];
};

export type UserMetaDataInput = {
  field: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Json']>;
};

export enum UserRole {
  Admin = 'ADMIN',
  LlunaAdmin = 'LLUNA_ADMIN',
  PrimaryAdmin = 'PRIMARY_ADMIN',
  TeamManager = 'TEAM_MANAGER',
  TeamMember = 'TEAM_MEMBER'
}

export type UserScope = {
  __typename?: 'UserScope';
  accountId: Scalars['String'];
  employmentTypes: Array<Scalars['String']>;
  functions: Array<Scalars['String']>;
  genders: Array<Scalars['String']>;
  jobTitles: Array<Scalars['String']>;
  offices: Array<Scalars['String']>;
  raceEthinicities: Array<Scalars['String']>;
};

export type Users = {
  __typename?: 'Users';
  cursor?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  users: Array<User>;
};

export enum WdStatus {
  Active = 'ACTIVE',
  Ended = 'ENDED',
  Pending = 'PENDING',
  Ready = 'READY'
}

export type WorkDesign = {
  __typename?: 'WorkDesign';
  availableUntil: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  dimensions: Array<FwDimension>;
  framework: Framework;
  frameworkId: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']>;
  rawResponses?: Maybe<Scalars['Json']>;
  status: WdStatus;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type SendPasswordResetLinkMutationVariables = Exact<{
  input: UserEmailInput;
}>;


export type SendPasswordResetLinkMutation = { __typename?: 'Mutation', sendPasswordResetLink: boolean };

export type CreateAccountMutationVariables = Exact<{
  input: AccountInput;
}>;


export type CreateAccountMutation = { __typename?: 'Mutation', createAccount: { __typename?: 'Account', users: Array<{ __typename?: 'User', id: string, roles: Array<UserRole>, isOnboardingPollComplete: boolean, team?: { __typename?: 'Team', id: string, name?: string | null } | null }> } };

export type CreatePlainAccountMutationVariables = Exact<{
  input: PlainAccountInput;
}>;


export type CreatePlainAccountMutation = { __typename?: 'Mutation', createPlainAccount: { __typename?: 'Account', id: string, name?: string | null, organizations: Array<{ __typename?: 'Organization', name?: string | null, teams: Array<{ __typename?: 'Team', name?: string | null }> }> } };

export type CreateSubscriptionMutationVariables = Exact<{
  input: CreateSubscriptionInput;
}>;


export type CreateSubscriptionMutation = { __typename?: 'Mutation', createSubscription: { __typename?: 'Subscription', id: string, activeFrom: any, activeTo: any, isTrial: boolean, active: boolean, seats: number, seatsTaken: number, plan?: { __typename?: 'PlanPrice', id: string, pricePerUnit: number } | null } };

export type UpdateLlunaAccountMutationVariables = Exact<{
  input: UpdateAccountInput;
}>;


export type UpdateLlunaAccountMutation = { __typename?: 'Mutation', updateLlunaAccount: { __typename?: 'Account', id: string, integrationCollaboration?: boolean | null, integrationHRIS?: boolean | null } };

export type DimensionDataFragment = { __typename?: 'Dimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, accountId?: string | null, text: string, type: FeatureType, choices: Array<{ __typename?: 'Choice', id: string, text: string, order?: number | null, placeholderValue?: any | null, placeholderText?: string | null }>, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null } }> };

export type CreateDimensionMutationVariables = Exact<{
  input: DimensionInput;
}>;


export type CreateDimensionMutation = { __typename?: 'Mutation', createDimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, accountId?: string | null, text: string, type: FeatureType, choices: Array<{ __typename?: 'Choice', id: string, text: string, order?: number | null, placeholderValue?: any | null, placeholderText?: string | null }>, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null } }> } };

export type UpdateDimensionMutationVariables = Exact<{
  input: DimensionUpdateInput;
}>;


export type UpdateDimensionMutation = { __typename?: 'Mutation', updateDimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, accountId?: string | null, text: string, type: FeatureType, choices: Array<{ __typename?: 'Choice', id: string, text: string, order?: number | null, placeholderValue?: any | null, placeholderText?: string | null }>, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null } }> } };

export type RemoveDimensionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveDimensionMutation = { __typename?: 'Mutation', removeDimension: { __typename?: 'Dimension', id: string } };

export type DimensionsQueryVariables = Exact<{ [key: string]: never; }>;


export type DimensionsQuery = { __typename?: 'Query', dimensions: Array<{ __typename?: 'Dimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, accountId?: string | null, text: string, type: FeatureType, choices: Array<{ __typename?: 'Choice', id: string, text: string, order?: number | null, placeholderValue?: any | null, placeholderText?: string | null }>, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null } }> }> };

export type DimensionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DimensionQuery = { __typename?: 'Query', dimension?: { __typename?: 'Dimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, accountId?: string | null, text: string, type: FeatureType, choices: Array<{ __typename?: 'Choice', id: string, text: string, order?: number | null, placeholderValue?: any | null, placeholderText?: string | null }>, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null } }> } | null };

export type FeatureDataFragment = { __typename?: 'Feature', id: string, text: string, title?: string | null, type: FeatureType, accountId?: string | null, hasReport?: boolean | null, reportKey?: string | null, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, accountId?: string | null, text: string, type: FeatureType, choices: Array<{ __typename?: 'Choice', id: string, text: string, order?: number | null, placeholderValue?: any | null, placeholderText?: string | null }>, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null } }> }, choices: Array<{ __typename?: 'Choice', id: string, order?: number | null, text: string, placeholderValue?: any | null, placeholderText?: string | null, impactIds: Array<string> }> };

export type ChoiceDataFragment = { __typename?: 'Choice', id: string, order?: number | null, text: string, placeholderValue?: any | null, placeholderText?: string | null, impactIds: Array<string> };

export type CreateFeatureMutationVariables = Exact<{
  data: FeatureCreateInput;
}>;


export type CreateFeatureMutation = { __typename?: 'Mutation', createFeature: { __typename?: 'Feature', id: string, text: string, title?: string | null, type: FeatureType, accountId?: string | null, hasReport?: boolean | null, reportKey?: string | null, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, accountId?: string | null, text: string, type: FeatureType, choices: Array<{ __typename?: 'Choice', id: string, text: string, order?: number | null, placeholderValue?: any | null, placeholderText?: string | null }>, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null } }> }, choices: Array<{ __typename?: 'Choice', id: string, order?: number | null, text: string, placeholderValue?: any | null, placeholderText?: string | null, impactIds: Array<string> }> } };

export type RemoveFeatureMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveFeatureMutation = { __typename?: 'Mutation', removeFeature: { __typename?: 'Feature', id: string, text: string, type: FeatureType } };

export type UpdateFeatureMutationVariables = Exact<{
  data: FeatureUpdateInput;
}>;


export type UpdateFeatureMutation = { __typename?: 'Mutation', updateFeature: { __typename?: 'Feature', id: string, text: string, title?: string | null, type: FeatureType, accountId?: string | null, hasReport?: boolean | null, reportKey?: string | null, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, accountId?: string | null, text: string, type: FeatureType, choices: Array<{ __typename?: 'Choice', id: string, text: string, order?: number | null, placeholderValue?: any | null, placeholderText?: string | null }>, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null } }> }, choices: Array<{ __typename?: 'Choice', id: string, order?: number | null, text: string, placeholderValue?: any | null, placeholderText?: string | null, impactIds: Array<string> }> } };

export type FeaturesQueryVariables = Exact<{
  input: TableDataInput;
}>;


export type FeaturesQuery = { __typename?: 'Query', features: Array<{ __typename?: 'Feature', id: string, text: string, title?: string | null, type: FeatureType, accountId?: string | null, hasReport?: boolean | null, reportKey?: string | null, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, accountId?: string | null, text: string, type: FeatureType, choices: Array<{ __typename?: 'Choice', id: string, text: string, order?: number | null, placeholderValue?: any | null, placeholderText?: string | null }>, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null } }> }, choices: Array<{ __typename?: 'Choice', id: string, order?: number | null, text: string, placeholderValue?: any | null, placeholderText?: string | null, impactIds: Array<string> }> }> };

export type FeatureQueryVariables = Exact<{
  featureId: Scalars['ID'];
}>;


export type FeatureQuery = { __typename?: 'Query', feature?: { __typename?: 'Feature', id: string, text: string, title?: string | null, type: FeatureType, accountId?: string | null, hasReport?: boolean | null, reportKey?: string | null, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, accountId?: string | null, text: string, type: FeatureType, choices: Array<{ __typename?: 'Choice', id: string, text: string, order?: number | null, placeholderValue?: any | null, placeholderText?: string | null }>, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null } }> }, choices: Array<{ __typename?: 'Choice', id: string, order?: number | null, text: string, placeholderValue?: any | null, placeholderText?: string | null, impactIds: Array<string> }> } | null };

export type ImpactsQueryVariables = Exact<{ [key: string]: never; }>;


export type ImpactsQuery = { __typename?: 'Query', impacts: Array<{ __typename?: 'Impact', id: string, title?: string | null, formula: string, key: string }> };

export type AccountsQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
}>;


export type AccountsQuery = { __typename?: 'Query', accounts: Array<{ __typename?: 'Account', id: string, name?: string | null, integrationCollaboration?: boolean | null, integrationHRIS?: boolean | null, isStripeCustomer: boolean, users: Array<{ __typename?: 'User', email: string, profile?: { __typename?: 'Profile', fullName: string } | null }> }> };

export type FrameworkCollectionDataFragment = { __typename?: 'Framework', id: string, title: string, description?: string | null, deleted?: boolean | null, participants?: number | null, startDate: any, endDate: any, published?: boolean | null, status: FrameworkStatus, dimensions: Array<{ __typename?: 'FWDimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, accountId?: string | null, text: string, type: FeatureType, choices: Array<{ __typename?: 'Choice', id: string, text: string, order?: number | null, placeholderValue?: any | null, placeholderText?: string | null }>, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null } }> }> };

export type FwDimensionDataFragment = { __typename?: 'FWDimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, accountId?: string | null, text: string, type: FeatureType, choices: Array<{ __typename?: 'Choice', id: string, text: string, order?: number | null, placeholderValue?: any | null, placeholderText?: string | null }>, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null } }> };

export type DeleteFrameworkMutationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteFrameworkMutationMutation = { __typename?: 'Mutation', deleteFramework?: { __typename?: 'Framework', id: string } | null };

export type CreateFrameworkMutationVariables = Exact<{
  input: FrameworkInputType;
}>;


export type CreateFrameworkMutation = { __typename?: 'Mutation', createFramework?: { __typename?: 'Framework', id: string, title: string, description?: string | null, deleted?: boolean | null, participants?: number | null, startDate: any, endDate: any, published?: boolean | null, status: FrameworkStatus, dimensions: Array<{ __typename?: 'FWDimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, accountId?: string | null, text: string, type: FeatureType, choices: Array<{ __typename?: 'Choice', id: string, text: string, order?: number | null, placeholderValue?: any | null, placeholderText?: string | null }>, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null } }> }> } | null };

export type UpdateFrameworkMutationVariables = Exact<{
  id: Scalars['ID'];
  input: FrameworkInputType;
}>;


export type UpdateFrameworkMutation = { __typename?: 'Mutation', updateFramework?: { __typename?: 'Framework', id: string, title: string, description?: string | null, deleted?: boolean | null, participants?: number | null, startDate: any, endDate: any, published?: boolean | null, status: FrameworkStatus, dimensions: Array<{ __typename?: 'FWDimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, accountId?: string | null, text: string, type: FeatureType, choices: Array<{ __typename?: 'Choice', id: string, text: string, order?: number | null, placeholderValue?: any | null, placeholderText?: string | null }>, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null } }> }> } | null };

export type AssignWorkDesignMutationVariables = Exact<{
  input: AssignWorkDesignInput;
}>;


export type AssignWorkDesignMutation = { __typename?: 'Mutation', assignWorkDesign: Array<{ __typename?: 'Framework', id: string, title: string, description?: string | null, deleted?: boolean | null, participants?: number | null, startDate: any, endDate: any, published?: boolean | null, status: FrameworkStatus, dimensions: Array<{ __typename?: 'FWDimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, accountId?: string | null, text: string, type: FeatureType, choices: Array<{ __typename?: 'Choice', id: string, text: string, order?: number | null, placeholderValue?: any | null, placeholderText?: string | null }>, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null } }> }> }> };

export type FrameworksCollectionQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<OrderByItemInput> | OrderByItemInput>;
  filter?: InputMaybe<Array<FilterItemInput> | FilterItemInput>;
  sharedOnly?: InputMaybe<Scalars['Boolean']>;
}>;


export type FrameworksCollectionQuery = { __typename?: 'Query', frameworks: Array<{ __typename?: 'Framework', id: string, title: string, description?: string | null, deleted?: boolean | null, participants?: number | null, startDate: any, endDate: any, published?: boolean | null, status: FrameworkStatus, dimensions: Array<{ __typename?: 'FWDimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, accountId?: string | null, text: string, type: FeatureType, choices: Array<{ __typename?: 'Choice', id: string, text: string, order?: number | null, placeholderValue?: any | null, placeholderText?: string | null }>, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null } }> }> }> };

export type FrameworkQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FrameworkQuery = { __typename?: 'Query', framework?: { __typename?: 'Framework', id: string, title: string, description?: string | null, deleted?: boolean | null, participants?: number | null, startDate: any, endDate: any, published?: boolean | null, status: FrameworkStatus, dimensions: Array<{ __typename?: 'FWDimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, accountId?: string | null, text: string, type: FeatureType, choices: Array<{ __typename?: 'Choice', id: string, text: string, order?: number | null, placeholderValue?: any | null, placeholderText?: string | null }>, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null } }> }> } | null };

export type SubmitAuthCodeMutationVariables = Exact<{
  input: FinchIntegrationInput;
}>;


export type SubmitAuthCodeMutation = { __typename?: 'Mutation', submitAuthCode: { __typename?: 'FinchIntegrationResponse', status: string, message: string } };

export type IntegrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type IntegrationsQuery = { __typename?: 'Query', integrations: Array<{ __typename?: 'Integration', id: string, name: string, status: string, accountIntegrations: Array<{ __typename?: 'AccountIntegration', id: string, accountId?: string | null, accessToken: string, status: string, atsName: string }> }> };

export type CheckInvitationQueryVariables = Exact<{
  invitationCode: Scalars['String'];
}>;


export type CheckInvitationQuery = { __typename?: 'Query', checkInvitation: { __typename?: 'Invitation', status: InvitationStatus, user?: { __typename?: 'User', email: string, profile?: { __typename?: 'Profile', name?: string | null, surname?: string | null } | null } | null } };

export type ProfileDataFragment = { __typename?: 'Profile', id: string, name?: string | null, surname?: string | null, avatar?: string | null, employmentType?: string | null, phoneNumber?: string | null, timeZone?: string | null, annualSalary?: number | null, function?: string | null, gender?: string | null, raceOrEthnicity?: string | null, hireDate?: any | null, jobTitle?: string | null, mindset?: string | null, office?: string | null, paidTimeOff?: number | null, managerId?: string | null, additionalPermissions?: UserAdditionalPermissions | null, filterScope?: any | null, addresses: Array<{ __typename?: 'Address', locality?: string | null, lat?: number | null, lng?: number | null }> };

export type ProfileUpdateMutationVariables = Exact<{
  input: ProfileUpdateInput;
}>;


export type ProfileUpdateMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'Profile', id: string, name?: string | null, surname?: string | null, avatar?: string | null, employmentType?: string | null, phoneNumber?: string | null, timeZone?: string | null, annualSalary?: number | null, function?: string | null, gender?: string | null, raceOrEthnicity?: string | null, hireDate?: any | null, jobTitle?: string | null, mindset?: string | null, office?: string | null, paidTimeOff?: number | null, managerId?: string | null, additionalPermissions?: UserAdditionalPermissions | null, filterScope?: any | null, addresses: Array<{ __typename?: 'Address', locality?: string | null, lat?: number | null, lng?: number | null }> } };

export type UpdateProfileAvatarMutationVariables = Exact<{
  src: Scalars['String'];
}>;


export type UpdateProfileAvatarMutation = { __typename?: 'Mutation', updateProfileAvatar: { __typename?: 'Profile', avatar?: string | null, id: string } };

export type AggregateFrameworkStatusQueryVariables = Exact<{
  frameworkId: Scalars['String'];
  userScope?: InputMaybe<Scalars['Json']>;
}>;


export type AggregateFrameworkStatusQuery = { __typename?: 'Query', aggregateFrameworkStatus: { __typename?: 'FrameworkSegment', type: string, labels: Array<string>, datasets: Array<{ __typename?: 'DataSet', label: string, data: Array<number>, title?: string | null }> } };

export type AggregateFrameworkDataQueryVariables = Exact<{
  frameworkId: Scalars['String'];
  userScope?: InputMaybe<Scalars['Json']>;
}>;


export type AggregateFrameworkDataQuery = { __typename?: 'Query', aggregateFrameworkData: Array<{ __typename?: 'FrameworkDimension', name: string, segments: Array<{ __typename?: 'FrameworkSegment', labels: Array<string>, type: string, responders?: Array<Array<{ __typename?: 'Profile', avatar?: string | null, fullName: string }>> | null, datasets: Array<{ __typename?: 'DataSet', label: string, data: Array<number>, title?: string | null, featureType?: FeatureType | null }>, placeholders?: Array<{ __typename?: 'EvaluatedChoicePlaceholder', type?: string | null, value?: string | null }> | null }> }> };

export type AggregateFrameworkImpactsQueryVariables = Exact<{
  frameworkId: Scalars['String'];
}>;


export type AggregateFrameworkImpactsQuery = { __typename?: 'Query', aggregateFrameworkImpacts: Array<{ __typename?: 'FrameworkImpact', key: string, sum: number, impacts: Array<{ __typename?: 'Impact', id: string, customFormula?: string | null, frequency?: ImpactFrequency | null, target?: ImpactTarget | null, title?: string | null, isCostRelated?: boolean | null, operation?: ImpactOperation | null, formula: string, key: string }> }> };

export type AggregateFrameworkWorkStyleQueryVariables = Exact<{
  frameworkId?: InputMaybe<Scalars['String']>;
  userScope?: InputMaybe<Scalars['Json']>;
}>;


export type AggregateFrameworkWorkStyleQuery = { __typename?: 'Query', aggregateFrameworkWorkStyle: Array<{ __typename?: 'FrameworkSegment', type: string, labels: Array<string>, datasets: Array<{ __typename?: 'DataSet', label: string, data: Array<number>, title?: string | null, featureType?: FeatureType | null }> }> };

export type AggregateFeaturesDataQueryVariables = Exact<{
  keys: Array<Scalars['String']> | Scalars['String'];
  frameworkId: Scalars['String'];
  userScope?: InputMaybe<Scalars['Json']>;
}>;


export type AggregateFeaturesDataQuery = { __typename?: 'Query', aggregateFeaturesData: { __typename?: 'FrameworkReport', features: Array<{ __typename?: 'FeatureReport', title?: string | null, question: string, choices: Array<{ __typename?: 'ChoiceReport', text: string, count: number, placeholderText?: string | null, placeholderValue?: string | null, responders: Array<{ __typename?: 'ResponderReport', userId: string, profile?: { __typename?: 'Profile', avatar?: string | null, fullName: string } | null, userImpacts: Array<{ __typename?: 'UserImpactReport', value: number, id: string, key: string }> }> }> }>, users: Array<{ __typename?: 'ResponderEvaluatedReport', userId: string, evaluatedImpacts: Array<{ __typename?: 'UserImpactReport', value: number, key: string }> }> } };

export type StripeCreateCheckoutSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type StripeCreateCheckoutSessionMutation = { __typename?: 'Mutation', stripeCreateCheckoutSession?: string | null };

export type StripeCreatePaymentIntentMutationVariables = Exact<{ [key: string]: never; }>;


export type StripeCreatePaymentIntentMutation = { __typename?: 'Mutation', stripeCreatePaymentIntent: { __typename?: 'StripePaymentIntent', clientSecret?: string | null } };

export type StripeSetupPaymentIntentMutationVariables = Exact<{ [key: string]: never; }>;


export type StripeSetupPaymentIntentMutation = { __typename?: 'Mutation', stripeSetupPaymentIntent: { __typename?: 'StripePaymentIntent', clientSecret?: string | null } };

export type StripeCreateCustomerPortalSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type StripeCreateCustomerPortalSessionMutation = { __typename?: 'Mutation', stripeCreateCustomerPortalSession: string };

export type TeamDataFragment = { __typename?: 'Team', id: string, name?: string | null, meta?: any | null };

export type TeamUpdateMutationVariables = Exact<{
  input: TeamUpdateInput;
}>;


export type TeamUpdateMutation = { __typename?: 'Mutation', updateTeam: { __typename?: 'Team', id: string, name?: string | null, meta?: any | null } };

export type TeamQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TeamQuery = { __typename?: 'Query', team?: { __typename?: 'Team', id: string, name?: string | null, meta?: any | null } | null };

export type AccountUserDataFragment = { __typename?: 'User', id: string, email: string, isEnabled: boolean, lastLoginAt?: any | null, roles: Array<UserRole>, additionalPermissions: Array<UserAdditionalPermissions>, filterScope?: any | null, managerId?: string | null, myLatestWorkDesign?: { __typename?: 'WorkDesign', id: string, status: WdStatus, rawResponses?: any | null } | null, profile?: { __typename?: 'Profile', id: string, name?: string | null, surname?: string | null, avatar?: string | null, employmentType?: string | null, phoneNumber?: string | null, timeZone?: string | null, annualSalary?: number | null, function?: string | null, gender?: string | null, raceOrEthnicity?: string | null, hireDate?: any | null, jobTitle?: string | null, mindset?: string | null, office?: string | null, paidTimeOff?: number | null, managerId?: string | null, additionalPermissions?: UserAdditionalPermissions | null, filterScope?: any | null, addresses: Array<{ __typename?: 'Address', locality?: string | null, lat?: number | null, lng?: number | null }> } | null, team?: { __typename?: 'Team', id: string } | null, workDesigns: Array<{ __typename?: 'WorkDesign', id: string, status: WdStatus, frameworkId: string }> };

export type MeDataFragment = { __typename?: 'User', id: string, roles: Array<UserRole>, createdAt: any, email: string, isVerified: boolean, isOnboardingPollComplete: boolean, additionalPermissions: Array<UserAdditionalPermissions>, team?: { __typename?: 'Team', id: string, name?: string | null } | null, profile?: { __typename?: 'Profile', name?: string | null, surname?: string | null, fullName: string } | null };

export type SubscriptionDataFragment = { __typename?: 'Subscription', id: string, activeFrom: any, activeTo: any, isTrial: boolean, active: boolean, seats: number, seatsTaken: number };

export type CreateTeamMemberMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateTeamMemberMutation = { __typename?: 'Mutation', createTeamMember: { __typename?: 'User', id: string, email: string, isEnabled: boolean, lastLoginAt?: any | null, roles: Array<UserRole>, additionalPermissions: Array<UserAdditionalPermissions>, filterScope?: any | null, managerId?: string | null, myLatestWorkDesign?: { __typename?: 'WorkDesign', id: string, status: WdStatus, rawResponses?: any | null } | null, profile?: { __typename?: 'Profile', id: string, name?: string | null, surname?: string | null, avatar?: string | null, employmentType?: string | null, phoneNumber?: string | null, timeZone?: string | null, annualSalary?: number | null, function?: string | null, gender?: string | null, raceOrEthnicity?: string | null, hireDate?: any | null, jobTitle?: string | null, mindset?: string | null, office?: string | null, paidTimeOff?: number | null, managerId?: string | null, additionalPermissions?: UserAdditionalPermissions | null, filterScope?: any | null, addresses: Array<{ __typename?: 'Address', locality?: string | null, lat?: number | null, lng?: number | null }> } | null, team?: { __typename?: 'Team', id: string } | null, workDesigns: Array<{ __typename?: 'WorkDesign', id: string, status: WdStatus, frameworkId: string }> } };

export type CreateUserMutationVariables = Exact<{
  input: CreatePlainUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: string, email: string, isEnabled: boolean, lastLoginAt?: any | null, roles: Array<UserRole>, additionalPermissions: Array<UserAdditionalPermissions>, filterScope?: any | null, managerId?: string | null, myLatestWorkDesign?: { __typename?: 'WorkDesign', id: string, status: WdStatus, rawResponses?: any | null } | null, profile?: { __typename?: 'Profile', id: string, name?: string | null, surname?: string | null, avatar?: string | null, employmentType?: string | null, phoneNumber?: string | null, timeZone?: string | null, annualSalary?: number | null, function?: string | null, gender?: string | null, raceOrEthnicity?: string | null, hireDate?: any | null, jobTitle?: string | null, mindset?: string | null, office?: string | null, paidTimeOff?: number | null, managerId?: string | null, additionalPermissions?: UserAdditionalPermissions | null, filterScope?: any | null, addresses: Array<{ __typename?: 'Address', locality?: string | null, lat?: number | null, lng?: number | null }> } | null, team?: { __typename?: 'Team', id: string } | null, workDesigns: Array<{ __typename?: 'WorkDesign', id: string, status: WdStatus, frameworkId: string }> } };

export type UpdateCurrentUserPollMutationVariables = Exact<{
  pollResult?: InputMaybe<Scalars['Json']>;
}>;


export type UpdateCurrentUserPollMutation = { __typename?: 'Mutation', updateCurrentUserPoll: { __typename?: 'User', id: string, meta?: any | null, isOnboardingPollComplete: boolean } };

export type ToggleUsersMutationVariables = Exact<{
  userIds: Array<Scalars['ID']> | Scalars['ID'];
  isEnabled: Scalars['Boolean'];
}>;


export type ToggleUsersMutation = { __typename?: 'Mutation', toggleUsers: boolean };

export type SubmitWorkDesignMutationVariables = Exact<{
  responses: Scalars['Json'];
  workDesignId: Scalars['ID'];
}>;


export type SubmitWorkDesignMutation = { __typename?: 'Mutation', submitWorkDesign: { __typename?: 'WorkDesign', id: string, title: string, description?: string | null, status: WdStatus, createdAt: any, availableUntil: any, dimensions: Array<{ __typename?: 'FWDimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, isFollowUp: boolean, text: string, type: FeatureType, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null }, choices: Array<{ __typename?: 'Choice', id: string, order?: number | null, placeholder?: Placeholder | null, placeholderValue?: any | null, placeholderText?: string | null, text: string }> }> }>, framework: { __typename?: 'Framework', id: string, title: string, startDate: any, endDate: any } } };

export type UpdateLastLoggedInMutationVariables = Exact<{ [key: string]: never; }>;


export type UpdateLastLoggedInMutation = { __typename?: 'Mutation', updateLastLoggedIn: { __typename?: 'User', id: string, lastLoginAt?: any | null } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, email: string, roles: Array<UserRole>, createdAt: any, isVerified: boolean, isOnboardingPollComplete: boolean, additionalPermissions: Array<UserAdditionalPermissions>, myLatestWorkDesign?: { __typename?: 'WorkDesign', id: string, status: WdStatus, availableUntil: any, framework: { __typename?: 'Framework', id: string, endDate: any } } | null, team?: { __typename?: 'Team', id: string, name?: string | null } | null, profile?: { __typename?: 'Profile', avatar?: string | null, name?: string | null, surname?: string | null, fullName: string } | null, account?: { __typename?: 'Account', id: string, isStripeCustomer: boolean, integrationHRIS?: boolean | null, integrationCollaboration?: boolean | null, subscriptions: Array<{ __typename?: 'Subscription', id: string, activeFrom: any, activeTo: any, isTrial: boolean, active: boolean, seats: number, seatsTaken: number }> } | null } | null };

export type CurrentAccountUsersQueryVariables = Exact<{
  input: TableDataInput;
}>;


export type CurrentAccountUsersQuery = { __typename?: 'Query', currentAccountUsers: { __typename?: 'Users', cursor?: string | null, total: number, users: Array<{ __typename?: 'User', id: string, email: string, isEnabled: boolean, lastLoginAt?: any | null, roles: Array<UserRole>, additionalPermissions: Array<UserAdditionalPermissions>, filterScope?: any | null, managerId?: string | null, myLatestWorkDesign?: { __typename?: 'WorkDesign', id: string, status: WdStatus, rawResponses?: any | null } | null, profile?: { __typename?: 'Profile', id: string, name?: string | null, surname?: string | null, avatar?: string | null, employmentType?: string | null, phoneNumber?: string | null, timeZone?: string | null, annualSalary?: number | null, function?: string | null, gender?: string | null, raceOrEthnicity?: string | null, hireDate?: any | null, jobTitle?: string | null, mindset?: string | null, office?: string | null, paidTimeOff?: number | null, managerId?: string | null, additionalPermissions?: UserAdditionalPermissions | null, filterScope?: any | null, addresses: Array<{ __typename?: 'Address', locality?: string | null, lat?: number | null, lng?: number | null }> } | null, team?: { __typename?: 'Team', id: string } | null, workDesigns: Array<{ __typename?: 'WorkDesign', id: string, status: WdStatus, frameworkId: string }> }> } };

export type AccountUsersQueryVariables = Exact<{
  input: TableDataInput;
  accountId: Scalars['String'];
}>;


export type AccountUsersQuery = { __typename?: 'Query', accountUsers: { __typename?: 'Users', cursor?: string | null, total: number, users: Array<{ __typename?: 'User', id: string, email: string, isEnabled: boolean, lastLoginAt?: any | null, roles: Array<UserRole>, additionalPermissions: Array<UserAdditionalPermissions>, filterScope?: any | null, managerId?: string | null, myLatestWorkDesign?: { __typename?: 'WorkDesign', id: string, status: WdStatus, rawResponses?: any | null } | null, profile?: { __typename?: 'Profile', id: string, name?: string | null, surname?: string | null, avatar?: string | null, employmentType?: string | null, phoneNumber?: string | null, timeZone?: string | null, annualSalary?: number | null, function?: string | null, gender?: string | null, raceOrEthnicity?: string | null, hireDate?: any | null, jobTitle?: string | null, mindset?: string | null, office?: string | null, paidTimeOff?: number | null, managerId?: string | null, additionalPermissions?: UserAdditionalPermissions | null, filterScope?: any | null, addresses: Array<{ __typename?: 'Address', locality?: string | null, lat?: number | null, lng?: number | null }> } | null, team?: { __typename?: 'Team', id: string } | null, workDesigns: Array<{ __typename?: 'WorkDesign', id: string, status: WdStatus, frameworkId: string }> }> } };

export type AccountUserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AccountUserQuery = { __typename?: 'Query', accountUser?: { __typename?: 'User', id: string, email: string, isEnabled: boolean, lastLoginAt?: any | null, roles: Array<UserRole>, additionalPermissions: Array<UserAdditionalPermissions>, filterScope?: any | null, managerId?: string | null, myLatestWorkDesign?: { __typename?: 'WorkDesign', id: string, status: WdStatus, rawResponses?: any | null } | null, profile?: { __typename?: 'Profile', id: string, name?: string | null, surname?: string | null, avatar?: string | null, employmentType?: string | null, phoneNumber?: string | null, timeZone?: string | null, annualSalary?: number | null, function?: string | null, gender?: string | null, raceOrEthnicity?: string | null, hireDate?: any | null, jobTitle?: string | null, mindset?: string | null, office?: string | null, paidTimeOff?: number | null, managerId?: string | null, additionalPermissions?: UserAdditionalPermissions | null, filterScope?: any | null, addresses: Array<{ __typename?: 'Address', locality?: string | null, lat?: number | null, lng?: number | null }> } | null, team?: { __typename?: 'Team', id: string } | null, workDesigns: Array<{ __typename?: 'WorkDesign', id: string, status: WdStatus, frameworkId: string }> } | null };

export type FwDimensionFragment = { __typename?: 'FWDimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, isFollowUp: boolean, text: string, type: FeatureType, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null }, choices: Array<{ __typename?: 'Choice', id: string, order?: number | null, placeholder?: Placeholder | null, placeholderValue?: any | null, placeholderText?: string | null, text: string }> }> };

export type MyWorkDesignQueryVariables = Exact<{ [key: string]: never; }>;


export type MyWorkDesignQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, myLatestWorkDesign?: { __typename?: 'WorkDesign', id: string, title: string, description?: string | null, status: WdStatus, createdAt: any, availableUntil: any, dimensions: Array<{ __typename?: 'FWDimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, isFollowUp: boolean, text: string, type: FeatureType, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null }, choices: Array<{ __typename?: 'Choice', id: string, order?: number | null, placeholder?: Placeholder | null, placeholderValue?: any | null, placeholderText?: string | null, text: string }> }> }>, framework: { __typename?: 'Framework', id: string, title: string, startDate: any, endDate: any } } | null } | null };

export type MyWorkDesignCollectionQueryVariables = Exact<{ [key: string]: never; }>;


export type MyWorkDesignCollectionQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, workDesigns: Array<{ __typename?: 'WorkDesign', id: string, title: string, description?: string | null, status: WdStatus, createdAt: any, availableUntil: any, dimensions: Array<{ __typename?: 'FWDimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, isFollowUp: boolean, text: string, type: FeatureType, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null }, choices: Array<{ __typename?: 'Choice', id: string, order?: number | null, placeholder?: Placeholder | null, placeholderValue?: any | null, placeholderText?: string | null, text: string }> }> }>, framework: { __typename?: 'Framework', id: string, title: string, startDate: any, endDate: any } }> } | null };

export type TeamMemberWorkDesignCollectionQueryVariables = Exact<{
  teamMemberId: Scalars['String'];
}>;


export type TeamMemberWorkDesignCollectionQuery = { __typename?: 'Query', userWorkDesigns: Array<{ __typename?: 'WorkDesign', id: string, title: string, description?: string | null, status: WdStatus, createdAt: any, availableUntil: any, dimensions: Array<{ __typename?: 'FWDimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, isFollowUp: boolean, text: string, type: FeatureType, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null }, choices: Array<{ __typename?: 'Choice', id: string, order?: number | null, placeholder?: Placeholder | null, placeholderValue?: any | null, placeholderText?: string | null, text: string }> }> }>, framework: { __typename?: 'Framework', id: string, title: string, startDate: any, endDate: any } }> };

export type DashboardTeamMemberQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardTeamMemberQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, roles: Array<UserRole>, createdAt: any, email: string, isVerified: boolean, isOnboardingPollComplete: boolean, additionalPermissions: Array<UserAdditionalPermissions>, myLatestWorkDesign?: { __typename?: 'WorkDesign', rawResponses?: any | null, id: string, title: string, description?: string | null, status: WdStatus, createdAt: any, availableUntil: any, dimensions: Array<{ __typename?: 'FWDimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, isFollowUp: boolean, text: string, type: FeatureType, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null }, choices: Array<{ __typename?: 'Choice', id: string, order?: number | null, placeholder?: Placeholder | null, placeholderValue?: any | null, placeholderText?: string | null, text: string }> }> }>, framework: { __typename?: 'Framework', id: string, title: string, startDate: any, endDate: any } } | null, team?: { __typename?: 'Team', id: string, name?: string | null } | null, profile?: { __typename?: 'Profile', name?: string | null, surname?: string | null, fullName: string } | null } | null };

export type TeamProfilesFieldsQueryVariables = Exact<{
  keys: Array<Scalars['String']> | Scalars['String'];
}>;


export type TeamProfilesFieldsQuery = { __typename?: 'Query', teamProfilesFields: Array<{ __typename?: 'UniqueProfileFields', key: string, values: Array<string> }> };

export type UserScopesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserScopesQuery = { __typename?: 'Query', userScopes: { __typename?: 'UserScope', accountId: string, jobTitles: Array<string>, offices: Array<string>, employmentTypes: Array<string>, functions: Array<string>, genders: Array<string>, raceEthinicities: Array<string> } };

export type ReportingManagersQueryVariables = Exact<{ [key: string]: never; }>;


export type ReportingManagersQuery = { __typename?: 'Query', reportingManagers: Array<{ __typename?: 'ReportingManager', id?: string | null, name?: string | null }> };

export type InviteUsersQueryVariables = Exact<{
  input: TableDataInput;
}>;


export type InviteUsersQuery = { __typename?: 'Query', inviteUsers: { __typename?: 'Users', cursor?: string | null, total: number, users: Array<{ __typename?: 'User', id: string, email: string, isEnabled: boolean, lastLoginAt?: any | null, roles: Array<UserRole>, additionalPermissions: Array<UserAdditionalPermissions>, filterScope?: any | null, managerId?: string | null, myLatestWorkDesign?: { __typename?: 'WorkDesign', id: string, status: WdStatus, rawResponses?: any | null } | null, profile?: { __typename?: 'Profile', id: string, name?: string | null, surname?: string | null, avatar?: string | null, employmentType?: string | null, phoneNumber?: string | null, timeZone?: string | null, annualSalary?: number | null, function?: string | null, gender?: string | null, raceOrEthnicity?: string | null, hireDate?: any | null, jobTitle?: string | null, mindset?: string | null, office?: string | null, paidTimeOff?: number | null, managerId?: string | null, additionalPermissions?: UserAdditionalPermissions | null, filterScope?: any | null, addresses: Array<{ __typename?: 'Address', locality?: string | null, lat?: number | null, lng?: number | null }> } | null, team?: { __typename?: 'Team', id: string } | null, workDesigns: Array<{ __typename?: 'WorkDesign', id: string, status: WdStatus, frameworkId: string }> }> } };

export type WorkDesignDataFragment = { __typename?: 'WorkDesign', id: string, title: string, description?: string | null, status: WdStatus, createdAt: any, availableUntil: any, dimensions: Array<{ __typename?: 'FWDimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, isFollowUp: boolean, text: string, type: FeatureType, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null }, choices: Array<{ __typename?: 'Choice', id: string, order?: number | null, placeholder?: Placeholder | null, placeholderValue?: any | null, placeholderText?: string | null, text: string }> }> }>, framework: { __typename?: 'Framework', id: string, title: string, startDate: any, endDate: any } };

export type WorkDesignQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WorkDesignQuery = { __typename?: 'Query', workDesign?: { __typename?: 'WorkDesign', rawResponses?: any | null, id: string, title: string, description?: string | null, status: WdStatus, createdAt: any, availableUntil: any, dimensions: Array<{ __typename?: 'FWDimension', id: string, name: string, meta?: any | null, features: Array<{ __typename?: 'Feature', id: string, isFollowUp: boolean, text: string, type: FeatureType, dimension: { __typename?: 'Dimension', id: string, name: string, meta?: any | null }, choices: Array<{ __typename?: 'Choice', id: string, order?: number | null, placeholder?: Placeholder | null, placeholderValue?: any | null, placeholderText?: string | null, text: string }> }> }>, framework: { __typename?: 'Framework', id: string, title: string, startDate: any, endDate: any } } | null };

export type CalculateImpactsQueryVariables = Exact<{
  workDesignId: Scalars['ID'];
  responses: Scalars['Json'];
}>;


export type CalculateImpactsQuery = { __typename?: 'Query', calculateImpacts: any };

export const DimensionDataFragmentDoc = gql`
    fragment DimensionData on Dimension {
  id
  name
  meta
  features {
    id
    accountId
    text
    type
    choices {
      id
      text
      order
      placeholderValue
      placeholderText
    }
    dimension {
      id
      name
      meta
    }
  }
}
    `;
export const ChoiceDataFragmentDoc = gql`
    fragment ChoiceData on Choice {
  id
  order
  text
  placeholderValue
  placeholderText
  impactIds
}
    `;
export const FeatureDataFragmentDoc = gql`
    fragment FeatureData on Feature {
  id
  text
  title
  type
  accountId
  dimension {
    ...DimensionData
  }
  choices {
    ...ChoiceData
  }
  hasReport
  reportKey
}
    ${DimensionDataFragmentDoc}
${ChoiceDataFragmentDoc}`;
export const FwDimensionDataFragmentDoc = gql`
    fragment FWDimensionData on FWDimension {
  id
  name
  meta
  features {
    id
    accountId
    text
    type
    choices {
      id
      text
      order
      placeholderValue
      placeholderText
    }
    dimension {
      id
      name
      meta
    }
  }
}
    `;
export const FrameworkCollectionDataFragmentDoc = gql`
    fragment FrameworkCollectionData on Framework {
  id
  title
  description
  deleted
  participants
  startDate
  endDate
  published
  status
  dimensions {
    ...FWDimensionData
  }
}
    ${FwDimensionDataFragmentDoc}`;
export const TeamDataFragmentDoc = gql`
    fragment TeamData on Team {
  id
  name
  meta
}
    `;
export const ProfileDataFragmentDoc = gql`
    fragment ProfileData on Profile {
  id
  name
  surname
  avatar
  addresses {
    locality
    lat
    lng
  }
  employmentType
  phoneNumber
  timeZone
  annualSalary
  function
  gender
  raceOrEthnicity
  hireDate
  jobTitle
  mindset
  office
  paidTimeOff
  managerId
  additionalPermissions
  filterScope
}
    `;
export const AccountUserDataFragmentDoc = gql`
    fragment AccountUserData on User {
  id
  email
  isEnabled
  lastLoginAt
  myLatestWorkDesign {
    id
    status
    rawResponses
  }
  profile {
    ...ProfileData
  }
  team {
    id
  }
  workDesigns {
    id
    status
    frameworkId
  }
  roles
  additionalPermissions
  filterScope
  managerId
}
    ${ProfileDataFragmentDoc}`;
export const MeDataFragmentDoc = gql`
    fragment MeData on User {
  id
  roles
  createdAt
  email
  isVerified
  isOnboardingPollComplete
  team {
    id
    name
  }
  profile {
    name
    surname
    fullName
  }
  additionalPermissions
}
    `;
export const SubscriptionDataFragmentDoc = gql`
    fragment SubscriptionData on Subscription {
  id
  activeFrom
  activeTo
  isTrial
  active
  seats
  seatsTaken
}
    `;
export const FwDimensionFragmentDoc = gql`
    fragment FWDimension on FWDimension {
  id
  name
  meta
  features {
    id
    isFollowUp
    text
    type
    dimension {
      id
      name
      meta
    }
    choices {
      id
      order
      placeholder
      placeholderValue
      placeholderText
      text
    }
  }
}
    `;
export const WorkDesignDataFragmentDoc = gql`
    fragment WorkDesignData on WorkDesign {
  id
  title
  description
  status
  createdAt
  availableUntil
  dimensions {
    ...FWDimension
  }
  framework {
    id
    title
    startDate
    endDate
  }
}
    ${FwDimensionFragmentDoc}`;
export const SendPasswordResetLinkDocument = gql`
    mutation SendPasswordResetLink($input: UserEmailInput!) {
  sendPasswordResetLink(input: $input)
}
    `;
export type SendPasswordResetLinkMutationFn = Apollo.MutationFunction<SendPasswordResetLinkMutation, SendPasswordResetLinkMutationVariables>;

/**
 * __useSendPasswordResetLinkMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetLinkMutation, { data, loading, error }] = useSendPasswordResetLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendPasswordResetLinkMutation(baseOptions?: Apollo.MutationHookOptions<SendPasswordResetLinkMutation, SendPasswordResetLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPasswordResetLinkMutation, SendPasswordResetLinkMutationVariables>(SendPasswordResetLinkDocument, options);
      }
export type SendPasswordResetLinkMutationHookResult = ReturnType<typeof useSendPasswordResetLinkMutation>;
export type SendPasswordResetLinkMutationResult = Apollo.MutationResult<SendPasswordResetLinkMutation>;
export type SendPasswordResetLinkMutationOptions = Apollo.BaseMutationOptions<SendPasswordResetLinkMutation, SendPasswordResetLinkMutationVariables>;
export const CreateAccountDocument = gql`
    mutation CreateAccount($input: AccountInput!) {
  createAccount(input: $input) {
    users {
      id
      roles
      isOnboardingPollComplete
      team {
        id
        name
      }
    }
  }
}
    `;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, options);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const CreatePlainAccountDocument = gql`
    mutation CreatePlainAccount($input: PlainAccountInput!) {
  createPlainAccount(input: $input) {
    id
    name
    organizations {
      teams {
        name
      }
      name
    }
  }
}
    `;
export type CreatePlainAccountMutationFn = Apollo.MutationFunction<CreatePlainAccountMutation, CreatePlainAccountMutationVariables>;

/**
 * __useCreatePlainAccountMutation__
 *
 * To run a mutation, you first call `useCreatePlainAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlainAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlainAccountMutation, { data, loading, error }] = useCreatePlainAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlainAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlainAccountMutation, CreatePlainAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlainAccountMutation, CreatePlainAccountMutationVariables>(CreatePlainAccountDocument, options);
      }
export type CreatePlainAccountMutationHookResult = ReturnType<typeof useCreatePlainAccountMutation>;
export type CreatePlainAccountMutationResult = Apollo.MutationResult<CreatePlainAccountMutation>;
export type CreatePlainAccountMutationOptions = Apollo.BaseMutationOptions<CreatePlainAccountMutation, CreatePlainAccountMutationVariables>;
export const CreateSubscriptionDocument = gql`
    mutation CreateSubscription($input: CreateSubscriptionInput!) {
  createSubscription(input: $input) {
    id
    activeFrom
    activeTo
    isTrial
    plan {
      id
      pricePerUnit
    }
    active
    seats
    seatsTaken
  }
}
    `;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const UpdateLlunaAccountDocument = gql`
    mutation UpdateLlunaAccount($input: UpdateAccountInput!) {
  updateLlunaAccount(input: $input) {
    id
    integrationCollaboration
    integrationHRIS
  }
}
    `;
export type UpdateLlunaAccountMutationFn = Apollo.MutationFunction<UpdateLlunaAccountMutation, UpdateLlunaAccountMutationVariables>;

/**
 * __useUpdateLlunaAccountMutation__
 *
 * To run a mutation, you first call `useUpdateLlunaAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLlunaAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLlunaAccountMutation, { data, loading, error }] = useUpdateLlunaAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLlunaAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLlunaAccountMutation, UpdateLlunaAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLlunaAccountMutation, UpdateLlunaAccountMutationVariables>(UpdateLlunaAccountDocument, options);
      }
export type UpdateLlunaAccountMutationHookResult = ReturnType<typeof useUpdateLlunaAccountMutation>;
export type UpdateLlunaAccountMutationResult = Apollo.MutationResult<UpdateLlunaAccountMutation>;
export type UpdateLlunaAccountMutationOptions = Apollo.BaseMutationOptions<UpdateLlunaAccountMutation, UpdateLlunaAccountMutationVariables>;
export const CreateDimensionDocument = gql`
    mutation CreateDimension($input: DimensionInput!) {
  createDimension(data: $input) {
    ...DimensionData
  }
}
    ${DimensionDataFragmentDoc}`;
export type CreateDimensionMutationFn = Apollo.MutationFunction<CreateDimensionMutation, CreateDimensionMutationVariables>;

/**
 * __useCreateDimensionMutation__
 *
 * To run a mutation, you first call `useCreateDimensionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDimensionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDimensionMutation, { data, loading, error }] = useCreateDimensionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDimensionMutation(baseOptions?: Apollo.MutationHookOptions<CreateDimensionMutation, CreateDimensionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDimensionMutation, CreateDimensionMutationVariables>(CreateDimensionDocument, options);
      }
export type CreateDimensionMutationHookResult = ReturnType<typeof useCreateDimensionMutation>;
export type CreateDimensionMutationResult = Apollo.MutationResult<CreateDimensionMutation>;
export type CreateDimensionMutationOptions = Apollo.BaseMutationOptions<CreateDimensionMutation, CreateDimensionMutationVariables>;
export const UpdateDimensionDocument = gql`
    mutation UpdateDimension($input: DimensionUpdateInput!) {
  updateDimension(data: $input) {
    ...DimensionData
  }
}
    ${DimensionDataFragmentDoc}`;
export type UpdateDimensionMutationFn = Apollo.MutationFunction<UpdateDimensionMutation, UpdateDimensionMutationVariables>;

/**
 * __useUpdateDimensionMutation__
 *
 * To run a mutation, you first call `useUpdateDimensionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDimensionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDimensionMutation, { data, loading, error }] = useUpdateDimensionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDimensionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDimensionMutation, UpdateDimensionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDimensionMutation, UpdateDimensionMutationVariables>(UpdateDimensionDocument, options);
      }
export type UpdateDimensionMutationHookResult = ReturnType<typeof useUpdateDimensionMutation>;
export type UpdateDimensionMutationResult = Apollo.MutationResult<UpdateDimensionMutation>;
export type UpdateDimensionMutationOptions = Apollo.BaseMutationOptions<UpdateDimensionMutation, UpdateDimensionMutationVariables>;
export const RemoveDimensionDocument = gql`
    mutation RemoveDimension($id: String!) {
  removeDimension(id: $id) {
    id
  }
}
    `;
export type RemoveDimensionMutationFn = Apollo.MutationFunction<RemoveDimensionMutation, RemoveDimensionMutationVariables>;

/**
 * __useRemoveDimensionMutation__
 *
 * To run a mutation, you first call `useRemoveDimensionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDimensionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDimensionMutation, { data, loading, error }] = useRemoveDimensionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveDimensionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDimensionMutation, RemoveDimensionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDimensionMutation, RemoveDimensionMutationVariables>(RemoveDimensionDocument, options);
      }
export type RemoveDimensionMutationHookResult = ReturnType<typeof useRemoveDimensionMutation>;
export type RemoveDimensionMutationResult = Apollo.MutationResult<RemoveDimensionMutation>;
export type RemoveDimensionMutationOptions = Apollo.BaseMutationOptions<RemoveDimensionMutation, RemoveDimensionMutationVariables>;
export const DimensionsDocument = gql`
    query Dimensions {
  dimensions {
    ...DimensionData
  }
}
    ${DimensionDataFragmentDoc}`;

/**
 * __useDimensionsQuery__
 *
 * To run a query within a React component, call `useDimensionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDimensionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDimensionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDimensionsQuery(baseOptions?: Apollo.QueryHookOptions<DimensionsQuery, DimensionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DimensionsQuery, DimensionsQueryVariables>(DimensionsDocument, options);
      }
export function useDimensionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DimensionsQuery, DimensionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DimensionsQuery, DimensionsQueryVariables>(DimensionsDocument, options);
        }
export type DimensionsQueryHookResult = ReturnType<typeof useDimensionsQuery>;
export type DimensionsLazyQueryHookResult = ReturnType<typeof useDimensionsLazyQuery>;
export type DimensionsQueryResult = Apollo.QueryResult<DimensionsQuery, DimensionsQueryVariables>;
export const DimensionDocument = gql`
    query Dimension($id: ID!) {
  dimension(id: $id) {
    ...DimensionData
  }
}
    ${DimensionDataFragmentDoc}`;

/**
 * __useDimensionQuery__
 *
 * To run a query within a React component, call `useDimensionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDimensionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDimensionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDimensionQuery(baseOptions: Apollo.QueryHookOptions<DimensionQuery, DimensionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DimensionQuery, DimensionQueryVariables>(DimensionDocument, options);
      }
export function useDimensionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DimensionQuery, DimensionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DimensionQuery, DimensionQueryVariables>(DimensionDocument, options);
        }
export type DimensionQueryHookResult = ReturnType<typeof useDimensionQuery>;
export type DimensionLazyQueryHookResult = ReturnType<typeof useDimensionLazyQuery>;
export type DimensionQueryResult = Apollo.QueryResult<DimensionQuery, DimensionQueryVariables>;
export const CreateFeatureDocument = gql`
    mutation CreateFeature($data: FeatureCreateInput!) {
  createFeature(data: $data) {
    ...FeatureData
  }
}
    ${FeatureDataFragmentDoc}`;
export type CreateFeatureMutationFn = Apollo.MutationFunction<CreateFeatureMutation, CreateFeatureMutationVariables>;

/**
 * __useCreateFeatureMutation__
 *
 * To run a mutation, you first call `useCreateFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeatureMutation, { data, loading, error }] = useCreateFeatureMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFeatureMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeatureMutation, CreateFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeatureMutation, CreateFeatureMutationVariables>(CreateFeatureDocument, options);
      }
export type CreateFeatureMutationHookResult = ReturnType<typeof useCreateFeatureMutation>;
export type CreateFeatureMutationResult = Apollo.MutationResult<CreateFeatureMutation>;
export type CreateFeatureMutationOptions = Apollo.BaseMutationOptions<CreateFeatureMutation, CreateFeatureMutationVariables>;
export const RemoveFeatureDocument = gql`
    mutation RemoveFeature($id: String!) {
  removeFeature(id: $id) {
    id
    text
    type
  }
}
    `;
export type RemoveFeatureMutationFn = Apollo.MutationFunction<RemoveFeatureMutation, RemoveFeatureMutationVariables>;

/**
 * __useRemoveFeatureMutation__
 *
 * To run a mutation, you first call `useRemoveFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFeatureMutation, { data, loading, error }] = useRemoveFeatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFeatureMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFeatureMutation, RemoveFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFeatureMutation, RemoveFeatureMutationVariables>(RemoveFeatureDocument, options);
      }
export type RemoveFeatureMutationHookResult = ReturnType<typeof useRemoveFeatureMutation>;
export type RemoveFeatureMutationResult = Apollo.MutationResult<RemoveFeatureMutation>;
export type RemoveFeatureMutationOptions = Apollo.BaseMutationOptions<RemoveFeatureMutation, RemoveFeatureMutationVariables>;
export const UpdateFeatureDocument = gql`
    mutation UpdateFeature($data: FeatureUpdateInput!) {
  updateFeature(data: $data) {
    ...FeatureData
  }
}
    ${FeatureDataFragmentDoc}`;
export type UpdateFeatureMutationFn = Apollo.MutationFunction<UpdateFeatureMutation, UpdateFeatureMutationVariables>;

/**
 * __useUpdateFeatureMutation__
 *
 * To run a mutation, you first call `useUpdateFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeatureMutation, { data, loading, error }] = useUpdateFeatureMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateFeatureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeatureMutation, UpdateFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeatureMutation, UpdateFeatureMutationVariables>(UpdateFeatureDocument, options);
      }
export type UpdateFeatureMutationHookResult = ReturnType<typeof useUpdateFeatureMutation>;
export type UpdateFeatureMutationResult = Apollo.MutationResult<UpdateFeatureMutation>;
export type UpdateFeatureMutationOptions = Apollo.BaseMutationOptions<UpdateFeatureMutation, UpdateFeatureMutationVariables>;
export const FeaturesDocument = gql`
    query Features($input: TableDataInput!) {
  features(input: $input) {
    ...FeatureData
  }
}
    ${FeatureDataFragmentDoc}`;

/**
 * __useFeaturesQuery__
 *
 * To run a query within a React component, call `useFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFeaturesQuery(baseOptions: Apollo.QueryHookOptions<FeaturesQuery, FeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeaturesQuery, FeaturesQueryVariables>(FeaturesDocument, options);
      }
export function useFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeaturesQuery, FeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeaturesQuery, FeaturesQueryVariables>(FeaturesDocument, options);
        }
export type FeaturesQueryHookResult = ReturnType<typeof useFeaturesQuery>;
export type FeaturesLazyQueryHookResult = ReturnType<typeof useFeaturesLazyQuery>;
export type FeaturesQueryResult = Apollo.QueryResult<FeaturesQuery, FeaturesQueryVariables>;
export const FeatureDocument = gql`
    query Feature($featureId: ID!) {
  feature(id: $featureId) {
    ...FeatureData
  }
}
    ${FeatureDataFragmentDoc}`;

/**
 * __useFeatureQuery__
 *
 * To run a query within a React component, call `useFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureQuery({
 *   variables: {
 *      featureId: // value for 'featureId'
 *   },
 * });
 */
export function useFeatureQuery(baseOptions: Apollo.QueryHookOptions<FeatureQuery, FeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeatureQuery, FeatureQueryVariables>(FeatureDocument, options);
      }
export function useFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeatureQuery, FeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeatureQuery, FeatureQueryVariables>(FeatureDocument, options);
        }
export type FeatureQueryHookResult = ReturnType<typeof useFeatureQuery>;
export type FeatureLazyQueryHookResult = ReturnType<typeof useFeatureLazyQuery>;
export type FeatureQueryResult = Apollo.QueryResult<FeatureQuery, FeatureQueryVariables>;
export const ImpactsDocument = gql`
    query Impacts {
  impacts {
    id
    title
    formula
    key
  }
}
    `;

/**
 * __useImpactsQuery__
 *
 * To run a query within a React component, call `useImpactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useImpactsQuery(baseOptions?: Apollo.QueryHookOptions<ImpactsQuery, ImpactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImpactsQuery, ImpactsQueryVariables>(ImpactsDocument, options);
      }
export function useImpactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImpactsQuery, ImpactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImpactsQuery, ImpactsQueryVariables>(ImpactsDocument, options);
        }
export type ImpactsQueryHookResult = ReturnType<typeof useImpactsQuery>;
export type ImpactsLazyQueryHookResult = ReturnType<typeof useImpactsLazyQuery>;
export type ImpactsQueryResult = Apollo.QueryResult<ImpactsQuery, ImpactsQueryVariables>;
export const AccountsDocument = gql`
    query Accounts($query: String) {
  accounts(query: $query) {
    id
    name
    integrationCollaboration
    integrationHRIS
    isStripeCustomer
    users {
      email
      profile {
        fullName
      }
    }
  }
}
    `;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAccountsQuery(baseOptions?: Apollo.QueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
      }
export function useAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
        }
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export type AccountsQueryResult = Apollo.QueryResult<AccountsQuery, AccountsQueryVariables>;
export const DeleteFrameworkMutationDocument = gql`
    mutation deleteFrameworkMutation($id: ID!) {
  deleteFramework(id: $id) {
    id
  }
}
    `;
export type DeleteFrameworkMutationMutationFn = Apollo.MutationFunction<DeleteFrameworkMutationMutation, DeleteFrameworkMutationMutationVariables>;

/**
 * __useDeleteFrameworkMutationMutation__
 *
 * To run a mutation, you first call `useDeleteFrameworkMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFrameworkMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFrameworkMutationMutation, { data, loading, error }] = useDeleteFrameworkMutationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFrameworkMutationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFrameworkMutationMutation, DeleteFrameworkMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFrameworkMutationMutation, DeleteFrameworkMutationMutationVariables>(DeleteFrameworkMutationDocument, options);
      }
export type DeleteFrameworkMutationMutationHookResult = ReturnType<typeof useDeleteFrameworkMutationMutation>;
export type DeleteFrameworkMutationMutationResult = Apollo.MutationResult<DeleteFrameworkMutationMutation>;
export type DeleteFrameworkMutationMutationOptions = Apollo.BaseMutationOptions<DeleteFrameworkMutationMutation, DeleteFrameworkMutationMutationVariables>;
export const CreateFrameworkDocument = gql`
    mutation createFramework($input: FrameworkInputType!) {
  createFramework(input: $input) {
    ...FrameworkCollectionData
  }
}
    ${FrameworkCollectionDataFragmentDoc}`;
export type CreateFrameworkMutationFn = Apollo.MutationFunction<CreateFrameworkMutation, CreateFrameworkMutationVariables>;

/**
 * __useCreateFrameworkMutation__
 *
 * To run a mutation, you first call `useCreateFrameworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFrameworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFrameworkMutation, { data, loading, error }] = useCreateFrameworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFrameworkMutation(baseOptions?: Apollo.MutationHookOptions<CreateFrameworkMutation, CreateFrameworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFrameworkMutation, CreateFrameworkMutationVariables>(CreateFrameworkDocument, options);
      }
export type CreateFrameworkMutationHookResult = ReturnType<typeof useCreateFrameworkMutation>;
export type CreateFrameworkMutationResult = Apollo.MutationResult<CreateFrameworkMutation>;
export type CreateFrameworkMutationOptions = Apollo.BaseMutationOptions<CreateFrameworkMutation, CreateFrameworkMutationVariables>;
export const UpdateFrameworkDocument = gql`
    mutation updateFramework($id: ID!, $input: FrameworkInputType!) {
  updateFramework(id: $id, input: $input) {
    ...FrameworkCollectionData
  }
}
    ${FrameworkCollectionDataFragmentDoc}`;
export type UpdateFrameworkMutationFn = Apollo.MutationFunction<UpdateFrameworkMutation, UpdateFrameworkMutationVariables>;

/**
 * __useUpdateFrameworkMutation__
 *
 * To run a mutation, you first call `useUpdateFrameworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFrameworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFrameworkMutation, { data, loading, error }] = useUpdateFrameworkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFrameworkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFrameworkMutation, UpdateFrameworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFrameworkMutation, UpdateFrameworkMutationVariables>(UpdateFrameworkDocument, options);
      }
export type UpdateFrameworkMutationHookResult = ReturnType<typeof useUpdateFrameworkMutation>;
export type UpdateFrameworkMutationResult = Apollo.MutationResult<UpdateFrameworkMutation>;
export type UpdateFrameworkMutationOptions = Apollo.BaseMutationOptions<UpdateFrameworkMutation, UpdateFrameworkMutationVariables>;
export const AssignWorkDesignDocument = gql`
    mutation AssignWorkDesign($input: AssignWorkDesignInput!) {
  assignWorkDesign(input: $input) {
    ...FrameworkCollectionData
  }
}
    ${FrameworkCollectionDataFragmentDoc}`;
export type AssignWorkDesignMutationFn = Apollo.MutationFunction<AssignWorkDesignMutation, AssignWorkDesignMutationVariables>;

/**
 * __useAssignWorkDesignMutation__
 *
 * To run a mutation, you first call `useAssignWorkDesignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignWorkDesignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignWorkDesignMutation, { data, loading, error }] = useAssignWorkDesignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignWorkDesignMutation(baseOptions?: Apollo.MutationHookOptions<AssignWorkDesignMutation, AssignWorkDesignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignWorkDesignMutation, AssignWorkDesignMutationVariables>(AssignWorkDesignDocument, options);
      }
export type AssignWorkDesignMutationHookResult = ReturnType<typeof useAssignWorkDesignMutation>;
export type AssignWorkDesignMutationResult = Apollo.MutationResult<AssignWorkDesignMutation>;
export type AssignWorkDesignMutationOptions = Apollo.BaseMutationOptions<AssignWorkDesignMutation, AssignWorkDesignMutationVariables>;
export const FrameworksCollectionDocument = gql`
    query FrameworksCollection($search: String, $sort: [OrderByItemInput!], $filter: [FilterItemInput!], $sharedOnly: Boolean) {
  frameworks(
    search: $search
    sort: $sort
    filter: $filter
    sharedOnly: $sharedOnly
  ) {
    ...FrameworkCollectionData
  }
}
    ${FrameworkCollectionDataFragmentDoc}`;

/**
 * __useFrameworksCollectionQuery__
 *
 * To run a query within a React component, call `useFrameworksCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrameworksCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrameworksCollectionQuery({
 *   variables: {
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *      sharedOnly: // value for 'sharedOnly'
 *   },
 * });
 */
export function useFrameworksCollectionQuery(baseOptions?: Apollo.QueryHookOptions<FrameworksCollectionQuery, FrameworksCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FrameworksCollectionQuery, FrameworksCollectionQueryVariables>(FrameworksCollectionDocument, options);
      }
export function useFrameworksCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FrameworksCollectionQuery, FrameworksCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FrameworksCollectionQuery, FrameworksCollectionQueryVariables>(FrameworksCollectionDocument, options);
        }
export type FrameworksCollectionQueryHookResult = ReturnType<typeof useFrameworksCollectionQuery>;
export type FrameworksCollectionLazyQueryHookResult = ReturnType<typeof useFrameworksCollectionLazyQuery>;
export type FrameworksCollectionQueryResult = Apollo.QueryResult<FrameworksCollectionQuery, FrameworksCollectionQueryVariables>;
export const FrameworkDocument = gql`
    query Framework($id: ID!) {
  framework(id: $id) {
    ...FrameworkCollectionData
  }
}
    ${FrameworkCollectionDataFragmentDoc}`;

/**
 * __useFrameworkQuery__
 *
 * To run a query within a React component, call `useFrameworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrameworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrameworkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFrameworkQuery(baseOptions: Apollo.QueryHookOptions<FrameworkQuery, FrameworkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FrameworkQuery, FrameworkQueryVariables>(FrameworkDocument, options);
      }
export function useFrameworkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FrameworkQuery, FrameworkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FrameworkQuery, FrameworkQueryVariables>(FrameworkDocument, options);
        }
export type FrameworkQueryHookResult = ReturnType<typeof useFrameworkQuery>;
export type FrameworkLazyQueryHookResult = ReturnType<typeof useFrameworkLazyQuery>;
export type FrameworkQueryResult = Apollo.QueryResult<FrameworkQuery, FrameworkQueryVariables>;
export const SubmitAuthCodeDocument = gql`
    mutation SubmitAuthCode($input: FinchIntegrationInput!) {
  submitAuthCode(input: $input) {
    status
    message
  }
}
    `;
export type SubmitAuthCodeMutationFn = Apollo.MutationFunction<SubmitAuthCodeMutation, SubmitAuthCodeMutationVariables>;

/**
 * __useSubmitAuthCodeMutation__
 *
 * To run a mutation, you first call `useSubmitAuthCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAuthCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAuthCodeMutation, { data, loading, error }] = useSubmitAuthCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitAuthCodeMutation(baseOptions?: Apollo.MutationHookOptions<SubmitAuthCodeMutation, SubmitAuthCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitAuthCodeMutation, SubmitAuthCodeMutationVariables>(SubmitAuthCodeDocument, options);
      }
export type SubmitAuthCodeMutationHookResult = ReturnType<typeof useSubmitAuthCodeMutation>;
export type SubmitAuthCodeMutationResult = Apollo.MutationResult<SubmitAuthCodeMutation>;
export type SubmitAuthCodeMutationOptions = Apollo.BaseMutationOptions<SubmitAuthCodeMutation, SubmitAuthCodeMutationVariables>;
export const IntegrationsDocument = gql`
    query Integrations {
  integrations {
    id
    name
    status
    accountIntegrations {
      id
      accountId
      accessToken
      status
      atsName
    }
  }
}
    `;

/**
 * __useIntegrationsQuery__
 *
 * To run a query within a React component, call `useIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useIntegrationsQuery(baseOptions?: Apollo.QueryHookOptions<IntegrationsQuery, IntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IntegrationsQuery, IntegrationsQueryVariables>(IntegrationsDocument, options);
      }
export function useIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntegrationsQuery, IntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IntegrationsQuery, IntegrationsQueryVariables>(IntegrationsDocument, options);
        }
export type IntegrationsQueryHookResult = ReturnType<typeof useIntegrationsQuery>;
export type IntegrationsLazyQueryHookResult = ReturnType<typeof useIntegrationsLazyQuery>;
export type IntegrationsQueryResult = Apollo.QueryResult<IntegrationsQuery, IntegrationsQueryVariables>;
export const CheckInvitationDocument = gql`
    query CheckInvitation($invitationCode: String!) {
  checkInvitation(invitationCode: $invitationCode) {
    status
    user {
      email
      profile {
        name
        surname
      }
    }
  }
}
    `;

/**
 * __useCheckInvitationQuery__
 *
 * To run a query within a React component, call `useCheckInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckInvitationQuery({
 *   variables: {
 *      invitationCode: // value for 'invitationCode'
 *   },
 * });
 */
export function useCheckInvitationQuery(baseOptions: Apollo.QueryHookOptions<CheckInvitationQuery, CheckInvitationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckInvitationQuery, CheckInvitationQueryVariables>(CheckInvitationDocument, options);
      }
export function useCheckInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckInvitationQuery, CheckInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckInvitationQuery, CheckInvitationQueryVariables>(CheckInvitationDocument, options);
        }
export type CheckInvitationQueryHookResult = ReturnType<typeof useCheckInvitationQuery>;
export type CheckInvitationLazyQueryHookResult = ReturnType<typeof useCheckInvitationLazyQuery>;
export type CheckInvitationQueryResult = Apollo.QueryResult<CheckInvitationQuery, CheckInvitationQueryVariables>;
export const ProfileUpdateDocument = gql`
    mutation ProfileUpdate($input: ProfileUpdateInput!) {
  updateProfile(input: $input) {
    ...ProfileData
  }
}
    ${ProfileDataFragmentDoc}`;
export type ProfileUpdateMutationFn = Apollo.MutationFunction<ProfileUpdateMutation, ProfileUpdateMutationVariables>;

/**
 * __useProfileUpdateMutation__
 *
 * To run a mutation, you first call `useProfileUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileUpdateMutation, { data, loading, error }] = useProfileUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfileUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ProfileUpdateMutation, ProfileUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProfileUpdateMutation, ProfileUpdateMutationVariables>(ProfileUpdateDocument, options);
      }
export type ProfileUpdateMutationHookResult = ReturnType<typeof useProfileUpdateMutation>;
export type ProfileUpdateMutationResult = Apollo.MutationResult<ProfileUpdateMutation>;
export type ProfileUpdateMutationOptions = Apollo.BaseMutationOptions<ProfileUpdateMutation, ProfileUpdateMutationVariables>;
export const UpdateProfileAvatarDocument = gql`
    mutation UpdateProfileAvatar($src: String!) {
  updateProfileAvatar(src: $src) {
    avatar
    id
  }
}
    `;
export type UpdateProfileAvatarMutationFn = Apollo.MutationFunction<UpdateProfileAvatarMutation, UpdateProfileAvatarMutationVariables>;

/**
 * __useUpdateProfileAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateProfileAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileAvatarMutation, { data, loading, error }] = useUpdateProfileAvatarMutation({
 *   variables: {
 *      src: // value for 'src'
 *   },
 * });
 */
export function useUpdateProfileAvatarMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileAvatarMutation, UpdateProfileAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileAvatarMutation, UpdateProfileAvatarMutationVariables>(UpdateProfileAvatarDocument, options);
      }
export type UpdateProfileAvatarMutationHookResult = ReturnType<typeof useUpdateProfileAvatarMutation>;
export type UpdateProfileAvatarMutationResult = Apollo.MutationResult<UpdateProfileAvatarMutation>;
export type UpdateProfileAvatarMutationOptions = Apollo.BaseMutationOptions<UpdateProfileAvatarMutation, UpdateProfileAvatarMutationVariables>;
export const AggregateFrameworkStatusDocument = gql`
    query AggregateFrameworkStatus($frameworkId: String!, $userScope: Json) {
  aggregateFrameworkStatus(frameworkId: $frameworkId, userScope: $userScope) {
    type
    labels
    datasets {
      label
      data
      title
    }
  }
}
    `;

/**
 * __useAggregateFrameworkStatusQuery__
 *
 * To run a query within a React component, call `useAggregateFrameworkStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateFrameworkStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateFrameworkStatusQuery({
 *   variables: {
 *      frameworkId: // value for 'frameworkId'
 *      userScope: // value for 'userScope'
 *   },
 * });
 */
export function useAggregateFrameworkStatusQuery(baseOptions: Apollo.QueryHookOptions<AggregateFrameworkStatusQuery, AggregateFrameworkStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateFrameworkStatusQuery, AggregateFrameworkStatusQueryVariables>(AggregateFrameworkStatusDocument, options);
      }
export function useAggregateFrameworkStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateFrameworkStatusQuery, AggregateFrameworkStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateFrameworkStatusQuery, AggregateFrameworkStatusQueryVariables>(AggregateFrameworkStatusDocument, options);
        }
export type AggregateFrameworkStatusQueryHookResult = ReturnType<typeof useAggregateFrameworkStatusQuery>;
export type AggregateFrameworkStatusLazyQueryHookResult = ReturnType<typeof useAggregateFrameworkStatusLazyQuery>;
export type AggregateFrameworkStatusQueryResult = Apollo.QueryResult<AggregateFrameworkStatusQuery, AggregateFrameworkStatusQueryVariables>;
export const AggregateFrameworkDataDocument = gql`
    query AggregateFrameworkData($frameworkId: String!, $userScope: Json) {
  aggregateFrameworkData(frameworkId: $frameworkId, userScope: $userScope) {
    name
    segments {
      responders {
        avatar
        fullName
      }
      datasets {
        label
        data
        title
        featureType
      }
      placeholders {
        type
        value
      }
      labels
      type
    }
  }
}
    `;

/**
 * __useAggregateFrameworkDataQuery__
 *
 * To run a query within a React component, call `useAggregateFrameworkDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateFrameworkDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateFrameworkDataQuery({
 *   variables: {
 *      frameworkId: // value for 'frameworkId'
 *      userScope: // value for 'userScope'
 *   },
 * });
 */
export function useAggregateFrameworkDataQuery(baseOptions: Apollo.QueryHookOptions<AggregateFrameworkDataQuery, AggregateFrameworkDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateFrameworkDataQuery, AggregateFrameworkDataQueryVariables>(AggregateFrameworkDataDocument, options);
      }
export function useAggregateFrameworkDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateFrameworkDataQuery, AggregateFrameworkDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateFrameworkDataQuery, AggregateFrameworkDataQueryVariables>(AggregateFrameworkDataDocument, options);
        }
export type AggregateFrameworkDataQueryHookResult = ReturnType<typeof useAggregateFrameworkDataQuery>;
export type AggregateFrameworkDataLazyQueryHookResult = ReturnType<typeof useAggregateFrameworkDataLazyQuery>;
export type AggregateFrameworkDataQueryResult = Apollo.QueryResult<AggregateFrameworkDataQuery, AggregateFrameworkDataQueryVariables>;
export const AggregateFrameworkImpactsDocument = gql`
    query AggregateFrameworkImpacts($frameworkId: String!) {
  aggregateFrameworkImpacts(frameworkId: $frameworkId) {
    impacts {
      id
      customFormula
      frequency
      target
      title
      isCostRelated
      operation
      formula
      key
    }
    key
    sum
  }
}
    `;

/**
 * __useAggregateFrameworkImpactsQuery__
 *
 * To run a query within a React component, call `useAggregateFrameworkImpactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateFrameworkImpactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateFrameworkImpactsQuery({
 *   variables: {
 *      frameworkId: // value for 'frameworkId'
 *   },
 * });
 */
export function useAggregateFrameworkImpactsQuery(baseOptions: Apollo.QueryHookOptions<AggregateFrameworkImpactsQuery, AggregateFrameworkImpactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateFrameworkImpactsQuery, AggregateFrameworkImpactsQueryVariables>(AggregateFrameworkImpactsDocument, options);
      }
export function useAggregateFrameworkImpactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateFrameworkImpactsQuery, AggregateFrameworkImpactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateFrameworkImpactsQuery, AggregateFrameworkImpactsQueryVariables>(AggregateFrameworkImpactsDocument, options);
        }
export type AggregateFrameworkImpactsQueryHookResult = ReturnType<typeof useAggregateFrameworkImpactsQuery>;
export type AggregateFrameworkImpactsLazyQueryHookResult = ReturnType<typeof useAggregateFrameworkImpactsLazyQuery>;
export type AggregateFrameworkImpactsQueryResult = Apollo.QueryResult<AggregateFrameworkImpactsQuery, AggregateFrameworkImpactsQueryVariables>;
export const AggregateFrameworkWorkStyleDocument = gql`
    query AggregateFrameworkWorkStyle($frameworkId: String, $userScope: Json) {
  aggregateFrameworkWorkStyle(frameworkId: $frameworkId, userScope: $userScope) {
    type
    labels
    datasets {
      label
      data
      title
      featureType
    }
  }
}
    `;

/**
 * __useAggregateFrameworkWorkStyleQuery__
 *
 * To run a query within a React component, call `useAggregateFrameworkWorkStyleQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateFrameworkWorkStyleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateFrameworkWorkStyleQuery({
 *   variables: {
 *      frameworkId: // value for 'frameworkId'
 *      userScope: // value for 'userScope'
 *   },
 * });
 */
export function useAggregateFrameworkWorkStyleQuery(baseOptions?: Apollo.QueryHookOptions<AggregateFrameworkWorkStyleQuery, AggregateFrameworkWorkStyleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateFrameworkWorkStyleQuery, AggregateFrameworkWorkStyleQueryVariables>(AggregateFrameworkWorkStyleDocument, options);
      }
export function useAggregateFrameworkWorkStyleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateFrameworkWorkStyleQuery, AggregateFrameworkWorkStyleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateFrameworkWorkStyleQuery, AggregateFrameworkWorkStyleQueryVariables>(AggregateFrameworkWorkStyleDocument, options);
        }
export type AggregateFrameworkWorkStyleQueryHookResult = ReturnType<typeof useAggregateFrameworkWorkStyleQuery>;
export type AggregateFrameworkWorkStyleLazyQueryHookResult = ReturnType<typeof useAggregateFrameworkWorkStyleLazyQuery>;
export type AggregateFrameworkWorkStyleQueryResult = Apollo.QueryResult<AggregateFrameworkWorkStyleQuery, AggregateFrameworkWorkStyleQueryVariables>;
export const AggregateFeaturesDataDocument = gql`
    query AggregateFeaturesData($keys: [String!]!, $frameworkId: String!, $userScope: Json) {
  aggregateFeaturesData(
    keys: $keys
    frameworkId: $frameworkId
    userScope: $userScope
  ) {
    features {
      title
      question
      choices {
        text
        responders {
          userId
          profile {
            avatar
            fullName
          }
          userImpacts {
            value
            id
            key
          }
        }
        count
        placeholderText
        placeholderValue
      }
    }
    users {
      userId
      evaluatedImpacts {
        value
        key
      }
    }
  }
}
    `;

/**
 * __useAggregateFeaturesDataQuery__
 *
 * To run a query within a React component, call `useAggregateFeaturesDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateFeaturesDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateFeaturesDataQuery({
 *   variables: {
 *      keys: // value for 'keys'
 *      frameworkId: // value for 'frameworkId'
 *      userScope: // value for 'userScope'
 *   },
 * });
 */
export function useAggregateFeaturesDataQuery(baseOptions: Apollo.QueryHookOptions<AggregateFeaturesDataQuery, AggregateFeaturesDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateFeaturesDataQuery, AggregateFeaturesDataQueryVariables>(AggregateFeaturesDataDocument, options);
      }
export function useAggregateFeaturesDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateFeaturesDataQuery, AggregateFeaturesDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateFeaturesDataQuery, AggregateFeaturesDataQueryVariables>(AggregateFeaturesDataDocument, options);
        }
export type AggregateFeaturesDataQueryHookResult = ReturnType<typeof useAggregateFeaturesDataQuery>;
export type AggregateFeaturesDataLazyQueryHookResult = ReturnType<typeof useAggregateFeaturesDataLazyQuery>;
export type AggregateFeaturesDataQueryResult = Apollo.QueryResult<AggregateFeaturesDataQuery, AggregateFeaturesDataQueryVariables>;
export const StripeCreateCheckoutSessionDocument = gql`
    mutation StripeCreateCheckoutSession {
  stripeCreateCheckoutSession
}
    `;
export type StripeCreateCheckoutSessionMutationFn = Apollo.MutationFunction<StripeCreateCheckoutSessionMutation, StripeCreateCheckoutSessionMutationVariables>;

/**
 * __useStripeCreateCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useStripeCreateCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeCreateCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeCreateCheckoutSessionMutation, { data, loading, error }] = useStripeCreateCheckoutSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useStripeCreateCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<StripeCreateCheckoutSessionMutation, StripeCreateCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StripeCreateCheckoutSessionMutation, StripeCreateCheckoutSessionMutationVariables>(StripeCreateCheckoutSessionDocument, options);
      }
export type StripeCreateCheckoutSessionMutationHookResult = ReturnType<typeof useStripeCreateCheckoutSessionMutation>;
export type StripeCreateCheckoutSessionMutationResult = Apollo.MutationResult<StripeCreateCheckoutSessionMutation>;
export type StripeCreateCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<StripeCreateCheckoutSessionMutation, StripeCreateCheckoutSessionMutationVariables>;
export const StripeCreatePaymentIntentDocument = gql`
    mutation StripeCreatePaymentIntent {
  stripeCreatePaymentIntent {
    clientSecret
  }
}
    `;
export type StripeCreatePaymentIntentMutationFn = Apollo.MutationFunction<StripeCreatePaymentIntentMutation, StripeCreatePaymentIntentMutationVariables>;

/**
 * __useStripeCreatePaymentIntentMutation__
 *
 * To run a mutation, you first call `useStripeCreatePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeCreatePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeCreatePaymentIntentMutation, { data, loading, error }] = useStripeCreatePaymentIntentMutation({
 *   variables: {
 *   },
 * });
 */
export function useStripeCreatePaymentIntentMutation(baseOptions?: Apollo.MutationHookOptions<StripeCreatePaymentIntentMutation, StripeCreatePaymentIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StripeCreatePaymentIntentMutation, StripeCreatePaymentIntentMutationVariables>(StripeCreatePaymentIntentDocument, options);
      }
export type StripeCreatePaymentIntentMutationHookResult = ReturnType<typeof useStripeCreatePaymentIntentMutation>;
export type StripeCreatePaymentIntentMutationResult = Apollo.MutationResult<StripeCreatePaymentIntentMutation>;
export type StripeCreatePaymentIntentMutationOptions = Apollo.BaseMutationOptions<StripeCreatePaymentIntentMutation, StripeCreatePaymentIntentMutationVariables>;
export const StripeSetupPaymentIntentDocument = gql`
    mutation StripeSetupPaymentIntent {
  stripeSetupPaymentIntent {
    clientSecret
  }
}
    `;
export type StripeSetupPaymentIntentMutationFn = Apollo.MutationFunction<StripeSetupPaymentIntentMutation, StripeSetupPaymentIntentMutationVariables>;

/**
 * __useStripeSetupPaymentIntentMutation__
 *
 * To run a mutation, you first call `useStripeSetupPaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeSetupPaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeSetupPaymentIntentMutation, { data, loading, error }] = useStripeSetupPaymentIntentMutation({
 *   variables: {
 *   },
 * });
 */
export function useStripeSetupPaymentIntentMutation(baseOptions?: Apollo.MutationHookOptions<StripeSetupPaymentIntentMutation, StripeSetupPaymentIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StripeSetupPaymentIntentMutation, StripeSetupPaymentIntentMutationVariables>(StripeSetupPaymentIntentDocument, options);
      }
export type StripeSetupPaymentIntentMutationHookResult = ReturnType<typeof useStripeSetupPaymentIntentMutation>;
export type StripeSetupPaymentIntentMutationResult = Apollo.MutationResult<StripeSetupPaymentIntentMutation>;
export type StripeSetupPaymentIntentMutationOptions = Apollo.BaseMutationOptions<StripeSetupPaymentIntentMutation, StripeSetupPaymentIntentMutationVariables>;
export const StripeCreateCustomerPortalSessionDocument = gql`
    mutation StripeCreateCustomerPortalSession {
  stripeCreateCustomerPortalSession
}
    `;
export type StripeCreateCustomerPortalSessionMutationFn = Apollo.MutationFunction<StripeCreateCustomerPortalSessionMutation, StripeCreateCustomerPortalSessionMutationVariables>;

/**
 * __useStripeCreateCustomerPortalSessionMutation__
 *
 * To run a mutation, you first call `useStripeCreateCustomerPortalSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeCreateCustomerPortalSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeCreateCustomerPortalSessionMutation, { data, loading, error }] = useStripeCreateCustomerPortalSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useStripeCreateCustomerPortalSessionMutation(baseOptions?: Apollo.MutationHookOptions<StripeCreateCustomerPortalSessionMutation, StripeCreateCustomerPortalSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StripeCreateCustomerPortalSessionMutation, StripeCreateCustomerPortalSessionMutationVariables>(StripeCreateCustomerPortalSessionDocument, options);
      }
export type StripeCreateCustomerPortalSessionMutationHookResult = ReturnType<typeof useStripeCreateCustomerPortalSessionMutation>;
export type StripeCreateCustomerPortalSessionMutationResult = Apollo.MutationResult<StripeCreateCustomerPortalSessionMutation>;
export type StripeCreateCustomerPortalSessionMutationOptions = Apollo.BaseMutationOptions<StripeCreateCustomerPortalSessionMutation, StripeCreateCustomerPortalSessionMutationVariables>;
export const TeamUpdateDocument = gql`
    mutation TeamUpdate($input: TeamUpdateInput!) {
  updateTeam(input: $input) {
    ...TeamData
  }
}
    ${TeamDataFragmentDoc}`;
export type TeamUpdateMutationFn = Apollo.MutationFunction<TeamUpdateMutation, TeamUpdateMutationVariables>;

/**
 * __useTeamUpdateMutation__
 *
 * To run a mutation, you first call `useTeamUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamUpdateMutation, { data, loading, error }] = useTeamUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TeamUpdateMutation, TeamUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TeamUpdateMutation, TeamUpdateMutationVariables>(TeamUpdateDocument, options);
      }
export type TeamUpdateMutationHookResult = ReturnType<typeof useTeamUpdateMutation>;
export type TeamUpdateMutationResult = Apollo.MutationResult<TeamUpdateMutation>;
export type TeamUpdateMutationOptions = Apollo.BaseMutationOptions<TeamUpdateMutation, TeamUpdateMutationVariables>;
export const TeamDocument = gql`
    query Team($id: ID!) {
  team(id: $id) {
    ...TeamData
  }
}
    ${TeamDataFragmentDoc}`;

/**
 * __useTeamQuery__
 *
 * To run a query within a React component, call `useTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamQuery(baseOptions: Apollo.QueryHookOptions<TeamQuery, TeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamQuery, TeamQueryVariables>(TeamDocument, options);
      }
export function useTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamQuery, TeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamQuery, TeamQueryVariables>(TeamDocument, options);
        }
export type TeamQueryHookResult = ReturnType<typeof useTeamQuery>;
export type TeamLazyQueryHookResult = ReturnType<typeof useTeamLazyQuery>;
export type TeamQueryResult = Apollo.QueryResult<TeamQuery, TeamQueryVariables>;
export const CreateTeamMemberDocument = gql`
    mutation CreateTeamMember($input: CreateUserInput!) {
  createTeamMember(input: $input) {
    ...AccountUserData
  }
}
    ${AccountUserDataFragmentDoc}`;
export type CreateTeamMemberMutationFn = Apollo.MutationFunction<CreateTeamMemberMutation, CreateTeamMemberMutationVariables>;

/**
 * __useCreateTeamMemberMutation__
 *
 * To run a mutation, you first call `useCreateTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMemberMutation, { data, loading, error }] = useCreateTeamMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamMemberMutation, CreateTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamMemberMutation, CreateTeamMemberMutationVariables>(CreateTeamMemberDocument, options);
      }
export type CreateTeamMemberMutationHookResult = ReturnType<typeof useCreateTeamMemberMutation>;
export type CreateTeamMemberMutationResult = Apollo.MutationResult<CreateTeamMemberMutation>;
export type CreateTeamMemberMutationOptions = Apollo.BaseMutationOptions<CreateTeamMemberMutation, CreateTeamMemberMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreatePlainUserInput!) {
  createUser(input: $input) {
    ...AccountUserData
  }
}
    ${AccountUserDataFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateCurrentUserPollDocument = gql`
    mutation UpdateCurrentUserPoll($pollResult: Json) {
  updateCurrentUserPoll(pollResult: $pollResult) {
    id
    meta
    isOnboardingPollComplete
  }
}
    `;
export type UpdateCurrentUserPollMutationFn = Apollo.MutationFunction<UpdateCurrentUserPollMutation, UpdateCurrentUserPollMutationVariables>;

/**
 * __useUpdateCurrentUserPollMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserPollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserPollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserPollMutation, { data, loading, error }] = useUpdateCurrentUserPollMutation({
 *   variables: {
 *      pollResult: // value for 'pollResult'
 *   },
 * });
 */
export function useUpdateCurrentUserPollMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCurrentUserPollMutation, UpdateCurrentUserPollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCurrentUserPollMutation, UpdateCurrentUserPollMutationVariables>(UpdateCurrentUserPollDocument, options);
      }
export type UpdateCurrentUserPollMutationHookResult = ReturnType<typeof useUpdateCurrentUserPollMutation>;
export type UpdateCurrentUserPollMutationResult = Apollo.MutationResult<UpdateCurrentUserPollMutation>;
export type UpdateCurrentUserPollMutationOptions = Apollo.BaseMutationOptions<UpdateCurrentUserPollMutation, UpdateCurrentUserPollMutationVariables>;
export const ToggleUsersDocument = gql`
    mutation ToggleUsers($userIds: [ID!]!, $isEnabled: Boolean!) {
  toggleUsers(userIds: $userIds, isEnabled: $isEnabled)
}
    `;
export type ToggleUsersMutationFn = Apollo.MutationFunction<ToggleUsersMutation, ToggleUsersMutationVariables>;

/**
 * __useToggleUsersMutation__
 *
 * To run a mutation, you first call `useToggleUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleUsersMutation, { data, loading, error }] = useToggleUsersMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      isEnabled: // value for 'isEnabled'
 *   },
 * });
 */
export function useToggleUsersMutation(baseOptions?: Apollo.MutationHookOptions<ToggleUsersMutation, ToggleUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleUsersMutation, ToggleUsersMutationVariables>(ToggleUsersDocument, options);
      }
export type ToggleUsersMutationHookResult = ReturnType<typeof useToggleUsersMutation>;
export type ToggleUsersMutationResult = Apollo.MutationResult<ToggleUsersMutation>;
export type ToggleUsersMutationOptions = Apollo.BaseMutationOptions<ToggleUsersMutation, ToggleUsersMutationVariables>;
export const SubmitWorkDesignDocument = gql`
    mutation SubmitWorkDesign($responses: Json!, $workDesignId: ID!) {
  submitWorkDesign(responses: $responses, workDesignId: $workDesignId) {
    ...WorkDesignData
  }
}
    ${WorkDesignDataFragmentDoc}`;
export type SubmitWorkDesignMutationFn = Apollo.MutationFunction<SubmitWorkDesignMutation, SubmitWorkDesignMutationVariables>;

/**
 * __useSubmitWorkDesignMutation__
 *
 * To run a mutation, you first call `useSubmitWorkDesignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitWorkDesignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitWorkDesignMutation, { data, loading, error }] = useSubmitWorkDesignMutation({
 *   variables: {
 *      responses: // value for 'responses'
 *      workDesignId: // value for 'workDesignId'
 *   },
 * });
 */
export function useSubmitWorkDesignMutation(baseOptions?: Apollo.MutationHookOptions<SubmitWorkDesignMutation, SubmitWorkDesignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitWorkDesignMutation, SubmitWorkDesignMutationVariables>(SubmitWorkDesignDocument, options);
      }
export type SubmitWorkDesignMutationHookResult = ReturnType<typeof useSubmitWorkDesignMutation>;
export type SubmitWorkDesignMutationResult = Apollo.MutationResult<SubmitWorkDesignMutation>;
export type SubmitWorkDesignMutationOptions = Apollo.BaseMutationOptions<SubmitWorkDesignMutation, SubmitWorkDesignMutationVariables>;
export const UpdateLastLoggedInDocument = gql`
    mutation UpdateLastLoggedIn {
  updateLastLoggedIn {
    id
    lastLoginAt
  }
}
    `;
export type UpdateLastLoggedInMutationFn = Apollo.MutationFunction<UpdateLastLoggedInMutation, UpdateLastLoggedInMutationVariables>;

/**
 * __useUpdateLastLoggedInMutation__
 *
 * To run a mutation, you first call `useUpdateLastLoggedInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLastLoggedInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLastLoggedInMutation, { data, loading, error }] = useUpdateLastLoggedInMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateLastLoggedInMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLastLoggedInMutation, UpdateLastLoggedInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLastLoggedInMutation, UpdateLastLoggedInMutationVariables>(UpdateLastLoggedInDocument, options);
      }
export type UpdateLastLoggedInMutationHookResult = ReturnType<typeof useUpdateLastLoggedInMutation>;
export type UpdateLastLoggedInMutationResult = Apollo.MutationResult<UpdateLastLoggedInMutation>;
export type UpdateLastLoggedInMutationOptions = Apollo.BaseMutationOptions<UpdateLastLoggedInMutation, UpdateLastLoggedInMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    email
    roles
    createdAt
    isVerified
    isOnboardingPollComplete
    additionalPermissions
    myLatestWorkDesign {
      id
      status
      availableUntil
      framework {
        id
        endDate
      }
    }
    team {
      id
      name
    }
    profile {
      avatar
      name
      surname
      fullName
    }
    account {
      id
      isStripeCustomer
      subscriptions {
        ...SubscriptionData
      }
      integrationHRIS
      integrationCollaboration
    }
  }
}
    ${SubscriptionDataFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const CurrentAccountUsersDocument = gql`
    query CurrentAccountUsers($input: TableDataInput!) {
  currentAccountUsers(input: $input) {
    cursor
    total
    users {
      ...AccountUserData
    }
  }
}
    ${AccountUserDataFragmentDoc}`;

/**
 * __useCurrentAccountUsersQuery__
 *
 * To run a query within a React component, call `useCurrentAccountUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentAccountUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentAccountUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCurrentAccountUsersQuery(baseOptions: Apollo.QueryHookOptions<CurrentAccountUsersQuery, CurrentAccountUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentAccountUsersQuery, CurrentAccountUsersQueryVariables>(CurrentAccountUsersDocument, options);
      }
export function useCurrentAccountUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentAccountUsersQuery, CurrentAccountUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentAccountUsersQuery, CurrentAccountUsersQueryVariables>(CurrentAccountUsersDocument, options);
        }
export type CurrentAccountUsersQueryHookResult = ReturnType<typeof useCurrentAccountUsersQuery>;
export type CurrentAccountUsersLazyQueryHookResult = ReturnType<typeof useCurrentAccountUsersLazyQuery>;
export type CurrentAccountUsersQueryResult = Apollo.QueryResult<CurrentAccountUsersQuery, CurrentAccountUsersQueryVariables>;
export const AccountUsersDocument = gql`
    query AccountUsers($input: TableDataInput!, $accountId: String!) {
  accountUsers(input: $input, accountId: $accountId) {
    cursor
    total
    users {
      ...AccountUserData
    }
  }
}
    ${AccountUserDataFragmentDoc}`;

/**
 * __useAccountUsersQuery__
 *
 * To run a query within a React component, call `useAccountUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountUsersQuery(baseOptions: Apollo.QueryHookOptions<AccountUsersQuery, AccountUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountUsersQuery, AccountUsersQueryVariables>(AccountUsersDocument, options);
      }
export function useAccountUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountUsersQuery, AccountUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountUsersQuery, AccountUsersQueryVariables>(AccountUsersDocument, options);
        }
export type AccountUsersQueryHookResult = ReturnType<typeof useAccountUsersQuery>;
export type AccountUsersLazyQueryHookResult = ReturnType<typeof useAccountUsersLazyQuery>;
export type AccountUsersQueryResult = Apollo.QueryResult<AccountUsersQuery, AccountUsersQueryVariables>;
export const AccountUserDocument = gql`
    query AccountUser($id: String!) {
  accountUser(id: $id) {
    ...AccountUserData
  }
}
    ${AccountUserDataFragmentDoc}`;

/**
 * __useAccountUserQuery__
 *
 * To run a query within a React component, call `useAccountUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountUserQuery(baseOptions: Apollo.QueryHookOptions<AccountUserQuery, AccountUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountUserQuery, AccountUserQueryVariables>(AccountUserDocument, options);
      }
export function useAccountUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountUserQuery, AccountUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountUserQuery, AccountUserQueryVariables>(AccountUserDocument, options);
        }
export type AccountUserQueryHookResult = ReturnType<typeof useAccountUserQuery>;
export type AccountUserLazyQueryHookResult = ReturnType<typeof useAccountUserLazyQuery>;
export type AccountUserQueryResult = Apollo.QueryResult<AccountUserQuery, AccountUserQueryVariables>;
export const MyWorkDesignDocument = gql`
    query MyWorkDesign {
  me {
    id
    myLatestWorkDesign {
      ...WorkDesignData
    }
  }
}
    ${WorkDesignDataFragmentDoc}`;

/**
 * __useMyWorkDesignQuery__
 *
 * To run a query within a React component, call `useMyWorkDesignQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyWorkDesignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyWorkDesignQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyWorkDesignQuery(baseOptions?: Apollo.QueryHookOptions<MyWorkDesignQuery, MyWorkDesignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyWorkDesignQuery, MyWorkDesignQueryVariables>(MyWorkDesignDocument, options);
      }
export function useMyWorkDesignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyWorkDesignQuery, MyWorkDesignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyWorkDesignQuery, MyWorkDesignQueryVariables>(MyWorkDesignDocument, options);
        }
export type MyWorkDesignQueryHookResult = ReturnType<typeof useMyWorkDesignQuery>;
export type MyWorkDesignLazyQueryHookResult = ReturnType<typeof useMyWorkDesignLazyQuery>;
export type MyWorkDesignQueryResult = Apollo.QueryResult<MyWorkDesignQuery, MyWorkDesignQueryVariables>;
export const MyWorkDesignCollectionDocument = gql`
    query MyWorkDesignCollection {
  me {
    id
    workDesigns {
      ...WorkDesignData
    }
  }
}
    ${WorkDesignDataFragmentDoc}`;

/**
 * __useMyWorkDesignCollectionQuery__
 *
 * To run a query within a React component, call `useMyWorkDesignCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyWorkDesignCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyWorkDesignCollectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyWorkDesignCollectionQuery(baseOptions?: Apollo.QueryHookOptions<MyWorkDesignCollectionQuery, MyWorkDesignCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyWorkDesignCollectionQuery, MyWorkDesignCollectionQueryVariables>(MyWorkDesignCollectionDocument, options);
      }
export function useMyWorkDesignCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyWorkDesignCollectionQuery, MyWorkDesignCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyWorkDesignCollectionQuery, MyWorkDesignCollectionQueryVariables>(MyWorkDesignCollectionDocument, options);
        }
export type MyWorkDesignCollectionQueryHookResult = ReturnType<typeof useMyWorkDesignCollectionQuery>;
export type MyWorkDesignCollectionLazyQueryHookResult = ReturnType<typeof useMyWorkDesignCollectionLazyQuery>;
export type MyWorkDesignCollectionQueryResult = Apollo.QueryResult<MyWorkDesignCollectionQuery, MyWorkDesignCollectionQueryVariables>;
export const TeamMemberWorkDesignCollectionDocument = gql`
    query TeamMemberWorkDesignCollection($teamMemberId: String!) {
  userWorkDesigns(teamMemberId: $teamMemberId) {
    ...WorkDesignData
  }
}
    ${WorkDesignDataFragmentDoc}`;

/**
 * __useTeamMemberWorkDesignCollectionQuery__
 *
 * To run a query within a React component, call `useTeamMemberWorkDesignCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMemberWorkDesignCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMemberWorkDesignCollectionQuery({
 *   variables: {
 *      teamMemberId: // value for 'teamMemberId'
 *   },
 * });
 */
export function useTeamMemberWorkDesignCollectionQuery(baseOptions: Apollo.QueryHookOptions<TeamMemberWorkDesignCollectionQuery, TeamMemberWorkDesignCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamMemberWorkDesignCollectionQuery, TeamMemberWorkDesignCollectionQueryVariables>(TeamMemberWorkDesignCollectionDocument, options);
      }
export function useTeamMemberWorkDesignCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamMemberWorkDesignCollectionQuery, TeamMemberWorkDesignCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamMemberWorkDesignCollectionQuery, TeamMemberWorkDesignCollectionQueryVariables>(TeamMemberWorkDesignCollectionDocument, options);
        }
export type TeamMemberWorkDesignCollectionQueryHookResult = ReturnType<typeof useTeamMemberWorkDesignCollectionQuery>;
export type TeamMemberWorkDesignCollectionLazyQueryHookResult = ReturnType<typeof useTeamMemberWorkDesignCollectionLazyQuery>;
export type TeamMemberWorkDesignCollectionQueryResult = Apollo.QueryResult<TeamMemberWorkDesignCollectionQuery, TeamMemberWorkDesignCollectionQueryVariables>;
export const DashboardTeamMemberDocument = gql`
    query DashboardTeamMember {
  me {
    ...MeData
    myLatestWorkDesign {
      ...WorkDesignData
      rawResponses
    }
  }
}
    ${MeDataFragmentDoc}
${WorkDesignDataFragmentDoc}`;

/**
 * __useDashboardTeamMemberQuery__
 *
 * To run a query within a React component, call `useDashboardTeamMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardTeamMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardTeamMemberQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardTeamMemberQuery(baseOptions?: Apollo.QueryHookOptions<DashboardTeamMemberQuery, DashboardTeamMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardTeamMemberQuery, DashboardTeamMemberQueryVariables>(DashboardTeamMemberDocument, options);
      }
export function useDashboardTeamMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardTeamMemberQuery, DashboardTeamMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardTeamMemberQuery, DashboardTeamMemberQueryVariables>(DashboardTeamMemberDocument, options);
        }
export type DashboardTeamMemberQueryHookResult = ReturnType<typeof useDashboardTeamMemberQuery>;
export type DashboardTeamMemberLazyQueryHookResult = ReturnType<typeof useDashboardTeamMemberLazyQuery>;
export type DashboardTeamMemberQueryResult = Apollo.QueryResult<DashboardTeamMemberQuery, DashboardTeamMemberQueryVariables>;
export const TeamProfilesFieldsDocument = gql`
    query TeamProfilesFields($keys: [String!]!) {
  teamProfilesFields(keys: $keys) {
    key
    values
  }
}
    `;

/**
 * __useTeamProfilesFieldsQuery__
 *
 * To run a query within a React component, call `useTeamProfilesFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamProfilesFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamProfilesFieldsQuery({
 *   variables: {
 *      keys: // value for 'keys'
 *   },
 * });
 */
export function useTeamProfilesFieldsQuery(baseOptions: Apollo.QueryHookOptions<TeamProfilesFieldsQuery, TeamProfilesFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamProfilesFieldsQuery, TeamProfilesFieldsQueryVariables>(TeamProfilesFieldsDocument, options);
      }
export function useTeamProfilesFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamProfilesFieldsQuery, TeamProfilesFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamProfilesFieldsQuery, TeamProfilesFieldsQueryVariables>(TeamProfilesFieldsDocument, options);
        }
export type TeamProfilesFieldsQueryHookResult = ReturnType<typeof useTeamProfilesFieldsQuery>;
export type TeamProfilesFieldsLazyQueryHookResult = ReturnType<typeof useTeamProfilesFieldsLazyQuery>;
export type TeamProfilesFieldsQueryResult = Apollo.QueryResult<TeamProfilesFieldsQuery, TeamProfilesFieldsQueryVariables>;
export const UserScopesDocument = gql`
    query UserScopes {
  userScopes {
    accountId
    jobTitles
    offices
    employmentTypes
    functions
    genders
    raceEthinicities
  }
}
    `;

/**
 * __useUserScopesQuery__
 *
 * To run a query within a React component, call `useUserScopesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserScopesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserScopesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserScopesQuery(baseOptions?: Apollo.QueryHookOptions<UserScopesQuery, UserScopesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserScopesQuery, UserScopesQueryVariables>(UserScopesDocument, options);
      }
export function useUserScopesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserScopesQuery, UserScopesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserScopesQuery, UserScopesQueryVariables>(UserScopesDocument, options);
        }
export type UserScopesQueryHookResult = ReturnType<typeof useUserScopesQuery>;
export type UserScopesLazyQueryHookResult = ReturnType<typeof useUserScopesLazyQuery>;
export type UserScopesQueryResult = Apollo.QueryResult<UserScopesQuery, UserScopesQueryVariables>;
export const ReportingManagersDocument = gql`
    query ReportingManagers {
  reportingManagers {
    id
    name
  }
}
    `;

/**
 * __useReportingManagersQuery__
 *
 * To run a query within a React component, call `useReportingManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportingManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingManagersQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportingManagersQuery(baseOptions?: Apollo.QueryHookOptions<ReportingManagersQuery, ReportingManagersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportingManagersQuery, ReportingManagersQueryVariables>(ReportingManagersDocument, options);
      }
export function useReportingManagersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportingManagersQuery, ReportingManagersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportingManagersQuery, ReportingManagersQueryVariables>(ReportingManagersDocument, options);
        }
export type ReportingManagersQueryHookResult = ReturnType<typeof useReportingManagersQuery>;
export type ReportingManagersLazyQueryHookResult = ReturnType<typeof useReportingManagersLazyQuery>;
export type ReportingManagersQueryResult = Apollo.QueryResult<ReportingManagersQuery, ReportingManagersQueryVariables>;
export const InviteUsersDocument = gql`
    query InviteUsers($input: TableDataInput!) {
  inviteUsers(input: $input) {
    cursor
    total
    users {
      ...AccountUserData
    }
  }
}
    ${AccountUserDataFragmentDoc}`;

/**
 * __useInviteUsersQuery__
 *
 * To run a query within a React component, call `useInviteUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUsersQuery(baseOptions: Apollo.QueryHookOptions<InviteUsersQuery, InviteUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InviteUsersQuery, InviteUsersQueryVariables>(InviteUsersDocument, options);
      }
export function useInviteUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InviteUsersQuery, InviteUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InviteUsersQuery, InviteUsersQueryVariables>(InviteUsersDocument, options);
        }
export type InviteUsersQueryHookResult = ReturnType<typeof useInviteUsersQuery>;
export type InviteUsersLazyQueryHookResult = ReturnType<typeof useInviteUsersLazyQuery>;
export type InviteUsersQueryResult = Apollo.QueryResult<InviteUsersQuery, InviteUsersQueryVariables>;
export const WorkDesignDocument = gql`
    query WorkDesign($id: ID!) {
  workDesign(id: $id) {
    ...WorkDesignData
    rawResponses
  }
}
    ${WorkDesignDataFragmentDoc}`;

/**
 * __useWorkDesignQuery__
 *
 * To run a query within a React component, call `useWorkDesignQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkDesignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkDesignQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkDesignQuery(baseOptions: Apollo.QueryHookOptions<WorkDesignQuery, WorkDesignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkDesignQuery, WorkDesignQueryVariables>(WorkDesignDocument, options);
      }
export function useWorkDesignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkDesignQuery, WorkDesignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkDesignQuery, WorkDesignQueryVariables>(WorkDesignDocument, options);
        }
export type WorkDesignQueryHookResult = ReturnType<typeof useWorkDesignQuery>;
export type WorkDesignLazyQueryHookResult = ReturnType<typeof useWorkDesignLazyQuery>;
export type WorkDesignQueryResult = Apollo.QueryResult<WorkDesignQuery, WorkDesignQueryVariables>;
export const CalculateImpactsDocument = gql`
    query CalculateImpacts($workDesignId: ID!, $responses: Json!) {
  calculateImpacts(workDesignId: $workDesignId, responses: $responses)
}
    `;

/**
 * __useCalculateImpactsQuery__
 *
 * To run a query within a React component, call `useCalculateImpactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateImpactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateImpactsQuery({
 *   variables: {
 *      workDesignId: // value for 'workDesignId'
 *      responses: // value for 'responses'
 *   },
 * });
 */
export function useCalculateImpactsQuery(baseOptions: Apollo.QueryHookOptions<CalculateImpactsQuery, CalculateImpactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalculateImpactsQuery, CalculateImpactsQueryVariables>(CalculateImpactsDocument, options);
      }
export function useCalculateImpactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalculateImpactsQuery, CalculateImpactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalculateImpactsQuery, CalculateImpactsQueryVariables>(CalculateImpactsDocument, options);
        }
export type CalculateImpactsQueryHookResult = ReturnType<typeof useCalculateImpactsQuery>;
export type CalculateImpactsLazyQueryHookResult = ReturnType<typeof useCalculateImpactsLazyQuery>;
export type CalculateImpactsQueryResult = Apollo.QueryResult<CalculateImpactsQuery, CalculateImpactsQueryVariables>;