import React, { useMemo, useRef } from "react";
import { sum } from "lodash";
import { ChartData } from "chart.js";
import { ChartProps, Line } from "react-chartjs-2";
import { labelColors } from "../DoughnutChart/plugins";
import * as S from "./styles";
import { MenuOutlined } from "@ant-design/icons";
import { Popover, Spin } from "antd";
import { lineChartOptions } from "./options";
import { NoData } from "../NoData";
import { toPng, toJpeg } from "html-to-image";

type ILine = ChartProps<"line", number[]>;

export type LineChartProps = {
  data?: ChartData<"line">;
  options?: ILine["options"];
  fullWidth?: boolean;
  loading?: boolean;
  isOnDashboard?: boolean;
};

export const LineChart: React.FC<LineChartProps> = ({
  data,
  fullWidth,
  loading,
  isOnDashboard,
}) => {
  const myRef = useRef<any>();
  const { datasets = [] } = data || {};
  const colors = labelColors(datasets.length);
  const dataWithChartAttrs = useMemo(
    () => ({
      ...data,
      datasets: datasets.map((dataset, i) => ({
        ...dataset,
        data: dataset.data.map(
          (item, i) => Number(item) / (sum(datasets.map((d) => d.data[i])) || 1)
        ),
        lineTension: 0,
        fill: false,
        borderColor: colors[i],
        backgroundColor: colors[i],
      })),
    }),
    [data, datasets, colors]
  );

  if (loading) return <Spin />;
  const downloadGraphImage = (imagetype: any) => {
    if (imagetype === "png") {
      toPng(myRef.current, { backgroundColor: "#FFF" })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "Line-Chart.png";
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (imagetype === "jpeg") {
      toJpeg(myRef.current, { backgroundColor: "#FFF" })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "Line-Chart.jpeg";
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const content = (
    <div>
      <S.Cursor onClick={() => downloadGraphImage("jpeg")}>
        Download JPEG
      </S.Cursor>
      <S.Cursor onClick={() => downloadGraphImage("png")}>
        Download PNG
      </S.Cursor>
    </div>
  );
  const isData =
    data?.datasets?.every((dataset) => dataset.data.length) || false;
  return (
    <S.Wrap ref={myRef} isData={isData}>
      {isData ? (
        <S.ChartWrap fullWidth={fullWidth} isOnDashboard={isOnDashboard}>
          <S.optionContainer>
            <S.Hint>percentage of respondents</S.Hint>
            <Popover
              placement="bottom"
              content={content}
              overlayStyle={{ maxHeight: "100%" }}
              overlayInnerStyle={{ maxWidth: "fit-content" }}
            >
              {" "}
              <MenuOutlined />
            </Popover>
          </S.optionContainer>
          <Line data={dataWithChartAttrs} options={lineChartOptions} />
        </S.ChartWrap>
      ) : (
        <NoData />
      )}
    </S.Wrap>
  );
};
