import { Space } from "antd";
import Title from "antd/lib/typography/Title";
import { ButtonTertiary, Icon } from "components";
import Text from "antd/lib/typography/Text";
import theme from "theme";
import { VFC } from "react";
import { HeaderWrap } from "../styles";

interface ReportsHeaderProps {
  onExportButtonClick: () => void;
}

export const ReportsHeader: VFC<ReportsHeaderProps> = ({
  onExportButtonClick,
}) => (
  <HeaderWrap>
    <Title level={3}>Team Reporting</Title>
    <div />
    <Space size={10} style={{ justifySelf: "end" }}>
      {/* <ButtonTertiary
        transparent
        style={{ border: `1px solid ${theme.colors.blue_100}` }}
        icon={<Icon fontSize={19} name="visibleYellow" />}
      >
        <Text strong>Print PDF</Text>
      </ButtonTertiary> */}
      <ButtonTertiary
        onClick={onExportButtonClick}
        icon={<Icon fontSize={15} name="download" />}
      >
        <Text strong>Download XLSX</Text>
      </ButtonTertiary>
    </Space>
  </HeaderWrap>
);
