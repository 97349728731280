import React, { FC } from "react";
import { Col, Result, Row } from "antd";
import { ImgSvg } from "../../components";
import relax from "assets/icons/relax.svg";
import Text from "antd/lib/typography/Text";

type Props = {
  props?: string;
};

const PageNotFound: FC<Props> = () => {
  return (
    <Row justify="center" align="middle" style={{ height: "100vh" }}>
      <Col span={24}>
        <Result
          icon={<ImgSvg src={relax} alt={"girl"} />}
          title="404"
          subTitle={
            <Text type="secondary">
              Sorry, the page you visited does not exist.
            </Text>
          }
        />
      </Col>
    </Row>
  );
};

export default PageNotFound;
