import { ReactiveVar } from "@apollo/client";
import { UserRoleConfig } from "./constants";
import { ApplicationState, InvitationState, Me } from "./types";
import { initialApplicationState } from ".";
import { auth } from "utils/firebase";

export const setSelectedRole = (
  selectedRole: ReactiveVar<UserRoleConfig | null>
) => {
  return (state: UserRoleConfig | null) => {
    selectedRole(state);
  };
};

export const setInvitation = (
  invitationStore: ReactiveVar<InvitationState>
) => {
  return (invitation: InvitationState) => {
    invitationStore(invitation);
  };
};

export const onAuthChange = (
  applicationStore: ReactiveVar<ApplicationState>
) => {
  return (newState: ApplicationState) => {
    applicationStore(newState);
  };
};

export const refreshUser = (
  applicationStore: ReactiveVar<ApplicationState>
) => {
  return async () => {
    auth.currentUser?.reload().then(() => {
      applicationStore({
        ...applicationStore(),
        firebaseUser: auth.currentUser,
      });
    });
  };
};

export const updateProfileImage = (
  applicationStore: ReactiveVar<ApplicationState>
) => {
  return async (src: string) => {
    const user = applicationStore().user;
    const profile = user?.profile;

    if (!user || !profile) return;
    applicationStore({
      ...applicationStore(),
      user: {
        ...user,
        profile: {
          ...profile,
          avatar: src,
        },
      },
    });
  };
};

export const reset = (applicationStore: ReactiveVar<ApplicationState>) => {
  return () => {
    applicationStore({ ...initialApplicationState, isLoading: false });
  };
};
