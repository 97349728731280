import { Input, InputProps } from "antd";
import { ChangeEvent, FocusEvent, useState } from "react";
import * as S from "./MaterialInput.style";
import "./MaterialInput.style.less";

export type MaterialInputProps = InputProps & {
  color?: string;
  type?: "text" | "password";
};

export const MaterialInput: React.VFC<MaterialInputProps> = ({
  placeholder,
  prefix,
  color,
  type = "text",
  ...params
}) => {
  // We cannot rely on the styled wrapper for focused state, because input may be wrapped by antd
  // with different classes. Therefore, let's manage the state internally here
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState(params.defaultValue);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (params.onChange) params.onChange(e);
    setValue(e.target.value);
  };

  const onFocusHandler =
    (state: boolean) => (e: FocusEvent<HTMLInputElement>) => {
      if (params.onFocus) params.onFocus(e);
      setFocused(state);
    };

  const InputComponent = type === "text" ? Input : Input.Password;

  return (
    <S.MaterialTextInput
      hasValue={!!value}
      isFocused={focused}
      isPrefix={!!prefix}
    >
      <InputComponent
        onFocus={onFocusHandler(true)}
        onBlur={onFocusHandler(false)}
        {...params}
        onChange={onChangeHandler}
      />
      {prefix}
      <label>
        <span>{placeholder}</span>
      </label>
      <span className="bar" />
    </S.MaterialTextInput>
  );
};

export default MaterialInput;
