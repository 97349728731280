import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  TypePolicies,
  createHttpLink,
  FieldFunctionOptions,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { auth } from "./firebase";

export const getHeaders = async (): Promise<{
  headers: {
    authorization: string;
  };
}> => {
  const token = await auth.currentUser?.getIdToken();
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  };
};

const authLink = setContext(getHeaders);

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI || "http://localhost:4000/graphql",
});

const typePolicies: TypePolicies = {
  Feature: {
    keyFields: false,
  },
  FWDimension: {
    keyFields: false,
  },
  Query: {
    fields: {
      me: {
        merge(existing, incoming) {
          return existing;
        },
      },
      framework: (_, { args, toReference }) =>
        toReference({ __typename: "Framework", id: args?.id }),
      accountUsers: {
        keyArgs(args) {
          // When `cursor` or `take` changes, don't cache the query separately.
          const { cursor, take, ...keys } = args || {};
          return JSON.stringify(keys);
        },
        merge(existing, incoming, { args, readField }) {
          if (!existing) return incoming;

          // If pages don't match, overwrite.
          if (existing.cursor !== args?.cursor) return incoming;

          return {
            ...existing,
            cursor: incoming.cursor,
            total: incoming.total,
            users: [...(existing?.users || []), ...(incoming?.users || [])],
          };
        },
      },
    },
  },
};

export const initApollo = () => {
  const client = new ApolloClient({
    link: ApolloLink.from([authLink, httpLink]),
    cache: new InMemoryCache({ typePolicies }),
  });

  window.Cache = client.cache as any;

  return client;
};
