import React, { FC } from "react";
import * as S from "./styles";
import { Icon } from "components";
import { TagProps } from "antd/lib/tag";

type Props = TagProps & {
  label: string;
  active?: boolean;
};

export const DimensionChip: FC<Props> = ({ active, label, ...props }) => (
  <S.Chip active={active} {...props}>
    {label}
    <Icon
      name={active ? "indicatorMinus" : "indicatorPlus"}
      fontSize={24}
      style={{ marginLeft: 4 }}
    />
  </S.Chip>
);
