import React, { useEffect, useState } from "react";
import { Col, Row, Select, Space } from "antd";

import { ReactComponent as DownArrow } from "assets/icons/downArrow.svg";

import * as S from "./styles";

import { MINDSETS, MindsetInfo } from "./constants";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { Icon } from "components";
import { Framework } from "codegen/generated/graphql";
import Title from "antd/lib/typography/Title";
import { getFormattedDate } from "utils/formatters";

type Props = {
  onComplete: (mindset: {
    primary: MindsetInfo;
    secondary?: MindsetInfo;
  }) => void;
  framework?: Pick<Framework, "title" | "startDate" | "endDate">;
  currentMindset: { primary: MindsetInfo; secondary: MindsetInfo };
};

const Mindset: React.FC<Props> = ({
  onComplete,
  framework,
  currentMindset,
}) => {
  const [primary, setPrimary] = useState<MindsetInfo | undefined>(undefined);
  const [secondary, setSecondary] = useState<MindsetInfo | undefined>(
    undefined
  );

  const { sm, xs } = useBreakpoint();
  useEffect(() => {
    if (!!primary && primary === secondary) setSecondary(undefined);
    // @TODO: would be nice to refactor it & include mindset state into the rest of the form state
    if (primary === undefined && secondary === undefined) {
      setPrimary(currentMindset.primary);
      setSecondary(currentMindset.secondary);
    }
  }, [primary, secondary, currentMindset]);

  return (
    <Row gutter={[0, xs ? 8 : 18]} style={{ padding: xs ? "20px" : "40px" }}>
      <Col sm={{ span: 12 }} xs={{ span: 22 }} md={{ span: 16 }}>
        <Space size={20} direction="vertical">
          <Title level={3}>{framework?.title}</Title>
          <Space size={20} direction="vertical">
            <div>
              <span>{getFormattedDate(framework?.startDate)}</span>
              {" - "}
              <span>{getFormattedDate(framework?.endDate)}</span>
            </div>
          </Space>
          <S.Title>
            To get started, take a moment to share your work/life mindset for
            the duration of this work design.
          </S.Title>
        </Space>
      </Col>
      <Col sm={{ span: 12 }} xs={{ span: 24 }} md={{ span: 12 }}>
        <S.Description>
          Your individual choice is confidential and will only be shared in
          aggregated reporting and statistics. Additionally, your selection has
          no impact on the options you are able to pick from.
        </S.Description>
      </Col>
      <Col span={24}>
        <Row
          style={{ display: "flex", alignItems: "stretch" }}
          gutter={[20, 8]}
          wrap={!sm}
        >
          {MINDSETS.map((mindset) => (
            <Col
              key={mindset.id}
              sm={{ span: 12 }}
              xs={{ span: undefined }}
              flex={1}
              style={{ flexBasis: sm ? 0 : undefined }}
            >
              <S.Card
                key={mindset.id}
                isHighlighted={primary?.id === mindset.id}
                onClick={() => setPrimary(mindset)}
              >
                <Row>
                  <Col span={24}>
                    <Row gutter={[0, 8]}>
                      <Col span={24}>
                        <S.DirectionRow
                          justify={xs ? "start" : "center"}
                          align={"middle"}
                          gutter={[16, 16]}
                          isMobile={xs}
                        >
                          <Col>
                            <Icon
                              width="3em"
                              height="3em"
                              name={mindset.icon}
                            />
                          </Col>
                          <Col>
                            <S.Title>{mindset.id}</S.Title>
                          </Col>
                        </S.DirectionRow>
                      </Col>
                      <Col>
                        <S.MindsetDescription isMobile={xs}>
                          {mindset.description}
                        </S.MindsetDescription>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </S.Card>
            </Col>
          ))}
        </Row>
      </Col>
      <Col span={24}>
        <Row justify={"space-between"}>
          <Col xs={{ span: 24 }} md={{ span: 6 }}>
            <h4>Secondary choice (optional)</h4>
            <Select
              disabled={!primary}
              style={{ width: "100%" }}
              defaultValue="Choose one"
              value={secondary?.id}
              size={"large"}
              suffixIcon={<DownArrow />}
              onChange={(selected) =>
                setSecondary(MINDSETS.find(({ id }) => id === selected))
              }
            >
              {MINDSETS.filter(({ id }) => id !== primary?.id).map(
                (mindset) => (
                  <Select.Option key={mindset.id} value={mindset.id}>
                    {mindset.id}
                  </Select.Option>
                )
              )}
            </Select>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 4 }}>
            <S.Btn
              disabled={!primary}
              /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
              onClick={() => onComplete({ primary: primary!, secondary })}
            >
              <S.ButtonLabel>{"Let's go"}</S.ButtonLabel>
            </S.Btn>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Mindset;
