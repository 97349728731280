import { ReactElement, useMemo, useState } from "react";
import theme from "theme";
import { Input, Col, Row, notification } from "antd";
import Title from "antd/lib/typography/Title";
import Text from "antd/es/typography/Text";
import { makeUseModal, ModalProps } from "lib/useModal";
import { ReactComponent as Search } from "assets/icons/search.svg";

import { Table, Button, Icon, FrameworksStatusTag } from "components";
import { useTable, useColumns } from "hooks/table";

import {
  useAssignWorkDesignMutation,
  useFrameworksCollectionLazyQuery,
  CurrentAccountUsersDocument,
  MeDocument,
  FilterOperatorType,
} from "codegen/generated/graphql";
import { getFormattedDate } from "utils/formatters";

type PropsType = {
  id: any;
};

const InviteUserToFramework = ({
  id,
  setShow,
}: PropsType & ModalProps): ReactElement => {
  const [assignWorkDesign] = useAssignWorkDesignMutation({
    onCompleted: () => {
      notification.success({
        type: "success",
        message: "Framework has been assigned.",
        placement: "bottomLeft",
      });
      setShow(false);
    },
    onError: (e) => {
      notification.error({
        type: "error",
        message: `Could not assign framework`,
        placement: "bottomLeft",
      });
    },
    refetchQueries: [{ query: MeDocument }],
  });

  const [searchInputValue, setSearchInputValue] = useState("");

  const handleOnInvite = async () => {
    await assignWorkDesign({
      variables: {
        input: {
          frameworkIds: selected,
          userIds: [id],
        },
      },
      refetchQueries: [{ query: CurrentAccountUsersDocument }],
    });
  };

  const columns = useColumns(
    [
      { title: "Name", dataIndex: "title" },
      {
        title: "State",
        dataIndex: "status",
        // @TODO this sort doesn't work now as status is derived value
        sorter: false,
        render: (status) => {
          return <FrameworksStatusTag status={status} />;
        },
      },
      {
        title: "Start date",
        dataIndex: "startDate",
        formatter: getFormattedDate,
      },
    ],
    {
      sorter: true,
      Wrapper: ({ children }) => <Text type="secondary">{children}</Text>,
    }
  );

  const currentDate = useMemo(() => new Date(), []);

  const { tableProps, selected } = useTable(
    useFrameworksCollectionLazyQuery({
      variables: {
        filter: [
          {
            key: "endDate",
            operator: FilterOperatorType.Gt,
            valueDate: currentDate,
          },
        ],
      },
    }),
    {
      key: "frameworks",
      searchInputValue,
    }
  );

  const frameworksTable = useMemo(() => {
    return (
      <Table
        {...tableProps}
        columns={columns}
        size="small"
        rowKey={"id"}
        locale={{
          emptyText: (
            <Text type="secondary">
              It seems there are no Members who match your filters
            </Text>
          ),
        }}
      />
    );
  }, [columns, tableProps]);

  return (
    <>
      <Title level={3}>Invite to Framework</Title>

      <Row>
        <Col span={16}>
          <Input
            placeholder="Search by name..."
            size={"large"}
            suffix={<Search />}
            value={searchInputValue}
            onChange={(e) => {
              setSearchInputValue(e.target.value);
            }}
          />
        </Col>
        <Col span={7} offset={1}>
          <Button
            $width="171px"
            size="large"
            icon={<Icon name="plus" />}
            $bgColor={theme.colors.blue_100}
            onClick={handleOnInvite}
          >
            <Text strong>
              Share
              {selected.length > 0 ? ` (${selected.length})` : ""}
            </Text>
          </Button>
        </Col>
      </Row>

      {frameworksTable}
    </>
  );
};

export const useInviteUserToFrameworkDialog = makeUseModal<PropsType>(
  InviteUserToFramework,
  { width: "900px" }
);

export default useInviteUserToFrameworkDialog;
