import React, { useEffect, useMemo, useState } from "react";

import dragIcon from "assets/icons/drag.svg";

import * as S from "./styles";
import { MindsetInfo } from "../mindset/constants";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { FwDimensionFragment } from "codegen/generated/graphql";

import { Col, Row, Spin } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { map, sumBy } from "lodash";
import {
  getFormattedDate,
  formattedUsd,
  formattedDays,
} from "utils/formatters";

// @TODO - extract this hash table into some better place :)
const IMPACTS = {
  reimbursableExpense: {
    key: "reimbursableExpense",
    name: "Reimbursable expenses",
    initialValue: 0,
    formatter: formattedUsd,
  },
  baseSalary: {
    key: "baseSalary",
    name: "Base salary adjustment",
    initialValue: 0,
    formatter: formattedUsd,
  },
  bonus: {
    key: "bonus",
    name: "Bonus",
    initialValue: 0,
    formatter: formattedUsd,
  },
  taxableIncome: {
    key: "taxableIncome",
    name: "Taxable income",
    initialValue: 0,
    formatter: formattedUsd,
  },
  paidTimeOff: {
    key: "paidTimeOff",
    name: "Days off adjustment",
    initialValue: 0,
    formatter: formattedDays,
  },
};

type Props = {
  startDate?: string;
  endDate?: string;
  primaryMindset?: any;
  secondaryMindset?: MindsetInfo;
  title?: string | null;
  dimensions: FwDimensionFragment[];
  completedFeatureIds: string[];
  calculatedImpacts: Record<string, { sum: number; key: string }>;
  isCalculationInProgress: boolean;
};

type DimensionsProgress = {
  dimensionName: string;
  totalFeaturesCount: number;
  completedFeaturesCount: number;
};

const getDimensionsProgress = (
  dimensions: FwDimensionFragment[],
  completedFeatureIds: string[]
): DimensionsProgress[] =>
  map(dimensions, ({ features, name }) => ({
    dimensionName: name,
    totalFeaturesCount: features.length,
    completedFeaturesCount: sumBy(completedFeatureIds, (featureId) =>
      features.some((feature) => feature.id === featureId) ? 1 : 0
    ),
  }));

const FrameworkOverview: React.FC<Props> = ({
  primaryMindset,
  secondaryMindset,
  title,
  startDate,
  endDate,
  dimensions,
  completedFeatureIds,
  isCalculationInProgress,
  calculatedImpacts,
}) => {
  const { sm: isDesktop } = useBreakpoint();
  const [showFrameworkOverview, setShowFrameworkOverview] = useState<boolean>();

  useEffect(() => {
    // default value of isDesktop is undefined on first render, so we need to update
    if (isDesktop === undefined) return;
    setShowFrameworkOverview(isDesktop);
  }, [isDesktop]);

  const dimensionsProgress = useMemo(
    () => getDimensionsProgress(dimensions, completedFeatureIds),
    [dimensions, completedFeatureIds]
  );

  return (
    <S.Container isDesktop={isDesktop}>
      {!isDesktop && (
        <S.Handle onClick={() => setShowFrameworkOverview((v) => !v)}>
          <S.HandleIcon src={dragIcon} />
        </S.Handle>
      )}
      {showFrameworkOverview && (
        <S.Wrapper isDesktop={isDesktop}>
          <S.Name level={3}>{title}</S.Name>
          <S.Row>
            <S.Label>Start date</S.Label>
            {startDate && <S.Label bold>{getFormattedDate(startDate)}</S.Label>}
          </S.Row>
          <S.Row>
            <S.Label>End date</S.Label>
            {endDate && <S.Label bold>{getFormattedDate(endDate)}</S.Label>}
          </S.Row>
          <Row gutter={[0, 12]}>
            {dimensionsProgress.map(
              ({
                completedFeaturesCount,
                totalFeaturesCount,
                dimensionName,
              }) => {
                const isCompleted =
                  completedFeaturesCount === totalFeaturesCount;
                return (
                  <Col span={24}>
                    <S.FrameworkProgressTag
                      isCompleted={isCompleted}
                      key={dimensionName}
                    >
                      {`${dimensionName} (${completedFeaturesCount}/${totalFeaturesCount})`}
                      {isCompleted ? (
                        <CheckCircleFilled style={{ marginLeft: "10px" }} />
                      ) : null}
                    </S.FrameworkProgressTag>
                  </Col>
                );
              }
            )}
          </Row>
          <S.Divider />
          <S.Row>
            <S.Label>Primary mindset</S.Label>
            <S.Label bold>{primaryMindset?.id}</S.Label>
          </S.Row>
          {secondaryMindset && (
            <S.Row>
              <S.Label>Secondary mindset</S.Label>
              <S.Label bold>{secondaryMindset?.id}</S.Label>
            </S.Row>
          )}
          <S.Divider />

          <Spin tip="Calculating..." spinning={isCalculationInProgress}>
            {Object.values(IMPACTS).map(({ name, key, formatter }) => {
              return (
                calculatedImpacts[key] && (
                  <S.Row>
                    <S.Label>{name}</S.Label>
                    <S.Label bold>
                      {formatter(calculatedImpacts[key].sum)}
                    </S.Label>
                  </S.Row>
                )
              );
            })}
          </Spin>
        </S.Wrapper>
      )}
    </S.Container>
  );
};

export default FrameworkOverview;
