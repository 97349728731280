import React from "react";
import * as S from "./styles";

type Props = {
  src: string;
  alt: string;
  style?: React.CSSProperties;
};

export const ImgSvg: React.FC<Props> = ({ src, alt, style }) => {
  return <S.Img src={src} alt={alt} style={style} />;
};
