import { UserRole } from "codegen/generated/graphql";
import { createContext } from "react";

type PermissionContextType = {
  isAllowed: (roles: UserRole[]) => boolean;
};

// Default behaviour for the Permission Provider Context
// i.e. if for whatever reason the consumer is used outside of a provider
// The permission will not be granted if no provider says otherwise
const defaultBehaviour: PermissionContextType = {
  isAllowed: () => false,
};

// Create the context
const PermissionContext =
  createContext<PermissionContextType>(defaultBehaviour);

export default PermissionContext;
