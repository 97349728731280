import React from "react";
import theme from "../../theme";
import { TagAttributes } from "../../common/types";
import { useTagAttributes } from "../../utils/hooks";
import { CommonTag } from "./commonTag";

import { WdStatus } from "codegen/generated/graphql";

const attributesMap = new Map<WdStatus, TagAttributes>([
  [WdStatus.Active, { text: "Active", bgColor: theme.colors.green_40 }],
  [WdStatus.Pending, { text: "Pending", bgColor: theme.colors.red_40 }],
  [WdStatus.Ready, { text: "Ready", bgColor: theme.colors.yellow_40 }],
  [WdStatus.Ended, { text: "Ended", bgColor: theme.colors.grey_70 }],
]);

type Props = {
  status: WdStatus;
};

export const WorkDesignStatusTag: React.FC<Props> = (props) => {
  const { status } = props;

  const attributes = useTagAttributes(attributesMap, status);

  return <CommonTag tagAttributes={attributes} />;
};
