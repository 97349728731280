import React, { FC } from "react";

import * as S from "./styles";
import { useHistory } from "react-router-dom";
import { Routes } from "router/routes";

type Props = {
  tabs: string[];
  activeTab?: string;
  onTabPress?: (tabName: string) => void;
};

const Header: FC<Props> = ({ tabs, activeTab, onTabPress }) => {
  const history = useHistory();
  return (
    <S.Wrapper>
      <S.LogoImage light onClick={() => history.push(Routes.DASHBOARD.path)} />
      <S.Row>
        {tabs.map((tab) => {
          const isActive = tab === activeTab;
          return (
            <S.Tab key={tab} onClick={() => onTabPress?.(tab)}>
              <S.TabLabel isActive={isActive}>{tab}</S.TabLabel>
              {isActive && <S.Highlight />}
            </S.Tab>
          );
        })}
      </S.Row>
    </S.Wrapper>
  );
};

export default Header;
