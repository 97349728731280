import styled from "styled-components";
import { Menu } from "antd";

export const Wrap = styled.div`
  padding: 2.2rem 3rem;
  overflow: auto;
  height: 100%;
`;

export const UIRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const UICol = styled.span`
  width: 48%;
`;

export const SubHeader = styled.header`
  width: 100%;
  border-bottom: 1px solid #eff1f4;
  margin: 0 0 1rem 1rem;
  & > h3 {
    color: ${({ theme }) => theme.colors.blue_100};
  }
`;

export const LayoutSubmenu = styled(Menu)`
  list-style: none;
  &.ant-menu > div > li.ant-menu-item-selected {
    background-color: transparent;
  }
  &.ant-menu > div > li.ant-menu-item-selected > span > a {
    color: ${({ theme }) => theme.colors.blue_100};
  }
  &.ant-menu > div > li.ant-menu-item-active > span > a {
    color: ${({ theme }) => theme.colors.blue_100};
  }
  &.ant-menu > div > li.ant-menu-item > span > a {
    color: ${({ theme }) => theme.colors.grey_70};
  }
  border-radius: ${({ theme }) => theme.radius.commonRadius};
  height: 78.5vh;
  margin-left: 0.7rem;
  padding: 3rem 1rem;
  background: radial-gradient(
    96.28% 274.83% at 6.01% 5.14%,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
`;

export const MenuUnderline = styled.div`
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #eff1f4;
`;
