import React, { useCallback, useState } from "react";
import {
  FrameworkCollectionDataFragment,
  FrameworksCollectionDocument,
  FrameworksCollectionQuery,
  FrameworkStatus,
  useDeleteFrameworkMutationMutation,
} from "codegen/generated/graphql";
import { Button, Dropdown, Menu, notification, Space, Tooltip } from "antd";
import { useInviteToFrameworkDialog } from "pages/frameworks/collection/InviteToFrameworkDialog.page";
import Text from "antd/lib/typography/Text";
import { ListRowContainer } from "components/ListRowContainer";
import { FrameworksStatusTag } from "components";
import { GhostButton } from "pages/frameworks/collection/Collection.styles";
import { EllipsisOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import theme from "theme";
import { generatePath, useHistory } from "react-router";
import { Routes } from "router/routes";

import * as S from "./Section.styles";

type InviteButtonProps = {
  status: FrameworkStatus;
  clickedId: string;
};

type Props = {
  title: string | undefined;
  children: FrameworkCollectionDataFragment[];
};

export const FrameworksSection: React.FC<Props> = (props) => {
  const { title, children } = props;

  const history = useHistory();

  const [deleteFramework] = useDeleteFrameworkMutationMutation({
    onError: () =>
      notification.error({
        type: "error",
        message: "We weren't able to delete your framework.",
        placement: "bottomLeft",
      }),
    update: (cache, result) => {
      const queryResult = cache.readQuery<FrameworksCollectionQuery>({
        query: FrameworksCollectionDocument,
      });
      const deletedId = result?.data?.deleteFramework?.id;
      const originalData = queryResult?.frameworks ?? [];
      if (!deletedId || !originalData.length) return;
      cache.writeQuery<FrameworksCollectionQuery>({
        query: FrameworksCollectionDocument,
        data: {
          ...queryResult,
          frameworks: originalData.filter(({ id }) => id !== deletedId),
        },
      });
    },
  });

  const handlePreview = (id: string) => (e: any) => {
    e.stopPropagation();
    history.push(generatePath(Routes.FRAMEWORK_PREVIEW.path, { id }));
  };
  const handleDelete = useCallback(
    (id: string) => {
      const deleteConfirmed = window.confirm(
        "Are you sure you want to delete this framework?"
      );
      if (deleteConfirmed) return deleteFramework({ variables: { id } });
    },
    [deleteFramework]
  );
  const handleEdit = useCallback(
    (id: string) => {
      history.push(generatePath(Routes.FRAMEWORKS_EDIT.path, { id }));
    },
    [history]
  );

  const [InviteDialog, show] = useInviteToFrameworkDialog();

  const InviteActionButton = ({ status, clickedId }: InviteButtonProps) =>
    ([
      FrameworkStatus.Active,
      FrameworkStatus.Created,
      FrameworkStatus.Shared,
    ].includes(status) ? (
      <Tooltip placement="bottom" title="Invite users">
        <GhostButton
          type="ghost"
          icon={<UsergroupAddOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            show({ id: clickedId });
          }}
          style={{
            minHeight: 40,
            minWidth: 40,
          }}
        />
      </Tooltip>
    ) : (
      <GhostButton
        type="ghost"
        icon={<UsergroupAddOutlined />}
        onClick={(e) => {
          e.stopPropagation();
          show({ id: clickedId });
        }}
        style={{
          minHeight: 40,
          minWidth: 40,
          visibility: "hidden",
        }}
      />
    )) || null;

  const RowMenu = (status: FrameworkStatus, id: string) => {
    const menuItems = getMenu(status, id);

    if (!menuItems || !menuItems.length) return <></>;

    return (
      <Menu onClick={(e) => e.domEvent.stopPropagation()}>
        {getMenu(status, id)}
      </Menu>
    );
  };

  const getMenu = useCallback(
    (status: FrameworkStatus, id: string) => {
      const DeleteAction = (
        <Menu.Item key="delete" danger onClick={() => handleDelete(id)}>
          Delete
        </Menu.Item>
      );

      const EditAction = (
        <Menu.Item key="edit" onClick={() => handleEdit(id)}>
          Edit
        </Menu.Item>
      );

      switch (status) {
        case FrameworkStatus.Active:
        case FrameworkStatus.Shared:
          return [];
        case FrameworkStatus.Created:
          return [EditAction, DeleteAction];
        case FrameworkStatus.Ended:
          return [DeleteAction];
        default:
          return [DeleteAction];
      }
    },
    [handleDelete, handleEdit]
  );

  if (!children.length) return null;

  return (
    <S.Wrapper>
      <Text strong>{`${title} (${children.length})`}</Text>
      <Space
        style={{ display: "flex", flex: 1, marginTop: 10 }}
        direction="vertical"
        size={10}
      >
        <InviteDialog id="" />
        {children.map(({ id, title, status, startDate, endDate }) => (
          <ListRowContainer key={id}>
            <S.Row onClick={handlePreview(id)}>
              <Text strong ellipsis style={{ width: "100%" }}>
                {title}
              </Text>
              <FrameworksStatusTag status={status} />
              <Text
                style={{
                  width: "50%",
                  paddingLeft: "22px",
                  paddingRight: "22px",
                }}
              >
                {new Date(startDate).toLocaleDateString()} -{" "}
                {new Date(endDate).toLocaleDateString()}
              </Text>
              <Space>
                <InviteActionButton status={status} clickedId={id} />
                {!!getMenu(status, id).length ? (
                  <Dropdown overlay={RowMenu(status, id)} trigger={["click"]}>
                    <Button
                      onClick={(e) => e.stopPropagation()}
                      type="link"
                      icon={
                        <EllipsisOutlined
                          rotate={90}
                          color={theme.colors.blue_100}
                        />
                      }
                    />
                  </Dropdown>
                ) : (
                  <Space style={{ visibility: "hidden" }}>
                    <Dropdown
                      overlay={RowMenu(status, id)}
                      trigger={["click"]}
                      visible={false}
                    >
                      <Button
                        onClick={(e) => e.stopPropagation()}
                        type="link"
                        icon={
                          <EllipsisOutlined
                            rotate={90}
                            color={theme.colors.blue_100}
                          />
                        }
                      />
                    </Dropdown>
                  </Space>
                )}
              </Space>
            </S.Row>
          </ListRowContainer>
        ))}
      </Space>
    </S.Wrapper>
  );
};
