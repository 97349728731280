import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
  background: ${(props) => props.theme.colors.white};
  padding: 20px 0;
`;

export const WrapRelative = styled.div`
  margin: 0 20px;
  position: relative;
`;
