import React, { createContext, useContext, useMemo, useState } from "react";
import { PollStep } from "../../common/constants";
import { noop } from "lodash";

export type PollContextType = {
  isPollModalVisible: boolean;
  setPollModalVisibility: (isVisible: boolean) => void;
  currentPollStep: PollStep | undefined;
  setCurrentPollStep: (step: PollStep | undefined) => void;
};

export const PollContext = createContext<PollContextType>({
  isPollModalVisible: false,
  setPollModalVisibility: noop,
  currentPollStep: PollStep.MANAGER_STEP_ONE,
  setCurrentPollStep: noop,
});

export const PollContextProvider: React.FC = ({ children }) => {
  const [isPollModalVisible, setIsPollModalVisible] = useState(false);
  const [currentPollStep, setCurrentPollStep] = useState<PollStep>();

  const contextValue = useMemo<PollContextType>(
    () => ({
      isPollModalVisible,
      setPollModalVisibility: setIsPollModalVisible,
      currentPollStep,
      setCurrentPollStep,
    }),

    [isPollModalVisible, currentPollStep]
  );

  return (
    <PollContext.Provider value={contextValue}>{children}</PollContext.Provider>
  );
};

export const usePollContext = () => useContext(PollContext);
