import { Row, Col, Space, Collapse, RadioChangeEvent } from "antd";
import * as S from "./Subscribe.styles";
import Button from "components/Button";
import Input from "components/Input";
import { useReactiveVar } from "@apollo/client";
import { selectedPlanVar, planMutations, amountOfSeatsVar } from "store/plan";
import { useHistory } from "react-router";
import { Routes } from "router/routes";
import { history } from "router/history";
import { useAuthContext } from "utils/context/auth";
import { RadioGroup } from "components";

const { Panel } = Collapse;

export const withCommas = (num: string) =>
  num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const PlanSummary = () => {
  const { push: localPush } = useHistory();
  const { user } = useAuthContext();

  const planTuple = useReactiveVar(selectedPlanVar);
  const amountOfSeats = useReactiveVar(amountOfSeatsVar);
  const { updatePlan, updateAmountOfSeats } = planMutations;

  if (!planTuple) return null;

  const [plan, priceIndex] = planTuple;

  const options = plan.pricing.map(({ label }, i: number) => ({
    value: i,
    label,
  }));

  const pricePerMonth =
    plan.pricing[priceIndex].price / plan.pricing[priceIndex].months;
  const totalPrice = (amountOfSeats * pricePerMonth) / 5;

  const onFrequencyChange = (e: RadioChangeEvent) => {
    updatePlan(plan, e.target.value);
  };

  const onContinueHandler = async () => {
    if (!user) return history.push(Routes.SIGN_UP.path);
    else localPush("payment");
  };

  return (
    <Row gutter={24}>
      <Col span={12} style={{ display: "flex" }}>
        <S.FancyCard>
          <Space direction="vertical" size="large">
            <Row align="middle" justify="space-between">
              <Col>
                <S.Title level={3}>{plan.label}</S.Title>
              </Col>
              <Col>
                <Row gutter={6} align="middle">
                  <Col>
                    <S.Text style={{ fontSize: 15 }}>Starting at</S.Text>
                  </Col>
                  <Col>
                    <S.TextTitle strong>
                      {/* TODO: figure out how should the price be rounded */}$
                      {pricePerMonth.toFixed(0)}/mo for 5 users
                    </S.TextTitle>
                  </Col>
                </Row>
              </Col>
            </Row>
            <S.TextTitle strong>
              Boost your subscription with add on services available at any
              time.
            </S.TextTitle>
            <S.Paragraph>{plan.summary}</S.Paragraph>
          </Space>
          <S.Collapse ghost defaultActiveKey={["1"]}>
            {plan.details.map((detail, i) => (
              <Panel
                style={{ padding: "16px 0" }}
                header={detail.label}
                key={i + 1}
              >
                <Space direction="vertical" size="small">
                  <S.Text>{detail.description}</S.Text>
                  {i === 0 && (
                    <S.TextTitle style={{ fontSize: 15 }} strong>
                      Pricing available upon request
                    </S.TextTitle>
                  )}
                </Space>
              </Panel>
            ))}
          </S.Collapse>
          <S.TextTitle strong>
            Reach out to connect with an expert work designer, and discuss your
            needs
          </S.TextTitle>
          <S.Paragraph style={{ marginTop: 23, fontSize: 15 }}>
            Contact us at{" "}
            <a href="mailto: howdy@hellolunna.com">howdy@hellolunna.com</a>
          </S.Paragraph>
        </S.FancyCard>
      </Col>
      <Col span={12} style={{ display: "flex" }}>
        <S.FancyCard>
          <Space direction="vertical" size="middle">
            <S.TextTitle strong>How big is your team?</S.TextTitle>
            <Row>
              <Col span={8}>
                <Input
                  label="Number of Seats"
                  value={String(amountOfSeats)}
                  onChange={(e) => updateAmountOfSeats(Number(e.target.value))}
                  type="number"
                  name="seats"
                  style={{ marginBottom: "9px" }}
                  step={5}
                  min={5}
                />
              </Col>
            </Row>
          </Space>
          <S.Paragraph style={{ marginBottom: "22px" }}>
            Plans are billed in 5 seat increments
          </S.Paragraph>
          <S.TextTitle style={{ marginBottom: "10px" }} strong>
            Billing Cycle
          </S.TextTitle>
          <RadioGroup
            style={{ marginBottom: "33px" }}
            optionType="default"
            options={options}
            defaultValue={priceIndex}
            onChange={onFrequencyChange}
          />
          <S.TextTitle style={{ marginBottom: "22px" }} strong>
            Plan Cost
          </S.TextTitle>

          <S.LargeText strong>7 days free</S.LargeText>
          {/* TODO: figure out how should the price be rounded */}
          <S.Paragraph>
            Then ${withCommas(totalPrice.toFixed(0))} per month
          </S.Paragraph>

          <S.ButtonWrap>
            <Space direction="vertical" size="middle">
              <S.TextTitle strong>7-day free trial on all plans</S.TextTitle>
              <Button
                type="primary"
                $bgColor="#01083A"
                $hoverBgColor="#01083A"
                size="large"
                $height="48px"
                $width="100%"
                onClick={onContinueHandler}
              >
                Continue
              </Button>
            </Space>
          </S.ButtonWrap>
        </S.FancyCard>
      </Col>
    </Row>
  );
};
