import styled from "styled-components";
import { Button, Progress } from "antd";

export const Wrapper = styled.div<{ isMobile: boolean }>`
  float: bottom;
  min-height: fit-content;
  display: flex;
  flex-direction: row;
  padding: ${({ isMobile }) => (isMobile ? 0 : "24px 15px 20px")};
  align-items: flex-end;
  background: ${({ theme }) => theme.colors.blue_10};
`;

export const NavBtn = styled(Button)`
  background: #ffffff;
  border: 1px solid #c3cafe;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 16px 25px;
  margin-left: 12px;
  height: 35px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconNext = styled.img`
  width: 10px;
`;

export const IconPrev = styled(IconNext)`
  transform: rotate(180deg);
`;

export const ProgressLabel = styled.span`
  color: ${({ theme }) => theme.colors.grey};
  font-size: 15px;
`;

export const ProgressWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 26px;
`;

export const ProgressBar = styled(Progress).attrs(({ theme }) => ({
  strokeWidth: 5,
  strokeColor: theme.colors.gold_60,
  trailColor: theme.colors.gold_20,
}))`
  height: 5px;
  line-height: 5px;
  margin-top: 8px;
`;
