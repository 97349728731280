import React from "react";
import skyBoySvg from "assets/images/signUp.svg";
import { BackgroundContainer } from "components/BackgroundContainer";
import { SignUpSuccess as SignUpSuccessComponent } from "components/SignUpSuccess";

import { ContainerWithImage } from "components/ContainerWithImage";

export const SignUpSuccess: React.FC = () => {
  return (
    <BackgroundContainer>
      <ContainerWithImage image={{ src: skyBoySvg, alt: "sky-boy" }}>
        <SignUpSuccessComponent />
      </ContainerWithImage>
    </BackgroundContainer>
  );
};

export default SignUpSuccess;
