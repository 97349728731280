import React from "react";
import { Col, Row } from "antd";
import Title from "antd/es/typography/Title";
import { ModalStepsNavigation } from "../../common/types";
import { ButtonPrimary } from "../index";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

type Props = ModalStepsNavigation & {
  children?: never;
};

export const Congratulations: React.FC<Props> = (props) => {
  const { onNextStepClick } = props;
  const { xs } = useBreakpoint();

  return (
    <Row justify="center">
      <Col span={15} style={{ marginTop: xs ? "0" : "238px" }}>
        <Row justify="center" gutter={[0, 30]}>
          <Col span={24}>
            <Title level={xs ? 3 : 1} style={{ textAlign: "center" }}>
              Congratulations
            </Title>
          </Col>
          <Col span={24}>
            <Title
              type="secondary"
              level={xs ? 4 : 3}
              style={{ textAlign: "center" }}
            >
              You are taking steps to help more humans experience work designed
              for life.
              <br />
              For that, we thank you.
            </Title>
          </Col>
          <Col span={xs ? 24 : 15}>
            <ButtonPrimary onClick={onNextStepClick} htmlType="submit">
              Go to Dashboard
            </ButtonPrimary>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
