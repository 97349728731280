import React from "react";
import { Col, Row, Form } from "antd";
import Title from "antd/es/typography/Title";
import { ModalStepsNavigation } from "../../common/types";
import { CheckboxWithBackground } from "../CheckboxWithBackground";
import { PollFooter } from "../index";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const questionnaireOptions = [
  { value: "1", label: "Team culture definition" },
  { value: "2", label: "Cash compensation" },
  { value: "3", label: "Work style" },
  { value: "4", label: "Paid time off" },
  { value: "5", label: "Family benefits" },
  { value: "6", label: "Professional development" },
  { value: "7", label: "Perks" },
  { value: "8", label: "Flexible schedule design" },
  { value: "9", label: "Health & wellness" },
  { value: "10", label: "Employee ownership/equity" },
];

const formFieldName = "employeeGoals";

type Props = ModalStepsNavigation & {
  children?: never;
};

export const EmployeePollStepTwo: React.FC<Props> = (props) => {
  const { onNextStepClick, onSkipStepClick, onBackStepClick } = props;
  const { xs } = useBreakpoint();

  return (
    <Form.Item noStyle>
      <Row justify="center">
        <Col style={{ marginTop: xs ? "60px" : "130px" }}>
          <Row justify="space-between" gutter={[0, xs ? 11 : 20]}>
            <Col span={24}>
              <Title level={xs ? 2 : 1} style={{ textAlign: "center" }}>
                Let’s Us Get to Know You Better
              </Title>
            </Col>
            <Col span={24}>
              <Row gutter={[0, 50]}>
                <Col span={24}>
                  <Title
                    type="secondary"
                    level={xs ? 4 : 3}
                    style={{ textAlign: "center" }}
                  >
                    Pick up to five areas you wish you had more personalization:
                  </Title>
                </Col>
                <Col span={24}>
                  <Row gutter={[0, xs ? 24 : 80]}>
                    <Col offset={4} span={16}>
                      <CheckboxWithBackground
                        options={questionnaireOptions}
                        hasTwoColumnLayout={!xs}
                        formFieldName={formFieldName}
                      />
                    </Col>
                    <Col span={24}>
                      <PollFooter
                        backButtonConfig={{
                          label: "Back",
                          onClick: onBackStepClick,
                        }}
                        nextButtonConfig={{
                          label: "2/2 Next",
                          onClick: onNextStepClick,
                        }}
                        skipButtonConfig={{
                          label: "Skip",
                          onClick: onSkipStepClick,
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Item>
  );
};
