import React from "react";
import { Modal } from "./modal";
import { BackgroundContainer } from "components";
import { ImgSvg } from "../ImgSvg";
import llunas from "../../assets/images/llunas.svg";
import { PollForm } from "../Forms/PollForm";
import { usePollContext } from "utils/context";

export const PollModal: React.FC = () => {
  const { isPollModalVisible } = usePollContext();

  return (
    <Modal visible={isPollModalVisible} footer={null}>
      <BackgroundContainer
        decorationElement={
          <ImgSvg
            src={llunas}
            alt="llunas"
            style={{
              position: "absolute",
              width: "40%",
              height: "30%",
              right: 0,
              top: -40,
            }}
          />
        }
      >
        <PollForm isInModal />
      </BackgroundContainer>
    </Modal>
  );
};
