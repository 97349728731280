import { Form } from "antd";
import React from "react";
import { useUpdateCurrentUserPollMutation } from "codegen/generated/graphql";
import { usePollContext } from "utils/context";
import { PollStep } from "common/constants";
import {
  Congratulations,
  EmployeePollStepOne,
  EmployeePollStepTwo,
  ManagerPollStepOne,
  ManagerPollStepTwo,
} from "../ModalContent";
import { useHistory } from "react-router-dom";
import { Routes } from "router/routes";

type Props = {
  isInModal: boolean;
};

export const PollForm: React.FC<Props> = ({ isInModal }) => {
  const history = useHistory();

  const [updatePoll] = useUpdateCurrentUserPollMutation({
    onCompleted: () => {
      !isInModal && history.push(Routes.DASHBOARD.path);
    },
  });

  const { setPollModalVisibility, setCurrentPollStep, currentPollStep } =
    usePollContext();

  const [form] = Form.useForm();

  const handleSkipClick = React.useCallback(() => {
    isInModal && setPollModalVisibility(false);
    form.submit();
  }, [isInModal, setPollModalVisibility, form]);

  const stepsMap = React.useMemo(
    () =>
      new Map<PollStep, React.ReactNode>([
        [
          PollStep.MANAGER_STEP_ONE,
          <ManagerPollStepOne
            onNextStepClick={() => {
              setCurrentPollStep(PollStep.MANAGER_STEP_TWO);
            }}
            onSkipStepClick={handleSkipClick}
          />,
        ],
        [
          PollStep.MANAGER_STEP_TWO,
          <ManagerPollStepTwo
            onNextStepClick={() => {
              setCurrentPollStep(PollStep.MANAGER_STEP_THREE);
            }}
            onBackStepClick={() => {
              setCurrentPollStep(PollStep.MANAGER_STEP_ONE);
            }}
            onSkipStepClick={handleSkipClick}
          />,
        ],
        [
          PollStep.MANAGER_STEP_THREE,
          <Congratulations
            onNextStepClick={() => {
              setPollModalVisibility(false);
            }}
          />,
        ],
        [
          PollStep.EMPLOYEE_STEP_ONE,
          <EmployeePollStepOne
            onNextStepClick={() => {
              setCurrentPollStep(PollStep.EMPLOYEE_STEP_TWO);
            }}
            onSkipStepClick={handleSkipClick}
          />,
        ],
        [
          PollStep.EMPLOYEE_STEP_TWO,
          <EmployeePollStepTwo
            onNextStepClick={() => {
              setCurrentPollStep(PollStep.EMPLOYEE_STEP_THREE);
            }}
            onBackStepClick={() => {
              setCurrentPollStep(PollStep.EMPLOYEE_STEP_ONE);
            }}
            onSkipStepClick={handleSkipClick}
          />,
        ],
        [
          PollStep.EMPLOYEE_STEP_THREE,
          <Congratulations
            onNextStepClick={() => setPollModalVisibility(false)}
          />,
        ],
      ]),
    [setPollModalVisibility, setCurrentPollStep, handleSkipClick]
  );

  return (
    <Form
      form={form}
      onFinish={(value) => updatePoll({ variables: { pollResult: value } })}
      autoComplete="off"
    >
      <Form.List name="pollResults">
        {() => (currentPollStep ? stepsMap.get(currentPollStep) : null)}
      </Form.List>
    </Form>
  );
};
