import { FC } from "react";

import { useDashboardTeamMemberQuery } from "codegen/generated/graphql";
import ContentLayout from "layout/ContentLayout";
import { ContentType } from "layout/ContentLayout/ContentLayout";
import { EmployeeWorkDesign } from "../components/EmployeeWorkDesign";
import { Col, Row } from "antd";
import { Carousel, ActionBanner } from "components";

import * as S from "./styles";
import { NoWorkDesigns } from "components/WorkDesignTable/styles";
import { EmployeeMindset } from "../components/EmployeeMindset";
import { usePoll } from "utils/hooks";

type Props = {
  xs?: boolean;
};
export const Dashboard: FC<Props> = ({ xs }) => {
  usePoll();
  const { data, loading } = useDashboardTeamMemberQuery();

  if (!data) return null;

  const workDesignWithResponses = data?.me?.myLatestWorkDesign;

  if (!workDesignWithResponses && xs)
    return <NoWorkDesigns>There is no Work Design to complete</NoWorkDesigns>;

  return (
    <ContentLayout typeContent={ContentType.FullWidth}>
      <S.ContentWrap>
        <Row style={{ display: "flex", flex: 1 }} gutter={[15, 10]}>
          <Col span={16}>
            <ActionBanner meData={data?.me} isLoading={loading} />
          </Col>
          <Col span={8}>
            <Carousel />
          </Col>
        </Row>
        {!workDesignWithResponses && xs ? (
          <NoWorkDesigns>There is no Work Design to complete</NoWorkDesigns>
        ) : (
          <S.Row flex={2}>
            <EmployeeWorkDesign
              workDesign={data?.me?.myLatestWorkDesign}
              responses={data?.me?.myLatestWorkDesign?.rawResponses}
            />
            <S.Spacer />
            <EmployeeMindset />
          </S.Row>
        )}
      </S.ContentWrap>
    </ContentLayout>
  );
};
