import React from "react";
import pinkWaveSvg from "assets/images/pinkWave.svg";
import { BackgroundContainer } from "components/BackgroundContainer";
import { Col, Row } from "antd";
import { ImgSvg } from "../../components/ImgSvg";
import { RequestInvitationForm } from "../../components/Forms";

const RequestInvitation: React.FC = () => {
  return (
    <BackgroundContainer>
      <Row>
        {/* Left column */}
        <Col
          offset={2}
          span={8}
          style={{ padding: "0 23px", marginTop: "210px" }}
        >
          <RequestInvitationForm />
        </Col>
        {/* Right column */}
        <Col offset={2} span={12}>
          <Row justify="end">
            <Col>
              <ImgSvg src={pinkWaveSvg} alt="pinkWaveSvg" />
            </Col>
          </Row>
        </Col>
      </Row>
    </BackgroundContainer>
  );
};

export default RequestInvitation;
