import React from "react";
import { Col, Row } from "antd";
import { Icon } from "../index";
import { ButtonTertiary } from "components";
import Text from "antd/lib/typography/Text";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

type ButtonConfig = {
  label: string;
  onClick: (() => void) | undefined;
};

export type Props = {
  backButtonConfig?: ButtonConfig;
  nextButtonConfig?: ButtonConfig;
  skipButtonConfig?: ButtonConfig;
};

export const PollFooter: React.FC<Props> = (props) => {
  const { backButtonConfig, nextButtonConfig, skipButtonConfig } = props;
  const { sm, xs } = useBreakpoint();

  return (
    <Row style={{ position: "relative" }}>
      <Col span={24}>
        <Row justify="center" gutter={[16, 0]}>
          {nextButtonConfig && (
            <Col span={xs ? 24 : undefined}>
              <ButtonTertiary
                htmlType="submit"
                onClick={nextButtonConfig.onClick}
                style={{ width: !sm ? "100%" : "162px" }}
              >
                <Text strong>{nextButtonConfig.label}</Text>
              </ButtonTertiary>
            </Col>
          )}
          {skipButtonConfig && (
            <Col span={xs ? 24 : undefined}>
              <ButtonTertiary
                outlined
                transparent
                style={{ width: !sm ? "100%" : "162px" }}
                icon={<Icon name="close" style={{ fontSize: "15px" }} />}
                onClick={skipButtonConfig.onClick}
                htmlType="submit"
              >
                <Text strong style={{ textAlign: "center" }}>
                  {skipButtonConfig.label}
                </Text>
              </ButtonTertiary>
            </Col>
          )}
        </Row>
      </Col>
      <Col
        style={{ position: xs ? "relative" : "absolute", left: 0, top: 0 }}
        span={xs ? 24 : undefined}
      >
        {backButtonConfig && (
          <ButtonTertiary
            outlined
            transparent
            icon={<Icon name="leftArrow" />}
            onClick={backButtonConfig.onClick}
            style={{ width: !sm ? "100%" : "162px" }}
          >
            <Text strong>{backButtonConfig.label}</Text>
          </ButtonTertiary>
        )}
      </Col>
    </Row>
  );
};
