import ContentLayout from "layout/ContentLayout";
import { ContentType } from "layout/ContentLayout/ContentLayout";
import { Switch, Route, useParams } from "react-router-dom";
import { Routes } from "router/routes";
import { AccountCreate } from "./components/AccountCreate";
import { AccountUserAdd } from "./components/AccountUserAdd";
import AccountList from "./components/AccountList";
import {
  useCreatePlainAccountMutation,
  AccountsDocument,
  useCreateUserMutation,
  CreateUserMutationVariables,
} from "codegen/generated/graphql";
import { AccountInput } from "./types";
import { AccountUserList } from "./components/AccountUserList";

const AccountsPage = () => {
  const [createAccount, { loading }] = useCreatePlainAccountMutation({
    refetchQueries: [
      {
        query: AccountsDocument,
      },
    ],
  });

  const [createUser] = useCreateUserMutation();

  return (
    <Switch>
      <Route path={Routes.ADMIN_ACCOUNT_CREATE.path}>
        <ContentLayout typeContent={ContentType.FullWidth}>
          <AccountCreate
            loading={loading}
            handleCreate={(fields: AccountInput) =>
              createAccount({
                variables: {
                  input: fields,
                },
              })
            }
          />
        </ContentLayout>
      </Route>
      <Route path={Routes.ADMIN_ACCOUNTS.path} exact component={AccountList} />
      <Route
        path={Routes.ADMIN_ACCOUNT_USER_LIST.path}
        component={AccountUserList}
        exact
      />
      <Route path={Routes.ADMIN_ACCOUNT_USER_ADD.path}>
        <ContentLayout
          typeContent={ContentType.FullWidth}
          header={{
            title: "Add new user",
          }}
        >
          <AccountUserAdd
            handleCreate={(fields: CreateUserMutationVariables) =>
              createUser({
                variables: fields,
                update: (cache) => {
                  cache.evict({
                    fieldName: "accountUsers",
                  });
                },
              })
            }
          />
        </ContentLayout>
      </Route>
    </Switch>
  );
};

export default AccountsPage;
