import { FC } from "react";
import { Link } from "react-router-dom";
import { Routes } from "router/routes";

const TemplatesCollection: FC = () => {
  return (
    <>
      <div>TemplatesCollection</div>
      <Link to={Routes.FRAMEWORKS_NEW_TEMPLATE.path}>
        <button>+ New Template</button>
      </Link>
    </>
  );
};

export default TemplatesCollection;
