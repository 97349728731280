import { FC, useState } from "react";
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import {
  LayoutSubmenu,
  LayoutSubmenuItem,
  MenuUnderline,
} from "./Profile.styles";
import Wrapper from "components/Wrapper";
import theme from "theme";
import { Button } from "antd";
import TeamProfile from "./TeamProfile";
import ManageSubscription from "./ManageSubscription";
import { menuItems } from "./menuItems";
import Account from "./Account";
import { Routes } from "router/routes";
import { ButtonTertiary } from "components/Button";
import { ReactComponent as Upsell } from "assets/icons/upsell.svg";
import ContentLayout from "layout/ContentLayout";
import { ContentType } from "layout/ContentLayout/ContentLayout";
import { UserRole } from "codegen/generated/graphql";
import { Icon, Restricted } from "components";
import Text from "antd/es/typography/Text";
import { useSubscribeDialog } from "pages/subscribe/Subscribe.dialog";
import { useAuthContext } from "utils/context";
import Integrations from "./Integrations";
import IntegrationModal from "./Integrations/IntegrationModal";

type ProfileProps = {
  props?: string;
};

const Page = () => {
  const { path } = useRouteMatch();

  // @TODO: Get rid of "isEditing" state, it's was cut out from the scope
  const [isEditing, setIsEditing] = useState(true);
  const [selectedActionId, setSelectedActionId] = useState<number | null>(null);

  return (
    <Switch>
      <Route exact path={path}>
        <TeamProfile
          setSelectedId={setSelectedActionId}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      </Route>
      <Route
        path={Routes.TEAM_PROFILE_SUBSCRIPTION.path}
        component={ManageSubscription}
      />
      <Route path={Routes.TEAM_PROFILE_ACCOUNT.path}>
        <Wrapper float="right" margin="2rem 6rem 0 1rem">
          {/* <ActionsCard
            setSelectedId={setSelectedActionId}
            selectedId={selectedActionId}
            title="Account Actions"
            deleteTitle="Account"
            actions={[
              {
                title: "Change password",
                action: () => {
                  // @TODO: corresponding changeOwnershipMutation(...);
                  return;
                },
              },
            ]}
          />*/}
        </Wrapper>
        <Account />
      </Route>
      <Route path={Routes.TEAM_INTEGRATIONS.path} component={Integrations} />
    </Switch>
  );
};

// @TODO - menuItems passed as props so Menu component can be reused in /my-team/members/
export const Menu: FC = () => {
  const { user } = useAuthContext();

  const { path } = useRouteMatch();
  const location = useLocation();
  const [isIntegrationModalVisible, setShowIntegrationModal] = useState(false);

  return (
    <LayoutSubmenu>
      {menuItems(user).map((item) => {
        const { key, activeIcon, inactiveIcon, title } = item;

        return (
          <MenuUnderline key={key}>
            <LayoutSubmenuItem
              $active={location.pathname === path + item.path}
              key={key}
              icon={
                <Icon
                  name={
                    location.pathname === path + item.path
                      ? activeIcon
                      : inactiveIcon
                  }
                />
              }
            >
              {item?.title !== "Integrations" ? (
                <Link to={path + item.path}>{title}</Link>
              ) : user?.account?.integrationHRIS ||
                user?.account?.integrationCollaboration ? (
                <Link to={path + item.path}>{title}</Link>
              ) : (
                <Link to={path} onClick={() => setShowIntegrationModal(true)}>
                  {title}
                </Link>
              )}
            </LayoutSubmenuItem>
          </MenuUnderline>
        );
      })}
      <IntegrationModal
        isIntegrationModalVisible={isIntegrationModalVisible}
        setShowIntegrationModal={setShowIntegrationModal}
      />
    </LayoutSubmenu>
  );
};

const Profile: FC<ProfileProps> = () => {
  const [Dialog, show] = useSubscribeDialog();
  const { user } = useAuthContext();
  return (
    <ContentLayout
      header={{
        title: "My Team Profile",
        actions: [
          <Restricted
            to={[UserRole.PrimaryAdmin, UserRole.Admin, UserRole.TeamManager]}
          >
            {user?.account?.isStripeCustomer ? (
              <ButtonTertiary
                icon={<Upsell />}
                style={{ backgroundColor: theme.colors.grey_70 }}
                onClick={show}
              >
                <Text strong>Modify/Upgrade Account</Text>
              </ButtonTertiary>
            ) : null}
          </Restricted>,
        ],
      }}
      typeContent={ContentType.WithSubmenu}
      sideContent={<Menu />}
    >
      <Wrapper
        height="84.5vh"
        width="100%"
        gradientBg="linear-gradient(108.84deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.37) 101.71%);"
        radius={theme.radius.commonRadius}
      >
        <Dialog />
        <Page />
      </Wrapper>
    </ContentLayout>
  );
};

export default Profile;
