import styled from "styled-components";
import { ListRowContainer } from "components/ListRowContainer";
import { Typography } from "antd";

export const TableRow = styled(ListRowContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled(Typography.Text)`
  margin-left: 15px;
`;

export const NoWorkDesigns = styled.div`
  margin: 30vh auto;
`;

export const Info = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
`;

export const Detail = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
