import React from "react";
import { Col, Row, Spin } from "antd";

export const MobileLoader: React.FC = () => {
  return (
    <Row justify="center" align="middle" style={{ height: "100vh" }}>
      <Col>
        <Spin size="large" />
      </Col>
    </Row>
  );
};
