import { useEffect, useState, VFC } from "react";
import moment from "moment-timezone";

import {
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Typography,
  Button,
  Checkbox,
  Space,
} from "antd";
import { WorkDesignStatusTag } from "components/Tag";
import {
  useAccountUserQuery,
  AccountUserQuery,
  useProfileUpdateMutation,
  UserRole,
  useUpdateProfileAvatarMutation,
  useUserScopesQuery,
  useReportingManagersQuery,
  UserAdditionalPermissions,
} from "codegen/generated/graphql";
import Loader from "components/Loader";
import { useParams } from "react-router-dom";
import { Avatar, ButtonPrimary, Restricted, FileUpload } from "components";
import { getFormattedDate } from "utils/formatters";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { phoneNumberStyle } from "../../styles";
import * as S from "../styles";
import { useAuthContext } from "utils/context";
import { appMutations } from "store/app";
import { DimensionChip } from "components/DimensionChip";
import ConfigureScopeModal from "../../ConfigureScopeModal";
import PrimaryAdminConfirmModal from "../../PrimaryAdminConfirmModal";
const { Text, Link } = Typography;

interface MemberProfileProps {
  isManager?: boolean;
  isMeMember?: boolean;
}

// TODO:Extract form itself and PrimaryAdmin role part of the form into separate components to decrease the size

const MemberProfile: VFC<MemberProfileProps> = ({ isManager, isMeMember }) => {
  const { firebaseUser, user } = useAuthContext();
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [showUserPermissionsSection, setShowUserPermissionsSection] =
    useState(false);
  const [showConfigureScopeModal, setShowConfigureScopeModal] = useState(false);
  const [isPrimaryAdminConfirmModalVisible, setShowPrimaryAdminConfirmModal] =
    useState(false);
  const [isPrimaryAdminSelected, setPrimaryAdminSelected] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<any[]>([]);
  const [userAdditionalPermissions, setUserAdditionalPermissions] = useState<
    any[]
  >([]);
  const [configureScopeSelectedData, setConfigureScopeSelectedData] =
    useState<any>({});

  const { data, loading, error } = useAccountUserQuery({
    variables: {
      id,
    },
  });
  const {
    data: reportingManagerData,
    loading: reportingManagerLoading,
    error: reportingManagerError,
  } = useReportingManagersQuery({});

  const {
    data: userScopeData,
    loading: userScopeLoading,
    error: userScopeError,
  } = useUserScopesQuery({ skip: user?.roles[0] === UserRole.TeamMember });

  const [updateProfileAvatar] = useUpdateProfileAvatarMutation({
    onCompleted: ({ updateProfileAvatar: { avatar } }) => {
      notification.success({
        type: "success",
        message: `Profile avatar has been updated`,
        placement: "bottomLeft",
      });

      // @TODO - find out how to update apollo cache and subscribe for these updates to sync with reactive variables
      // MAybe get rid of the reactive variables completely here??
      appMutations.updateProfileImage(avatar || "");
    },
  });

  const [updateProfile] = useProfileUpdateMutation({
    onCompleted: () => {
      notification.success({
        type: "success",
        message: `Profile was updated`,
        placement: "bottomLeft",
      });
      if (isPrimaryAdminSelected && user) {
        appMutations.onAuthChange({
          user: { ...user, roles: [UserRole.Admin] },
          firebaseUser: firebaseUser,
          isLoading: false,
        });
        appMutations.setSelectedRole({
          id: UserRole.PrimaryAdmin,
          title: "Team",
        });
      }
    },
    onError: () => {
      notification.error({
        type: "warning",
        message: `Something went wrong`,
        placement: "bottomLeft",
      });
    },
  });

  const handleOnFinish = (values: any) => {
    updateProfile({
      variables: {
        input: {
          ...values,
          function: values.function.trim(),
          gender: values.gender.trim(),
          jobTitle: values.jobTitle.trim(),
          office: values.office.trim(),
          name: values.name.trim(),
          surname: values.surname.trim(),
          raceOrEthnicity: values.raceOrEthnicity.trim(),
          annualSalary: parseInt(values.annualSalary),
          paidTimeOff: parseInt(values.paidTimeOff),
          roles: selectedRoles.length ? [selectedRoles[0].value] : [],
          additionalPermissions: userAdditionalPermissions,
          filterScope: configureScopeSelectedData,
          id,
        },
      },
    });
  };

  const userResponsesFactory = (data?: AccountUserQuery) => {
    if (!data?.accountUser?.myLatestWorkDesign?.rawResponses) return {};
    return data?.accountUser.myLatestWorkDesign.rawResponses;
  };

  const responses = userResponsesFactory(data);

  const status = data?.accountUser?.myLatestWorkDesign?.status;
  const lastLoginAt = data?.accountUser?.lastLoginAt || undefined;
  const mindset = responses.primaryMindset;
  const email = data?.accountUser?.email || "";
  const avatar = data?.accountUser?.profile?.avatar || "";
  const profile = data?.accountUser?.profile;
  const hireDate = moment(data?.accountUser?.profile?.hireDate).isValid()
    ? moment(data?.accountUser?.profile?.hireDate)
    : null;
  const managerId = data?.accountUser?.managerId || "";
  const role = data?.accountUser?.roles || undefined;
  const gender = data?.accountUser?.profile?.gender || "";
  const raceOrEthnicity = data?.accountUser?.profile?.raceOrEthnicity || "";
  const userAdditionalPermissionsResponse =
    data?.accountUser?.additionalPermissions || undefined;
  const userReportViewerScope = data?.accountUser?.filterScope || {};

  const initialValues = {
    ...profile,
    hireDate,
    email,
    managerId,
    timeZone: profile?.timeZone ?? moment.tz.guess(),
  };

  const renderTimezoneOptions = () => {
    const timeZones = moment.tz.names();

    return timeZones.map((timezone) => {
      return (
        <Select.Option value={timezone} key={timezone}>
          {timezone}
        </Select.Option>
      );
    });
  };

  const renderReportingManager = () => {
    return (
      reportingManagerData &&
      reportingManagerData?.reportingManagers &&
      reportingManagerData?.reportingManagers.map((reportingManager: any) => {
        return (
          <Select.Option value={reportingManager.id} key={reportingManager.id}>
            {reportingManager.name}
          </Select.Option>
        );
      })
    );
  };

  const primaryRolesList = [
    { id: 1, name: "PrimaryAdmin", value: "PRIMARY_ADMIN" },
    { id: 2, name: "Admin", value: "ADMIN" },
    { id: 3, name: "Manager", value: "TEAM_MANAGER" },
    { id: 4, name: "Member", value: "TEAM_MEMBER" },
  ];

  const adminRolesList = [
    { id: 1, name: "Admin", value: "ADMIN" },
    { id: 3, name: "Manager", value: "TEAM_MANAGER" },
    { id: 3, name: "Member", value: "TEAM_MEMBER" },
  ];

  const managerRolesList = [
    { id: 2, name: "Manager", value: "TEAM_MANAGER" },
    { id: 3, name: "Member", value: "TEAM_MEMBER" },
  ];

  const memberRolesList = [{ id: 3, name: "Member", value: "TEAM_MEMBER" }];

  const isMemberRolePriorityGreater = (memberRole: any, userRole: any) => {
    if (
      memberRole === UserRole.PrimaryAdmin &&
      userRole !== UserRole.PrimaryAdmin
    ) {
      return true;
    } else if (
      memberRole === UserRole.Admin &&
      userRole !== UserRole.Admin &&
      userRole !== UserRole.PrimaryAdmin
    ) {
      return true;
    } else if (
      memberRole === UserRole.TeamManager &&
      userRole === UserRole.TeamMember
    ) {
      return true;
    } else {
      return false;
    }
  };

  const continuePrimaryRoleSelection = () => {
    setSelectedRoles([primaryRolesList[0]]);
    setPrimaryAdminSelected(true);
    setShowPrimaryAdminConfirmModal(false);
  };

  const triggerRoleSelection = (dimension: any) => {
    if (
      dimension.value === UserRole.PrimaryAdmin &&
      role?.[0] !== UserRole.PrimaryAdmin
    ) {
      setShowPrimaryAdminConfirmModal(true);
    } else {
      setSelectedRoles([dimension]);
      setShowUserPermissionsSection(true);
      setPrimaryAdminSelected(false);
    }
  };
  const additionalPermissions = [
    { id: 1, label: "Report Viewer", value: "REPORT_VIEWER" },
    { id: 2, label: "Framework Manager", value: "FRAMEWORK_MANAGER" },
  ];

  const onChangeUserPermission = (checkedValues: any) => {
    setUserAdditionalPermissions(checkedValues);
  };

  useEffect(() => {
    if (role && role?.length) {
      setSelectedRoles([{ value: role[0] }]);
      setShowUserPermissionsSection(true);
    }
    if (userAdditionalPermissionsResponse) {
      setUserAdditionalPermissions(userAdditionalPermissionsResponse);
    }
  }, [role, userAdditionalPermissionsResponse]);

  if (loading || userScopeLoading || reportingManagerLoading) return <Loader />;

  if (error || !data?.accountUser) {
    return <Text>Something went wrong</Text>;
  }

  return (
    <Col offset={1}>
      <Row>
        {/*Avatar col*/}
        <Col span={8}>
          <Row gutter={[0, 24]} style={{ height: "100%" }} align={"top"}>
            <Col span={24}>
              {isMeMember ? (
                <div
                  style={{
                    width: 120,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <FileUpload
                    reference={firebaseUser?.uid ?? ""}
                    onSuccess={(src: string) =>
                      updateProfileAvatar({ variables: { src } })
                    }
                    component={(props: any) => (
                      <Avatar
                        alt="profilePhoto"
                        size={112}
                        src={avatar}
                        {...props}
                      />
                    )}
                  />
                  <Button
                    hidden={!avatar}
                    type="link"
                    size="small"
                    onClick={() =>
                      updateProfileAvatar({ variables: { src: "" } })
                    }
                  >
                    <Text style={{ fontSize: 11 }}>Remove photo</Text>
                  </Button>
                </div>
              ) : (
                <Avatar alt="profilePhoto" size={112} src={avatar} />
              )}
            </Col>
            <Col span={24} style={{ display: "flex", flexDirection: "column" }}>
              <Text strong>Mindset</Text>
              <Text type="secondary">{mindset}</Text>
            </Col>
          </Row>
        </Col>
        {/*WD Status col*/}
        <Col span={8}>
          <Row gutter={[0, 24]} style={{ height: "100%" }} align={"bottom"}>
            <Col span={24}>
              <Col>
                <Text strong>Work Design Status</Text>
              </Col>
              <Col>{status && <WorkDesignStatusTag status={status} />}</Col>
            </Col>
            <Col span={24}>
              <Divider style={{ margin: 0 }} />
            </Col>
            <Col span={24} style={{ display: "flex", flexDirection: "column" }}>
              <Text strong>Last Login</Text>
              <Text type="secondary">
                {getFormattedDate(lastLoginAt, false, true)}
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>

      {/*Form section */}
      <Row style={{ marginTop: "24px" }}>
        <Col span={16}>
          <Form
            name="profile"
            layout="vertical"
            form={form}
            initialValues={initialValues}
            onFinish={(values) => handleOnFinish(values)}
          >
            <Row>
              <Col span={11}>
                <Form.Item
                  label={<Text strong>First Name</Text>}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "First Name can't be empty",
                    },
                  ]}
                >
                  <Input size="large" disabled={!isManager} />
                </Form.Item>
              </Col>
              <Col offset={1} span={12}>
                <Form.Item
                  name="surname"
                  label={<Text strong>Last Name</Text>}
                  rules={[
                    { required: true, message: "Last Name can't be empty" },
                  ]}
                >
                  <Input size="large" disabled={!isManager} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="email"
                  label={<Text strong>Email</Text>}
                  rules={[
                    {
                      required: true,
                      message: "Email can't be empty",
                    },
                  ]}
                >
                  <Input size="large" disabled={!isManager} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="phoneNumber"
                  label={<Text strong>Cell Phone Number</Text>}
                >
                  <PhoneInput
                    buttonStyle={phoneNumberStyle}
                    inputStyle={phoneNumberStyle}
                    country={"us"}
                    onChange={form.setFieldsValue}
                  />
                </Form.Item>
              </Col>
              {/*For Team Manager*/}
              <Restricted
                to={[
                  UserRole.PrimaryAdmin,
                  UserRole.Admin,
                  UserRole.TeamManager,
                ]}
              >
                <Col span={11}>
                  <Form.Item
                    label={<Text strong>Hire Date</Text>}
                    name="hireDate"
                  >
                    {!isManager ? (
                      <Input size="large" />
                    ) : (
                      <DatePicker
                        style={{ width: "100%" }}
                        placeholder="Pick a date"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col offset={1} span={12}>
                  <Form.Item
                    name="employmentType"
                    label={<Text strong>Employment Type</Text>}
                  >
                    {isManager ? (
                      <Select allowClear>
                        <Select.Option value="Full Time" key="1">
                          Full Time
                        </Select.Option>
                        <Select.Option value="Part Time" key="2">
                          Part Time
                        </Select.Option>
                      </Select>
                    ) : (
                      <Input size="large" disabled />
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="gender" label={<Text strong>Gender</Text>}>
                    <Input size="large" disabled={!isManager} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="raceOrEthnicity"
                    label={<Text strong>Race / Ethnicity</Text>}
                  >
                    <Input size="large" disabled={!isManager} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="function"
                    label={<Text strong>Function</Text>}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    name="jobTitle"
                    label={<Text strong>Job Title</Text>}
                  >
                    <Input size="large" disabled={!isManager} />
                  </Form.Item>
                </Col>
                <Col offset={1} span={12}>
                  <Form.Item name="office" label={<Text strong>Office</Text>}>
                    <Input size="large" disabled={!isManager} />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    name="annualSalary"
                    label={<Text strong>Annual Salary</Text>}
                  >
                    <Input
                      prefix="$"
                      size="large"
                      disabled={!isManager}
                      type="number"
                      min={0}
                    />
                  </Form.Item>
                </Col>
                <Col offset={1} span={12}>
                  <Form.Item
                    name="paidTimeOff"
                    label={<Text strong>Paid Time Off (Days)</Text>}
                  >
                    <InputNumber
                      size="large"
                      disabled={!isManager}
                      type="number"
                      min={0}
                    />
                  </Form.Item>
                </Col>
              </Restricted>

              <Col span={24}>
                <Form.Item
                  label={<Text strong>Time Zone</Text>}
                  name="timeZone"
                  rules={[
                    { required: true, message: "Time Zone can't be empty" },
                  ]}
                >
                  <Select style={{ width: 400 }}>
                    {renderTimezoneOptions()}
                  </Select>
                </Form.Item>
              </Col>
              <Restricted
                to={[
                  UserRole.PrimaryAdmin,
                  UserRole.Admin,
                  UserRole.TeamManager,
                  UserRole.TeamMember,
                ]}
              >
                <Col span={24}>
                  <Form.Item
                    label={<Text strong>Reporting Manager</Text>}
                    name="managerId"
                  >
                    <Select
                      style={{ width: 400 }}
                      disabled={
                        user?.roles[0] === role?.[0] &&
                        user?.roles[0] !== UserRole.PrimaryAdmin
                      }
                    >
                      {renderReportingManager()}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={<Text strong>Select Role</Text>}
                    name="roles"
                  >
                    <Row gutter={[16, 12]} style={{ paddingLeft: "7px" }}>
                      {(user?.roles[0] !== role?.[0] &&
                      !isMemberRolePriorityGreater(role?.[0], user?.roles[0])
                        ? user?.roles[0] === UserRole.PrimaryAdmin
                          ? primaryRolesList
                          : user?.roles[0] === UserRole.Admin
                          ? adminRolesList
                          : user?.roles[0] === UserRole.TeamManager
                          ? managerRolesList
                          : memberRolesList
                        : primaryRolesList.filter(
                            (item) => item.value === role?.[0]
                          )
                      ).map((dimension) => {
                        let isActive = false;
                        if (selectedRoles && selectedRoles.length) {
                          isActive = selectedRoles.some(
                            ({ value }) => value === dimension?.value
                          );
                        }
                        return (
                          <DimensionChip
                            key={dimension.id}
                            active={isActive}
                            label={`${dimension.name}`}
                            onClick={() => triggerRoleSelection(dimension)}
                          />
                        );
                      })}
                    </Row>
                    <br></br>
                    <a
                      href="https://help.hellolluna.com/en/articles/6244831-roles-in-lluna"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn more about role types.
                    </a>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  {showUserPermissionsSection &&
                    (selectedRoles?.[0]?.value === UserRole.TeamManager ||
                      selectedRoles?.[0]?.value === UserRole.TeamMember) && (
                      <S.Wrapper>
                        <div style={{ marginBottom: "15px" }}>
                          <Text strong>Additional User Permissions</Text>
                        </div>
                        <Checkbox.Group
                          disabled={
                            user?.roles[0] === role?.[0] &&
                            user?.roles[0] !== UserRole.PrimaryAdmin
                          }
                          options={additionalPermissions}
                          onChange={onChangeUserPermission}
                          defaultValue={userAdditionalPermissions}
                        />
                        {userAdditionalPermissions.includes(
                          UserAdditionalPermissions.ReportViewer
                        ) &&
                          (user?.roles[0] !== role?.[0] ||
                            user?.roles[0] === UserRole.PrimaryAdmin) && (
                            <Space style={{ marginTop: "8px" }}>
                              <Link
                                onClick={() => setShowConfigureScopeModal(true)}
                              >
                                Configure Scope{" "}
                                <S.UIRightOutlined
                                  style={{ fontSize: "15px" }}
                                />
                              </Link>
                            </Space>
                          )}
                        <div style={{ marginTop: "15px" }}>
                          <strong>Report Viewer:</strong> Can view/create
                          reports for a specific scope, but cannot create/send
                          frameworks
                        </div>
                        <div>
                          <strong>Framework Manager:</strong> Can help create
                          frameworks, but cannot view results
                        </div>
                      </S.Wrapper>
                    )}
                </Col>
              </Restricted>
            </Row>
            <Col
              span={24}
              style={{
                margin: "40px 0",
              }}
            >
              <ButtonPrimary
                htmlType="submit"
                disabled={!isMeMember && !isManager}
              >
                <Text strong>Save</Text>
              </ButtonPrimary>
            </Col>
          </Form>
        </Col>
      </Row>
      <ConfigureScopeModal
        isConfigureScopeModalVisible={showConfigureScopeModal}
        setShowConfigureScopeModal={setShowConfigureScopeModal}
        userScopeData={userScopeData}
        setConfigureScopeSelectedData={setConfigureScopeSelectedData}
        userReportViewerScope={userReportViewerScope}
      />
      <PrimaryAdminConfirmModal
        isPrimaryAdminConfirmModalVisible={isPrimaryAdminConfirmModalVisible}
        setShowPrimaryAdminConfirmModal={setShowPrimaryAdminConfirmModal}
        continuePrimaryRoleSelection={continuePrimaryRoleSelection}
      />
    </Col>
  );
};

export default MemberProfile;
