import { CSSProperties, FC, ReactNode } from "react";
import { UIImage } from "./image.styles";

interface ImageProps {
  src: ReactNode & string;
  style?: CSSProperties;
}

const Image: FC<ImageProps> = ({ src, style }) => {
  return <UIImage src={src} style={style} />;
};

export default Image;
