import { makeVar, ReactiveVar } from "@apollo/client";

export const vars: { name: string; value: any }[] = [];

const LS_PREFIX = "_lluna__";

// @TODO - improve typing
const getLSItem = <T>(key: string) => {
  try {
    return JSON.parse(localStorage.getItem(LS_PREFIX + key) ?? "") as T;
  } catch (e) {
    return null;
  }
};
const setLSItem =
  <T>(key: string) =>
  (val: T) =>
    localStorage.setItem(LS_PREFIX + key, JSON.stringify(val));

export const createReactiveVar = <T>(
  name: string,
  initialValue: T,
  storeInLocalStorage = false
) => {
  const reactiveVar: ReactiveVar<T> = makeVar<T>(
    !storeInLocalStorage ? initialValue : getLSItem<T>(name) || initialValue
  );
  vars.push({ name, value: () => reactiveVar() });

  const setter = storeInLocalStorage ? setLSItem<T>(name) : () => void 0;

  const reactiveVarWithCache = new Proxy(reactiveVar, {
    apply(target, thisArg, args) {
      args.length && setter(args[0]);
      return Reflect.apply(target, thisArg, args);
    },
  });

  return reactiveVarWithCache;
};
