import { ButtonCore } from "components";
import styled from "styled-components";

export const Container = styled.div`
  overflow: auto;
  height: 50vh;
  width: 100%;
  display: inline-grid;
  padding: 0 10px 10px 10px;
`;

export const Wrapper = styled.div`
  display: inline-grid;
  justify-items: center;
  align-self: center;
  grid-gap: 20px;
`;
export const VideoButton = styled(ButtonCore)`
  background-color: ${({ theme }) => theme.colors.blue_30};
  color: ${({ theme }) => theme.colors.blue_100};
  font-weight: bolder;
  height: 48px;
  width: 212px;
`;
