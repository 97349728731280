import { FirebaseOptions, initializeApp } from "firebase/app";
import {
  initializeAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  browserLocalPersistence,
  browserPopupRedirectResolver,
  createUserWithEmailAndPassword,
  User,
  confirmPasswordReset,
  applyActionCode,
  reauthenticateWithCredential,
  AuthCredential,
  updatePassword,
  updateProfile,
  AuthError,
  UserCredential,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import { getStorage } from "firebase/storage";

import { UpdateLastLoggedInDocument } from "codegen/generated/graphql";

import { client } from "apolloClient";

enum Env {
  STAGING = "staging",
  DEMO = "demo",
  PRODUCTION = "production",
}

const configs = {
  [Env.STAGING]: {
    apiKey: "AIzaSyAg4Ca3ZQr-mpUHejQcTqf2CZvr4PAAe7w",
    authDomain: "lluna-staging.firebaseapp.com",
    projectId: "lluna-staging",
    storageBucket: "lluna-staging.appspot.com",
  },
  [Env.PRODUCTION]: {
    apiKey: "AIzaSyCaTFydmfYT-8jOL9FY75yjXDHr_-U29tU",
    authDomain: "lluna-production.firebaseapp.com",
    projectId: "lluna-production",
    storageBucket: "lluna-production.appspot.com",
  },
} as Record<Env, FirebaseOptions>;

const firebaseConfig =
  configs[(process.env.REACT_APP_ENV as Env) || Env.STAGING];

const app = initializeApp(firebaseConfig);

const auth = initializeAuth(app, {
  persistence: browserLocalPersistence,
  popupRedirectResolver: browserPopupRedirectResolver,
});

const storage = getStorage(app);

const authProviders = {
  google: new GoogleAuthProvider(),
  microsoft: new OAuthProvider("microsoft.com"),
  linkedin: new OAuthProvider("linkedin.com"),
};

export type AuthProviderId = keyof typeof authProviders;

const AuthErrorMessages: Record<string, string> = {
  default: "Authentication failed",
  "auth/email-already-in-use": "You have already registered. Please log in",
  "auth/operation-not-allowed": "Operation not allowed",
  "auth/weak-password": "Weak password",
  "auth/invalid-email": "Invalid email address",
  "auth/user-disabled": "User banned",
  "auth/user-not-found": "User not found",
  "auth/wrong-password": "Incorrect password",
};

const CustomErrorCode = "custom";
const DifferentCredentialCode = "auth/account-exists-with-different-credential";

export const updateLastLoggedIn = (user: UserCredential) =>
  client
    .mutate({
      mutation: UpdateLastLoggedInDocument,
    })
    .then(() => user);

export const getAuthErrorMessage = (error: AuthError) => {
  const { code, message, customData } = error;
  console.error(`Auth error [${code}] ${message}`, customData);
  return code === CustomErrorCode
    ? message
    : AuthErrorMessages[code] ||
        `${AuthErrorMessages.default}: ${code
          .replace(/.*\//g, "")
          .replace(/-/g, " ")}`;
};

export const authError = (message: string, data?: Partial<AuthError>) =>
  Object.assign(new Error(message), {
    ...data,
    code: CustomErrorCode,
    message,
  });

// Handle sign-in to accounts with different credentials
// https://firebase.google.com/docs/auth/web/microsoft-oauth#expandable-1
const linkDifferentCredential = async (error: AuthError) => {
  if (error.code !== DifferentCredentialCode) throw error;
  const { email = "" } = error.customData || {};
  const methods = await fetchSignInMethodsForEmail(auth, email).catch(logError);
  // TODO: Implement sign-in with existing provider and linking new credential
  const providers = methods?.join(", or ") || "a different provider";
  throw authError(`Please log in with ${providers}`, error);
};

const logError = (error: Error) => console.error(error);
const logOperation =
  (operation: string) => (userCredentials: UserCredential) => {
    const { user, providerId } = userCredentials;
    console.log(
      `User ${operation} with ${providerId || "email"} as ${user.email} [${
        user.uid
      }]${user.emailVerified ? "" : " (not verified)"}`,
      userCredentials
    );
    return userCredentials;
  };
const logSignIn = logOperation("signed in");
const logSignUp = logOperation("signed up");

export const signInWithProvider = async (
  providerId: AuthProviderId,
  invitedUserEmail = ""
) =>
  signInWithPopup(
    auth,
    authProviders[providerId].setCustomParameters({
      login_hint: invitedUserEmail,
    })
  )
    .then(logSignIn)
    .catch(linkDifferentCredential);

export const signInWithPassword = (email: string, password: string) =>
  signInWithEmailAndPassword(auth, email, password)
    .then(logSignIn)
    .then(updateLastLoggedIn);

export const signUp = (email: string, password: string, name?: string) =>
  createUserWithEmailAndPassword(auth, email, password)
    .then(logSignUp)
    .then(async (userCredentials) => {
      if (name)
        await updateProfile(userCredentials.user, { displayName: name });
      return userCredentials;
    });

// TODO: Remove, done on backend now
// export const sendVerificationLink = (user: User) => sendEmailVerification(user);
//
// export const sendPasswordResetLink = (email: string) =>
//   sendPasswordResetEmail(auth, email, {
//     url: window.location.origin + "/reset-password?email=" + email,
//   });

export const reauthenticateUser = (user: User, credential: AuthCredential) =>
  reauthenticateWithCredential(user, credential);

export const updateFirebaseUserPassword = (user: User, password: string) =>
  updatePassword(user, password);

export const confirmPasswordResetLink = (
  oobCode: string,
  newPassword: string
) => confirmPasswordReset(auth, oobCode, newPassword);

export const confirmEmailVerification = (oobCode: string) =>
  applyActionCode(auth, oobCode);

export type { UserCredential } from "firebase/auth";
export { auth, storage };
