import { FC } from "react";

import { WorkDesignView } from "components";
import { DashboardCard } from "../DashboardCard";
import * as S from "./styles";

import { WdStatus, WorkDesignDataFragment } from "codegen/generated/graphql";
import { Typography } from "antd";
import { ReactComponent as Play } from "assets/icons/play.svg";

type Props = {
  workDesign: WorkDesignDataFragment | undefined | null;
  responses: any;
};

export const EmployeeWorkDesign: FC<Props> = ({ workDesign, responses }) => (
  <DashboardCard span={24} icon="eclipseYellow" title="Current Work Design">
    <S.Container>
      {workDesign?.status === WdStatus.Pending || workDesign === null ? (
        <S.Wrapper>
          <Typography.Title level={1}>First things first,</Typography.Title>
          <Typography.Text style={{ textAlign: "center" }}>
            You still haven’t submitted your Work Design,
            <br />
            Learn more about work designed for life.
          </Typography.Text>
          <a
            href="https://www.youtube.com/watch?v=PQEOG2zt8Y4&feature=youtu.be&ab_channel=LLUNA"
            target="_blank"
            rel="noreferrer noopener"
          >
            <S.VideoButton icon={<Play />}>Learn more</S.VideoButton>
          </a>
        </S.Wrapper>
      ) : (
        <WorkDesignView
          dimensions={(workDesign?.dimensions as any) ?? []}
          values={responses?.choices}
        />
      )}
    </S.Container>
  </DashboardCard>
);
