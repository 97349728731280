import React, {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useContext,
} from "react";
import moment from "moment";
import { AuthContext } from "utils/context/auth";
import * as S from "./TeamProfile.styles";
import { Col, Row, notification, Form } from "antd";
import Image from "components/Image";
import natureRun from "assets/images/natureRun.svg";
import theme from "theme";
import Input from "components/Input";
import Wrapper from "components/Wrapper";
import Avatar from "components/Avatar";
import { ButtonPrimary } from "components/Button";
import {
  UserRole,
  useTeamQuery,
  useTeamUpdateMutation,
} from "codegen/generated/graphql";
import { Restricted } from "components";
import Text from "antd/lib/typography/Text";

interface TeamProfileProps {
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  setSelectedId: Dispatch<SetStateAction<number | null>>;
}

export enum FORM_FIELD_NAME {
  TEAM_NAME = "name",
  WORK_DAYS = "workDays",
  MORNING = "morning",
  AFTERNOON = "afternoon",
  EVENING = "evening",
  PRIORITY = "priority",
}

type TeamProfileType = {
  [FORM_FIELD_NAME.TEAM_NAME]: string;
  [FORM_FIELD_NAME.WORK_DAYS]: number[];
  [FORM_FIELD_NAME.MORNING]: any;
  [FORM_FIELD_NAME.AFTERNOON]: any;
  [FORM_FIELD_NAME.EVENING]: any;
  [FORM_FIELD_NAME.PRIORITY]: string;
};

const TeamProfile: FC<TeamProfileProps> = ({ isEditing }) => {
  const { user } = useContext(AuthContext);
  const [form] = Form.useForm();

  const { data } = useTeamQuery({
    variables: { id: user?.team?.id as string },
  });

  const [updateTeam] = useTeamUpdateMutation();

  const handleSubmit = async (
    teamName: string,
    workDays: number[],
    morning: typeof moment[],
    afternoon: typeof moment[],
    evening: typeof moment[],
    priority: string
  ) => {
    return await updateTeam({
      variables: {
        input: {
          name: teamName,
          meta: {
            ///////////////////// Won't be part of v1
            // workDays,
            // morning: [morning[0].toString(), morning[1].toString()],
            // afternoon: [afternoon[0].toString(), afternoon[1].toString()],
            // evening: [evening[0].toString(), evening[1].toString()],
            priority,
          },
        },
      },
    })
      .then(() => {
        notification.success({
          type: "success",
          message: "Team profile saved",
          placement: "bottomLeft",
        });
      })
      .catch((e) => {
        notification.error({
          type: "error",
          message: e,
          placement: "bottomLeft",
        });
      });
  };

  const teamInitials = (teamName: string) => {
    if (!!teamName) {
      // Check if there are 2 or more words in teamName
      const hasWhiteSpace = () =>
        teamName.indexOf(" ") > 0 &&
        teamName.length - 1 !== teamName.indexOf(" ");
      // First two characters of the first word only
      const oneWordChars = teamName.substr(0, 2).toUpperCase();
      // First characters of the first two words
      const twoWordsChars =
        teamName.substr(0, 1).toUpperCase() +
        teamName
          .slice(teamName.indexOf(" ") + 1)
          .substr(0, 1)
          .toUpperCase();
      // If there are two words, return twoWordsChars, alse return oneWordChars
      return hasWhiteSpace() ? twoWordsChars : oneWordChars;
    } else return null;
  };

  // @ TODO: connect to BE if needed
  const priorities = [
    "Team engagement",
    "Talent attraction",
    "Employee retention",
    "Inclusion & belonging",
    "Team productivity",
  ];

  const json = data?.team?.meta;

  const initialValues = {
    name: data?.team?.name,
    workDays: json?.workDays || [],
    priority: json?.priority,
    morning: json?.morning
      ? [moment(json?.morning[0]), moment(json.morning[1])]
      : null,
    afternoon: json?.afternoon
      ? [moment(json?.afternoon[0]), moment(json?.afternoon[1])]
      : null,
    evening: json?.evening
      ? [moment(json?.evening[0]), moment(json?.evening[1])]
      : null,
  };

  return (
    <>
      {!data?.team ? (
        <>
          <S.NoTeamsWrap>
            <Image style={{ width: "25vw" }} src={natureRun} />
            <S.NoTeamsText>
              It seems there aren't any teams available
            </S.NoTeamsText>
          </S.NoTeamsWrap>
        </>
      ) : (
        <Row>
          <Col offset={2} span={20}>
            <S.Title level={5} margin="0 0 1rem 0">
              Picture
            </S.Title>
            <Form
              name={"accountDetailForm"}
              form={form}
              autoComplete={"off"}
              initialValues={initialValues}
              onFinish={({
                name,
                afternoon,
                evening,
                morning,
                priority,
                workDays,
              }: TeamProfileType) => {
                handleSubmit(
                  name,
                  workDays,
                  morning,
                  afternoon,
                  evening,
                  priority
                );
              }}
            >
              <Form.Item shouldUpdate>
                {() => (
                  <Avatar
                    fallback={initialValues.name || ""}
                    style={{
                      fontWeight: "bold",
                      fontSize: "2.3em",
                    }}
                    size={112}
                  >
                    {teamInitials(
                      form.getFieldValue(FORM_FIELD_NAME.TEAM_NAME)
                    )}
                  </Avatar>
                )}
              </Form.Item>

              {isEditing ? (
                <Wrapper margin="1.8rem 0 0 0" width="22vw">
                  <Form.Item
                    name={FORM_FIELD_NAME.TEAM_NAME}
                    rules={[
                      {
                        required: true,
                        message: "Value is required",
                      },
                    ]}
                  >
                    <Input label="Team Name" name="teamName" />
                  </Form.Item>
                </Wrapper>
              ) : (
                <>
                  <S.Title level={5} margin="2rem 0 0.5rem 0">
                    Team Name
                  </S.Title>
                  <S.Answer>{data?.team?.name}</S.Answer>
                </>
              )}
              <Restricted
                to={[
                  UserRole.PrimaryAdmin,
                  UserRole.Admin,
                  UserRole.TeamManager,
                ]}
              >
                <S.PriorityWrap>
                  <S.Title level={4} margin="1rem 0 0.5rem 0">
                    #1 Priority
                  </S.Title>
                  <Form.List name={FORM_FIELD_NAME.PRIORITY}>
                    {() => (
                      <>
                        {/* @TODO: in case of dynamic data from BE this needs to be connected to results from query */}
                        {priorities.map((prio, idx) => (
                          <Fragment key={idx}>
                            {idx === 2 ? <br /> : null}
                            <S.PriorityTag
                              $displayed={
                                form.getFieldValue(FORM_FIELD_NAME.PRIORITY) ===
                                  prio || isEditing
                              }
                              selected={
                                form.getFieldValue(FORM_FIELD_NAME.PRIORITY) ===
                                prio
                              }
                              onClick={async () => {
                                form.setFieldsValue({
                                  [FORM_FIELD_NAME.PRIORITY]: prio,
                                });
                              }}
                              color={
                                form.getFieldValue(FORM_FIELD_NAME.PRIORITY) ===
                                prio
                                  ? theme.colors.blue_100
                                  : "white"
                              }
                            >
                              {prio}
                            </S.PriorityTag>
                          </Fragment>
                        ))}
                      </>
                    )}
                  </Form.List>
                </S.PriorityWrap>
                <br />
                {isEditing ? (
                  <Form.Item shouldUpdate>
                    {() => (
                      <ButtonPrimary
                        htmlType="submit"
                        disabled={
                          !form.isFieldsTouched() ||
                          !!form
                            .getFieldsError()
                            .filter(({ errors }) => errors.length).length
                        }
                      >
                        <Text strong>Save Changes</Text>
                      </ButtonPrimary>
                    )}
                  </Form.Item>
                ) : null}
              </Restricted>
            </Form>
          </Col>
        </Row>
      )}
    </>
  );
};

export default TeamProfile;
