import styled, { css } from "styled-components";
import { ButtonTertiary as Button } from "components";

export const PreferredTimes = styled.div`
  grid-area: preferredTimes;
`;

export const TeamLocations = styled.div`
  grid-area: teamLocations;
`;

export const FrameworkInsights = styled.div`
  grid-area: frameworkInsights;
`;

export const ButtonTertiary = styled(Button)`
  width: 150px;
  border: 1px solid ${({ theme }) => theme.colors.blue_100};
  position: relative;
`;

export const LocationsChartWrap = styled.div`
  height: 80%;
  margin-bottom: 20px;
`;

export const LegendWrap = styled.div`
  margin-left: 10px;
`;

export const LocationsWrap = styled.div<{ isData: boolean }>`
  height: 300px;
  ${({ isData }) =>
    !isData &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

export const InsightsWrap = styled.div<{ isData: boolean }>`
  height: 300px;
  display: flex;
  padding: 10px 15px 0 5px;
  flex-direction: column;
  gap: 10px;
  ${({ isData }) =>
    !isData &&
    css`
      justify-content: center;
      align-items: center;
    `}
`;

export const InsightsHeadline = styled.div`
  color: ${({ theme }) => theme.colors.grey_50};
`;

export const Day = styled.span`
  color: ${({ theme }) => theme.colors.blue_80};
  font-size: 0.9em;
  font-weight: 600;
`;

export const OutOf = styled.span<{ noVotes: boolean }>`
  color: ${({ theme }) => theme.colors.grey_70};
  font-size: 0.9em;
  font-weight: 600;
  ${({ noVotes }) =>
    !noVotes &&
    css`
      margin-left: 10px;
    `}
`;

export const Hr = styled.hr`
  padding: 0.5px;
  color: red;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey_1};
  border: none;
`;

export const BarWrap = styled.div`
  height: 80%;
  padding: 0 0 20px 0;
`;

export const NoDataWrap = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const position = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 0.5rem;
`;

export const Cursor = styled.p`
  cursor: pointer;
  padding: 10px 0;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.1);
  }
`;
