import { Component } from "react";

export const isNested = Symbol("nested");

export const noop = () =>
  Promise.resolve({
    default: class Noop extends Component {
      render() {
        return "Noop";
      }
    },
  });

noop.isNested = isNested;
