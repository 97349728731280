import React, { useState, VFC } from "react";
import * as S from "./styles";
import Text from "antd/es/typography/Text";

import {
  Checkbox,
  FeatureTypeTag,
  Placeholder,
  Radio,
  Input,
} from "components";
import {
  Choice,
  FwDimensionFragment,
  FeatureType,
} from "codegen/generated/graphql";
import { Row, Space } from "antd";
import { sortChoices } from "../../utils/hooks";
import Choices from "pages/frameworks/features/components/UpsertFeatureForm/components/Choices";

type Props = {
  dimensions: FwDimensionFragment[];
  values?: { [key: string]: any };
  showAllChoices?: boolean;
  showAllToggleEnabled?: boolean;
};

type ChoiceContentProps = {
  props: Pick<Choice, "placeholderText" | "placeholderValue" | "text">;
};

const ChoiceContent: VFC<ChoiceContentProps> = ({
  props: { placeholderValue, placeholderText, text },
}) => (
  <Space size={10}>
    {placeholderValue && (
      <Placeholder value={placeholderValue} text={placeholderText} />
    )}
    {text}
  </Space>
);
export const WorkDesignView: React.FC<Props> = ({
  dimensions,
  values,
  showAllChoices,
  showAllToggleEnabled,
}) => {
  const [showAll, setShowAll] = useState(!!showAllChoices);
  return (
    <S.Wrapper>
      {showAllToggleEnabled && (
        <Checkbox
          onChange={(v) => setShowAll(v.target.value)}
          value={showAll}
          style={{ float: "right" }}
        >
          Show refused choices
        </Checkbox>
      )}
      {dimensions?.map((dimension) => (
        <>
          <S.Tag
            key={dimension.id}
            label={dimension.name ?? ""}
            color={dimension.meta.textColor}
            background={dimension.meta.backgroundColor}
          />
          {dimension?.features?.map(({ id, text, type, choices }) => {
            const value = values?.[id];
            return (
              <S.Card direction="vertical" size={16} key={id}>
                <Row justify="space-between" wrap={false} align="top">
                  <Text>{text}</Text>
                  <FeatureTypeTag type={type} />
                </Row>

                {type === FeatureType.Text && (
                  <Input name="preview" checked={!!value} value={value}></Input>
                )}

                {sortChoices(choices).map((choice, i) => {
                  const selectIsVisible =
                    Array.isArray(value) &&
                    value.some(
                      (selectValue: string) => selectValue === choice.id
                    );
                  const choicesIsVisible =
                    showAll || selectIsVisible || value === choice.id;
                  if (!choicesIsVisible) return null;
                  return type === FeatureType.MultiOption ? (
                    <Checkbox
                      key={choice.id}
                      disabled={!selectIsVisible}
                      checked={selectIsVisible}
                    >
                      <ChoiceContent props={choice} />
                    </Checkbox>
                  ) : (
                    <Radio
                      key={choice.id}
                      disabled
                      checked={value === choice.id}
                    >
                      <ChoiceContent props={choice} />
                    </Radio>
                  );
                })}
              </S.Card>
            );
          })}
        </>
      ))}
    </S.Wrapper>
  );
};
