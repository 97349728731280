import React from "react";
import { Row, Col } from "antd";
import Text from "antd/es/typography/Text";
import { SText } from "./styles";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import theme from "theme";

type Props = {
  isUppercaseValid?: boolean;
  isStrengthValid?: boolean;
  isLengthValid?: boolean;
};

const passwordListItems = {
  uppercase: `• Use non standard Uppercasing`,
  strength: `• At least one number, and one symbol`,
  length: `• Password must contain 6 characters`,
};

export const PasswordTooltipContent: React.FC<Props> = (props) => {
  const { isUppercaseValid, isStrengthValid, isLengthValid } = props;

  return (
    <Row>
      <Col span={24}>
        <Text
          strong
          style={{
            fontSize: "13px",
            color: theme.colors.grey_70,
          }}
        >
          Current Password Strength
        </Text>
      </Col>
      <Col span={24}>
        <Text>
          {!isUppercaseValid || !isStrengthValid || !isLengthValid ? (
            <>
              <ExclamationCircleOutlined
                style={{ marginRight: "4px", color: theme.colors.red_40 }}
              />
              <SText strong textColor={"red"}>
                Weak
              </SText>
            </>
          ) : (
            <SText strong textColor={"green"}>
              Strong
            </SText>
          )}
        </Text>
      </Col>
      <Col span={24}>
        <Text style={{ fontSize: "11px", color: theme.colors.grey_70 }}>
          Password requirements
        </Text>
      </Col>
      <Col span={24}>
        <SText textColor={!isUppercaseValid ? "red" : "grey"}>
          {passwordListItems.uppercase}
        </SText>
      </Col>
      <Col span={24}>
        <SText textColor={!isStrengthValid ? "red" : "grey"}>
          {passwordListItems.strength}
        </SText>
      </Col>
      <Col span={24}>
        <SText textColor={!isLengthValid ? "red" : "grey"}>
          {passwordListItems.length}
        </SText>
      </Col>
    </Row>
  );
};
