import { BarChart } from "components/Charts/BarChart";
import { teamLocationsOptions } from "components/Charts/BarChart/mock";
import { Legend } from "components/Charts/Legend";
import { NoData } from "components/Charts/NoData";
import { DashboardCard } from "pages/dashboard/components/DashboardCard";
import { useMemo, useRef, VFC } from "react";
import * as S from "./styles";
import { labelColors } from "components/Charts/DoughnutChart/plugins";
import { WorkLocation } from "./insightsData";
import { Popover } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { toPng, toJpeg } from "html-to-image";

const borderRadius = 10;
const tLBarPer = 0.4;
const tLCatPer = 0.4;

function createChart(datasets: any) {
  const colors = labelColors(datasets.length);
  if (!datasets || !datasets.length)
    return {
      labels: [],
      datasets: [],
    };

  return {
    labels: datasets[0][1].map((line: any) => line[0]),
    datasets: datasets.map((dataset: any, i: number) => ({
      label: dataset[0],
      data: dataset[1].map((line: any) => line[1]),
      backgroundColor: colors[i],
      borderRadius,
      borderColor: "transparent",
      barPercentage: tLBarPer,
      categoryPercentage: tLCatPer,
      borderSkipped: false,
    })),
  };
}

type Props = {
  data: (WorkLocation | string | number[])[][];
};

export const TeamLocations: VFC<Props> = ({ data }) => {
  const myRef = useRef<any>(null);
  const chartData = useMemo(() => {
    return createChart(data);
  }, [data]);
  const downloadGraphImage = (imagetype: any) => {
    if (imagetype === "png") {
      toPng(myRef.current, { backgroundColor: "#FFF" })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "BarChart.png";
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (imagetype === "jpeg") {
      toJpeg(myRef.current, { backgroundColor: "#FFF" })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "BarChart.jpeg";
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const isData = Object.values(chartData).every((x) => x.length);
  const content = (
    <div>
      <S.Cursor onClick={() => downloadGraphImage("jpeg")}>
        Download JPEG
      </S.Cursor>
      <S.Cursor onClick={() => downloadGraphImage("png")}>
        Download PNG
      </S.Cursor>
    </div>
  );

  return (
    <S.TeamLocations ref={myRef}>
      <DashboardCard title="Team Locations" icon="reportsGrey">
        <S.position>
          <Popover
            placement="bottom"
            content={content}
            overlayInnerStyle={{ maxWidth: "fit-content" }}
          >
            <MenuOutlined />
          </Popover>
        </S.position>
        <S.LocationsWrap isData={!!isData}>
          {!!isData ? (
            <>
              <S.LocationsChartWrap>
                <BarChart data={chartData} options={teamLocationsOptions} />
              </S.LocationsChartWrap>
              <S.LegendWrap>
                <Legend
                  items={chartData.datasets.map((d: any) => ({
                    bgColor: d.backgroundColor,
                    label: d.label,
                  }))}
                  numOfCols={2}
                  isCountDisplayed={false}
                />
              </S.LegendWrap>
            </>
          ) : (
            <NoData />
          )}
        </S.LocationsWrap>
      </DashboardCard>
    </S.TeamLocations>
  );
};
