import { Col, Form, Row, Checkbox } from "antd";
import {
  CreateUserMutationVariables,
  UserRole,
} from "codegen/generated/graphql";
import { MaterialInput, ButtonPrimary } from "components";
import { useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { Routes } from "router/routes";

export const AccountUserAdd: React.VFC<{
  handleCreate: (fields: CreateUserMutationVariables) => Promise<any>;
}> = ({ handleCreate }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);

  const roles = [
    UserRole.PrimaryAdmin,
    UserRole.Admin,
    UserRole.TeamManager,
    UserRole.TeamMember,
  ];

  return (
    <Form
      name={"signUpForm"}
      form={form}
      autoComplete={"off"}
      onFinish={({ repeatPassword, ...fields }) => {
        setLoading(true);
        handleCreate({ input: { ...fields, accountId: id } })
          .then(() => setLoading(false))
          .then(() =>
            history.push(
              generatePath(Routes.ADMIN_ACCOUNT_USER_LIST.path, { id })
            )
          );
      }}
    >
      <Row justify="start" gutter={[0, 16]}>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row justify="space-between" gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Value is required",
                      },
                    ]}
                  >
                    <MaterialInput placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="surname"
                    rules={[
                      {
                        required: true,
                        message: "Value is required",
                      },
                    ]}
                  >
                    <MaterialInput placeholder="Last Name" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <MaterialInput placeholder={"Email Address"} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldsValue }) => {
                  const { password } = getFieldsValue();
                  return (
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <MaterialInput placeholder={"Password"} type="password" />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="repeatPassword"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, repeatedPassword) {
                      if (
                        !repeatedPassword ||
                        repeatedPassword === getFieldValue("password")
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <MaterialInput
                  placeholder={"Repeat Password"}
                  type="password"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="roles">
                <Checkbox.Group options={roles}>
                  <Checkbox>Primary Admin</Checkbox>
                  <Checkbox>Admin</Checkbox>
                  <Checkbox>Team manager</Checkbox>
                  <Checkbox>Team member</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <ButtonPrimary loading={loading} htmlType="submit">
                Sign up
              </ButtonPrimary>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
