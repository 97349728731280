import { debounce } from "lodash";
import { SelectProps } from "antd/es/select";
import React, { useCallback, useEffect } from "react";
import TabsBar from "components/TabsBar";
import { Select, Wrapper } from "components";
import * as S from "./styles";
import { useAccountsLazyQuery } from "codegen/generated/graphql";
import { useFeatureContext } from "../context";

interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType>, "options" | "children"> {
  fetchOptions: {
    fetch: any;
    result: any;
  };
  debounceTimeout?: number;
}

const DebounceSelect = <
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any
>({
  fetchOptions: { fetch, result },
  debounceTimeout = 800,
  ...props
}: DebounceSelectProps) => {
  const [options, setOptions] = React.useState<ValueType[]>([]);

  const debounceFetcher = React.useMemo(() => {
    const fetchResults = (value: string) => {
      //setOptions([]);

      fetch({
        variables: {
          query: value,
        },
      });
    };

    return debounce(fetchResults, debounceTimeout, { leading: true });
  }, [debounceTimeout, fetch]);

  useEffect(() => {
    if (result.data)
      setOptions(
        result.data.accounts.map((account: any) => ({
          label: (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>{account.users[0]?.email || account.name}</div>
              <small>{account.users[0]?.profile.fullName}</small>
            </div>
          ),
          value: account.id,
        }))
      );
  }, [result.data]);

  useEffect(fetch, [fetch]);

  return (
    <Select<any>
      filterOption={false}
      onSearch={debounceFetcher}
      showSearch
      loading={result.loading}
      {...props}
      options={options}
    />
  );
};

export const ChoicesSidebar = () => {
  const {
    state: {
      data: { accountId },
    },
    onChange,
  } = useFeatureContext();

  const [fetch, result] = useAccountsLazyQuery();

  const handleAccountChange = useCallback(
    (value: any) => {
      onChange("accountId")(value);
    },
    [onChange]
  );

  const fixedAccoundId = accountId || undefined;

  const TeamsContent = (
    <>
      <S.SidebarTitle>Choose one account</S.SidebarTitle>

      <Wrapper>
        <DebounceSelect
          placeholder="Select users"
          fetchOptions={{ fetch, result }}
          style={{ width: "100%" }}
          onChange={(e) => handleAccountChange(e)}
          value={fixedAccoundId}
          allowClear
        />
      </Wrapper>
    </>
  );

  const tabs = [
    {
      tab: "Teams",
      key: "components",
      tabsContent: TeamsContent,
    },
  ];

  return <TabsBar style={{ position: "sticky", top: 0 }} tabsOptions={tabs} />;
};

export default ChoicesSidebar;
