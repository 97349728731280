import styled from "styled-components";

export const Container = styled.div<{ selected: boolean }>`
  overflow: hidden;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px solid ${({ selected }) => (selected ? "#01083a" : "transparent")};
  align-items: center;
  display: flex;
  transform: rotate(-45deg);
`;

export const Left = styled.div<{ background: string }>`
  width: 32px;
  height: 40px;
  background: ${({ background }) => background};
  transform-origin: top right;
`;

export const Right = styled.div<{ background: string }>`
  width: 32px;
  height: 40px;
  background: ${({ background }) => background};
`;
