import { useState } from "react";
import { Button, Col, Form, Row, Modal, Tooltip } from "antd";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import { ReactComponent as GoogleSvg } from "../../assets/icons/google.svg";
import { ReactComponent as OutlookSvg } from "../../assets/icons/outlook.svg";
import { ReactComponent as LinkedinSvg } from "../../assets/icons/linkedin.svg";
import { MaterialInput } from "../MaterialInput";
import { PasswordTooltipContent } from "../Tooltip/PasswordTooltipContent";
import { Checkbox } from "../Checkbox";
import Link from "antd/es/typography/Link";
import EXTERNAL_LINKS from "../../utils/externalLinks";
import { Routes } from "../../router/routes";
import { useHistory } from "react-router-dom";
import { UserCredential, AuthProviderId } from "utils/firebase";
import { validateCheckbox } from "utils/form";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { ButtonPrimary } from "components";
import { CheckInvitationQuery } from "codegen/generated/graphql";

interface Props {
  signUpWithPassword: (
    email: string,
    password: string,
    name?: string
  ) => Promise<UserCredential>;
  signUpWithProvider: (provider: AuthProviderId) => Promise<UserCredential>;
  /**
   * Will be rendered reduced form if we have invited user profile already
   */
  invitedUser?: CheckInvitationQuery["checkInvitation"]["user"];
}

export const SignUpForm: React.FC<Props> = ({
  signUpWithPassword,
  signUpWithProvider,
  invitedUser,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { md, xs } = useBreakpoint();
  const isInvited = !!invitedUser;
  const [loading, setLoading] = useState(false);

  return (
    <Form
      name={"signUpForm"}
      form={form}
      autoComplete={"off"}
      onFinish={({ name, lastName, email, password }) => {
        setLoading(true);
        signUpWithPassword(
          invitedUser?.email || email,
          password,
          `${name} ${lastName}`
        ).catch((err: Error) =>
          Modal.error({ title: err.message, onOk: () => setLoading(false) })
        );
      }}
    >
      <Row justify={md ? "start" : "center"} gutter={[0, 16]}>
        <Col>
          <Title level={1}>Register</Title>
        </Col>
        {isInvited && (
          <Col span={24}>
            <Row justify={md ? "start" : "center"}>
              <Col>
                <Text type="secondary">Set up your password before log in</Text>
              </Col>
            </Row>
          </Col>
        )}
        <Col span={24}>
          <Row>
            {!isInvited && (
              <>
                <Col span={24}>
                  <Row justify="space-between" gutter={[16, 0]}>
                    <Col span={12}>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Value is required",
                          },
                        ]}
                      >
                        <MaterialInput placeholder="First Name" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: "Value is required",
                          },
                        ]}
                      >
                        <MaterialInput placeholder="Last Name" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Please input your E-mail!",
                      },
                    ]}
                  >
                    <MaterialInput placeholder={"Email Address"} />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col span={24}>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldsValue }) => {
                  const { password } = getFieldsValue();
                  return (
                    <Tooltip
                      visible={!!password && !xs}
                      color="#fff"
                      placement={xs ? "bottom" : "right"}
                      overlayInnerStyle={{
                        boxShadow: "0px 2px 4px rgba(232, 232, 233, 0.5)",
                        width: "270px",
                        padding: "15px",
                        fontSize: "13px",
                      }}
                      title={
                        <PasswordTooltipContent
                          isLengthValid={true}
                          isUppercaseValid={false}
                          isStrengthValid={true}
                        />
                      }
                    >
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                      >
                        <MaterialInput
                          placeholder={"Password"}
                          type="password"
                        />
                      </Form.Item>
                    </Tooltip>
                  );
                }}
              </Form.Item>
            </Col>
            <Row gutter={[0, 25]}>
              <Col span={24}>
                <Form.Item
                  name="repeatPassword"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, repeatedPassword) {
                        if (
                          !repeatedPassword ||
                          repeatedPassword === getFieldValue("password")
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <MaterialInput
                    placeholder={"Repeat Password"}
                    type="password"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  valuePropName="checked"
                  initialValue={false}
                  name="tos"
                  rules={[validateCheckbox("You have to agree with our TOS")]}
                >
                  <Checkbox>
                    <Text>
                      <span>I accept our </span>
                      <Link target="_blank" href={EXTERNAL_LINKS.termsAndConds}>
                        Terms &amp; Conditions
                      </Link>
                      <span>, and </span>
                      <Link target="_blank" href={EXTERNAL_LINKS.privacyPolicy}>
                        {" Privacy Policy"}
                      </Link>
                    </Text>
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={24}>
                <ButtonPrimary loading={loading} htmlType="submit">
                  Sign up
                </ButtonPrimary>
              </Col>
              <Col span={24}>
                <Row justify="center">
                  <Col>
                    <Text type="secondary">
                      or use your information instead
                    </Text>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="space-between" gutter={[10, 10]}>
                  <Col xs={{ span: 24 }} md={{ span: 8 }}>
                    {/*TODO: Replace with custom button*/}
                    <Button
                      block
                      onClick={() => signUpWithProvider("google")}
                      style={{
                        height: "48px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <GoogleSvg />
                    </Button>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 8 }}>
                    {/*TODO: Replace with custom button*/}
                    <Button
                      block
                      onClick={() => signUpWithProvider("microsoft")}
                      style={{
                        height: "48px",

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <OutlookSvg />
                    </Button>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 8 }}>
                    {/*TODO: Replace with custom button*/}
                    <Button
                      block
                      onClick={() => signUpWithProvider("linkedin")}
                      style={{
                        height: "48px",

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <LinkedinSvg />
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="center">
                  <Col>
                    <Text type="secondary">
                      {"Already registered? "}
                      <Link
                        onClick={() => history.push(Routes.LOGIN.path)}
                        underline={true}
                      >
                        {"Log In"}
                      </Link>
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
