import { useState } from "react";
import { getHeaders } from "utils/apollo";

const createDownloadLinkAndDownload = (fileName: string) => (blob: Blob) => {
  const anchor = document.createElement("a");
  anchor.download = fileName;
  anchor.href = window.URL.createObjectURL(blob);
  anchor.click();
};

export const useDownload = (mimeType?: string, options: RequestInit = {}) => {
  const [isInProgress, setIsInProgress] = useState(false);

  const { headers, ...requestInitRest } = options;
  const download = async (fileName: string, url: string) => {
    setIsInProgress(true);

    return fetch(url, {
      headers: {
        ...(await getHeaders()).headers,
        ...(mimeType && { Accept: mimeType }),
        ...headers,
      },
      ...requestInitRest,
    })
      .then((response) => response.blob())
      .then(createDownloadLinkAndDownload(fileName))
      .catch((e) => console.log(e))
      .finally(() => setIsInProgress(false));
  };

  return { isInProgress, download };
};
