import React, { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Col,
  Row,
  Space,
  Menu,
  Dropdown,
  notification,
  Button,
  Tooltip,
} from "antd";
import Text from "antd/es/typography/Text";

import { ButtonTertiary, Icon, Table, DimensionTag } from "components";

import ContentLayout from "layout/ContentLayout";
import { ContentType } from "layout/ContentLayout/ContentLayout";
import { Routes } from "router/routes";

import {
  FeaturesDocument,
  FeaturesQuery,
  useRemoveFeatureMutation,
} from "codegen/generated/graphql";
import { useColumns, useTable } from "hooks/table";
import { generatePath } from "react-router";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { EllipsisOutlined } from "@ant-design/icons";
import theme from "theme";

const onCompleted = () => {
  notification.success({
    type: "success",
    message: `Feature has been deleted.`,
    placement: "bottomLeft",
  });
};

const onError = (err: ApolloError) => {
  notification.error({
    type: "error",
    message: "Failed to delete the feature. Please try again later.",
    placement: "bottomLeft",
  });
  console.warn(err);
};

export const Features: React.FC = () => {
  // fetch features
  const fetch = useLazyQuery(FeaturesDocument);
  const [removeFeature] = useRemoveFeatureMutation({
    // @TODO: refetch() should be deleted and updating cache should be fixed
    // refetchQueries: [{ query: FeatureDocument }],
    onCompleted,
    onError,
  });
  const history = useHistory();

  const handleRemove = (id: string) => () =>
    removeFeature({
      variables: {
        id,
      },
    });

  // TODO - handle loading
  // TODO - handle error

  const { tableProps, data: { features = [] } = {} } = useTable<
    FeaturesQuery["features"]
  >(fetch, {
    key: "features",
  });

  const renderMenu = ({ id }: { id: string }) => (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu>
          <Menu.Item key="edit">
            <Link
              to={generatePath(Routes.FRAMEWORKS_FEATURE_UPDATE.path, {
                id,
              })}
            >
              Edit
            </Link>
          </Menu.Item>

          <Menu.Item key="delete" onClick={handleRemove(id)}>
            Delete
          </Menu.Item>
        </Menu>
      }
    >
      <Button
        type="link"
        icon={<EllipsisOutlined rotate={90} color={theme.colors.blue_100} />}
      />
    </Dropdown>
  );

  const columns = useColumns<FeaturesQuery["features"]>([
    {
      title: "Question",
      dataIndex: "text",
      sorter: true,
      onFilter: (a, b: any) => {
        return b.text === a;
      },
      filterSearch: true,
      filters: features.map((feature: any) => ({
        text: feature.text,
        value: feature.text,
      })),
    },
    {
      title: "Dimension",
      key: "dimension.name",
      align: "right",
      sorter: true,
      fixed: "right",
      render: ({ dimension, accountId }) => (
        <Space>
          {accountId && (
            <Tooltip title="Custom feature">
              <Button
                shape="circle"
                type="link"
                icon={
                  <Icon width="1.15em" height="1.15em" name="custom-feature" />
                }
              />
            </Tooltip>
          )}
          <DimensionTag
            background={dimension?.meta.backgroundColor}
            color={dimension?.meta.textColor}
            label={dimension?.name ?? ""}
          />
        </Space>
      ),
    },
    {
      align: "right",
      fixed: "right",
      key: "more",
      render: renderMenu,
    },
  ]);

  const headerConfig = useMemo(
    () => ({
      title: "Features collection",
      actions: (
        <Space size={10}>
          {/*<ButtonTertiary icon={<Icon name="plus" />} transparent disabled>
            Next Follow Up Question
          </ButtonTertiary>*/}
          <ButtonTertiary
            icon={<Icon name="plus" />}
            onClick={() =>
              history.push(Routes.FRAMEWORKS_FEATURE_CREATE_NEW.path)
            }
            disabled={false} // TODO update
          >
            <Text strong>New Feature</Text>
          </ButtonTertiary>
        </Space>
      ),
    }),
    [history]
  );

  return (
    <ContentLayout
      header={headerConfig}
      typeContent={ContentType.FullWidth}
      sideContent={<></>}
    >
      <Row gutter={[0, 24]}>
        <Text>{`${features?.length ?? 0} Features`}</Text>
        <Col span={24}>
          <div
            style={{
              width: "100%",
            }}
          >
            <Table
              {...tableProps}
              columns={columns}
              size="small"
              rowKey={"id"}
              locale={{
                emptyText: (
                  <Text type="secondary">
                    It seems there are no Members who match your filters
                  </Text>
                ),
              }}
            />
          </div>
        </Col>
      </Row>
    </ContentLayout>
  );
};

export default Features;
