import { VFC } from "react";
import { FeaturePlaceholder } from "utils/helpers";

import * as S from "./placeholder.styles";

export type PlaceholderFieldProps = {
  value?: FeaturePlaceholder | string;
  text?: string | null;
};

export const PlaceholderField: VFC<PlaceholderFieldProps> = ({
  value,
  text,
}) => {
  return (
    <S.Wrap>
      <S.ValueWrap>
        {text} {value}
      </S.ValueWrap>
    </S.Wrap>
  );
};
