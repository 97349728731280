import { Rule, RuleObject } from "rc-field-form/lib/interface";

export const validateCheckbox = (error: string): Rule => ({
  validator: (_: RuleObject, value: boolean, cb: (error?: string) => void) => {
    if (value) return cb();
    else return cb(error);
  },
  transform: (value) => !!value,
  type: "boolean",
});
