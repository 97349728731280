import styled from "styled-components";
import { Space } from "antd";
import DimensionTag from "components/DimensionTag";

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

export const Card = styled(Space)`
  padding: 16px 22px;
  background: #ffffff;
  border: 1px solid #eff1f4;
  box-sizing: border-box;
  border-radius: 3px;
  margin: 8px 0;
`;

export const Tag = styled(DimensionTag)`
  margin: 30px 0 20px;
`;
