import { useReactiveVar } from "@apollo/client";
import { PlanTuple } from "types/plan";
import { createReactiveVar } from "store/utils";
import { updatePlan, updateAmountOfSeats } from "./mutations";

export const selectedPlanVar = createReactiveVar<PlanTuple | null>(
  "selectedPlan",
  null,
  true
);

export const amountOfSeatsVar = createReactiveVar<number>(
  "amountOfSeats",
  5,
  true
);

export const planMutations = {
  updateAmountOfSeats: updateAmountOfSeats(amountOfSeatsVar),
  updatePlan: updatePlan(selectedPlanVar),
};

export const usePlanStore = () => useReactiveVar(selectedPlanVar);
