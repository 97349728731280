import { Select as AntdSelect } from "antd";
import styled from "styled-components";

const { Option: AntOption } = AntdSelect;

export const Select = styled(AntdSelect)`
  & > .ant-select-selector > .ant-select-selection-item {
    font-size: 0.8em;
  }
`;

export const Option = styled(AntOption)`
  /* background-color: blue; */
`;
