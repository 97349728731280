import React, { useEffect } from "react";
import { Col, Row, Typography } from "antd";
import Image from "../Image";
import logo from "assets/icons/lluna-logo-inverted.svg";

const { Title } = Typography;

export const SplashScreen: React.VFC = () => {
  useEffect(() => {
    const words = document.querySelectorAll(
      ".word"
    ) as unknown as HTMLElement[];
    const wordArray: HTMLElement[][] = [];
    let currentWord = 0;

    words[currentWord].style.opacity = "1";
    for (let i = 0; i < words.length; i++) {
      splitLetters(words[i]);
    }

    function changeWord() {
      const cw = wordArray[currentWord];
      const nw =
        currentWord === words.length - 1
          ? wordArray[0]
          : wordArray[currentWord + 1];
      for (let i = 0; i < cw.length; i++) {
        animateLetterOut(cw, i);
      }

      for (let i = 0; i < nw.length; i++) {
        nw[i].className = "letter behind";
        const el = nw[0].parentElement;
        if (el) {
          el.style.opacity = "1";
        }
        animateLetterIn(nw, i);
      }

      currentWord = currentWord === wordArray.length - 1 ? 0 : currentWord + 1;
    }

    function animateLetterOut(cw: HTMLElement[], i: number) {
      setTimeout(function () {
        cw[i].className = "letter out";
      }, i * 80);
    }

    function animateLetterIn(nw: HTMLElement[], i: number) {
      setTimeout(function () {
        nw[i].className = "letter in";
      }, 340 + i * 80);
    }

    function splitLetters(word: HTMLElement) {
      const content = word.innerHTML;
      word.innerHTML = "";
      const letters = [];
      for (let i = 0; i < content.length; i++) {
        const letter = document.createElement("span");
        letter.className = "letter";
        letter.innerHTML = content.charAt(i);
        word.appendChild(letter);
        letters.push(letter);
      }

      wordArray.push(letters);
    }

    changeWord();
    const interval = setInterval(changeWord, 4000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="splashscreen">
      <Row justify="center">
        <Col>
          <Image src={logo} style={{ marginTop: "60px" }} />
        </Col>
        <Col span={24}>
          <Title style={{ color: "#fff", textAlign: "center" }}>
            {`Work Designed${" "}`}
            <span className="text">
              <span style={{ paddingRight: "160px" }}>{`for${" "}`}</span>
              <span className="word">life.</span>
              <span className="word">humans.</span>
              <span className="word">you.</span>
            </span>
          </Title>
        </Col>
      </Row>
    </div>
  );
};
