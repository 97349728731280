import theme from "theme";

// TODO: types
export const pieChartPlugins = (total: number): any[] => [
  // plugin for centering the total number into middle of the circle
  {
    id: "pieChart",
    beforeDraw: (chart: any) => {
      const width = chart.chartArea.width,
        height = chart.chartArea.height,
        ctx = chart.ctx;

      ctx.restore();
      ctx.textBaseline = "middle";
      ctx.lineWidth = 1;
      const totalText = "Total",
        totalNumber = total,
        numberX = Math.round((width - ctx.measureText(totalNumber).width) / 2),
        numberY = height / 2 + 15,
        textY = numberY - 25,
        textX = Math.round((width - ctx.measureText(totalText).width) / 2) + 13;

      // Needs to be separated due to different styles
      // ---------------------------------------------
      // "Total" text style
      ctx.font = "1.1em sans-serif";
      ctx.fillStyle = theme.colors.grey_70;
      ctx.fillText(totalText, textX, textY);
      // Total number style
      ctx.font = "bold 1.8em sans-serif";
      ctx.fillStyle = theme.colors.blue_90;
      ctx.fillText(totalNumber, numberX, numberY);
      ctx.save();
      //chart.update();
    },
  },
];

export const lineChartPlugins = (): any[] => [
  {
    id: "lineChart",
    beforeDraw: (chart: any) => {
      // chart.canvas.parentNode.style.height = "20px";
      // const ctx = chart.ctx;
      // ctx.restore();
      // ctx.height = "500px";
    },
  },
];

const hslToHex = (...args: number[]) => {
  const [h, s] = args;
  let l = args[2];
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

const createLabelColorsStepGenerator = () => {
  const hslColors: HslColorsType[] = [
    { initHue: 233, initSaturation: 97, initLightness: 40 },
    { initHue: 339, initSaturation: 63, initLightness: 45 },
    { initHue: 181, initSaturation: 69, initLightness: 30 },
    { initHue: 233, initSaturation: 85, initLightness: 19 },
    { initHue: 34, initSaturation: 97, initLightness: 34 },
    { initHue: 180, initSaturation: 68, initLightness: 16 },
    { initHue: 340, initSaturation: 100, initLightness: 25 },
  ];

  const cache = new Map(
    hslColors.map((c, i) => [i, hslToHex(...Object.values(c))])
  );

  return (steps: number): string[] => {
    return Array.from({ length: steps }).map((_, i) => {
      const baseColor = hslColors[i % hslColors.length];
      const lightnessCoeficient = Math.ceil((i + 1) / hslColors.length);
      if (!cache.get(i))
        cache.set(
          i,
          hslToHex(
            ...Object.values({
              ...baseColor,
              initLightness:
                baseColor.initLightness * 1.25 ** lightnessCoeficient,
            })
          )
        );
      return cache.get(i) as string;
    });
  };
};

type HslColorsType = {
  initHue: number;
  initSaturation: number;
  initLightness: number;
};

export const labelColors = createLabelColorsStepGenerator();
