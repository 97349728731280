import styled from "styled-components";
import { Menu } from "antd";

export const Wrap = styled.div`
  width: 99%;
  margin: 0 auto;
`;

export const SubHeader = styled.header`
  width: 100%;
  border-bottom: 1px solid #eff1f4;
  margin: 0 0 1rem 1rem;
  padding: 0 0 0.5rem 0;
  & > h3 {
    color: ${({ theme }) => theme.colors.blue_100};
  }
`;

export const LayoutSubmenu = styled(Menu)`
  list-style: none;
  &.ant-menu > div > li.ant-menu-item-selected {
    background-color: transparent;
  }
  &.ant-menu > div > li.ant-menu-item-selected > span > a {
    color: ${({ theme }) => theme.colors.blue_100};
  }
  &.ant-menu > div > li.ant-menu-item-active > span > a {
    color: ${({ theme }) => theme.colors.blue_100};
  }
  &.ant-menu > div > li.ant-menu-item > span > a {
    color: ${({ theme }) => theme.colors.grey_70};
  }
  border-radius: ${({ theme }) => theme.radius.commonRadius};
  height: 83.5vh;
  padding: 3rem 1rem;
  width: fit-content;
  background: radial-gradient(
    96.28% 274.83% at 6.01% 5.14%,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
`;
export const LayoutSubmenuItem = styled(Menu.Item)<{ $active: boolean }>`
  &.ant-menu-item > span > a {
    color: ${({ $active, theme }) =>
      $active ? theme.colors.blue_100 : theme.colors.grey_70} !important;
  }
  &.ant-menu-item-selected {
    background: transparent !important;
  }
`;

export const MenuUnderline = styled.div`
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #eff1f4;
`;
