import { ChangeEvent, FC, useState } from "react";
import * as S from "./input.styles";
import { InputProps } from "antd/lib/input/Input";
import { ImgSvg } from "components";
import invisible from "assets/icons/invisible.svg";
import visible from "assets/icons/visible.svg";
import { useField } from "formik";

export interface UIInputProps extends InputProps {
  label?: string;
  name: string;
  withIcon?: boolean;
  margin?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}
const Input: FC<UIInputProps> = (props) => {
  const { type = "text", label, withIcon, name } = props;
  const [inputType, setInputType] = useState(type);

  return (
    <S.Label htmlFor={name}>
      {label}
      {withIcon && (
        <S.IconWrap
          onClick={() => {
            return setInputType(inputType === "text" ? "password" : "text");
          }}
        >
          <ImgSvg
            alt="icon"
            src={inputType === "password" ? invisible : visible}
          />
        </S.IconWrap>
      )}
      <S.UIInput {...props} type={inputType}></S.UIInput>
    </S.Label>
  );
};

export const FormikInput: FC<UIInputProps> = ({ name, ...props }) => {
  const [{ value, onChange }] = useField(name);

  return <Input name={name} value={value} onChange={onChange} {...props} />;
};

export default Input;
