import styled from "styled-components";
import { Progress } from "antd";

export const Wrap = styled.div`
  width: 100%;
  padding: 1rem 1rem 1.5rem 1rem;
  background: white;
  border-radius: 0 0 20px 20px;
`;

export const UIProgress = styled(Progress)`
  &.ant-progress > span.ant-progress-text {
    display: none;
  }
  &.ant-progress > div.ant-progress-outer {
    width: 100%;
    padding: 0;
  }
  &.ant-progress > div > div > div.ant-progress-bg {
    background-image: ${({ theme }) =>
      `linear-gradient(to bottom right, ${theme.colors.gold_60}, ${theme.colors.gold_40})`};
    height: 5px !important;
  }
  &.ant-progress > div > div.ant-progress-inner {
    // + "30" is opacity of 0.3 appended to main gold color - https://www.digitalocean.com/community/tutorials/css-hex-code-colors-alpha-values
    background-color: ${({ theme }) => theme.colors.gold_60 + "30"};
  }
`;

export const UITitle = styled.span`
  color: ${({ theme }) => theme.colors.grey_70};
  font-weight: lighter;
  margin: 0;
`;
export const Seats = styled.p`
  font-size: 15px !important;
  color: ${({ theme }) => theme.colors.blue_100};
  margin: 0;
  font-size: 0.9em;
  font-weight: normal;
`;
export const Bagde = styled.span`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue_100};
  padding: 0.08rem 0.5rem 0 0.5rem;
  border-radius: ${({ theme }) => theme.radius.commonRadius};
  float: right;
  font-size: 0.9em;
`;
