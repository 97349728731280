import { createReactiveVar } from "../utils";
import {
  setSelectedRole,
  reset,
  setInvitation,
  onAuthChange,
  refreshUser,
  updateProfileImage,
} from "./mutations";
import { ApplicationState, InvitationState } from "./types";
import { UserRoleConfig } from "./constants";
import { useReactiveVar } from "@apollo/client";

export const selectedRoleVar = createReactiveVar<UserRoleConfig | null>(
  "selectedRole",
  null
);

export const initialApplicationState: ApplicationState = {
  isLoading: true,
  user: null,
  firebaseUser: null,
};

export const applicationStore = createReactiveVar<ApplicationState>(
  "applicationStore",
  initialApplicationState
);

export const invitationVar = createReactiveVar<InvitationState>(
  "invitation",
  null
);

export const appMutations = {
  setSelectedRole: setSelectedRole(selectedRoleVar),
  setInvitation: setInvitation(invitationVar),
  updateProfileImage: updateProfileImage(applicationStore),
  onAuthChange: onAuthChange(applicationStore),
  refreshUser: refreshUser(applicationStore),
  reset: reset(applicationStore),
};

export const useApplicationStore = () => useReactiveVar(applicationStore);
export const useInvitationStore = () => useReactiveVar(invitationVar);
