import { VFC } from "react";
import {
  OpenTextItem,
  OpenTextName,
  OpenTextAnswer,
  OpenTextWrap,
  Header,
  HeaderItem,
  OpenTextNamePopup,
  HeaderItemPopup,
} from "./styles";
import { Avatar } from "components";
import { Space } from "antd";
import { makeUseModal } from "lib/useModal";
export type Responder = {
  fullName: string;
  avatar: string;
};

export type ResponsesWithUsers = {
  answer: string;
  responders: Responder[];
  placeholderValue: string[];
  placeholderType: string[];
  placeholderTimeRange: string[];
};

type Props = {
  tableData: ResponsesWithUsers[];
};

export const OpenTextTable: VFC<Props> = ({ tableData }) => {
  return (
    <div style={{ maxHeight: "fit-content" }}>
      <div>Response count: {tableData.length}</div>
      <br></br>
      <OpenTextWrap>
        <Header>
          {/* <HeaderItem>Name</HeaderItem> */}
          {/* <HeaderItem>Answer</HeaderItem> */}
        </Header>
        {tableData.flatMap((answer) => {
          // @TODO temp solution, change to unique id
          return answer.responders.map((resp, respIdx) => {
            return (
              <OpenTextItem key={resp.fullName}>
                <OpenTextName>
                  <Space size={5} direction="horizontal">
                    <Avatar src={resp.avatar} /> <span>{resp.fullName}</span>
                  </Space>
                </OpenTextName>
                <OpenTextAnswer>{answer.answer}</OpenTextAnswer>
              </OpenTextItem>
            );
          });
        })}
      </OpenTextWrap>
    </div>
  );
};

export const OpenTextPopup: VFC<Props> = ({ tableData }) => {
  return (
    <div style={{ maxHeight: "fit-content" }}>
      <OpenTextWrap>
        <Header>
          <HeaderItemPopup>
            {tableData[0].placeholderValue} {tableData[0].answer}
          </HeaderItemPopup>
        </Header>
        {tableData.flatMap((answer) => {
          // @TODO temp solution, change to unique id
          return answer.responders.map((resp, respIdx) => {
            return (
              <OpenTextItem key={resp.fullName}>
                <OpenTextNamePopup>
                  <Space size={5} direction="horizontal">
                    <Avatar src={resp.avatar} /> <span>{resp.fullName}</span>
                  </Space>
                </OpenTextNamePopup>
              </OpenTextItem>
            );
          });
        })}
      </OpenTextWrap>
    </div>
  );
};

export const useOpenTextTableModal = makeUseModal(OpenTextPopup);
