import React from "react";
import { ImgSvg } from "../ImgSvg";
import logoSrc from "assets/icons/lluna-logo.svg";
import * as S from "./styles";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

type Props = {
  children: React.ReactNode;
  decorationElement?: React.ReactNode;
  footerDecorationElement?: React.ReactNode;
};

export const BackgroundContainer: React.FC<Props> = ({
  children,
  decorationElement,
  footerDecorationElement,
}) => {
  const { xs } = useBreakpoint();
  return (
    <S.Container>
      <ImgSvg
        src={logoSrc}
        alt="luna-logo"
        style={{
          position: "absolute",
          top: "33px",
          left: xs ? "50%" : "37px",
          transform: xs ? "translateX(-50%)" : undefined,
        }}
      />
      {decorationElement}
      <S.WrapRelative>
        <div style={{ zIndex: 1, position: "relative" }}>{children}</div>
        {footerDecorationElement}
      </S.WrapRelative>
    </S.Container>
  );
};
