import styled from "styled-components";
import { Typography } from "antd";

const { Text: AText } = Typography;

export const Text = styled(AText).attrs<{ alt?: boolean }>(
  ({ alt, theme }) => ({
    color: alt ? theme.colors.grey : theme.colors.blue_100,
  })
)<{ alt?: boolean }>`
  &.ant-typography {
    color: ${(props: any) => props.color};
    font-size: 15px;
    line-height: 21.5px;
  }
`;
