import React from "react";
import { usePlanStore, planMutations } from "store/plan";

import skyBoySvg from "assets/images/signUp.svg";
import {
  BackgroundContainer,
  ContainerWithImage,
  DataWrapper,
  SignUpForm,
} from "components";

import { useAuthContext } from "utils/context";
import { signUp, signInWithProvider } from "utils/firebase";
import { InvitationStatus } from "codegen/generated/graphql";
import { Routes } from "router/routes";
import { Redirect } from "react-router-dom";

const checkError = "Can't verify the invitation";
const checkNote = "Please try again later";
// eslint-disable-next-line no-restricted-globals
const checkRetry = () => location.reload();

const InvitationErrors = {
  [InvitationStatus.Active]: undefined,
  [InvitationStatus.Used]: "You have already registered",
  [InvitationStatus.Expired]: "Expired invitation",
  [InvitationStatus.Invalid]: "Invalid invitation",
};

const InvitationNotes = {
  [InvitationStatus.Active]: undefined,
  [InvitationStatus.Used]: "Please log in",
  [InvitationStatus.Expired]: "Please contact Your manager",
  [InvitationStatus.Invalid]: "Please contact Your manager",
};

export const SignUp: React.FC = () => {
  const plan = usePlanStore();
  const { invitation } = useAuthContext();
  const { loading, error, status } = invitation || {};

  if (!plan && !invitation) {
    return <Redirect to={Routes.SUBSCRIBE.path} />;
  }

  return (
    <BackgroundContainer>
      <ContainerWithImage image={{ src: skyBoySvg, alt: "sky-boy" }}>
        <DataWrapper
          loading={loading}
          loadingText="Verifying the invitation..."
          error={error ? checkError : status && InvitationErrors[status]}
          errorNote={error ? checkNote : status && InvitationNotes[status]}
          next={error ? checkRetry : Routes.LOGIN.path}
          nextText={error ? "Retry" : "Log in"}
        >
          <SignUpForm
            signUpWithPassword={signUp}
            signUpWithProvider={signInWithProvider}
            invitedUser={invitation?.user}
          />
        </DataWrapper>
      </ContainerWithImage>
    </BackgroundContainer>
  );
};

export default SignUp;
